import { Tabs, Divider } from 'antd';
import Icon,{ UnorderedListOutlined, CalendarOutlined } from '@ant-design/icons';
import constants from '../../constants/constants';
import DistributionSVG from '../../assets/distribution-rules.svg';
import CheckSVG from '../../assets/priority-rules-icon.svg';
import PriorityLogs from './PriorityLogs';
import DistributionRulesLogs from './DistributionRules';
const { TabPane } = Tabs;

const ActivityLogsTabs = () => {

	return (
		<div>
      {/* <div className='uppercase font-bold text-xl'>Application Logs</div>
			<Divider className='my-3'/> */}
			<Tabs defaultActiveKey="1" >
      <TabPane
        tab={ <span className='uppercase'><Icon component={() => (<img src={CheckSVG} alt='icon' />)} />{constants.ActivityLogsTab1}</span> }
        key={1}>
				<PriorityLogs/>
      </TabPane>
			<TabPane
        tab={ <span className='uppercase'><Icon component={() => (<img src={DistributionSVG} alt='icon' />)} />{constants.ActivityLogsTab2}</span> }
        key={2}
      >
				<DistributionRulesLogs/>
      </TabPane>
			</Tabs>
		</div>
	);
};

export default ActivityLogsTabs;