import { Tabs, Divider } from 'antd';
import { CalendarOutlined, ProfileOutlined } from '@ant-design/icons';
import YourJobsAll from '../YourJobsAll';
import MultiJobAmin from './MultiJobAdmin';
import MultiYourJobsPerformance from './MultiYourJobsPerformance';
//import YourJobsPerformance from './YourJobPerformance';
const { TabPane } = Tabs;

const MultiYourJobsContainer = (props) => {
 
	//Render
	return (
		<div>
			<Tabs defaultActiveKey="1">
			<TabPane
        tab={ <span className='uppercase'><CalendarOutlined className='text-xl' />Your Jobs</span> }
        key={1}
      >
        <MultiJobAmin/>
      </TabPane>
			<TabPane
        tab={ <span className='uppercase'><ProfileOutlined className='text-xl' />Performance</span> }
        key={2}
      >
        <MultiYourJobsPerformance/>
      </TabPane>
			</Tabs>
		</div>
	);
};

export default MultiYourJobsContainer;