import { Space, DatePicker, Select, Button, Input, Table, Popconfirm, Tooltip, Modal, Form, Switch, message } from 'antd';
import { EditOutlined, FileExcelOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import moment from 'moment';
import WebLinks from '../../api/WebLinks';
import constants from '../../constants/constants';
import APIUtils from '../../api/APIUtils';
import dayjs from 'dayjs';
import { GetCustomPriorityLevelIcons, GetPriorityLevelColors, GetPriorityLevelIcons } from '../../globalFunctions/GlobalFunctions';

const { RangePicker } = DatePicker;
const { Search, TextArea } = Input;
const { Option } = Select

const ClickSpendPerJob = (props) => {
  //Variable declaration
  const [dataSource, setDataSource] = useState([]);
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [rangePickerValue, setRangePickerValue] = useState([]);
  const [count, setCount] = useState(0);
  const [filterPriorityLevel, setFilterPriorityLevel] = useState(null);
  const [searchJobTitleRef, setSearchJobTitleRef] = useState(null);
  const [searchLocationPostcode, setSearchLocationPostcode] = useState(null);
  const [priorityColorCode,setPriorityColorCode]=useState([]);
  const [priorityCheck,setPriorityCheck]=useState("f");
  //Function on page load
  useEffect(() => {
    getBuyerPrioritylist();
    let fromDate = props && props.dateSelectedValue ? props.dateSelectedValue[0] : dayjs().format(constants.MonthFormat);
    let toDate = props && props.dateSelectedValue ? props.dateSelectedValue[1] : dayjs().format(constants.MonthFormat);
    if (props && props.dateSelectedValue) {
      form.setFieldsValue({
        rangePicker: [dayjs(props.dateSelectedValue[0]), dayjs(props.dateSelectedValue[1])],
      })
    } else {
      form.setFieldsValue({
        rangePicker: [dayjs(), dayjs()],
      })
    }
    setRangePickerValue([
      fromDate,
      toDate
    ]);
    fetchSpendPerJobsList(fromDate, toDate, null);
  }, []);

  //Function to get priority list
  const getBuyerPrioritylist=async()=>{
    let url = WebLinks.GetBuyerPriorityFilters + Cookies.get(constants.COOKIE_SELECTED_BUYER_ID);    
    const response = await APIUtils.apiCall('GET', url);
    if (!response.isCatch && response.resultObj) {
      let temppriorityColorCode=[];
      setPriorityCheck(response?.resultObj?.data?.length>5?"t":"f");
      response?.resultObj?.data.forEach(element => {
        let priorityObj={
          value:element.priorityLevel,
          colorValue:response?.resultObj?.data?.length>5?GetCustomPriorityLevelIcons(element.priorityLevel):GetPriorityLevelColors(element.priorityLevel),
          customName:element.customName,
        }
        temppriorityColorCode.push(priorityObj);
        setPriorityColorCode(temppriorityColorCode);
      });
    } else {
			setPriorityColorCode([]);
      message.error(response?.resultObj?.message);
    }
  };

  // Table columns
  const yourJobsColumns = [
    {
      title: 'Active',
      key: 'active',
      dataIndex: 'active',
      align: 'center',
      sorter: (a, b) => (a.active === b.active ? 0 : a.active ? 1 : -1),
      render: (_, record) =>
        record.active === true ? (
          <Button
            icon={<CheckOutlined className="text-xl text-lime-500" />}
            type="text"
            onClick={() => changeActive(record.id, record.active)}
          />
        ) : (
          <Button
            icon={<CloseOutlined className="text-xl text-lime-500" style={{ color: "red" }} />}
            type="text"
            onClick={() => changeActive(record.id, record.active)}
          />
        ),
    },
    {
      title: 'Reference',
      dataIndex: 'reference',
      key: 'reference',
      sorter: (a, b) => {
        const referenceA = parseInt(a.reference.match(/\d+/)[0], 10);
        const referenceB = parseInt(b.reference.match(/\d+/)[0], 10);

        return referenceA - referenceB;
      },
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      sorter: (a, b) => a.priority - b.priority,
      align: "center",
      render: (text,element) => <Space>
					{/* {GetPriorityLevelIcons(text)} */}
          <div className={priorityCheck==="t"?GetCustomPriorityLevelIcons(element.priority):GetPriorityLevelColors(element.priority)}></div>
				</Space>
    },
    {
      title: 'Job Title',
      dataIndex: 'title',
      key: 'jobTitle',
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: 'Company',
      dataIndex: 'companyName',
      key: 'companyName',
      sorter: (a, b) => a.companyName.localeCompare(b.companyName),
    },
    {
      title: 'Location',
      dataIndex: 'place',
      key: 'place',
      sorter: (a, b) => a.place.localeCompare(b.place),
    },
    {
      title: 'Postcode',
      dataIndex: 'postcode',
      key: 'postcode',
      sorter: (a, b) => {
        if (a.postcode === '-') return -1; // '-' comes first
        if (b.postcode === '-') return 1; // '-' comes first
        return a.postcode.localeCompare(b.postcode);
      },
    },
    {
      title: 'Apps',
      dataIndex: 'applicationCount',
      key: 'applicationCount',
      sorter: (a, b) => a.applicationCount - b.applicationCount,
    },
    {
      title: '/Day',
      dataIndex: 'applicationCount',
      key: 'applicationCount',
      render: (_, record) => {
        const daysValue = GetNoofDays(record.applicationCount, record.posted);
        const formattedValue = Number.isInteger(daysValue) ? daysValue.toFixed(0) : daysValue.toFixed(2);
        return (
          <div>
            {formattedValue}
          </div>
        );
      },
      sorter: (a, b) => {
        const aValue = GetNoofDays(a.applicationCount, a.posted);
        const bValue = GetNoofDays(b.applicationCount, b.posted);

        return aValue - bValue; // You can adjust the comparison logic as needed
      },

    },
    {
      title: 'Spend',
      dataIndex: 'spend',
      key: 'spend',
      sorter: (a, b) => a.spend - b.spend,
    },
    {
      title: 'CPA',
      dataIndex: 'cpa',
      key: 'cpa',
      sorter: (a, b) => a.cpa - b.cpa,
    },
  ];

  //Fetch the jobs upload history for the last 30 days 
  const fetchSpendPerJobsList = async (fromDate, toDate, priorityLevel = null) => {
    setIsLoading(true);
    let url = WebLinks.GetClickSpendPerJob + Cookies.get(constants.COOKIE_SELECTED_BUYER_ID) + "&fromDate=" + fromDate + "&toDate=" + toDate;
    if (priorityLevel && priorityLevel !== "-1") {
      url += "&priorityLevel=" + priorityLevel;
    }
    const response = await APIUtils.apiCall('GET', url);
    if (!response.isCatch && response.resultObj) {
      setDataSource(response.resultObj.data);
      setFilteredDataSource(response.resultObj.data)
      setCount(response.resultObj.getCount);
    } else {
      message.error(response.resultObj?.message);
    }
    setIsLoading(false);
  };

  const GetNoofDays = (apps, createdOn) => {
    if (apps === 0) {
      return 0;
    }
    // Check if `createdOn` is a valid date
    const creationDate = new Date(createdOn);
    if (isNaN(creationDate)) {
      return 0; // Handle invalid date gracefully
    }
    const currentDate = new Date();
    const difference = currentDate - creationDate;
    if (difference === 0) {
      return apps; // Avoid division by zero
    }
    const daysDifference = Math.floor(difference / (1000 * 60 * 60 * 24)) + 1;
    // Ensure `apps` is a valid number before division
    if (isNaN(apps)) {
      return 0; // Handle invalid `apps` value gracefully
    }
    return apps / daysDifference;
  };

  //Function to change the Active button to cross or tick.
  const changeActive = async (rowKey, previousValue) => {
    let tbldata = [...dataSource];
    let index = tbldata.findIndex((row) => row.id === rowKey);
    if (index > -1) {
      if (tbldata[index].active === true) {
        tbldata[index].active = false;
      } else {
        tbldata[index].active = true;
      }
      setFilteredDataSource(tbldata);
    }
    //Api call to update in DB
    let activePayload = null;
    activePayload = {
      jobId: rowKey,
      active: !previousValue,
    }
    const activeResponse = await APIUtils.apiCall('PUT', WebLinks.PutActiveJob, JSON.stringify(activePayload));
    if (activeResponse.isCatch) {
      message.error(message.resultObj.message);
    }
  };

  const downloadFile = () => {
    setIsLoading(true)
    //api/Report/SpendPerJob?buyerId=555&fromDate=Dec-2023&toDate=Dec-2023
    const buyerId = Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)
    let url = WebLinks.DownloadClickSpendPerJobFile + buyerId + '&fromDate=' + rangePickerValue[0] + '&toDate=' + rangePickerValue[1];
    if (filterPriorityLevel && filterPriorityLevel !== "-1") {
      url += "&priorityLevel=" + filterPriorityLevel;
    }
    const accessToken = Cookies.get(constants.COOKIE_TOKEN)
    fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      }
    })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', "SpendPerJobReport.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setIsLoading(false)
        message.success('File downloaded successfully');
      })
      .catch(error => {
        setIsLoading(false)
        message.error('Error downloading file');
      });
  }

  //Function runs when range picker value is changed
  const RangePickerChanged = (_, dateString) => {
    props.changeDate(dateString);
    setRangePickerValue(dateString);
    fetchSpendPerJobsList(dateString[0], dateString[1], filterPriorityLevel);
  };

  // Function to handle search
  const handleSearch = (value, isJobTitleRef) => {
    if (value) {
      const searchTermLower = value.toLowerCase();
      const keyToSearch = isJobTitleRef ? ['title', 'reference'] : ['place', 'postcode'];

      const results = filteredDataSource.filter((item) =>
        keyToSearch.some((key) => item[key].toString().toLowerCase().includes(searchTermLower))
      );

      setFilteredDataSource(results);
      setCount(results.length);
    }
    else {
      setFilteredDataSource(dataSource)
      setCount(dataSource.length)
    }
  };

  const onClear = () => {
    setFilteredDataSource(dataSource)
    setSearchJobTitleRef(null)
    setSearchLocationPostcode(null)
  }

  //Function on priority filter change
  const filterPriority = (value) => {
    if(value!==-1){
    setFilterPriorityLevel(value)
    }else{
      setFilterPriorityLevel(null);
    }
    fetchSpendPerJobsList(rangePickerValue[0], rangePickerValue[1], value);
  }
  return (
    <div>
      <div className="flex flex-wrap justify-between items-center gap-1 mb-3">
        <Space wrap >
          <Form form={form} className='!w-full flex justify-between items-center mb-3'>
            <Form.Item name="rangePicker" className='mb-0'>
              <RangePicker className="w-[65vw] md:w-60" picker="month" placeholder={["From", "To"]} onChange={RangePickerChanged} format={constants.MonthFormat} />
            </Form.Item>
          </Form>
        </Space>
        <Space>
          <Search
            placeholder="Job Title / Ref"
            allowClear={false}
            onSearch={(e) => handleSearch(e, true)}
            enterButton
            value={searchJobTitleRef}
            onChange={(e) => setSearchJobTitleRef(e.target.value)}
            className="w-full md:w-60"
          />
          <Search
            placeholder="Location / Postcode"
            allowClear={false}
            onSearch={(e) => handleSearch(e, false)}
            enterButton
            value={searchLocationPostcode}
            onChange={(e) => setSearchLocationPostcode(e.target.value)}
            className="w-full md:w-60"
          />
          <Button onClick={() => onClear()}>Clear Search</Button>
          <Select
           onChange={filterPriority}
            defaultValue={"-1"}
            placeholder="Please select"
            className="w-[65vw] md:w-60"
            // options={[
            //   {
            //     value: "-1",
            //     label: (
            //       <Space>
            //         Priority Filter - Show All
            //       </Space>
            //     ),
            //   },
            //   {
            //     value: "0",
            //     label: (
            //       <Space>
            //         <div className="w-3.5 h-3.5 border rounded-full border-black bg-white"></div>
            //         Organic
            //       </Space>
            //     ),
            //   },
            //   {
            //     value: "1",
            //     label: (
            //       <Space>
            //         <div className="w-3.5 h-3.5 border rounded-full border-black bg-lime-400"></div>
            //         Standard
            //       </Space>
            //     ),
            //   },
            //   {
            //     value: "2",
            //     label: (
            //       <Space>
            //         <div className="w-3.5 h-3.5 border rounded-full border-black bg-orange-400"></div>
            //         Extra
            //       </Space>
            //     ),
            //   },
            //   {
            //     value: "3",
            //     label: (
            //       <Space>
            //         <div className="w-3.5 h-3.5 border rounded-full border-black bg-red-600"></div>
            //         Max (inc. Social Media)
            //       </Space>
            //     ),
            //   },
            //   {
            //     value: "4",
            //     label: (
            //       <Space>
            //         <div className="w-3.5 h-3.5 border rounded-full border-black bg-purple-600"></div>
            //         Critical (inc. Social Media)
            //       </Space>
            //     ),
            //   },
            // ]}
          >
            <Option value="-1">
              <Space>
                Priority Filter - Show All
              </Space>
            </Option>
            {priorityColorCode?.map((item=>{
                  return(
                <Option value={item.value}>
                    <Space>
                      <div className={item.colorValue}></div>
                      {item.customName}
                    </Space>             
                </Option>
            )
            }))}
          </Select>
          <Tooltip placement='right' title="Download as Excel">
            <Button onClick={() => downloadFile()} className="bg-green-800 text-white hover:!text-white hover:!border-green-500" icon={<FileExcelOutlined />}> {constants.Download}</Button>
          </Tooltip>
        </Space>
      </div>
      <div className="overflow-x-auto">
        <Table
          loading={isLoading}
          size='small'
          pagination={{
            showSizeChanger: true,
            pageSizeOptions: ["200", "500", "1000", "2000"],
            defaultPageSize: 200,
          }}
          columns={yourJobsColumns}
          dataSource={filteredDataSource}
          footer={() => `Showing 1 to ${Math.min(200, count)} of ${count} entries`}
        />
      </div>
    </div>
  );
};

export default ClickSpendPerJob;