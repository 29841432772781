import { Image, Space, Table, message } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import constants from '../../constants/constants';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import WebLinks from '../../api/WebLinks';
import APIUtils from '../../api/APIUtils';
import { GetCustomPriorityLevelIcons, GetPriorityLevelColors } from '../../globalFunctions/GlobalFunctions';

const{Column}=Table;

const PriorityLevelRules = (props) => {
	//Variable Declaration
	const [priorityList,setPriorityList]=useState([]);
	const [suppliers,setSuppliers]=useState([]);
	const [pageNumber,setPageNumber]=useState(1);
  const [sortDesc,setSortDesc]=useState(true);
  const [total,setTotal]=useState(0);
  const [loading,setloading]=useState(false);

	//On load
	useEffect(()=>{
		fetchData();
		setSuppliers(props.suppliers);
	},[props]);

	//Functions 
	//Function to fetch priority data
	const fetchData = async () =>{
		setloading(true);
		const occupations = await APIUtils.apiCall("GET",WebLinks.GetDistributionPriorityRules+"buyerId="+Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)+
		"&limit="+constants.pageSize+"&pageNo="+pageNumber+"&sortDesc="+sortDesc,null);
		if (occupations.isCatch) {
			message.error(constants.ERROR_MESSAGE);
		} else {
			if(occupations.resultObj?.statusCode==="2000") {
				setPriorityList(occupations.resultObj.data)
				setTotal(occupations.resultObj.data?.getCount | 0);
			} else {
				message.success(occupations.resultObj?.message | constants.ERROR_MESSAGE)
			}
		}
		setloading(false);
	}

	//Function to insert occupation block
	const InsertPriorityBlock = async (buyerId,supplierId,priorityId, priorityIndex,supplierIndex) =>{
		let insertPayload=null;
		insertPayload={
			buyerId:buyerId,
			SupplierId:supplierId,
			priorityId:priorityId,
			UpdatedByBuyerId:Cookies.get(constants.COOKIE_LOGGED_BUYER_ID)
		}
		const insertPriorityResponse = await APIUtils.apiCall('POST',WebLinks.InsertPriorityBlock,JSON.stringify(insertPayload));
    if(!insertPriorityResponse.isCatch && insertPriorityResponse.resultObj.statusCode==="2000"){
			let temppriorityData=[...priorityList]
			temppriorityData[priorityIndex].suppliers[supplierIndex].isActive=false;
			setPriorityList(temppriorityData);
			message.success(insertPriorityResponse.resultObj.message)
    }else{
			message.error(insertPriorityResponse.resultObj.message)
    }
	};

	//Function to insert occupation block
	const DeletePriorityBlock = async (buyerId,supplierId,priorityId,priorityIndex,supplierIndex) =>{
		let deletePayload=null;
		deletePayload={
			buyerId:buyerId,
			SupplierId:supplierId,
			priorityId:priorityId,
			UpdatedByBuyerId:Cookies.get(constants.COOKIE_LOGGED_BUYER_ID)
		}
		const deletePriorityResponse = await APIUtils.apiCall('DELETE',WebLinks.DeletePriorityBlock,JSON.stringify(deletePayload));
    if(!deletePriorityResponse.isCatch && deletePriorityResponse.resultObj.statusCode==="2000"){
			let tempPriorityData=[...priorityList]
			tempPriorityData[priorityIndex].suppliers[supplierIndex].isActive=true;
			setPriorityList(tempPriorityData);
			message.success(deletePriorityResponse.resultObj.message)
    }else{
			message.error(deletePriorityResponse.resultObj.message)
    }
	};

	// Table start
	const onChangeTable = (pagination, filters, sorter, extra)=>{
		setSortDesc(sorter.order==="ascend"?false:true);
		fetchData();
	  }
	// Table end
	
	return (
		<div>
			<div className='overflow-x-auto'>
				<Table dataSource={priorityList} 
					loading={loading}
					sortDirections={['ascend', 'descend','ascend']}
					pagination={{
						onChange: (page) => {
							setPageNumber(page);
						},
						defaultPageSize: constants.pageSize,
						pageSizeOptions: ["25", "50", "100"],
						total:total,
					}}
					scroll={{ x: 1000, y: 590 }} 
					onChange={onChangeTable}>
					<Column title="Priority Level" width="150px" dataIndex="priorityName" key="priorityName" sorter={(a, b) =>( a.priorityName - b.priorityName)} fixed={window.innerWidth > 1024 ? true : false} render={(text,element)=>(<Space><div className={priorityList.length>5?GetCustomPriorityLevelIcons(element.priorityLevel):GetPriorityLevelColors(element.priorityLevel)}></div> {text}</Space>)}/>
					<Column title="Job Count" width="80px" dataIndex="priorityCount" key="priorityCount" align='center' fixed={window.innerWidth > 1024 ? true : false}/>
					{suppliers?.map((item,index)=>{ 
						return(<Column width="80px" title={item.logoURL && item.logoURL!==""?<Image src={item.logoURL} preview={false} width={70}/>:item.supplierName} dataIndex={item.supplierName} key={item.supplierName} align='center'
						render={(_,data,pIndex) => {
							let supplierIndex = data.suppliers.findIndex((dataItem)=>dataItem.supplierName === item.supplierName);
							let supplierItem = data.suppliers[supplierIndex]
							return(
								<>
									{supplierItem.isActive?
										<CheckOutlined className='text-xl text-lime-500' onClick={()=>{InsertPriorityBlock(data.buyerId,supplierItem.supplierId,data.priorityLevel,pIndex,supplierIndex)}} />:
										<CloseOutlined className='text-xl text-red-500' onClick={()=>{DeletePriorityBlock(data.buyerId,supplierItem.supplierId,data.priorityLevel,pIndex,supplierIndex)}} />}
								</>
							)
							}}/>)
						})}
				</Table>
			</div>
		</div>
	);
};

export default PriorityLevelRules;