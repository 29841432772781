import { Tabs, Divider } from 'antd';
import constants from '../../constants/constants';
import { CalendarOutlined, ProfileOutlined } from '@ant-design/icons';
import Budgets from '../Budgets';
import Spend from './Spend';
import { useLocation } from 'react-router-dom';
import BudgetsAdmin from '../admin/BudgetsAdmin';
import { useEffect } from 'react';
import { useState } from 'react';
import ContractsSpend from './ContractsSpend';
const { TabPane } = Tabs;

const BudgetContainer = () => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState("");
  useEffect(() => {
    // Access the current path from the location object
    console.log('Current Path:', location.pathname);
    setCurrentPath(location.pathname);
    // Your logic based on the current path can go here
  }, [location.pathname]);
	//Render
	return (
		<div>
      <div className='uppercase font-bold text-xl'>Budgets</div>
			<Divider className='my-3'/>
			<Tabs defaultActiveKey="1">
			<TabPane
        tab={ <span className='uppercase'><CalendarOutlined className='text-xl' />{constants.BudgetTab1}</span> }
        key={1}
      >
       {currentPath==="/budgets-admin"?<BudgetsAdmin/>:<Budgets/>}
      </TabPane>
			<TabPane
        tab={ <span className='uppercase'><ProfileOutlined className='text-xl' />{constants.BudgetTab2}</span> }
        key={2}
      >
        <Spend/>
      </TabPane>
      <TabPane
        tab={ <span className='uppercase'><ProfileOutlined className='text-xl' />{constants.BudgetTab5}</span> }
        key={3}
      >
        <ContractsSpend/>
      </TabPane>
			</Tabs>
		</div>
	);
};

export default BudgetContainer;