import { Space, Divider, Select, Input, Table, Modal, Form, message, Button } from 'antd';
import constants from '../../constants/constants';
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import WebLinks from '../../api/WebLinks';
import APIUtils from '../../api/APIUtils';
import moment from 'moment';

const { Search } = Input;
const { Option } = Select;

const OccupationAdmin = () => {
    const buyerId = Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)
    const [filterOccupation, setFilterOccupation] = useState([]);
    const [jobOccupation, setJobOccupation] = useState([]);
    const [newOccupations, setNewOccupations] = useState([]);
    const [selectedOccupation, setSelectedOccupation] = useState(null);
    const [filterOccupationLoading, setFilterOccupationLoading] = useState(false);
    const [jobOccupationLoading, setJobOccupationLoading] = useState(false);
    const [newOccupationsLoading, setNewOccupationsLoading] = useState(false);
    const [jobDetail, setJobDetail] = useState(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [tableConfig, setTableConfig] = useState({
        pageNo: 1,
        limit: 100,
        sortBy: 'Posted',
        sortDesc: false,
        search: ''
    });
    const [total, setTotal] = useState();
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [form] = Form.useForm();
    const [occupationIdValue,setOccupationIdValue]=useState();

    //Function on page load
    useEffect(() => {
        fetchJobOccupations()
    }, [tableConfig]);

    useEffect(() => {
        fetchFilterOccupations();
    }, [buyerId]);

    const fetchFilterOccupations = async () => {
        setFilterOccupationLoading(true)
        let url = WebLinks.OccupationsList + buyerId
        const response = await APIUtils.apiCall('GET', url);
        if (!response.isCatch && response.resultObj) {
            //let data = response.resultObj.data?.sort((a, b) => a.occupation.localeCompare(b.occupation));
            setFilterOccupation(response.resultObj.data);
            setFilterOccupationLoading(false)
        } else {
            setFilterOccupationLoading(false)
            message.error(response.resultObj.message);
        }
    }

    const fetchJobOccupations = async (occupationId) => {
        setJobOccupationLoading(true)
        const queryParams = new URLSearchParams(tableConfig);
        if (occupationId||occupationIdValue) {
            let tempOccupationId=occupationId?occupationId:occupationIdValue
            queryParams.set('occupationId', tempOccupationId);
        }
        const url = `${WebLinks.JobOccupations}${buyerId}&${queryParams.toString()}`;
        const response = await APIUtils.apiCall('GET', url);
        if (!response.isCatch && response.resultObj) {
            setTotal(response.resultObj.getCount)
            setJobOccupation(response.resultObj.data);
            setJobOccupationLoading(false)
        } else {
            setJobOccupationLoading(false)
            message.error(response.resultObj.message);
        }
    }

    // Table columns
    const jobsOccupationsColumns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a.id - b.id
        },
        {
            title: 'Posted',
            dataIndex: 'posted',
            key: 'posted',
            sorter: (a, b) => a.posted - b.posted,
            render: (text) =>
            (
                <span className='whitespace-nowrap'>{moment(text).format(constants.DateFormat2)}</span>
            )
        },
        {
            title: 'Reference',
            dataIndex: 'reference',
            key: 'reference',
            sorter: (a, b) => a.reference - b.reference,
        },
        {
            title: 'Job Title',
            dataIndex: 'title',
            key: 'jobTitle',
            sorter: true,
            render: (text, record) => (
                <a onClick={() => onTitleClick(record.id, record.title)}>{text}</a>
            ),
        },
        {
            title: 'Occupation',
            dataIndex: 'occupation',
            key: 'occupation',
            sorter: true,
        },
        {
            title: 'City',
            dataIndex: 'place',
            key: 'place',
            sorter: (a, b) => a.place - b.place,
        },
        // {
        //     title: 'County',
        //     dataIndex: 'county',
        //     key: 'county',
        //     sorter: (a, b) => a.county - b.county,
        // },
        {
            title: 'Postcode',
            dataIndex: 'postcode',
            key: 'postcode',
            sorter: (a, b) => a.postcode - b.postcode,
        }
    ];
    // Table end

    const changeOccupation = (e) => {
        setOccupationIdValue(e)
        setTableConfig((prevConfig) => ({
            ...prevConfig,
            pageNo: 1
        }));
        fetchJobOccupations(e)
    }

    const onTitleClick = async (jobId, jobTitle) => {
        setIsEditModalOpen(true)
        setNewOccupationsLoading(true)
        setJobDetail({
            jobId, jobTitle
        })
        let url = WebLinks.NewOccupations
        const response = await APIUtils.apiCall('GET', url);
        if (!response.isCatch && response.resultObj) {
            setNewOccupations(response.resultObj.data);
            setNewOccupationsLoading(false)
        } else {
            setNewOccupationsLoading(false)
            message.error(response.resultObj.message);
        }
    }

    const saveOccupation = async (values) => {
        changeJobOccupation([jobDetail.jobId], values.occupation)
        // const url = `${WebLinks.UpdateJobOccupation}${jobDetail.jobId}&occupationId=${values.occupation}`;
        // const response = await APIUtils.apiCall('PUT', url);
        // if (!response.isCatch && response.resultObj) {
        //     setIsEditModalOpen(false)

        // } else {
        //     message.error(response.resultObj.message);
        // }
    }

    const onSearch = async (value) => {
        setTableConfig((prevConfig) => ({
            ...prevConfig,
            search: value,
            pageNo: 1
        }));
    }

    const onChangeTable = (pagination, filters, sorter) => {
        const { current, pageSize } = pagination;

        setTableConfig((prevConfig) => ({
            ...prevConfig,
            pageNo: current,
            limit: pageSize,
        }));

        if (sorter && sorter.field) {
            setTableConfig((prevConfig) => ({
                ...prevConfig,
                sortBy: sorter.field,
                sortDesc: sorter.order === 'descend',
            }));
        }
    };

    //Function to get the Priority Level to change multiple jobs.
    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => onSelectChange(selectedRowKeys, selectedRows),
    };

    const changeJobOccupation = async (id, occupation) => {
        const url = `${WebLinks.UpdateJobOccupation}${occupation}`
        const payload = JSON.stringify(id)
        const response = await APIUtils.apiCall('PUT', url, payload);
        if (!response.isCatch && response.resultObj) {
            fetchJobOccupations()
            setIsEditModalOpen(false)

        } else {
            message.error(response.resultObj.message);
        }
    }

    return (
        <div>
            <div className="flex flex-wrap justify-between items-center gap-1">
                <Space>
                    <div className="uppercase font-bold text-xl">Your Jobs Occupations</div>
                </Space>
            </div>
            <Divider className="my-3" />
            <div className="flex flex-wrap justify-between items-center gap-1 mb-3">
                <Space wrap>
                    <span>Update all selected jobs to Occupation:</span>
                    <Space.Compact>
                        <Select
                            placeholder="Occupation"
                            className="w-[65vw] md:w-60"
                            loading={filterOccupationLoading}
                            onChange={(e) => setSelectedOccupation(e)}
                        >
                            {
                                filterOccupation?.map((item) => (
                                    <Option key={item.id} value={item.id}>{item.occupation}</Option>
                                ))
                            }
                        </Select>
                        <Button type="primary" onClick={() => changeJobOccupation(selectedRowKeys, selectedOccupation)}>Update</Button>
                    </Space.Compact>
                </Space>
                <Space wrap>
                    <span>Filter by Occupation:</span>
                    <Select
                        placeholder="Occupation filter Drop down"
                        className="w-[65vw] md:w-60"
                        onChange={changeOccupation}
                        defaultValue={''}
                        loading={filterOccupationLoading}
                    >
                        <Option key='0' value=''>Show all</Option>
                        {
                            filterOccupation?.map((item) => (
                                <Option key={item.id} value={item.id}>{item.occupation}</Option>
                            ))
                        }</Select>
                </Space>

                <Search
                    placeholder="Search"
                    allowClear
                    onSearch={onSearch}
                    enterButton
                    className='w-60'
                />
            </div>
            <div className="overflow-x-auto">
                <Table
                    size='small'
                    rowKey="id"
                    rowSelection={{
                        type: 'checkbox',
                        ...rowSelection,
                        selectedRowKeys: selectedRowKeys
                    }}
                    columns={jobsOccupationsColumns}
                    dataSource={jobOccupation}
                    pagination={{
                        pageSizeOptions: ["50", "100", "200", "500"],
                        total: total,
                        current: tableConfig.pageNo,
                        pageSize: tableConfig.limit,
                    }}
                    loading={jobOccupationLoading}
                    onChange={onChangeTable}
                />
            </div>
            <Modal
                title="Edit Job Occupation"
                open={isEditModalOpen}
                onCancel={() => setIsEditModalOpen(false)}
                okText={<span className="capitalize">Save</span>}
                onOk={() => form.submit()}
                style={{ top: 20 }}
                width={800}
            >
                <h1>{jobDetail?.jobTitle}</h1>
                <Form form={form} name="form_in_modal" layout="vertical" onFinish={saveOccupation}>
                    <Form.Item
                        name="occupation"
                        rules={[
                            {
                                required: true,
                                message: "Required field",
                            },
                        ]}
                    >
                        <Select
                            placeholder="Occupation"
                            className="w-[65vw] md:w-60"
                            showSearch
                            loading={newOccupationsLoading}
                            filterOption={(input, option) =>
                                (option?.props?.children ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {
                                newOccupations?.map((item) => (
                                    <Option key={item.id} value={item.id}>{item.occupation}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>

                </Form>
            </Modal>
        </div>
    );
};

export default OccupationAdmin;