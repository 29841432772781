/* eslint-disable no-unused-vars */
import React from "react";
import { BrowserRouter  } from "react-router-dom";
import { createRoot} from "react-dom/client"
import reportWebVitals from "./reportWebVitals";
//Route Dictionary
import RouteManager from "./routes/Routes";
//Parent Styles
import "./index.css";
import "./App.scss";
import { ContextProvider } from "./Context";

const App = () => (
	<ContextProvider>
		<BrowserRouter >
			<RouteManager/>
		</BrowserRouter>
	</ContextProvider>
);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App/>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
