import Cookies from "js-cookie";
import constants from "../constants/constants";

export function useAuth() {
  return {
    isUserAuthed(){
        return Cookies.get(constants.COOKIE_TOKEN) || false;
    },
    loginAuth(accessToken, rememberMe) {
      return new Promise((res) => {
        if(rememberMe){
            //TODO: remove the expiry date keep the cookies unless user logs in
          Cookies.set(constants.COOKIE_TOKEN, accessToken, {path:'/',expires:constants.COOKIE_EXPIRY_DAYS});
        }else{
          Cookies.set(constants.COOKIE_TOKEN, accessToken)
        }
        res();
      });
    },
    logoutAuth() {
      return new Promise((res) => {
        Cookies.remove(constants.COOKIE_TOKEN);
        Cookies.remove(constants.COOKIE_LOGGED_BUYER_ID);
        Cookies.remove(constants.COOKIE_LOGGED_BUYER_NAME);
        Cookies.remove(constants.COOKIE_SELECTED_BUYER_ID);
        Cookies.remove(constants.COOKIE_SELECTED_BUYER_NAME);
        Cookies.remove(constants.COOKIE_LOGGEDIN_IS_SUB_BUYER_ADMIN);
        Cookies.remove(constants.COOKIE_SELECTED_IS_SUB_BUYER_ADMIN);
        Cookies.remove(constants.COOKIE_LOGGED_BUYER_SELF_POSTED);
        Cookies.remove(constants.COOKIE_SELECTED_BUYER_SELF_POSTED);
        Cookies.remove(constants.COOKIE_SOCIAL_TOKEN);
        Cookies.remove(constants.COOKIE_IS_ADMIN);
        Cookies.remove(constants.COOKIE_CANDIDATE_SEARCH);
        res();
      });
    },
  };
}