import { Tabs, Divider } from 'antd';
import { CalendarOutlined, ProfileOutlined } from '@ant-design/icons';
import YourJobsAll from '../YourJobsAll';
import YourJobsPerformance from './YourJobPerformance';
import { useEffect, useState } from 'react';
const { TabPane } = Tabs;

const YourJobsContainerAdmin = (props) => {
const[buyerId,setBuyerId]=useState();

  //Function on page load
  useEffect(() => {
    setBuyerId(buyerId);
  }, [buyerId]);
	//Render
	return (
		<div>
      {/* <div className='uppercase font-bold text-xl'>Your Jobs</div> */}
			{/* <Divider className='my-3'/> */}
			<Tabs defaultActiveKey="1">
			<TabPane
        tab={ <span className='uppercase'><CalendarOutlined className='text-xl' />Your Jobs</span> }
        key={1}
      >
        <YourJobsAll buyerId={props.location.state.Id}/>
      </TabPane>
			<TabPane
        tab={ <span className='uppercase'><ProfileOutlined className='text-xl' />Performance</span> }
        key={2}
      >
        <YourJobsPerformance buyerId={props.location.state.Id}/>
      </TabPane>
			</Tabs>
		</div>
	);
};

export default YourJobsContainerAdmin;