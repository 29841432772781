import { Tabs, Divider } from 'antd';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { PicCenterOutlined,PicRightOutlined } from '@ant-design/icons';
import JobsInFeedBlock from './JobsInFeedBlock';
import JobsOverride from './JobsOverride';
const { TabPane } = Tabs;

const JobsInfeedOverrideTabs = () => {
  const { supplierId } = useParams();
	return (
		<div>
      {/* <div className='uppercase font-bold text-xl'>Click Report</div> <CalendarOutlined className='text-xl' />*/}
			{/* <Divider className='my-3'/> */}
			<Tabs defaultActiveKey="2" >
      <TabPane
        tab={ <span className='uppercase'><PicCenterOutlined className='text-xl'/>Jobs In Feed</span> }
        key={2}>
          <JobsInFeedBlock/>
      </TabPane>
			<TabPane
        tab={ <span className='uppercase'><PicRightOutlined className='text-xl'/>Jobs Override</span> }
        key={1}
      >
        <JobsOverride/>
      </TabPane>
			</Tabs>
		</div>
	);
};

export default JobsInfeedOverrideTabs;