
import { Table, Input, Button, message, Space } from 'antd';
import constants from '../../constants/constants';
import { CheckOutlined, CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import APIUtils from '../../api/APIUtils';
import WebLinks from '../../api/WebLinks';
import Cookies from 'js-cookie';

const { TextArea } = Input;

const QualityFilterOccupation = ({ filterValue }) => {
    const buyerId = Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)
    const [occupation, setOccupation] = useState([]);
    const [datasource, setDatasource] = useState([]);

    // Function on load and when filterValue changes
    useEffect(() => {
        // Check the filterValue and trigger the API call if needed
        if (filterValue === "All" || filterValue === "Rules") {
            fetchData();
        }
    }, [filterValue]);

    // Function to get occupation list
    const fetchData = async () => {
        const url = WebLinks.QualityFilter + buyerId + "&qualityFilterType=" + filterValue;
        const response = await APIUtils.apiCall('GET', url);
        if (!response.isCatch && response.resultObj.statusCode === "2000") {
            setDatasource(response.resultObj.data)
        }
    };

    const changeFeedback = (value, record) => {
        const updatedDatasource = datasource.map((item) =>
            item.occupationId === record.occupationId ? { ...item, feedbackText: value, isTextUpdated: true } : item
        );
        setDatasource(updatedDatasource);

    }

    const submitFeedback = async (e) => {
        const url = WebLinks.SubmitQualityFilterFeedback;
        const payload = {
            "buyerId": buyerId,
            "occupationId": e.occupationId,
            "feedbackText": e.feedbackText,
        }
        const response = await APIUtils.apiCall('PUT', url, JSON.stringify(payload));
        if (!response.isCatch && response.resultObj.statusCode === "2000") {
            message.success(response.resultObj.message);
            const updatedDatasource = datasource.map((item) =>
                item.occupationId === e.occupationId ? { ...item, isTextUpdated: false } : item
            );
            setDatasource(updatedDatasource);

        }
    }

    const updateQualityFilter = async (value) => {
        const newDatasource = datasource.map((item) =>
            item.occupationId === value.occupationId ? { ...item, buyerQualityFilter: !item.buyerQualityFilter } : item
        );
        setDatasource(newDatasource);
        const url = WebLinks.UpdateQualityFilter + buyerId + "&occupationId=" + value.occupationId;
        const response = await APIUtils.apiCall('POST', url);
        if (!response.isCatch && response.resultObj.statusCode === "2000") {
            message.success(response.resultObj.message);
        }
    }

    const deleteQualityFilter = async (value) => {
        const newDatasource = datasource.map((item) =>
            item.occupationId === value.occupationId ? { ...item, buyerQualityFilter: !item.buyerQualityFilter } : item
        );
        setDatasource(newDatasource);
        const url = WebLinks.DeleteQualityFilter + buyerId + "&occupationId=" + value.occupationId;
        const response = await APIUtils.apiCall('DELETE', url);
        if (!response.isCatch && response.resultObj.statusCode === "2000") {
            fetchData()
            message.success(response.resultObj.message);
        }
    }

    //Render
    return (
        <div>
            {filterValue !== "None" &&
                <Table
                    pagination={false}
                    dataSource={datasource}
                    scroll={{ x: '1000' }}
                >
                    <Table.Column key="occupationId" title="ID" dataIndex="occupationId" />
                    <Table.Column key="occupationId" title="Occupation" dataIndex="occupationName" />
                    {filterValue === "Rules" &&
                        <Table.Column key="buyerQualityFilter" title="Filter" dataIndex="buyerQualityFilter"
                            render={(_, record) => (
                                record.buyerQualityFilter ?
                                    (<CheckOutlined className='text-xl text-lime-500' onClick={() => deleteQualityFilter(record)} />) :
                                    (<CloseOutlined className='text-xl text-red-500' onClick={() => updateQualityFilter(record)} />)
                            )}
                        />
                    }
                    <Table.Column
                        key="feedbackText" title="Feedback to Filter Team" dataIndex="feedbackText"
                        render={(_, record) => (
                            <div>
                                <TextArea
                                    onChange={(e) => changeFeedback(e.target.value, record)}
                                    defaultValue={record.feedbackText}
                                    rows={3}
                                    className='w-56 md:w-full'
                                />
                            </div>
                        )}
                    />
                    <Table.Column key="id" title="" width={20}
                        render={(_, record) => (
                            <div color='red'>
                                {record.isTextUpdated && (
                                    <div>
                                        <Button onClick={() => submitFeedback(record)} type='primary' icon={<SaveOutlined />} className='!capitalize'>Update</Button>
                                    </div>
                                )}
                            </div>
                        )} />
                </Table>}
        </div>
    );
};

export default QualityFilterOccupation;