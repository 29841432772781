import { DatePicker, Space, Select, Table, message, Spin, Form, Image, Tooltip, Button } from 'antd';
import dayjs from 'dayjs';
import {FileExcelOutlined } from '@ant-design/icons';
import constants from '../../constants/constants';
import { useEffect, useState } from 'react';
import WebLinks from '../../api/WebLinks';
import Cookies from 'js-cookie';
import APIUtils from '../../api/APIUtils';
import { downloadFiles } from '../../globalFunctions/GlobalFunctions';
const { RangePicker } = DatePicker;

const OccupationReport = (props) => {

	//Variable declaration
	const columns = [
		{
			title: 'Month/Supplier',
			dataIndex: 'supplierName',
			key: 'supplierName',
			sorter:(a, b) => a.supplierName - b.supplierName,
			render:(_,record)=>{
                return(
					<>
					{record.logoURL && record.logoURL!==""?
						<Image src={record.logoURL} preview={false} width={100}/>:
						<span>{record.supplierName}</span>
					}
					</>
				)
				
			}	
		},
		{
			title: 'Applies',
			dataIndex: 'applies',
			key: 'applies',
			align:'center',
			sorter:(a, b) => a.applies - b.applies,
		},
		{
			title: 'Cost',
			dataIndex: 'totalCost',
			key: 'totalCost',
			align:'center',
			sorter:(a, b) => a.totalCost - b.totalCost,
		},
		{
			title: 'Cost/Apply',
			dataIndex: 'totalCostApplies',
			key: 'totalCostApplies',
			align:'center',
			sorter:(a, b) => a.totalCostApplies - b.totalCostApplies,
		},
		{
			title: 'Quality',
			dataIndex: 'quality',
			key: 'quality',
			align:'center',
			sorter:(a, b) => a.quality - b.quality,
			render:(_,record)=>{
                return(
					<Space size={3}>
						<span>{record.quality+" "}</span><span>({record.qualityPer}%)</span>
					</Space>
				)
				
			}
		},
		{
			title: 'Cost/Quality',
			dataIndex: 'costQuality',
			key: 'costQuality',
			align:'center',
			sorter:(a, b) => a.costQuality - b.costQuality,
		},
		{
			title: 'Interviews',
			dataIndex: 'interview',
			key: 'interview',
			align:'center',
			sorter:(a, b) => a.interview - b.interview,
			render:(_,record)=>{
                return(
					<>
						<span>{record.interview+" " }</span><span>({record.interviewPer}%)</span>
					</>
				)
				
			}
		},
		{
			title: 'Cost/Interview',
			dataIndex: 'costInterview',
			key: 'costInterview',
			align:'center',
			sorter:(a, b) => a.costInterview - b.costInterview,
		},
		{
			title: 'Hires',
			dataIndex: 'hired',
			key: 'hired',
			align:'center',
			sorter:(a, b) => a.hired - b.hired,
			render:(_,record)=>{
                return(
					<Space size={3}>
						<span>{record.hired+" "}</span><span>({record.hiredPer}%)</span>
					</Space>
				)
				
			}
		},
		{
			title: 'Cost/Hire',
			dataIndex: 'costHired',
			key: 'costHired',
			align:'center',
			sorter:(a, b) => a.costHired - b.costHired,
		}
	];
	const [monthlyData,setMonthlyData]=useState([]);
	const [occupationMonthlyData,setOccupationMonthlyData]=useState([]);
	const [currentPage,setCurrentPage]= useState(1);
	const [pageSize,setPageSize]=useState(25);
	const [rangePickerValue,setRangePickerValue]= useState([]);
	const [loader,setLoader]=useState(false);
	const [totalMonthCount,setTotalMonthCount]=useState(0);
	const [monthReportTotal, setMonthReportTotal] = useState([]);
	const [occupations, setOccupations] = useState([]);
	const [selectedOccupations, setSelectedOccupations] = useState(['allOccupation']);
	const [form] = Form.useForm();

	//On load function call
	useEffect(()=>{
		if(props&&props.dateSelectedValue){
		form.setFieldsValue({
			rangePicker:[dayjs(props.dateSelectedValue[0]),dayjs(props.dateSelectedValue[1])],
		})
		}else{
			form.setFieldsValue({
				rangePicker:[dayjs(),dayjs()],
			})
		}
		setCurrentPage(1);
		setPageSize(25);
		setRangePickerValue(props&&props.dateSelectedValue?[dayjs(props.dateSelectedValue[0]).format(constants.MonthFormat),dayjs(props.dateSelectedValue[1]).format(constants.MonthFormat)]:[dayjs().format(constants.MonthFormat),dayjs().format(constants.MonthFormat)]);
		GetMonthlyReportData(1,25,props&&props.dateSelectedValue?props.dateSelectedValue[0]:dayjs().format(constants.MonthFormat),props&&props.dateSelectedValue?props.dateSelectedValue[1]:dayjs().format(constants.MonthFormat));
		getOccupationsList(props&&props.dateSelectedValue?props.dateSelectedValue[0]:dayjs().format(constants.MonthFormat),props&&props.dateSelectedValue?props.dateSelectedValue[1]:dayjs().format(constants.MonthFormat));
	},[props]);

	//Functions
	//Function gets occupation list from API
	const getOccupationsList = async (startDate,endDate)=>{
		setLoader(true);
		const response = await APIUtils.apiCall('GET',WebLinks.GetOccupations+Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)+"&fromDate="+startDate+"&toDate="+endDate);
		const defaultItem ={value: 'allOccupation',label: 'Show All'};
		if(!response.isCatch && response.resultObj.statusCode==="2000"){
			let occupationList=response.resultObj.data?.map((item,index)=>({key:index, value:item.id, label:item.occupation+" ("+item.occupationCount+")"}))
			setOccupations([{...defaultItem}, ...occupationList] || [{...defaultItem}]);
		}else{
			setOccupations([...defaultItem]);
			message.error(response.resultObj.message);
		}
		setLoader(false);
	};
	const occupationsToString = (e) =>{
				
		let newList=e;
		let selectedOccList=e?.filter((item)=>item==="allOccupation");
		if(e?.length==0){
			form.setFieldsValue({occupations:['allOccupation']})
		}
		if(e?.length==1 && selectedOccList.length>0){
			newList=[];
		}
		
		if(e?.length>1){
			if(selectedOccList?.length>0){newList=e?.filter((item)=>item!=="allOccupation");}
			// setSelectedOccupations(newList);
			form.setFieldsValue({occupations:newList})
		}
		return newList || [];
	}
	//Function runs when range picker value is changed
	const RangePickerChanged= (_,dateString) => {
		props.changeDate(dateString);
		setRangePickerValue(dateString);
		GetMonthlyReportData(currentPage,pageSize,dateString[0],dateString[1]);
	};

	const downloadFile = () => {
		setLoader(true)
		let selectedOccList=occupationsToString(form.getFieldValue("occupations"));
		if(selectedOccList?.length>0){
			selectedOccList=selectedOccList.filter((item)=>item!=="allOccupation");
			let occupationIDS=selectedOccList.join(",");
			downloadFiles(WebLinks.DownloadOccupationReport+Cookies.get(constants.COOKIE_SELECTED_BUYER_ID) + `&fromDate=${dayjs(rangePickerValue[0]).format(constants.MonthFormat)}&toDate=${dayjs(rangePickerValue[1]).format(constants.MonthFormat)}&occupationids=${occupationIDS}`,"OccupationReport.xlsx");
		}else{
			downloadFiles(WebLinks.DownloadOccupationReportAll+Cookies.get(constants.COOKIE_SELECTED_BUYER_ID) + `&fromDate=${dayjs(rangePickerValue[0]).format(constants.MonthFormat)}&toDate=${dayjs(rangePickerValue[1]).format(constants.MonthFormat)}`,"OccupationReport.xlsx");
		}
		setTimeout(() => {
				setLoader(false)
		}, 3000);
	};

	//Function to Month report data
	const GetMonthlyReportData =async(currentPage,pazeSize,startDate,endDate,selectedOccList=[])=>{
		setLoader(true);
		selectedOccList=occupationsToString(form.getFieldValue("occupations"));
		let apiurl=WebLinks.GetApplicationReport+"/"+Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)+"?";
		let occupationIDS="";
		if(selectedOccList?.length>0){
			apiurl=WebLinks.GetOccupationsReport+Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)+"&";
			selectedOccList=selectedOccList.filter((item)=>item!=="allOccupation");
			occupationIDS=selectedOccList.join(",");
		}
		const response = await APIUtils.apiCall('GET',apiurl+"fromData="+startDate+"&toDate="+endDate+"&occupationids="+occupationIDS);//"&limit="+pazeSize+"&pageNo="+currentPage);
		if(!response.isCatch && response.resultObj.statusCode==="2000"){
			let dataResponse,count;
			if(occupationIDS===""){
				dataResponse=response.resultObj.data.monthReport;
				count=response.resultObj.data.monthReportCount;
			}else{
				dataResponse=response.resultObj.data;
				count=response.resultObj.data.getCount;
			}
			setMonthReportTotal(dataResponse?.pop() || []);
			setMonthlyData(dataResponse);
			setTotalMonthCount(count);
			setLoader(false);
		}else{
			setMonthlyData([]);
			setLoader(false);
			message.error(response?.resultObj?.message || constants.ERROR_MESSAGE);
		}
	}
	//Function on change of application table
	const onchangeApplicationTable = (pagination, filters, sorter) =>{
		if(pagination){
			if(pageSize!==pagination.pageSize){
				setCurrentPage(1);
			}else{
				setCurrentPage(pagination?.current);
			}
			setPageSize(pagination?.pageSize);
			GetMonthlyReportData(pagination?.current,pagination?.pageSize,rangePickerValue[0],rangePickerValue[1])
		}
	};

	//Function to display the month total in the table
	const monthReportSummary =()=>{
		return (
			<>
				<Table.Summary.Row className='bg-teal-50'>
					<Table.Summary.Cell index={0} align='center'>
						<strong>Totals</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={1} className='text-center'>
						<strong>{monthReportTotal.applies}</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={2} className='text-center'>
						<strong>{monthReportTotal.totalCost}</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={3} className='text-center'>
						<strong>{monthReportTotal.totalCostApplies}</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={4} className='text-center'>
						<strong><Space size={3}>{monthReportTotal.quality+" "}<span>({monthReportTotal.qualityPer}%)</span></Space></strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={5} className='text-center'>
						<strong>{monthReportTotal.costQuality}</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={6} className='text-center'>
						<strong><Space size={3}>{monthReportTotal.interview+" "}<span>({monthReportTotal.interviewPer}%)</span></Space></strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={7} className='text-center'>
						<strong>{monthReportTotal.costInterview}</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={8} className='text-center'>
						<strong><Space size={3}>{monthReportTotal.hired+" "}<span>({monthReportTotal.hiredPer}%)</span></Space></strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={9} className='text-center'>
						<strong>{monthReportTotal.costHired}</strong>
					</Table.Summary.Cell>
				</Table.Summary.Row>
			</>
		)
	};

	return (
		<>
		<Spin spinning={loader}>
			<div>
				<Form form={form} className='!w-full flex flex-wrap gap-3 justify-between items-center mb-3'>
					<Space wrap>
						<Form.Item name="rangePicker" className='mb-0'>
						<RangePicker picker="month" placeholder={["From", "To"]} onChange={RangePickerChanged} format={constants.MonthFormat} />
						</Form.Item>
					</Space>
					<Space wrap>
						<label>Filter by Occupation</label>
							<Form.Item name="occupations" initialValue={["allOccupation"]} className='mb-0' >
								<Select
									className='!w-72'
									mode="multiple"
									allowClear
									maxTagCount='responsive'
									options={occupations}
									// value={selectedOccupations}
									onChange={(e)=>{
										GetMonthlyReportData(currentPage,pageSize,rangePickerValue[0],rangePickerValue[1],occupationsToString(e));
									}}
									optionFilterProp="label"
									filterSort={(optionA, optionB) =>
									  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
									}
								/>
							</Form.Item>
							<Tooltip placement='right' title="Download as Excel">
								<Button
										className='bg-green-800 text-white hover:!text-white hover:!border-green-500'
										icon={<FileExcelOutlined />}
										onClick={()=>{downloadFile()}}
								>
									Download as Excel
								</Button>
							</Tooltip>
					</Space>
				</Form>
				<div className='overflow-x-auto'>
					<Table
						columns={columns}
						dataSource={monthlyData}
						scroll={{
							x: 1000,
						}}
						sortDirections={['descend','ascend','descend']}
						// onChange ={onchangeApplicationTable}
						pagination={{
							total: totalMonthCount,
							// showSizeChanger: true,
							// pageSizeOptions: ["25", "50", "100"],
							// defaultPageSize: 25,
							// current: currentPage,
						}}
						summary={() => monthReportSummary()}
					/>
				</div>
			</div>
		</Spin>
		</>
	);
};

export default OccupationReport;