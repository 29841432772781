import Cookies from "js-cookie";
import constants from "../constants/constants";
import { useAuth } from "../services/AuthService";

class APIUtils{
    static getResultObj=(result, isCatch) => {
        let responseObj={"resultObj":null,"isCatch":false};
        responseObj.resultObj=result;
        responseObj.isCatch=isCatch;
        return responseObj;
    };
    static logoutUser = () =>{
      const {logoutAuth}=useAuth();
      logoutAuth().then(() => {
        window.location.href = '/login'
      })
    };
    static apiCall = async (method, url, body=null, isAuth = true,issocalAPI=false) => {
        let headerContent = {
            Accept: "application/json",
            "Content-Type": "application/json",
        };
        //If the call need authorization
        if (isAuth) {
            let token = "Bearer " + Cookies.get(constants.COOKIE_TOKEN)
            let socialtoken = "Bearer " + Cookies.get(constants.COOKIE_SOCIAL_TOKEN)
            let selectedBuyerId= Cookies.get(constants.COOKIE_SELECTED_BUYER_ID);
            if (token == null || token === "" || !selectedBuyerId) {
              //TODO: redirect to LOGIN
              // window.location.href = "/login";
              this.logoutUser();
            }
            headerContent['Authorization'] = issocalAPI?socialtoken:token;
        }
          if (method === "POST" || method === "PUT" || method === "DELETE") {
            return fetch(url, {
                method: method,
                headers: headerContent,
                body: body
              })
                .then((response) => response.json())
                .then((data) => {
                  if(data && data.status===401 && Cookies.get(constants.COOKIE_TOKEN)){
                    this.logoutUser(data);
                  }
                  return this.getResultObj(data,false);
                })
                .catch((_error) => {
                    return this.getResultObj(null,true);
                });
        } else {
          try {
            const response = await fetch(url, {
              method: method,
              headers: headerContent,
            })
        
            if (!response.ok) {
              throw new Error(response.status);
            }
        
            const result = await response.json();
            return this.getResultObj(result,false);

          } catch (err) {
            console.log(err);
            if(err && err===401 && Cookies.get(constants.COOKIE_TOKEN)){
              this.logoutUser();
            }
            return this.getResultObj(null,true);
          }
          

      }
    }
}
export default APIUtils;
