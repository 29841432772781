import React from 'react';
import { Typography } from 'antd';
const { Title } = Typography;

const TermsAndConditions = () => {
    return (
        <div className='container mx-auto my-5'>
            <Title level={2}>Terms And Conditions - Hire Intelligence</Title>

            <p className="mb-3">By agreeing to our Terms and Conditions you must also agree to our <a className="!text-cyan-500 hover:!text-cyan-300" href="/privacy-policy">Privacy Policy</a> and <a className="!text-cyan-500 hover:!text-cyan-300" href="/cookie-policy">Cookie Policy</a></p>

            <p className="mb-3">Access to particular areas of the Internet Site may be subject to additional terms to which you must consent in order to use those areas.</p>
            <p className="mb-3">BY ENTERING THE INTERNET SITE YOU AGREE TO THESE TERMS. IF YOU DO NOT AGREE TO THESE TERMS THEN PLEASE LEAVE THE INTERNET SITES IMMEDIATELY.</p>
            <p className="mb-3">If you knowingly send or infect the Internet Site with a virus, Trojan horse, worm, etc then the Company retains the right at its sole discretion to pursue you for all legal fees (including its own and those of any third party), damages and other expenses that may be incurred by it as a result of your actions.</p>
            <p className="mb-3">The Company accepts no liability in respect of the content of any third party material appearing on the Internet Site or in respect of the content of any other Internet Site to which the Internet Site may be linked from time to time. The Company makes no warranty in respect of the contents of this Internet Site and accepts no liability for any loss or damage whatsoever and however arising whether directly or indirectly as a result of access to and use of this Internet Site including without limitation acting or failing to act in reliance on any information contained herein.</p>
            <p className="mb-3">You understand that you are personally responsible for your behaviour while on the Internet Site, and agree to indemnify and hold the Company, subsidiaries, affiliates, and their officers, directors, employees and agents harmless from and against any loss, damage, liability, cost or expense of any kind (including legal fees and costs) that they may incur in connection with a third party claim or otherwise, in relation to your use of the Internet Site, access to the Internet Site, your violation of either these Terms or the rights of any third party, disclosure of membership passwords and any other matter in connection with your participation in the services on the Internet Site.</p>
            <p className="mb-3">The Company does not warrant that functions contained in the Internet Site content will be uninterrupted or error free, that defects will be corrected, or that the Company or the server(s) that makes it available are free of viruses or bugs.</p>
            <p className="mb-3">Save where specifically indicated otherwise, the Internet Site and its contents are targeted only at UK residents and goods and services offered are available only to UK residents. Any legal proceedings arising as a result of content of or use of the Internet Site shall be subject to the laws of England and Wales.</p>
            <p className="mb-3">Ownership. Except in the case of Forums, any other content you create, and content from sites included in the search engine the copyright in the Internet Site and its contents belongs to the Company. Data included in the search engine is not published by the company in its full form and is always displayed with the intention of the user visiting the target website. Reproduction in any form, printing or downloading of part or all of the contents is forbidden save in accordance with the licence granted below.</p>
            <p className="mb-3">Licence to copy for personal use You may print copies of any item in hard copy provided that you do not edit alter amend or delete any part of it or combine it with other material. You may recopy the material to individual third parties for their personal information only if you acknowledge the Internet Site as the source of the information by including such acknowledgement and the address of the Company and the Internet Site in the copy of the material AND you inform the third party that the material may not be copied or reproduced in any way. You may not supply the material to any third party for commercial gain.</p>
            <p className="mb-3">No part of the Internet Site or the contents thereof may be reproduced on or transmitted to or stored in any other web site or other form of electronic retrieval system without the Company authorisation.</p>

            <Title level={3}>SUBMITTING CONTENT</Title>
            <Title level={4}>Ownership</Title>
            <p className="mb-3">If you submit content to the Company (including without limitation any text, photograph, graphics, video or audio) , you grant the Company a licence to use, reproduce, modify, adapt, publish, create derivative works from, distribute and display in public that content in any form and media on or in connection with the Site, at no cost to us and for as long as we require and you warrant that you own or have rights to the intellectual property in such content and have the right to grant this licence. You waive any moral rights in your contribution. You will indemnify us and keep us indemnified from and against all costs, claims, damages and expenses made against or incurred by us as a result of a third party alleging that the use of such content by us infringes the intellectual property rights of a third party.</p>

            <Title level={4}>Submissions.</Title>
            <p className="mb-3">When submitting content, you agree not to do any of the following:</p>
            <ul className="list-disc mb-3 list-inside">
                <li>defame, abuse, harass, stalk, threaten or otherwise violate or infringe the rights of any person or entity (including, but not limited to, rights of intellectual property, confidentiality, or rights of privacy);</li>
                <li>upload or transmit any material which is unlawful, indecent, defamatory, hateful or racially, ethnically or otherwise offensive or objectionable, politically sensitive or of an obscene or menacing character, or that may cause annoyance, inconvenience or needless anxiety;</li>
                <li>upload or transmit files that contain software or other material protected by intellectual property laws unless you own or control the rights thereto or have received all necessary consents;</li>
                <li>upload or transmit any materials that infringe any patent, trademark, copyright or other proprietary rights of any third party;</li>
                <li>upload or transmit material that you do not have a right to make available under any law or contractual or fiduciary relationship (such as confidential information);</li>
                <li>upload or transmit any computer viruses, macro viruses, trojan horses, worms, corrupted files or anything else designed to interfere with, interrupt or disrupt the normal operating procedures of a computer;</li>
                <li>use the system of uploading or transmitting content (or any part thereof) in a way that may cause the system (or the servers or networks connected to the system) to be interrupted, damaged, rendered less efficient or such that the effectiveness or functionality of the system is in any way impaired;</li>
                <li>where you are not the author of any material you are uploading or transmitting , delete any author attributions, legal notices or proprietary designations or within the material;</li>
            </ul>

            <Title level={3}>TERMS AND CONDITIONS OF ACCEPTANCE OF ADVERTISEMENTS</Title>
            <p className="mb-3">The Internet Site is produced and published by the Company. Orders for insertion of advertisements in the Publication and/or the Internet Site are accepted subject to the following conditions. For the avoidance of doubt, in this section the words &quot;published&quot; or &quot;Publication&quot; shall mean published on any Internet Site(s) owned and produced by the Company or Electronic Newsletters / Communications via Email.</p>
            <p className="mb-3">The placing of an order constitutes a warranty from the advertiser and/or advertising agency to the Company:</p>
            <ul className="list-disc mb-3 list-inside">
                <li>that the advertisement is legal, decent, honest and truthful, complying with the British Codes of Advertising and Sales Promotion, with any relevant codes of practice and with the requirements of current legislation; and</li>
                <li>that the advertisement is not defamatory and does not infringe the copyright moral rights or any other rights of any third party.</li>
            </ul>

            <Title level={4}>Indemnity</Title>
            <p className="mb-3">The advertiser and/or advertising agency agrees to indemnify the Company in respect of all costs, claims, damages, or other charges arising directly or indirectly as a result of the publication of the advertisement(s).</p>
            <p className="mb-3">While every endeavour will be made to meet the wishes of advertisers, the Company does not guarantee the publication of any particular advertisement or its publication on any particular date.</p>
            <p className="mb-3">It is the responsibility of the advertiser/advertising agency to check the first appearance of any series of advertisements and notify the Company immediately of any errors. The Company assumes no responsibility for the correction of errors unless notified by the advertiser. In the event of any error, misprint or omission in the Publication or the Internet Site of an advertisement or part of an advertisement (however caused) the Company will either re-publish the advertisement or relevant part of the advertisement as the case may be or make a reasonable refund of or adjustment to the cost. No re-publication, refund or adjustment will be made where the error, misprint or omission does not materially detract from the advertisement.</p>
            <p className="mb-3">In no circumstances shall the total liability of the Company for any error, misprint or omission exceed:</p>
            <ul className="list-disc mb-3 list-inside">
                <li>the amount of a full refund of any price paid to the Company for the advertisement in connection with which liability arose; or</li>
                <li>the cost of a further or corrective advertisement of a type and standard reasonably comparable to that in connection with which liability arose.</li>
            </ul>
            <p className="mb-3">Save as set out above, the Company accepts no liability in respect of any loss or damage occasioned directly or indirectly as a result of publication of any advertisement or any loss or damage occasioned directly or indirectly by any total or partial failure (however caused) of publication of any advertisement on the Internet Site in which any advertisement is scheduled to appear.</p>
            <p className="mb-3">Without prejudice to the foregoing, the Company accepts no liability in respect of any loss or damage alleged to have arisen through delay in forwarding or omitting to forward replies to box numbers to the advertiser (however caused). The advertiser hereby authorises the Company to return to its originator or destroy any communication which, in the reasonable opinion of the Company, should not be delivered to the advertiser.</p>

            <Title level={4}>Refusals and Amendments</Title>
            <p className="mb-3">The Company reserves the right to:</p>
            <ul className="list-disc mb-3 list-inside">
                <li>Refuse any advertisement or if already accepted to cancel the order at any time by giving reasonable notice before the next insertion, but in that event the advertiser/advertising agency shall not be liable for payment of the difference (if any) between the rates for the series specified in the order and the usual price for the series of insertions which has appeared when the order is cancelled; and require any alteration it considers necessary or desirable in any advertisement.</li>
            </ul>

            <Title level={4}>Cancellation</Title>
            <p className="mb-3">An advertising agency may cancel any unexpired part of an order without penalty in the event of the death or failure of its client. Orders cannot be cancelled once the Company has commenced to carry out the order in accordance with the first publication date requested by the advertiser. In other cases the Company will require four clear working days&#39; notice of cancellation of any order or unexpired part of an order, or in the case of an advertisement which by reason of its position is chargeable at a premium rate, not less that twenty-eight clear working days&#39; notice. All cancellations must be notified in writing. Email notification of cancellation is not acceptable.</p>
            <p className="mb-3">The copyright for all purposes in which the Company or his employees have originated, contributed to or reworked shall vest in the Company.</p>
            <p className="mb-3">The placing of an order by an advertiser, or an advertising agency on behalf of a client, constitutes an assurance that all necessary authority and consents have been secured in respect of the use in the advertisement(s) (a) of pictorial or other representations of (or purporting to be of) living persons, and of references to any words attributed to living persons and (b) any material the copyright in which vests in a third party.</p>
            <p className="mb-3">Any material submitted by the advertiser is held by the Company at the advertiser&#39;s risk and should be insured by the advertiser against loss or damage from whatever cause. The Company reserves the right to destroy without notice all such property after the date of its last appearance in an advertisement unless the advertiser has given instructions to the contrary.</p>
            <p className="mb-3">The Company reserves the right to disclose the name and address of advertisers and/or agencies to the police, trading standards officials, or any other relevant authority and, where the Company in its sole discretion deems it reasonable, to other third parties.</p>

            <Title level={4}>Acceptance</Title>
            <p className="mb-3">The placing of an order for the insertion of an advertisement shall amount to an acceptance of the above conditions and any conditions stipulated on an agency&#39;s or advertiser&#39;s order form or elsewhere by an agency or an advertiser shall be void insofar as they are in conflict with them.</p>

            <Title level={4}>Credit References</Title>
            <p className="mb-3">In assessing your application for credit, to prevent fraud, to check your identity and to prevent money laundering, the Company may search the files of credit reference agencies who will record any credit searches on your file. The Company may also disclose details of how you conduct your account to such agencies. The information will be used by other credit grantors for making credit decisions about you and the people with whom you are financially associated, for fraud prevention, money laundering prevention and occasionally for tracing debtors. Information used for these purposes will include publicly available information such as electoral roll, county court judgments, bankruptcy orders or repossessions.</p>
            <p className="mb-3">The Company may also share your information with its business partners in the travel, property, automotive, financial services, leisure, entertainment, advertising, media, retail and mail order business for marketing purposes or it may send you information about other organisations&#39; goods and services. The Company or its business partners may contact you by mail, telephone, fax, e-mail or other electronic messaging service with offers of goods and services or information that may be of interest to you e.g. holidays, cars, property, financial and leisure services, entertainment, media products, advertising, clothing or household goods. By providing the Company with your fax number, land line and/or mobile telephone numbers or email address you consent to being contacted by these methods for these purposes. If you do not wish to receive marketing information from the Company or from the Company&#39;s business partners tick the appropriate box on the form you complete.</p>


            <Title level={4}>Requests</Title>
            <p className="mb-3">You are entitled to ask for a copy of the information the Company holds about you (for which the Company may charge a small fee) and to have any inaccuracies in your information corrected. If you wish to exercise your rights to this information please forward your request by email to contact@applygateway.com</p>
            <p className="mb-3">For quality control and training purposes, the Company may monitor or record your communications with it.</p>

            <Title level={4}>Changes in Your Data</Title>
            <p className="mb-3">If your personal details change, if you change your mind about any of your marketing preferences or if you have any queries about how the Company uses your information, please let the Company know online where appropriate by email contact@applygateway.com</p>
            <p className="mb-3">This data protection notice may change and therefore you should review it regularly. The Company will of course notify you of any changes where we are required to do so. The separate document Privacy Policy defines the usage of all personal data.</p>

            <Title level={3}>FORUMS / COMMUNITY / BLOGS REGISTRATION TERMS AND CONDITIONS</Title>
            <Title level={4}>Acceptance of Terms</Title>
            <p className="mb-3">In order to contribute to any of the interactive pages of the Internet Site (&quot;the Forums&quot;) you must agree to abide by the Site Rules and Swear Word Policy detailed below. If you do not agree then please leave the Forums immediately. By completing the Forum registration form and by entering your email address and password, you will be deemed to have accepted these Terms and Conditions in their entirety. Also, by signing up for any of the Internet Site&#39;s online services you are deemed to have accepted these Terms and Conditions in their entirety.</p>

            <Title level={4}>Accuracy of Records</Title>
            <p className="mb-3">During the registration process, you agree to provide true, accurate and complete information about yourself, and to update this information when it changes. If you do not update it, the Company may suspend or terminate your use of the Internet Site. Any personal information that you provide to the Company is subject to the terms of the Data Protection Notice and Cookie Policy detailed above.</p>
            <p className="mb-3">Unauthorised Access to your Password.</p>
            <p className="mb-3">You will not do anything that would assist anyone who is not a registered user to gain access to any secure area of the Internet Site. You undertake that you will not maliciously create additional registration accounts for the purpose of abusing the functionality of the Internet Site, or other users; nor will you seek to pass yourself off as another user.</p>

            <Title level={4}>Access Termination</Title>
            <p className="mb-3">The Company can terminate your access to the Internet Site at any time. The Company may also remove any content that you post without cause, without giving reasons and without notice to you. This includes all email accounts, postings, profiles or other personalised information you have created while on the Internet Site.</p>

            <Title level={4}>Complaints</Title>
            <p className="mb-3">We encourage users to inform The Company of unsuitable content, you can email us at contact@applygateway.com or use the report unsuitable content facility on the web site. The case of the latter you will be asked to log in / register (if not already logged in). Your complaint will be brought to the attention of the Company, who will decide whether the comment breaks the rules. If it does, they will remove it. If it doesn&#39;t, it will be allowed to remain on the Forum. This process may be automated, and the content may be removed automatically depending on the specific feature. The Company will not normally enter into correspondence about complaints, but will do so in special cases. Please do not abuse the complaints system. It is there to allow users to report any comments which you deem unsuitable and in breach of the Rules. Similarly, please do not complain about spelling errors, differences of opinion or multiple postings. Anyone who repeatedly abuses the complaints system simply because they disagree with comments may have their account suspended.</p>
            <p className="mb-3">Personal attacks are prohibited.</p>
            <p className="mb-3">Commonly known as flaming, personal attacks are posts which are designed to put down or insult Forum members. Text of this nature is not beneficial to the Company&#39;s community spirit and will not be tolerated.</p>

            <Title level={4}>The Law</Title>
            <p className="mb-3">The Company accepts no liability in respect of any material submitted by users and published by it and the Company is not responsible for its content and accuracy. Please keep your comments relevant, in good taste and civil. If anything you post is illegal (for example defamatory), condones illegal activities (such as recommends drug taking) or infringes the copyright of a third party then it will be removed.</p>

            <Title level={3}>DISABILITY ACCESS STATEMENT</Title>
            <p className="mb-3">The Internet Site has been built in compliance with W3C WAI standard Single A, to ensure accessibility to all users, irrespective of their physical ability.</p>
            <p className="mb-3">The site is tested to support IE6, IE7, IE8, Firefox 3.0, Chrome.</p>
            <p className="mb-3">The Company is dedicated to supporting all users. If you experience any difficulties using the Internet Site please contact the Company at contact@applygateway.com</p>

            <Title level={3}>CONTENT LINKED TO BY THE INTERNET SITE</Title>
            <p className="mb-3">The sites displayed as search results or linked to by The Company are developed by people over whom The Company exercises no control. The search results that appear from The Company indices are indexed by The Company&#39;s automated machinery and computers, and The Company cannot and does not screen all the links before including them in the indices from which such automated search results are gathered. A search using The Company services may produce search results and links to sites that some people find objectionable, inappropriate, or offensive. We cannot guarantee that search will not locate unintended or objectionable content and assume no responsibility for the content of any site included in any search results or otherwise linked to by the Company.</p>

            <Title level={3}>NO AUTOMATED QUERYING</Title>
            <p className="mb-3">You may not send automated queries of any sort to The Company system without express permission in advance from The Company. Note that &quot;sending automated queries&quot; includes, among other things:</p>
            <ul className="list-disc mb-3 list-inside">
                <li>meta-searching</li>
                <li>performing &quot;offline&quot; searches</li>
                <li>any related commercial benefit</li>
            </ul>
            <p className="mb-3">Full access is provided to Google, Yahoo, MSN, Live.com, ASK and other mainstream general search engines. Access is denied to search engines in the recruitment vertical and commercial compensation will be sought if the authorisation is abused.</p>
            <p className="mb-3">Please contact contact@applygateway.com for permission.</p>

            <Title level={3}>Your Privacy Rights</Title>
            <p className="mb-3">Hire Intelligence is committed to protecting the privacy of our users, and strives to provide a safe, secure user experience. This Privacy Policy sets forth the online data collection and usage policies and practices that apply to this web site.</p>
            <p className="mb-3">By registering or by using this site, you explicitly accept, without limitation or qualification, the collection, use and transfer of the personal information provided by you in the manner described in this Policy. Please read this Policy carefully as it affects your rights and liabilities under law. If you disagree with the way we collect and process personal information collected on the Web site, please do not use it.</p>

            <Title level={4}>Scope of this Policy</Title>
            <p className="mb-3">This Policy applies to Hire Intelligence.com (the “Site” or Sites”), which is owned by Hire Intelligence Ltd. and controlled by Hire Intelligence Ltd. It is Hire Intelligence’s policy to comply with data protection legislation. This regulates the processing of information relating to you and grants you various rights in respect of your personal data.</p>
            <p className="mb-3">The Sites contain links to other web sites over which we have no control. Hire Intelligence is not responsible for the privacy policies or practices of other web sites to which you choose to link from this site. We encourage you to review the privacy policies of those other web sites so you can understand how they collect, use and share your information.</p>
            <p className="mb-3">We also do not control the data use practices of either (a) employers or others who may access your information on this site, or (b) third party Ad Networks and other third parties with whom we work to serve you personalized ads on our sites. We encourage you to review the privacy policies and opt-out choices of those third parties so you can understand how they collect, use and share your information.</p>

            <Title level={4}>Information We Collect and Retain</Title>
            <p className="mb-3">You do not have to give us any personal information in order to perform job searches or to read the content portions of our Site. We collect the following general types of information about you when you visit our Sites: personal information, demographic information, behavioural information, and indirect information. Sometimes we collect combinations of all these types of information. In each case, it is indicated on the web site whether any personal or demographic data must be provided to use the requested service or not.</p>
            <p className="mb-3">Personal information is information that can be used to identify you, or any other individual to whom the information may relate, personally. We do not collect personal information unless you choose to provide it to us. Some examples of personal information that we collect in some areas of our Site, depending on the services you use and local law, are:</p>

            <ul className="list-disc mb-3 list-inside">
                <li>Your name</li>
                <li>Address</li>
                <li>Email address</li>
                <li>Telephone number</li>
                <li>Contact information</li>
                <li>CV, Resume and Job Application Data</li>
                <li>Information about third parties, such as references or contacts, that you provide to us if you have their express written consent to do so</li>
                <li>Demographic information is information that is not unique to you in the sense that it refers to selected population characteristics, such as your ZIP code or postal code, age, preferences, gender, race or ethnicity, occupation, career history, interests and favorites.</li>
            </ul>

            <p className="mb-3">We also collect behavioural information regarding how you use our sites and mobile applications, the areas of our site that you visit, what services you access, and information about your computer hardware and software including your IP address, geolocation, browser and operating system type, domain names, access times and referring Web site addresses. This information is necessary for analysing the use of resources, troubleshooting problems, preventing fraud, and improving our services.</p>
            <p className="mb-3">In addition, Hire Intelligence may collect indirect information about you when you use certain third party services on our web site. For example if you provide a title of ‘Mrs.’ we will assume you are female.</p>
            <p className="mb-3">We may personalize the advertising that you are shown on our sites or other sites with which we have a business relationship. In order to provide thispersonalization, in addition to information we collect about you on our sites, we acquire information (including personal, demographic, behavioural and indirect information) about you from third parties who provide it to us.</p>

            <Title level={4}>How Information is Used</Title>

            <p className="mb-3">By registering with Hire Intelligence, by managing your profile, or opting in when presented with choices, you have consented for us to use your information, subject to local law, in the following ways:</p>

            <ul className="list-disc mb-3 list-inside">
                <li>to allow you to create an account;</li>
                <li>to create a profile for you based on information that you have provided to us;</li>
                <li>to contact you about Hire Intelligence Site updates, informational and service-related communications, including important security updates;</li>
                <li>to enable you to provide feedback, to contact us and for us to respond to you;</li>
                <li>to conduct surveys, questionnaires, promotions and contests, and to provide the results thereof such as success stories and contest winners;</li>
                <li>to provide information about you and your potential interest in job postings to employer customers;</li>
                <li>to provide products and services to employer customers to complete the recruitment and hiring process, such as accessing the searchable CV database;</li>
                <li>to provide products and services that enable users to network, post information on bulletin boards, view and compare profiles, and career background and experience;</li>
                <li>to generate internal reports about the use of our site; and</li>
                <li>to provide ‘forward to a friend’ features.</li>
            </ul>


            <Title level={4}>Disclosure of Information to Others</Title>
            <p className="mb-3">We disclose to third parties within your country and abroad your personal information, combined personal, demographic, behavioural or indirect information, as set forth below.</p>

            <p className="mb-3">We also share aggregated anonymous information about visitors to the Sites with Hire Intelligence’s clients, partners, other Site visitors, and other third parties so that they can understand the kinds of visitors to the Sites and how those visitors use the Sites in order to serve advertisements to you on our Sites or other sites with which we have a business relationship. We may also aggregate data regarding job qualifications, schooling, age, experience level or other information relevant to the job search or competition among job seekers for any such job. Such aggregated data does not identify users individually, and may be made available to employers or fellow job seekers or community members.</p>
            <p className="mb-3">We supply technology, hosting, hiring management and related services to other leading companies to power recruitment areas on their Web sites (sometimes referred to as “Hosted Career Sites”). Personal and/or demographic information supplied by you in Hosted Career Sites becomes part of the Hire Intelligence database, but it is not accessible by anyone other than you, Hire Intelligence, and the company concerned without your consent. If you attempt to register with Hire Intelligence after registering with a Hosted Career Site, Hire Intelligence will recognise your information and you will be able to use the information you provided to the Hosted Career Site in your account registration.</p>
            <p className="mb-3">Information gathered on a page in a Hosted Career Site area or on a co-branded page may be retained by both Hire Intelligence and the other company. In that instance, the use of such information by the other company will be subject to the privacy policy of that company and Hire Intelligence is not responsible for that company&#39;s use of your personal or demographic information.</p>
            <p className="mb-3">If you apply for a position through a Hosted Career Site or a co-branded site, you may be asked to provide information on your age, gender, race where permitted by law. You should understand that, if provided, this information should only be used by employers in accordance with applicable law. However, Hire Intelligence is not responsible for the actions of such employers. All such information provided will be kept separate by Hire Intelligence from your expression of interest in any job opportunity. Providing this information is strictly voluntary and you will not be subject to any adverse action or treatment from Hire Intelligence if you choose not to provide this information.</p>
            <p className="mb-3">If you apply for a position on Hire Intelligence through a Hosted Career Site, you may be asked to provide information on your gender, race, other protected status, or for a government identity number where permitted by applicable law. Some employers are required by law to gather this information from job applicants for reporting and record-keeping requirements. You should understand that if provided, this information may only be used by employers only in accordance with applicable law and they should not use it in making any negative employment decisions. All information provided will be kept separate from your expression of interest in any job opportunity. Providing this information is subject to local law.</p>
            <p className="mb-3">We disclose information if legally required to do so, or at our discretion pursuant to a request from a governmental entity or if we believe in good faith – after considering your privacy interests and other factors – that such action is necessary to: (a) conform to legal requirements or comply with legal process; (b) protect our rights or property or our affiliated companies; (c) prevent a crime or protect national security; or (d) protect the personal safety of users or the public. Because Hire Intelligence Ltd is a UK company and information collected on our Sites is stored in whole or in part in the UK, your information may become subject to UK Law.</p>
            <p className="mb-3">We may disclose and transfer such information to a third party who acquires any or all of Hire Intelligence’s business units, whether such acquisition is by way of merger, consolidation or purchase of all or a substantial portion of our assets. In addition, in the event Hire Intelligence becomes the subject of an insolvency proceeding, whether voluntary or involuntary, Hire Intelligence or its liquidator, administrator, receiver or administrative receiver may sell, license or otherwise dispose of such information in a transaction approved by the court. You will be notified of the sale of all or a substantial portion of our business to a third party by email or through a prominent notice posted on the Sites.</p>
            <p className="mb-3">Again, in each of these situations, the recipients of your data may potentially be located in any country in the world. If you are a resident in any EU member state, for example, you must be aware that the EU authorities do not generally consider that the regulations of non-EU countries ensure an adequate or equivalent level of protection as compared to the EU data protection regulations.</p>

            <Title level={4}>An Important Note about Your CV</Title>

            <p className="mb-3">If you provide us with details of a reference, it is your responsibility to ensure that the person is aware that you have forwarded his/her details and has consented in writing for you to do so.</p>
            <p className="mb-3">Your CV must not contain sensitive data relating to your (i) racial or ethnic origin (ii) political beliefs (iii) philosophical or religious beliefs (iv) membership of a trade union or political party (v) physical or mental health or genetic makeup (vi) addictions, sexual life (vii) the commission of criminal offences or proceedings and associated penalties or fines, or (viii) any Social Security Number or national identification number.</p>


            <Title level={4}>Your Choices About Your Information</Title>
            <p className="mb-3">Because managing your career is a life-long process, we retain all the information we gather about you in an effort to make your repeat use with us more efficient, practical and relevant until you change or remove your personal data as described below. You may have access to, review, correct, update, change or delete your account profile information or CV at any time. Simply log into your account, go to your profile or CV, and make the necessary changes. You may delete your CV from Hire Intelligence’s online database at any time by accessing your account. Before providing you with a copy of your personal information or correcting, updating or deleting such information, we reserve the right to verify and authenticate your identity and the personal information to which you have requested access. Access to or correction, updating or deletion of your personal information may be denied or limited by Hire Intelligence if it would violate another person’s rights and/or as otherwise permitted by applicable law. We will respond to information access requests within 30 days of receipt. If we require additional time to provide access to your information, we will acknowledge receipt of your request within 30 days and promptly supplement our response within the time period required by applicable law. We will respond to information access requests within 30 days of receipt. If we require additional time to provide access to your information, we will acknowledge receipt of your request within 30 days and promptly supplement our response within the time period required by applicable law.</p>
            <p className="mb-3">If you wish to delete or close your account or account profile information altogether, please contact <a className="!text-cyan-500 hover:!text-cyan-300" href="https://aemail.com/3l5O">here</a> An email will be sent to you to confirm that your personal information has been deleted (save for an archival copy which is not accessible by you or third parties on the Internet). The archival copy is retained only for as long as Hire Intelligence reasonably considers necessary for audit and record purposes. We will also retain logs, demographic, indirect, and statistical information that may relate to you but do not identify you personally. If your personal information was previously accessed by others using our Sites, we are not able to delete the information or copies thereof from their systems.</p>

            <p className="mb-3">If you do not want your information to be processed as described by this policy, you make revoke your consent to our Privacy Policy. If you wish to do so, please contact us at <a className="!text-cyan-500 hover:!text-cyan-300" href="https://aemail.com/3l5O">here</a> However, please note that if you do withdraw your consent, you may not be able to use the relevant services and your account and profile information will be deleted.</p>

            <Title level={3}>Important Information</Title>
            <Title level={4}>Security</Title>
            <p className="mb-3">You are responsible for ensuring that your log-in credentials (your username and password) are kept confidential.</p>
            <p className="mb-3">Hire Intelligence has implemented technical and organisational measures designed to secure your personal information from accidental loss and from unauthorised access, use, alteration or disclosure. Notwithstanding such measures, the Internet is an open system and we cannot guarantee that unauthorised third parties will not be able to defeat those measures or use your personal information for improper purposes. Moreover, one of our primary purposes is to provide you with a platform to broadcast the information in your CV and profile widely in order to maximise your career opportunities. Such an environment does present a risk that unauthorised third parties will view this data, so you should refer to our Security Centre for additional information on conducting a safe job search. Further, your CV should not contain any sensitive data including personality profiles or data that you would not want made public. You should be aware that CVs you complete at your current place of work may be monitored by your current employer.</p>

            <Title level={4}>Children</Title>
            <p className="mb-3">Hire Intelligence is not intended for, nor do we knowingly collect information from, children under the age of 18.</p>

            <Title level={4}>Changes to Privacy Statement</Title>
            <p className="mb-3">If we decide to materially change the substance of this Privacy Statement, we will, where required, contact you via the email address that you maintain in your profile. We will also post those changes through a prominent notice on the web site so that you will always know what information we gather, how we might use that information, and to whom we will disclose it.</p>

            <Title level={4}>Use of Cookies</Title>
            <p className="mb-3">Cookies: Hire Intelligence uses &quot;cookies&quot; to help personalise and maximise your online experience and time online, including for storing user preferences, improving search results and ad selection, and tracking user trends.</p>
            <p className="mb-3">A cookie is a text file that is either stored in your computer’s memory temporarily (a “session” cookie) or placed on your hard drive (a “persistent” cookie) by a Web page server. Cookies are not used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to your computer and can only be read by a web server in the domain that issued the cookie to you.</p>
            <p className="mb-3">When you return to the Hire Intelligence site, cookies enable us to retrieve the information you previously provided, so you can easily use the features that you customised. Because of our use of cookies, we can deliver faster and more accurate results and a more personalised site experience. For example, if you personalise Hire Intelligence pages, or register for services, a cookie helps us to recall your specific information (such as user name, password and preferences).</p>
            <p className="mb-3">We predominately use “session” cookies, which are not permanently stored on your hard drive. Session cookies expire after two hours of inactivity or when you close your browser. When you carry out a job search or apply online a large amount of data is processed by our system and transferred through each page of the search or application process. This information includes your job search criteria, data that identifies your CV, covering letter, the job you are applying for, your right to work in the relevant country and the results of any questionnaire you have completed as part of the online application process as well as demographic information that is not unique to you. “Session” cookies allow us to keep track of this data until you have completed your search or application. We also use cookies to identify users who have been banned from using our forums for behaviour that contradicts our terms of use and to track click streams, for load balancing and to enable you to navigate through the site using redirection pages.</p>
            <p className="mb-3">We use “persistent” cookies primarily to ensure that we do not show you pages that you have already seen and to personalize your site experience and save you time. “Persistent” Cookies enable us to recognize whether you have responded to any poll or seen an advertisement or information notice placed on the site so that we do not show you the poll, advertisement or notice again the next time you visit our site. If you are a recruiter and have an alliance arrangement in place with Hire Intelligence we also use “persistent” cookies to recall your alliance status and display your correct pricing information when you access our site. The “persistent” cookies we use will remain on your computer after you have left our site and will expire when you log out of the Site or 60 days after your last visit for security cookies, 90 days after your last visit for poll response and alliance cookies, or two years after your last visit for advertisement and information notice cookies.</p>
            <p className="mb-3">We also allow other companies to display advertisements to you while you are visiting our Sites. Because your web browser must request these advertisements from the other companies’ servers, these companies can view, edit or set their own cookies, just as if you had requested a web page from their site. Hire Intelligence has no control over, and is not responsible for, the practices of those third party advertisers. Hire Intelligence encourages you to review the policies of such advertisers. We use other third party ad servers as well; a way to opt out of third party cookies is to modify your browser settings to decline cookies as described at the end of this statement.</p>
            <p className="mb-3">This website uses Google Analytics, a web analytics service provided by Google, Inc. (“Google”). Google Analytics uses “cookies”, which are text files placed on your computer, to help the website analyze how users use the site. The information generated by the cookie about your use of the website (including your IP address) will be transmitted to and stored by Google on servers in the United States. Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity for website operators and providing other services relating to website activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google&#39;s behalf. Google will not associate your IP address with any other data held by Google. You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not be able to use the full functionality of this website. By using this website, you consent to the processing of data about you by Google in the manner and for the purposes set out above.</p>
            <p className="mb-3">You have the ability to accept or decline cookies at any time by modifying your browser settings. See below for instructions. If you choose to decline cookies, however, that may hinder performance and negatively impact your experience on the web site.</p>
            <p className="mb-3">Thank you for using Hire Intelligence.</p>
        </div>
    );
};

export default TermsAndConditions;