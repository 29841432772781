import { Button, Checkbox, Col, Divider, Form, Image, Row, Select, Space, Spin, Table, message } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import constants from '../../constants/constants';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import WebLinks from '../../api/WebLinks';
import APIUtils from '../../api/APIUtils';
import { GetCustomPriorityLevelIcons, GetPriorityLevelColors } from '../../globalFunctions/GlobalFunctions';

const{Column}=Table;
const { Option } = Select;

const MultiSupplierAdmin = (props) => {
	//Variable Declaration
	const [priorityList,setPriorityList]=useState([]);
	const [suppliers,setSuppliers]=useState([]);
	const [pageNumber,setPageNumber]=useState(1);
  const [sortDesc,setSortDesc]=useState(true);
  const [total,setTotal]=useState(0);
  const [loading,setloading]=useState(false);
  const [form] = Form.useForm();
  const [supplierList,setsupplierList]=useState([]);
	const [multiBudget,setMultiBudget]=useState('0');

	//On load
	useEffect(()=>{
		fetchData();
	},[]);

	//Functions 
	//Function to fetch multi supplier admin data
	const fetchData = async () =>{
		setloading(true);
		const supplirAdmins = await APIUtils.apiCall("GET",WebLinks.GetMultiSupplierAdmin+Cookies.get(constants.COOKIE_SELECTED_BUYER_ID),null);
		if (supplirAdmins.isCatch) {
			message.error(constants.ERROR_MESSAGE);
		} else {
			if(supplirAdmins.resultObj?.statusCode==="2000") {
				setPriorityList(supplirAdmins.resultObj.data)
				setTotal(supplirAdmins.resultObj.data?.getCount || 0);
				setSuppliers(supplirAdmins?.resultObj?.supplierList);
			} else {
				message.error(supplirAdmins.resultObj?.message || constants.ERROR_MESSAGE)
			}
		}
		setloading(false);
	}

	// Table start
	const onChangeTable = (pagination, filters, sorter, extra)=>{
		setSortDesc(sorter.order==="ascend"?false:true);
		fetchData();
	  }
	// Table end
	
  //On supplier selected checkbox
  const onSupplierSelected=(e,supplierId)=>{
    let tempSupplierlist=supplierList;
		let tempIndex=tempSupplierlist.findIndex((item)=>item.supplierId===supplierId);
    if(e.target.checked){
      tempSupplierlist.push(supplierId);
    }else{
      tempSupplierlist=supplierList.filter(item => item!=supplierId);
    }
    setsupplierList(tempSupplierlist);
  }

	//Update selected suppliers
	const updateSelectedSuppliers=async(buyerId=null,supplierId=null,budgetvalue,reload=false,noneValue=false)=>{
		setloading(true);
		if(!supplierId || !supplierId.length>0){
			message.error("Please select suppliers to update.");
		}else{
		let payload=null;
		payload={
			buyerId: buyerId?.toString(),
			supplierId: supplierId.toString(),
			budget: budgetvalue,
			none:noneValue,
		}
		if(buyerId===null){
			payload.LoggedInBuyerId=Cookies.get(constants.COOKIE_LOGGED_BUYER_ID);
			payload.selectedBuyerId= Cookies.get(constants.COOKIE_SELECTED_BUYER_ID);
		}
		const updateSupplierAdmins = await APIUtils.apiCall("PUT",WebLinks.UpdateMultiSupplierAdmin,JSON.stringify(payload));
		if (updateSupplierAdmins.isCatch) {
			message.error(constants.ERROR_MESSAGE);
		} else {
			if(updateSupplierAdmins.resultObj?.statusCode==="2000") {
				message.success(updateSupplierAdmins.resultObj?.message)
			} else {
				message.error(updateSupplierAdmins.resultObj?.message | constants.ERROR_MESSAGE)
			}
		}
		}
		setloading(false);
		if(reload){
		setSuppliers([]);
		fetchData();
		}
	};

	return (
    <>
			<Spin spinning={false}>
				<div>
          <div className='uppercase font-bold text-xl'>Multi Supplier Admin</div>
          <Divider className='mt-2 mb-3' />
					<div className='flex justify-between items-center mb-3'>
						<Space wrap>
							<Form form={form} >
							<Row gutter={[12, 12]}>
								<Col>
								<Space wrap>
								<label>Update all selected jobs to:</label>
								<Form.Item name="supplierList" className='mb-0'>
                  <Space.Compact>
									<Select
										className='!w-72'
										defaultValue={'0'}
										allowClear={false}
										filterOption={(input, option) =>
												option.props.value.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
												option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
										}
										onChange={(e) => setMultiBudget(e)}
										>
											<Option key='Distribute' value='0'>Distribute</Option>
                      <Option key='Budget' value='1'>Budget</Option>
											<Option key='none' value='2'>None</Option>												
									</Select>
                  <Button type="primary" onClick={()=>updateSelectedSuppliers(null,supplierList,multiBudget==="1"?true:false,true,multiBudget==="2"?true:false)}>Update</Button>
                  </Space.Compact>
								</Form.Item>
								</Space>
                </Col>
								<Col></Col>
								</Row>
							</Form>
						</Space>
					</div>
          <div className='overflow-x-auto'>
            <Table dataSource={priorityList} 
              loading={loading}
              sortDirections={['ascend', 'descend','ascend']}
              // pagination={{
              //   onChange: (page) => {
              //     setPageNumber(page);
              //   },
              //   defaultPageSize: constants.pageSize,
              //   pageSizeOptions: ["25", "50", "100"],
              //   total:total,
              // }}
              pagination={false}
              // scroll={{ x: 1000, y: 590 }} 
              // onChange={onChangeTable}
							>
              <Column title="Cost Center"  dataIndex="buyerName" key="buyerName" sorter={(a, b) =>( a.buyerName - b.buyerName)} fixed={window.innerWidth > 1024 ? true : false} />
              {suppliers?.map((item,index)=>{ 
                return(<Column title={item.logoURL && item.logoURL!==""?<><Image src={item.logoURL} preview={false} width={70}/><br/><Checkbox onChange={(e)=>onSupplierSelected(e,item.supplierId)}/></>:item.supplierName} dataIndex={item.supplierName} key={item.supplierName} align='center'
                render={(_,data,pIndex) => {
                  let supplierIndex = data.costCenterSupplierDetails?.findIndex((dataItem)=>dataItem.supplierName === item.supplierName);
                  let supplierItem;
									if(supplierIndex>=0){
									 supplierItem = data.costCenterSupplierDetails[supplierIndex]
									}
                  return(
                    <>
										{/* <>{supplierItem?.supplierId},{supplierIndex}</> */}
										{/* {supplierItem?.supplierId? */}
                    <Select
											className='!w-30'
											placeholder={constants.supplierName}
											defaultValue={supplierItem?.supplierId?supplierItem?.budget?"1":"0":"2"}
											onChange={(e)=>updateSelectedSuppliers(data?.buyerId,[supplierItem?.supplierId?supplierItem.supplierId:item.supplierId],e==="1"?true:false,false,e==="2"?true:false)}
										>
											<Option key='Distribute' value='0'>Distribute</Option>
                      <Option key='Budget' value='1'>Budget</Option>
											<Option key='none' value='2'>None</Option>	
										</Select>
										{/* :<></>} */}
                    </>
                  )
                  }}/>)
                })}
            </Table>
          </div>
				</div>
			</Spin>
		</>
	);
};

export default MultiSupplierAdmin;