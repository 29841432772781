import { DatePicker, Space, Tooltip, Button, Select, Table,Checkbox, Modal, Input, Typography, message, Spin, Form, Image, Row, Col } from 'antd';
import {  EyeOutlined, FileExcelOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import constants from '../../constants/constants';
import dayjs from 'dayjs';
import APIUtils from '../../api/APIUtils';
import WebLinks from '../../api/WebLinks';
import { Excel } from "antd-table-saveas-excel";
import Cookies from 'js-cookie';
const { RangePicker } = DatePicker;
const { Search } = Input;
const { Link } = Typography;
const { Option } = Select;

const ClickDailyReport = (props) => {

	//Variable declaration
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [rangePickerValue, setRangePickerValue] = useState([]);
	const [allowedSupplierList, setAllowedSupplierList] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [clickDailyReportData, setClickDailyReportData] = useState([]);
	const [loader, setLoader] = useState(false);
	const [totalClickDailyCount, setTotalClickDailyCount] = useState(0);
	const [dateSelected, setDateSelected] = useState("");
	const [dailyClickReportTotal, setDailyClickReportTotal] = useState([]);
	const [suplierListLoader,setSuplierListLoader]= useState(false);
	const [supplierids,setSupplierIds]= useState("");
	const [modalDisplayDate,setModalDisplayDate]=useState("");
	const [form] = Form.useForm();
	const [clickReportDetailedData,setClickReportDetailedData]=useState([]);
	const [modalLoader,setModalLoader]=useState(false);
	let todaysDate=dayjs().format(constants.DateFormat);
	const sorter = (a, b) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);

	// Expanded Table
	const expandedTableColumns = [
		{
			title: 'Supplier',
			dataIndex: 'name',
			key: 'name',
			render: (_, record) => {
				return (
					<>
						{record.logoURL && record.logoURL !== "" ?
							<Image src={record.logoURL} preview={false} width={100} /> :
							<span>{record.name}</span>
						}
					</>
				)

			}
		},
		{
			title: 'Clicks',
			dataIndex: 'totalClicks',
			key: 'totalClicks',
			align: 'center'
		},
		{
			title: 'Cost',
			dataIndex: 'cost',
			key: 'cost',
			align: 'center',
			sorter: (a, b) => a.cost - b.cost,
		},
		{
			title: 'CPC',
			dataIndex: 'cpc',
			key: 'cpc',
			align: 'center',
			sorter: (a, b) => a.cpc - b.cpc,
		},
		{
			title: 'Expired',
			dataIndex: 'expired',
			key: 'expired',
			align: 'center',
			sorter: (a, b) => a.expired - b.expired,
		},
		{
			title: 'Rejected',
			dataIndex: 'rejected',
			key: 'rejected',
			align: 'center'
		},
		{
			title: 'Interview',
			dataIndex: 'interview',
			key: 'interview',
			align: 'center'
		},
		{
			title: 'Hired',
			dataIndex: 'hired',
			key: 'hired',
			align: 'center',
			sorter: (a, b) => a.hired - b.hired,
		},
	];
	// Main Table Below
	const columns = [
		{
			title: 'Day',
			dataIndex: 'reportDate',
			key: 'reportDate',
			sorter: (a, b) => sorter(a.reportDate, b.reportDate),
		},
		{
			title: 'Clicks',
			dataIndex: 'totalClicks',
			key: 'totalClicks',
			align: 'center',
			sorter: (a, b) => a.totalClicks - b.totalClicks,
		},
		{
			title: 'Cost',
			dataIndex: 'cost',
			key: 'cost',
			align: 'center',
			sorter: (a, b) => a.cost - b.cost,
		},
		{
			title: 'CPC',
			dataIndex: 'cpc',
			key: 'cpc',
			align: 'center',
			sorter: (a, b) => a.cpc - b.cpc,
		},
		{
			title: 'Expired',
			dataIndex: 'expired',
			key: 'expired',
			align: 'center',
			sorter: (a, b) => a.expired - b.expired,
		},
		{
			title: 'Rejected',
			dataIndex: 'rejected',
			key: 'rejected',
			align: 'center',
			sorter: (a, b) => a.rejected - b.rejected,
		},
		{
			title: 'Interview',
			dataIndex: 'interview',
			key: 'interview',
			align: 'center',
			sorter: (a, b) => a.interview - b.interview,
		},
		{
			title: 'Hired',
			dataIndex: 'hired',
			key: 'hired',
			align: 'center',
			sorter: (a, b) => a.hired - b.hired,
		},
		{
			title: 'View',
			key: 'view',
			align: 'center',
			render: (_,object) =>
				<Tooltip title="View">
					<Button size='small' type="link" onClick={()=>GetClickReportDetailedInfo(object.reportDate)} icon={<EyeOutlined />} />
				</Tooltip>,
		},
	];
	const ReportColOptions = [
		{
			label: 'Time',
			value: 'clickTime',
		},
		{
			label: 'Job Title',
			value: 'jobTitle',
		},
		{
			label: 'Board ID',
			value: 'boardId',
		},
		{
			label: 'Buyer',
			value: 'buyerName',
		},
		{
			label: 'User IP Address',
			value: 'userIpAddr',
		},
	];
	const fullReportColumns = [
		{
			title: '#',
			dataIndex: 'srNo',
			rowScope: 'row',
			width: '7%',
		},
		{
			title: 'Time',
			dataIndex: 'clickTime',
			key: 'clickTime',
			render:(text)=>
			<span>{text}</span>
		},
		{
			title: 'Job Title',
			dataIndex: 'jobTitle',
			key: 'jobTitle',
			width:'30%',
		},
		{
			title: 'Board ID',
			key: 'boardId',
			dataIndex: 'boardId',
		},
		{
			title: 'Buyer',
			key: 'buyerName',
			dataIndex: 'buyerName',
		},
		{
			title: 'User IP Address',
			key: 'userIpAddr',
			dataIndex: 'userIpAddr',
		},
	];
	let [fullReportColumnsToggle, setColumns] = useState(
    fullReportColumns.filter((item) => item.dataIndex !== '')
  );

	//Handles hide column functionality
  let HideCol = (checkedValues) => {
		let tempCheckedValues=checkedValues;
		tempCheckedValues.push('srNo');
		let tempColumns=
		fullReportColumns?.map((item)=>{
				if(tempCheckedValues.includes(item.dataIndex)){
					return item;
				}
		}
		);
		setColumns(tempColumns.filter((item)=>item));
  };

	//On load function call
	useEffect(() => {
		if (props && props.dateSelectedValue) {
			form.setFieldsValue({
				rangePicker: [dayjs(props.dateSelectedValue[0]), dayjs(props.dateSelectedValue[1])],
			})
		} else {
			form.setFieldsValue({
				rangePicker: [dayjs(), dayjs()],
			})
		}
		setCurrentPage(1);
		setPageSize(100);
		getAllowedSuppliers();
		setRangePickerValue(props && props.dateSelectedValue ? props.dateSelectedValue : [dayjs().format(constants.MonthFormat), dayjs().format(constants.MonthFormat)]);
		GetDailyClickReportData(1, 100, props && props.dateSelectedValue ? props.dateSelectedValue[0] : dayjs().format(constants.MonthFormat), props && props.dateSelectedValue ? props.dateSelectedValue[1] : dayjs().format(constants.MonthFormat));
	}, [props]);

	//Functions
	//Modal Functions
	const showModal = () => {
		setIsModalOpen(true);
	};
	const handleOk = () => {
		setIsModalOpen(false);
	};
	const handleCancel = () => {
		setIsModalOpen(false);
	};

	//Function runs when range picker value is changed
	const RangePickerChanged = (_, dateString) => {
		props.changeDate(dateString);
		setRangePickerValue(dateString);
		form.setFieldsValue({
			supplierList:["showall"]
		})
		GetDailyClickReportData(currentPage, pageSize, dateString[0], dateString[1],supplierids);
	};

	//Function to daily report data
	const GetDailyClickReportData = async (currentPage, pazeSize, startDate, endDate,suplierIds="") => {
		setLoader(true);
		const response = await APIUtils.apiCall('GET', WebLinks.GetClickReportDayDetails+ Cookies.get(constants.COOKIE_SELECTED_BUYER_ID) + "&startDate=" + startDate + "&endDate=" + endDate +"&supplierId="+suplierIds);
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			//Start: Added unique key to fix all inner tables opening together
			response.resultObj.data.map((item, index) => {
				item.key = index;
			})
			//end
			setDailyClickReportTotal(response.resultObj.data.pop());
			setClickDailyReportData(response?.resultObj?.data||[]);
			setTotalClickDailyCount(response?.resultObj?.getCount||10);
			setLoader(false);
		} else {
			setClickDailyReportData([]);
			setLoader(false);
			message.error(response.resultObj.message);
		}
	}

	//Function to return date in MM-DD-YYYY format
	const convertDateFormat=(dateValue)=>{
		if(dateValue){
			let date = dateValue.includes('/')?dateValue.split('/'):dateValue.split('-');
			let newDate= date[1]+"-"+date[0]+"-"+date[2];
			return newDate;
		}
	}
	//Function to get click report detailed info
	const GetClickReportDetailedInfo = async (datePassedValue) =>{
		let dateSelected= convertDateFormat(datePassedValue);
		setModalLoader(true);
		setModalDisplayDate(dateSelected);
		const response = await APIUtils.apiCall('GET',WebLinks.GetClickReportDetailedInfo+"?buyerId="+Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)+"&reportDate="+dateSelected);
    if(!response.isCatch && response.resultObj.statusCode==="2000"){
			setClickReportDetailedData(response.resultObj.data);
			showModal();
			setModalLoader(false);
    }else{
			setClickReportDetailedData([]);
      message.error( response.resultObj.message);
			setModalLoader(false);
    }
	};

	//Funcion to render inner table
	const getInnerTable = (tableData) => {
		return (
			<Table
				bordered
				size="small"
				columns={expandedTableColumns}
				dataSource={tableData}
				pagination={false}
			/>
		)
	}

	//Function on change of application table
	const onchangeApplicationTable = (pagination, filters, sorter) => {
		if (pagination) {
			if (pageSize !== pagination.pageSize) {
				setCurrentPage(1);
			} else {
				setCurrentPage(pagination?.current);
			}
			setPageSize(pagination?.pageSize);
			GetDailyClickReportData(pagination?.current, pagination?.pageSize, rangePickerValue[0], rangePickerValue[1],supplierids)
		}
	};

	//Function to display the daily total in the table
	const dailyReportSummary = () => {
		return (
			<>
				<Table.Summary.Row className='bg-teal-50'>
					<Table.Summary.Cell colSpan={2} align='center' index={0}>
						<strong>Total</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={1} className='text-center'>
						<strong>{dailyClickReportTotal.totalClicks}</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={2} className='text-center'>
						<strong>{dailyClickReportTotal.cost}</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={3} className='text-center'>
						<strong>{dailyClickReportTotal.cpc}</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={4} className='text-center'>
						<strong>{dailyClickReportTotal.expired}</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={5} className='text-center'>
						<strong>{dailyClickReportTotal.rejected}</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={6} className='text-center'>
						<strong>{dailyClickReportTotal.interview}</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={7} className='text-center'>
						<strong>{dailyClickReportTotal.hired}</strong>
					</Table.Summary.Cell>
				</Table.Summary.Row>
			</>
		)
	};

	//Function to get Allowed suppliers list
	const getAllowedSuppliers =async()=>{
		setSuplierListLoader(true);
		const response = await APIUtils.apiCall('GET',WebLinks.GetAllowedSuppliers+Cookies.get(constants.COOKIE_SELECTED_BUYER_ID));
		if(!response.isCatch && response.resultObj.statusCode==="2000"){
			setAllowedSupplierList(response?.resultObj?.data||[])
			setSuplierListLoader(false);
		}else{
			setAllowedSupplierList([]);
			setSuplierListLoader(false);
			message.error(response.resultObj.message);
		}
	}

	//Function on change of supplier selection
	const handleSupplierSelect=(selectedSuppliers)=>{
		if(selectedSuppliers.length>0){
			let tempSelectedSuppliers=selectedSuppliers.filter(value => value !== 'showall');
			let selectedSuppliersFinal=tempSelectedSuppliers.length>0?tempSelectedSuppliers:"";
			form.setFieldsValue({
				supplierList:selectedSuppliersFinal
			})
			GetDailyClickReportData(currentPage, pageSize,  rangePickerValue[0], rangePickerValue[1],selectedSuppliersFinal);
		}else{
			form.setFieldsValue({
				supplierList:["showall"]
			})
			GetDailyClickReportData(currentPage, pageSize,  rangePickerValue[0], rangePickerValue[1]);
		}
	}

	//Download table into an excel file
	const DownloadExcel = (columns,data,filename,sheetName) => {
    const excel = new Excel();
    excel
      .addSheet(sheetName)
      .addColumns(columns)
      .addDataSource(data, {
        str2Percent: true
      })
      .saveAs(filename+".xlsx");
  };

	return (
		<>
			<Spin spinning={loader}>
				<div>
					<div >
						{/* <Space > */}
							<Form form={form} className='!w-full flex justify-between items-center mb-3'>
								<Row gutter={[12, 12]}>
								<Col >
								<Form.Item name="rangePicker" className='mb-0'>
									<RangePicker picker="month" onChange={RangePickerChanged} format={constants.MonthFormat} />
								</Form.Item>
								</Col>
								<Col>
								<Space wrap>
								<label>Filter by Supplier</label>
								<Form.Item name="supplierList" className='mb-0'>
									<Select
										loading={suplierListLoader}
										className='!w-72'
										// value={selectedSupplier || []}
										placeholder={constants.supplierName}
										mode='multiple'
										allowClear
										defaultValue={"showall"}
										showSearch
									filterOption={(input, option) =>
											option.props.value.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
											option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
										onChange={(e) => handleSupplierSelect(e)}
										>
											<Option key='showall' value='showall'>Show all</Option>
												{allowedSupplierList?.map((supplier) => (
													<Option key={supplier.supplierId}
															value={supplier.supplierId}
													>
													{supplier.name}</Option>
										))}
									</Select>
								</Form.Item>
								<Tooltip placement='right' title="Download as Excel">
									<Button className='bg-green-800 text-white hover:!text-white hover:!border-green-500' 
										icon={<FileExcelOutlined />} 
										onClick={()=>
											DownloadExcel(
												columns.filter((item) => item.key !== 'view'),
												clickDailyReportData,
												"Click-Report-"+dateSelected+"("+todaysDate+")",
												"Click Report"
												)
											}
										/>
									</Tooltip>
								</Space>
								</Col>
								</Row>
							</Form>
							<Tooltip title="search">
								{/* <Button type="primary" icon={<SearchOutlined />} /> */}
							</Tooltip>
						{/* </Space> */}
					</div>
					<div className='overflow-x-auto'>
						<Table
							columns={columns}
							bordered
							sortDirections={['descend', 'ascend', 'descend']}
							expandable={{
								expandedRowRender: (record) => (<div>
									{getInnerTable(record.clickDayReports)}
								</div>)
							}}
							dataSource={clickDailyReportData}
							// onChange ={onchangeApplicationTable}
							pagination={{
								total: totalClickDailyCount,
								// showSizeChanger: true,
								pageSizeOptions: ["100", "250", "500"],
								defaultPageSize: 100,
								// current: currentPage,
							}}
							summary={() => dailyReportSummary()}
						/>
					</div>
					{/* View full report modal */}
					<Modal
						title={<span>Full report of {dayjs(modalDisplayDate).format(constants.DateFormat)}</span>}
						open={isModalOpen} 
						onOk={handleOk} 
						onCancel={handleCancel}
						footer={[]}
						style={{ top: 20 }}
						width={1000}
					>
						{clickReportDetailedData&&
							<>
							<Spin spinning={modalLoader}>
								<>
									<div className='flex flex-wrap justify-between items-center mb-3 gap-3'>
										<Tooltip placement='right' title="Download as Excel">
											<Button className='bg-green-800 text-white hover:!text-white hover:!border-green-500' icon={<FileExcelOutlined />} 
											onClick={()=>
												DownloadExcel(
													fullReportColumnsToggle,
													clickReportDetailedData,
													"Detailed-Click-Report-"+dayjs(modalDisplayDate).format(constants.DateFormat)+"("+todaysDate+")",
													"Detailed Click Report"
													)
												}
											/>
										</Tooltip>
										<div>
											<span className='font-semibold text-gray-400'>Hide Columns: </span>
											<Checkbox.Group options={ReportColOptions} defaultValue={['clickTime', 'jobTitle', 'boardId', 'buyerName', 'userIpAddr']} onChange={HideCol} />
										</div>
									</div>
									<Table columns={fullReportColumnsToggle} dataSource={clickReportDetailedData} scroll={{ x: 700, y: 300, }} />
								</>
							</Spin>
							</>
						}
					</Modal>
				</div>
			</Spin>
		</>
	);
};

export default ClickDailyReport;