import { InputNumber, Button, Table, Space, message, Form, Image, Select, Empty, Spin } from 'antd';
import constants from '../../constants/constants';
import React from "react";
import { useEffect } from 'react';
import APIUtils from '../../api/APIUtils';
import Cookies from 'js-cookie';
import WebLinks from '../../api/WebLinks';
import { useState } from 'react';
import { GetCustomPriorityLevelIcons, GetPriorityLevelColors, GetPriorityLevelIcons } from '../../globalFunctions/GlobalFunctions';
import { useContext } from 'react';
import { CurrencyContext } from '../../Context';

const BudgetsAdmin = () => {
	const currencyContext=useContext(CurrencyContext);
	const [Loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [total, setTotal] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [form] = Form.useForm();
	const [priorityCheck,setPriorityCheck]=useState("f");
	const columns = [
		{
			dataIndex: 'suppliers',
			key: 'suppliers',
			width: '15%',
			render: (text, record, index) => <>
				{index === 0 ? (<span className='uppercase font-extrabold text-lg'>Total Budget</span>) : (record.logoURL && record.logoURL !== "" ? <Image src={record.logoURL} preview={false} width={100} /> : <span className='uppercase font-bold text-gray-400'>{record.supplierName} Budget</span>)}
			</>
		},
		{
			dataIndex: 'budgetCategory',
			key: 'budgetCategory',
			width: '10%',
			render: (text, record, index) => <span className='font-bold text-gray-400'>
				{index === 0 ? "" : <Select value={record.budget} onChange={(e)=>budgetCategoryChange(e,index,record?.supplierId)} options={[{ value: true, label: <span>Budget</span> },{ value: false, label: <span>Distribute</span> }]} />}
			</span>,
		},
		{
			title: <span className='uppercase font-bold'>Last month</span>,
			align: "left",
			children: [
				{
					title: <span className='font-bold'>Budget</span>,
					dataIndex: 'lastMonth',
					key: 'lastMonth',
					render: (text,record) => <>{record.budget?<span className='font-extrabold'>{currencyContext.currency}{text}</span>:<span className='font-extrabold center'>-</span>}</>
				},
				{
					title: <span>(spend)</span>,
					dataIndex: 'lastMonthSpend',
					key: 'lastMonthSpend',
					render: (_, item) =>
					<>
						{item.budget?
							<Space size="small">
								<span>({currencyContext.currency}{item.lastMonthSpend})</span>
								{item.lastMonth > 0 ? <span className={item.lastMonthSpend > 0 ? 'text-green-500 font-bold' : 'text-red-500 font-bold'}>{item.lastMonthSpentPer + "%"}</span> : null}
							</Space>:
						<span className='font-extrabold center'>-</span>
						}
					</>
				}
			]
		},
		{
			title: <span className='uppercase font-bold'>This month</span>,
			align: "left",
			children: [
				{
					title: <span className='font-bold'>Budget</span>,
					key: 'thisMonth',
					dataIndex: 'thisMonth',
					width: '12.5%',
					render: (text, record, index) => (
						<>{record.budget?
						<>
							{index === 0 || !record.overallBudget ? (
								<span className='font-extrabold'>{currencyContext.currency}{text}</span>) :
								(
									<Form.Item name={'thisMonth' + index} initialValue={text} rules={[
										{
											required: true,
											message: "Required field",
										},
									]}>
										<InputNumber size="large" className='w-44' addonAfter={<Button className='warning-btn uppercase font-bold' onClick={(e) => updateBudget('thisMonth', index, record)}>{constants.UpdateBtn}</Button>} />
									</Form.Item>
								)}
						</>:
						<span className='font-extrabold center'>-</span>}</>
					),
				},
				{
					title: <span>(spend)</span>,
					key: 'thisMonthSpend',
					dataIndex: 'thisMonthSpend',
					width: '12.5%',
					render: (_, item) => (
						<>{item.budget?
						<Space size="small">
							<span>({currencyContext.currency}{item.thisMonthSpend})</span>
							{item.thisMonth > 0 ? <span className={item.thisMonthSpend > 0 ? 'text-green-500 font-bold' : 'text-red-500 font-bold'}>{item.thisMonthSpentPer + "%"}</span> : null}
						</Space>:
						<span className='font-extrabold center'>-</span>}</>

					),
				}
			]
		},
		{
			title: <>
				<span className='uppercase font-bold'>Next month</span>
				<br />
				<span className='font-bold'>Budget</span>
			</>,
			key: 'nextMonth',
			dataIndex: 'nextMonth',
			width: '25%',
			render: (text, record, index) => (
				<>{record.budget?
				<>
					{index === 0 || !record.overallBudget ? (
						<span className='font-extrabold'>{currencyContext.currency}{text}</span>) :
						(<div>
							<Form.Item name={'nextMonth' + index} initialValue={text} rules={[
								{
									required: true,
									message: "Required field",
								},
							]}>
								<InputNumber name={'nextMonth' + index} defaultValue={text} size="large" className='w-44' addonAfter={<Button className='warning-btn uppercase font-bold' onClick={(e) => updateBudget("nextMonth", index, record)}>{constants.UpdateBtn}</Button>} />
							</Form.Item>
						</div>)}
				</>:
				<span className='font-extrabold center'>-</span>}</>
			),
		},
	];

	const budgetCategoryChange=async(e,index,supplierId)=>{
		setLoading(true);
		let payload=null;
		payload={
			buyerId: Cookies.get(constants.COOKIE_SELECTED_BUYER_ID),
			supplierId: supplierId,
			budget: e?1:0,
		}
		const response = await APIUtils.apiCall('PUT', WebLinks.UpdateAllowedBodgetSupplier,JSON.stringify(payload) );
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			let tempData= [...data];
			tempData[index].budget=e;
			setData(tempData);
			setLoading(false);
		} else {
			message.error(response.resultObj.message);
			setLoading(false);
		}
	}

	// Priority expandable sub table.
	const expandedRowRender = (record, index, indent, expanded) => {
		const byPriorityColumns = [
			{
				dataIndex: 'suppliers',
				key: 'suppliers',
				width: '15.6%',
				render: (text) => <span className='uppercase font-bold invisible'>{text} Budget</span>,
			},
			{
				dataIndex: 'priorityLevel',
				key: 'priorityLevel',
				width: '12%',
				render: (text) => <Space>
					<div className={priorityCheck==="t"?GetCustomPriorityLevelIcons(text):GetPriorityLevelColors(text)}></div>
					<span>{text}</span>
				</Space>,
			},
			{
				title: <span className='uppercase font-bold'>Last month</span>,
				align: "left",
				children: [
					{
						title: <span className='font-bold'>Budget</span>,
						dataIndex: 'lastMonth',
						key: 'lastMonth',
						width: '8.5%',
						render: (text) => <span className='font-extrabold'>{currencyContext.currency}{text}</span>
					},
					{
						title: <span>(spend)</span>,
						dataIndex: 'lastMonthSpend',
						key: 'lastMonthSpend',
						width: '13%',
						render: (_, item) =>
							<Space size="small">
								<span>({currencyContext.currency}{item.lastMonthSpend})</span>
								{item.lastMonth > 0 ? <span className={item.lastMonthSpend > 0 ? 'text-green-500 font-bold' : 'text-red-500 font-bold'}>{item.lastMonthSpentPer}%</span> : null}
							</Space>
					}
				]
			},
			{
				title: <span className='uppercase font-bold'>This month</span>,
				align: "left",
				children: [
					{
						title: <span className='font-bold'>Budget</span>,
						key: 'thisMonth',
						dataIndex: 'thisMonth',
						width: '13%',
						render: (text, record, index) => (
							<Form.Item name={'thisMonth-priority' + index} initialValue={text} rules={[
								{
									required: true,
									message: "Required field",
								},
							]}>
								<InputNumber size="large" className='w-44' addonAfter={<Button className='warning-btn uppercase font-bold' onClick={(e) => updateBudget('thisMonth', index, record, true)}>{constants.UpdateBtn}</Button>} />
							</Form.Item>

						),
					},
					{
						title: <span>(spend)</span>,
						key: 'thisMonthSpend',
						dataIndex: 'thisMonthSpend',
						width: '13%',
						render: (_, item) => (
							<Space size="small">
								<span>({currencyContext.currency}{item.thisMonthSpend})</span>
								{item.thisMonth > 0 ? <span className={item.thisMonthSpend > 0 ? 'text-green-500 font-bold' : 'text-red-500 font-bold'}>{item.thisMonthSpentPer}%</span> : null}
							</Space>
						),
					}
				]
			},
			{
				title: <span className='uppercase font-bold'>Next month</span>,
				key: 'nextMonth',
				width: '26%',
				render: (text, record, index) => (
					<Form.Item name={'nextMonth-priority' + index} initialValue={text} rules={[
						{
							required: true,
							message: "Required field",
						},
					]}>
						<InputNumber size="large" className='w-44' addonAfter={<Button className='warning-btn uppercase font-bold' onClick={(e) => updateBudget('nextMonth', index, record, true)}>{constants.UpdateBtn}</Button>} />
					</Form.Item>

				),
			},
		];
		const byPriorityData = record.priorityList.filter((item)=>item.priorityLevel!==10);
		const byPriorityData1 = [
			{
				key: '0',
				suppliers: 'JobSwipe',
				priority: 'Organic'
			},
			{
				key: '1',
				suppliers: 'JobSwipe',
				priority: 'Standard'
			},
			{
				key: '2',
				suppliers: 'JobSwipe',
				priority: 'Extra'
			},
			{
				key: '3',
				suppliers: 'JobSwipe',
				priority: 'Max'
			},
			{
				key: '4',
				suppliers: 'JobSwipe',
				priority: 'Critical'
			},
		];
		return <Table columns={byPriorityColumns} dataSource={byPriorityData} size="middle" showHeader={false} pagination={false} />;
	};
	const getData = async (currentpage = 1) => {
		setLoading(true);
		const todayDate = new Date();
		const response = await APIUtils.apiCall('GET', WebLinks.GetBudget + Cookies.get(constants.COOKIE_SELECTED_BUYER_ID) + "&month=" + (todayDate.getMonth() + 1) + "&year=" + todayDate.getFullYear() + "&limit=" + constants.pageSizeBudgets + "&pageNo=" + currentpage);
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			//Start: Added unique key to fix all inner tables opening together
			response.resultObj.data.map((item, index) => {
				item.key = index;
				if(index===0){
					item.budget=true;
				}
			})
			//end
			setData(response.resultObj.data);
			setTotal(response.resultObj.getCount);
		} else {
			setData([]);
			message.error(response.resultObj.message);
		}
		setLoading(false);
	};
	const onchangeBudgetTable = (pagination, filters, sorter) => {
		if (pagination) {
			setCurrentPage(pagination?.current);
			getData(pagination?.current);
		}
	};
	const updateBudget = async (col, index, record, sub = false) => {
		setLoading(true);
		let fieldName = sub ? col + '-priority' + index : col + index;
		let Budget = form.getFieldValue(fieldName);
		const todayDate = new Date();
		let month = col === "nextMonth" ? todayDate.getMonth() + 2 : todayDate.getMonth() + 1;
		let year = todayDate.getFullYear();
		if (col === "nextMonth" && month > 12) {
			month = 1;
			year++;
		}
		const payload = {
			// id: 0,
			buyerId: parseInt(Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)),
			supplierId: record.supplierId,
			month: month,
			year: year,
			priorityLevel: record.overallBudget ? 10 : record.priorityLevel,
			budget: Budget,
		}
		const response = await APIUtils.apiCall('POST', WebLinks.UpdateBudget, JSON.stringify(payload));
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			let modifiedData = data.map((item, itemIndex) => {
				if (itemIndex === 0) {
					if (!sub || (sub && payload.priorityLevel === 10)) {
						if (col === "nextMonth") {
							item.nextMonth = response.resultObj.budgetTotal;
						} else {
							item.thisMonth = response.resultObj.budgetTotal;
							item.thisMonthSpentPer = response.resultObj.totalSpendPercent;
						}
					}
				}
				if (sub) {
					if (record.supplierId === item.supplierId) {
						//parent
						if (payload.priorityLevel === 10) {
							if (col === "nextMonth") {
								item.nextMonth = payload.budget;
							} else {
								item.thisMonth = payload.budget;
								item.thisMonthSpentPer = response.resultObj.monthSpentPercent;
							}
						}
						if (col === "thisMonth") { item.priorityList[index].thisMonthSpentPer = response.resultObj.monthSpentPercent; }
					}
				} else {
					if (itemIndex === index) {
						if (col === "thisMonth") {
							item.thisMonthSpentPer = response.resultObj.monthSpentPercent;
						}
					}
				}
				return item;
			})
			setData(modifiedData);
			message.success(response.resultObj.message);
		} else {
			message.error(response.resultObj.message);
		}
		setLoading(false);
	};
  //Function to get priority list
  const getBuyerPrioritylist=async()=>{
    let url = WebLinks.GetBuyerPriorityFilters + Cookies.get(constants.COOKIE_SELECTED_BUYER_ID);    
    const response = await APIUtils.apiCall('GET', url);
    if (!response.isCatch && response.resultObj) {
      setPriorityCheck(response?.resultObj?.data?.length>5?"t":"f");
    } else {
      message.error(response?.resultObj?.message);
    }
  };

	useEffect(() => {
		getBuyerPrioritylist();
		getData();
	}, [])
	return (
		<div>
			<Spin spinning={Loading}>
				<div className='flex flex-wrap justify-between items-center gap-1'>
					<div className='uppercase font-bold text-xl'></div>
					<span className='text-gray-400'>{constants.BudgetsMsg}</span>
				</div>
				<div className='mt-3 overflow-x-auto'>
					<Form form={form}>
						{data.length>0?
						<Table
							className='budgets-table'
							size="middle"
							loading={Loading}
							columns={columns}
							dataSource={data}
							scroll={{ x: 965, /* y: 450 */ }}
							expandable={{
								// defaultExpandedRowKeys: ['3', '4'],
								defaultExpandAllRows:true,
								expandedRowRender,
								rowExpandable: (record) => record.overallBudget == false ? true : false,//record.suppliers !== 'Indeed' && record.suppliers !== 'Total' && record.suppliers !== 'Social Media',
							}}
							pagination={{
								pageSize: constants.pageSizeBudgets,
								total: total,
								onChange: onchangeBudgetTable
							}}
						/>:<Empty/>}
					</Form>
				</div>
			</Spin>
		</div>
	);
};

export default BudgetsAdmin;
