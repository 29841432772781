import React from 'react';
import { Typography } from 'antd';
const { Title } = Typography;

const PrivacyPolicy = () => {
    return (
        <div className='container mx-auto my-5'>
            <Title level={2}>Privacy Notice</Title>
            <p className="mb-3">We are committed to protecting and respecting your privacy and are providing this notice because we think it is important that you understand how we look after your personal information and how we make sure that we meet our legal obligations to you under the UK data protection laws. We reserve the right to change this notice to reflect changed circumstances or where necessary to address new legal or regulatory requirements.</p>

            <Title level={3}>Who are we?</Title>
            <p className="mb-3">Hire Intelligence is a trading name of Apply Gateway Ltd, a company incorporated in England and Wales with Company Number 10988286  and registered office address Watcombe Priors Lodge, Torquay, TQ1 4SQ.</p>
            <p className="mb-3">Where we collect your personal data we are a controller of those data and are registered as a data controller with the Information Commissioner&#39;s Office under registration number ZA530038</p>
            <p className="mb-3">If you would like more information about us or the ways in which we handle your personal data, then please write to us at the address provided above or alternatively by using the contact email of our website which can be found <a className="!text-cyan-500 hover:!text-cyan-300" href="https://aemail.com/3l5O">here</a></p>

            <Title level={3}>How do we collect information about you?</Title>
            <p className="mb-3">We may collect different categories of personal data depending on the circumstances. For example, we may collect your personal data in the following situations:</p>
            <ul className="list-disc mb-3 list-inside">
                <li>When you provide data to us by email, telephone or using the &quot;Contact Us&quot; form on our website</li>
                <li>When you subscribe for a service or register as a user</li>
                <li>When you participate in a survey, post ratings or reviews</li>
                <li>When you post a question or an answer on a forum that we administer</li>
                <li>When you upload content, including CVs and/or cover letters</li>
                <li>When you consent to the use of cookies</li>
            </ul>
            <p className="mb-3">In some circumstances we may obtain your information from third parties such as LinkedIn, Indeed and CV Library or other similar platforms, from other companies within our group if you use websites they operate or services they provide, or from our business partners where you have specifically agreed for them to share your data with us.</p>
            <p className="mb-3">We may also collect your personal data through the use of cookies or similar technologies. For more information about how we use cookies please refer to our Cookie Notice which can be accessed at <a className="!text-cyan-500 hover:!text-cyan-300" href="/cookie-policy">https://www.applygateway.com/cookie-policy</a></p>

            <Title level={3}>What kinds of personal data do we collect?</Title>
            <p className="mb-3">We may collect and retain the following types of personal data:</p>
            <ul className="list-disc mb-3 list-inside">
                <li>Name, address and other contact details such as email address and telephone number</li>
                <li>Username and password</li>
                <li>Gender</li>
                <li>Occupation</li>
                <li>Education and employment history</li>
                <li>Employment references</li>
                <li>Personal interests and hobbies</li>
                <li>Profile imagery</li>
                <li>Government issued identity documents and/or numbers</li>
                <li>IP address and other identifiers captured by cookies or similar technologies</li>
                <li>Information and/or documents related to your legal right to work in the UK</li>
                <li>Other information included within your general profile, CV or cover letter</li>
                <li>Where you have requested chargeable services or are an existing customer of chargeable services, payment information including credit/debit card details and account details</li>
            </ul>
            <p className="mb-3">In addition, where you have consented to the collection of information through the use cookies, then we may also collect:</p>
            <ul className="list-disc mb-3 list-inside">
                <li>Technical information such as the type of device you are using, your IP address (which is the Internet protocol address used to connect your computer to the Internet), the type and version of the web browser you use, browser plug-in types and versions, the operating system and platform you use and your time zone setting;</li>
                <li>Information about how you found our website, the pages you looked at on our website and the website you visited when leaving our website (this is the full Uniform Resource Locators (URL) clickstream to, through and from the Website) including the date and time of your visit;</li>
                <li>Information about your visit, including opportunities, products or services that you viewed or searched for; page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), and methods used to browse away from the page and any phone number used to call our customer service number; and</li>
                <li>Your login information</li>
            </ul>
            <p className="mb-3">Sometimes we may anonymise data for further processing. The process of anonymising ensures that you are no longer identifiable from those data meaning that it is no longer personal data for the purposes of applicable data protection legislation.</p>

            <Title level={3}>Why do we process your personal data?</Title>
            <p className="mb-3">We process your personal data for different purposes depending on the nature of our relationship with you and the specific circumstances. For example, we may process your personal data for the following reasons:</p>
            <ul className="list-disc mb-3 list-inside">
                <li>Where you have contacted us in relation to our services, whether in relation to an existing account or otherwise, to enable us to respond to your enquiry.</li>
                <li>Where you are an existing customer, to enable us to provide our services to you including but not limited to creating an account and employment profile, maintaining bulletin boards (including posts and responses) and providing your information to employers who may have job opportunities appropriate to your candidate profile.</li>
                <li>Where you act for a prospective employer, to enable candidates to consider available employment opportunities and to communicate with you with respect to such opportunities.</li>
                <li>Where you have consented for us to send you direct marketing communications by electronic means (such as surveys, alerts, newsletters or other promotional or marketing communications), to generate mailing lists, issue communications to you and to process any responses.</li>
                <li>Where we are required to do so in order to comply with a legal or regulatory obligation.</li>
            </ul>
            <p className="mb-3">Where we have collected information about your visit to our website, we process your data to:</p>
            <ul className="list-disc mb-3 list-inside">
                <li>Ensure that the content is presented in the most effective manner for you and your device</li>
                <li>Allow you to participate in interactive features of our service, when you choose to do so</li>
                <li>Measure or understand the effectiveness of adverts we deliver to you and other users and to make sure that the advertising we deliver to you is relevant to you</li>
                <li>Make suggestions and recommendations to you and other users of the Website about goods or services that may interest you or them</li>
                <li>Administer our website, including troubleshooting, data analysis, testing, research, statistical and survey purposes and</li>
                <li>Help us keep our website safe and secure</li>
            </ul>
            <p className="mb-3">Where we receive information about you from our business partners, we will use that information to provide you with the information, products and services that you have told our business partners you are interested in receiving from us, such as emails regarding job opportunities and news available through our website.</p>
            <p className="mb-3">If you sign up for email job alerts, we will send you job alerts via email. You can unsubscribe at any time by clicking the unsubscribe link in the email or on the Your Details and Email Alerts accounts pages. We will share your email address and job-related preferences with the vendor that we use to send you email job alerts.</p>

            <Title level={3}>On what basis do we process your personal data?</Title>
            <p className="mb-3">We will typically process your personal data based on our legitimate interests or those of a third party in providing and perfecting the services we offer, and in supporting the effective operation and administration of our business. We will only rely on this basis where we are confident that our interests are not outweighed by the interests of your fundamental rights and freedoms, meaning that your rights are always considered and where appropriate will be prioritised over our own.</p>
            <p className="mb-3">In some circumstances we may process your personal data where necessary to perform a contract we have in place with you, or where you have requested us to do so prior to entering into a contract with us. There may also be instances where we have to process your data in order to comply with a legal or regulatory obligation.</p>
            <p className="mb-3">Sometimes we may also process your data on the basis of your consent. We will only rely on your consent where we are confident that it is unambiguous, informed, freely given and specific to the processing activities that are to be undertaken. Where you have provided your consent you can withdraw it at any time by contacting us using one of the methods described above. However, where you do withdraw consent this would not affect the lawfulness of processing that occurred beforehand but it could prevent us from being able to perform action(s) that you have requested.</p>

            <Title level={3}>What happens if I don’t provide you with my information?</Title>
            <p className="mb-3">You don&#39;t have to provide us with your information. However, if you choose not to provide us with data that we need perform a certain action then we will not be able to perform that action. For example, this could prevent us from responding to your enquiry or prevent you from participating in our services in the manner ordinarily envisaged.</p>

            <Title level={3}>Who do we share your information with?</Title>
            <p className="mb-3">We will share your information with the other members of our group of companies, partner job boards, recruitment agencies and prospective employers who will also act as controllers of your data.</p>

            <p className="mb-3">Outside of our group of companies and partner network we will share your information with carefully selected third parties used to supply or perform services which are necessary to our operations. Examples of this include providers of software that we incorporate in our IT infrastructure for business management purposes and service providers engaged in hosting our databases, as well as specialist service providers that are engaged in relation to the processing of payments. However, we will only share your information with such third parties to the extent necessary for them to provide us with the services we need.</p>
            <p className="mb-3">We may also share your information with third parties where:</p>
            <ul className="list-disc mb-3 list-inside">
                <li>We are obtaining professional advice or insurance coverage and disclosure of your information to our advisor(s) or insurer is relevant to that objective.</li>
                <li>You have agreed for marketing communications to be provided by named third party partners in which case we will share your information with those specific third parties.</li>
                <li>We are required to do so in order to comply with our legal obligations or the requirements of a regulatory authority.</li>
                <li>We acquire or dispose of any business or assets in which case we may share your information with the prospective buyer or seller of such business or assets.</li>
                <li>We or substantially all of our assets are acquired by a third party, in which case the information we hold about you will be one of the assets acquired by the third party buyer.</li>
                <li>We believe, in good faith, that it is necessary to protect our rights, property, safety or reputation or the rights, property, safety or reputation of any of our customers or partners.</li>
            </ul>

            <Title level={3}>Will my information be transferred to other countries?</Title>
            <p className="mb-3">The information we collect about you may be transferred to and stored outside the European Economic Area (<strong>EEA</strong>), in particular the United States of America.  It may also be processed by staff operating outside the EEA who work for us, another of our group companies or for one of our suppliers.</p>
            <p className="mb-3">Whenever we transfer your data, store it or otherwise allow it to be processed outside of the EEA we only do so in a manner compliant with the requirements of applicable data protection laws, including by ensuring that the recipient has the benefit of an adequacy decision of the European Commission (<strong>EC</strong>) or by otherwise ensuring that the transfer is subject to adequate safeguards such as the incorporation of EC approved Standard Contractual Clauses.</p>
            <p className="mb-3">For more information with respect to how appropriate protections are afforded to international data transfers please contact us using one of the methods described above.</p>

            <Title level={3}>How do we keep your information secure?</Title>
            <p className="mb-3">All information which you provide to us is stored on our secure servers. We have implemented appropriate technical and organisational measures to guard against the accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to personal data, including by ensuring that employees and third parties only have access to your data where necessary to achieve the purpose(s) of processing.</p>
            <p className="mb-3">However, unfortunately we do not have absolute control over all aspects of security. In particular, where we have given you (or where you have chosen) a password which enables you to access certain parts of our website, you are responsible for keeping this password confidential and we therefore ask you not to share a password with anyone.</p>
            <p className="mb-3">In addition, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our website and any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access.</p>
            <p className="mb-3">Our site may, from time to time, contain links to and from the websites of our partner networks, advertisers and affiliates. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any personal data to these websites.</p>

            <Title level={3}>How long do we keep your information for?</Title>
            <p className="mb-3">We will only retain your data for as long as necessary to fulfil the purpose(s) for which we collected it although may retain your data for a longer period where we have a legal or regulatory obligation to do so. In determining the appropriate retention period we shall have regard to the amount, nature and sensitivity of the data in question, in addition to the purpose(s) of processing and the potential risks of continued storage.</p>

            <Title level={3}>Do you conduct automated decision making?</Title>
            <p className="mb-3">We will not conduct any automated decision making in relation to the data that are provided to us.</p>

            <Title level={3}>What rights do I have in relation to my information?</Title>
            <ol className="list-decimal mb-3 list-inside">
                <li><strong>Right of access</strong>: subject to certain exceptions, you have the right of access to your personal data that we hold.</li>
                <li><strong>Right to rectify</strong>: if you discover that the information we hold about you is inaccurate or incomplete, you have the right to have this information rectified (i.e. corrected).</li>
                <li><strong>Right to be forgotten</strong>: you may ask us to delete information we hold about you in certain circumstances. This right is not absolute and in some circumstances it may not be possible for us to delete the information we hold about you</li>
                <li><strong>Right to restrict processing</strong>: in some cases you may have the right to have the processing of your personal information restricted.</li>
                <li><strong>Right to object to processing</strong>: you may object to the processing of your personal information when it is based upon our legitimate interests or where your data are processed for the purposes of direct marketing or statistical analysis.</li>
                <li><strong>Right to data portability</strong>: you have the right to receive, move, copy or transfer your personal information to another controller when we are processing your personal information based on consent or on a contract and the processing is carried out by automated means.</li>
            </ol>

            <Title level={3}>What if I have a complaint?</Title>
            <p className="mb-3">Should you have any complaints in relation to our handling of your information then we would welcome the opportunity to address that with you and ask that you contact us using one of the methods outlined above.</p>
            <p className="mb-3">You can also contact the Information Commissioner's Office (ICO) in order to register a complaint, by writing to them at Wycliffe House, Water Lane, Wilmslow, Cheshire, SK9 5AF or by contacting them by telephone on +44 (0)303 123 1113. Further information regarding the ICO can be obtained through its website which can be accessed at <a className="!text-cyan-500 hover:!text-cyan-300" href="https://ico.org.uk" target="_blank">https://ico.org.uk</a></p>
            <p className="mb-3">Thank you for using Hire Intelligence.</p>
        </div>
    );
};

export default PrivacyPolicy;