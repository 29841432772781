import React, { useState, useEffect } from 'react';
import { Spin, Table, Button, Modal, Form, Input, Select, message, Divider, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import WebLinks from '../api/WebLinks';
import APIUtils from '../api/APIUtils';
import Cookies from 'js-cookie';
import constants from '../constants/constants';

const { Option } = Select;

const QualityFiltering = () => {
  const buyerId = Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)
  const [loader, setLoader] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [occupations, setOccupations] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedQualityFilterId, setSelectedQualityFilterId] = useState(null);
  const [form] = Form.useForm();

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Occupation ID',
      dataIndex: 'occupationId',
      key: 'occupationId',
    },
    {
      title: 'Feedback Text',
      dataIndex: 'feedbackText',
      key: 'feedbackText',
    },
    {
      title: 'Created On',
      dataIndex: 'createdOn',
      key: 'createdOn',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <span>
          <Button size='small' type="link" icon={<EditOutlined />} onClick={() => handleEdit(record)} />
          <Divider type="vertical" />
          <Popconfirm
            title="Delete feedback"
            description="Are you sure to delete this?"
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button size='small' type="link" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </span>
      ),
    },
  ];

  useEffect(() => {
    getOccupation()
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoader(true);
    const url = WebLinks.GetQualityFilter + buyerId + '/qualityfilter';
    const response = await APIUtils.apiCall('GET', url);
    if (!response.isCatch && response.resultObj.statusCode === "2000") {
      setFilterData(response.resultObj.data?.qualityFilter);
      setLoader(false);
    } else {
      setFilterData([]);
      setLoader(false);
      message.error(response.resultObj.message);
    }
  };

  const getOccupation = async () => {
    const res = await APIUtils.apiCall("GET", WebLinks.GetOccupationList + buyerId, null);
    if (res.isCatch || res?.resultObj?.statusCode !== '2000') {
      message.error(res?.resultObj?.message || constants.ERROR_MESSAGE);
    } else {
      setOccupations(res.resultObj.data);
    }
  }



  const handleAddFilter = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedQualityFilterId(null)
    form.resetFields();
  };

  const handleEdit = (record) => {
    setIsModalVisible(true)
    form.setFieldsValue({
      occupationId: record.occupationId,
      feedbackText: record.feedbackText,
    });
    setSelectedQualityFilterId(record.id)
  };

  const handleDelete = async (record) => {
    const res = await APIUtils.apiCall("DELETE", WebLinks.AddQualityFilter + 'qualityfilter/' + record.id);
    if (res.isCatch || res?.resultObj?.statusCode !== '2000') {
      message.error(res?.resultObj?.message || constants.ERROR_MESSAGE);
    } else {
      fetchData()
      message.success("Deleted successfully");
    }
  };

  const handleOk = async (values) => {
    setLoader(true);
    const payload = {
      "buyerId": buyerId,
      "occupationId": values.occupationId,
      "feedbackText": values.feedbackText
    }
    let url = WebLinks.AddQualityFilter + buyerId + '/qualityfilter';
    if (selectedQualityFilterId) {
      url = WebLinks.AddQualityFilter + buyerId + '/qualityfilter/' + selectedQualityFilterId;
    }
    const response = await APIUtils.apiCall(selectedQualityFilterId ? 'PUT' : 'POST', url, JSON.stringify(payload));
    if (!response.isCatch && response.resultObj.statusCode === "2000") {
      setIsModalVisible(false)
      setSelectedQualityFilterId(null)
      form.resetFields();
      fetchData()
      message.success("Success");
      setLoader(false);
    } else {
      setLoader(false);
      message.error(response.resultObj.message);
    }
  };

  return (
    <div>
      <Spin spinning={loader}>
        <Button type="primary" icon={<PlusOutlined />} onClick={() => handleAddFilter()}>
          Add Quality Filter
        </Button>

        <Table columns={columns} dataSource={filterData} />

        <Modal
          title="Add Quality Filter"
          visible={isModalVisible}
          onOk={() => form.submit()}
          onCancel={() => handleCancel()}
        >
          <Form form={form} onFinish={(e) => handleOk(e)}>
            <Form.Item name="occupationId" label="Occupation">
              <Select>
                <Option key={0} value={0}>General Occupation</Option>
                {occupations.map((occupation) => (
                  <Option key={occupation.id} value={occupation.id}>
                    {occupation.occupation}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="feedbackText" label="Feedback">
              <Input.TextArea />
            </Form.Item>
          </Form>
        </Modal>
      </Spin>
    </div>
  );
};

export default QualityFiltering;
