import { DatePicker, Input, Button, Table, Divider, message, Spin, Select, Radio, Tooltip, Checkbox, Space } from 'antd';
import { DownloadOutlined, FileExcelOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import WebLinks from '../../api/WebLinks';
import APIUtils from '../../api/APIUtils';
import constants from '../../constants/constants';
import { downloadFiles } from '../../globalFunctions/GlobalFunctions';
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;

const MultiCandidateAdmin = (props) => {
    const currentDate = new Date()
    const currentMonth = currentDate.getMonth() + 1
    const currentYear = currentDate.getFullYear()
    var firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    var lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const buyerId = Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)
    //Variable declaration
    const history = useHistory();
    const [quality, setQuality] = useState(0);
    const [loader, setLoader] = useState(false);
    const [supplierLoader, setSupplierLoader] = useState(false);
    const [monthData, setMonthData] = useState({
        monthFrom: currentMonth,
        yearFrom: currentYear,
        monthTo: currentMonth,
        yearTo: currentYear,
        fromDate:firstDay,
        toDate:lastDay,
    });
    const [tableConfig, setTableConfig] = useState({
        pageNo: 1,
        limit: 100,
        sortBy: 'createdOn',
        sortDesc: true,
        search: Cookies.get(constants.COOKIE_CANDIDATE_SEARCH) || ''
    });
    const [total, setTotal] = useState();
    const [candidates, setCandidates] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState(['showall']);
    const { monthFrom, yearFrom, monthTo, yearTo,fromDate,toDate } = monthData;
    const [reload,setReload]=useState(false);
    const [loadcheck,setLoadCheck]=useState(props.location.state?true:false);
    const [qualityRadio,setQualityRadio]=useState(0);
    const [qualityFailRadio,setQualityFailRadio]=useState(0);
    const [internationalRadio,setInternationalRadio]=useState(0);
    const [interviewRadio,setInterviewRadio]=useState(0);
    const [hiredRadio,setHiredRadio]=useState(0);
    const [rejectedRadio,setRejectedRadio]=useState(0);
    const [qualifiedRadio,setQualifiedRadio]=useState(0);
    const columns = [
        {
            title: 'Date',
            dataIndex: 'createdOn',
            key: 'createdOn',
            width:"15%",
            render: (createdOn) => {
                 {return<span className='whitespace-nowrap'><p>{moment(createdOn).format('YYYY-MM-D')}</p><p>{moment(createdOn).format('h:mm a')}</p></span>}
            },
            sorter: (a, b) => moment(a.createdOn).unix() - moment(b.createdOn).unix(),
        },
        {
            title: 'App Status',
            dataIndex: 'appStatus',
            key: 'appStatus',
            sorter: (a, b) => {
                const nameA = `${a.appStatus}`.toLowerCase();
                const nameB = `${b.appStatus}`.toLowerCase();
                return nameA.localeCompare(nameB);
            }
        },
        {
            title: 'Ref',
            dataIndex: 'jobRefNumber',
            key: 'jobRefNumber',
            render: (_, record) => (<>
                <div><p>{record.buyerName}</p><p>{record.jobRefNumber}</p></div>
            </>),
            sorter: (a, b) => a.jobRefNumber - b.jobRefNumber,
        },
        {
            title: 'Job Title',
            dataIndex: 'jobTitle',
            key: 'jobTitle',
            sorter: (a, b) => {
                const nameA = `${a.jobTitle}`.toLowerCase();
                const nameB = `${b.jobTitle}`.toLowerCase();
                return nameA.localeCompare(nameB);
            }
        },
        {
            title: 'Location',
            dataIndex: 'jobLocation',
            key: 'jobLocation',
            sorter: (a, b) => {
                const nameA = `${a.jobLocation}`.toLowerCase();
                const nameB = `${b.jobLocation}`.toLowerCase();
                return nameA.localeCompare(nameB);
            }
        },
        {
            title: 'Name',
            dataIndex: 'firstName',
            key: 'firstName',
            sorter: (a, b) => {
                const nameA = `${a.firstName}`.toLowerCase();
                const nameB = `${b.firstName}`.toLowerCase();
                return nameA.localeCompare(nameB);
            },
            render: (_, record) => (<>
                <div>{record.firstName+" "+record.lastName}</div>
            </>),
        },
        // {
        //     title: 'Last Name',
        //     dataIndex: 'lastName',
        //     key: 'lastName',
        //     sorter: (a, b) => {
        //         const nameA = `${a.lastName}`.toLowerCase();
        //         const nameB = `${b.lastName}`.toLowerCase();
        //         return nameA.localeCompare(nameB);
        //     }
        // },
        {
            title: 'Contact',
            dataIndex: 'email',
            key: 'email',
            render: (_, record) => (<>
                <div>{record.email}</div><div>{record.telephone}</div>
            </>),
            sorter: (a, b) => {
                const nameA = `${a.email}`.toLowerCase();
                const nameB = `${b.email}`.toLowerCase();
                return nameA.localeCompare(nameB);
            }
        },
        {
            title: 'Status',
            key: 'status',
            render: (_, record) => (<>
                <Checkbox className='text-xs' checked={record.rejected}  onChange={(e) => UpdateCandidateStatus(e.target.checked, 'rejected', record)}>Rejected</Checkbox>
                <Checkbox className='text-xs' checked={record.qualified}  onChange={(e) => UpdateCandidateStatus(e.target.checked, 'qualified', record)}>Qualified</Checkbox>
                <Checkbox className='text-xs' checked={record.interview} onChange={(e) => UpdateCandidateStatus(e.target.checked, 'interview', record)}>InterView</Checkbox>
                <Checkbox className='text-xs' checked={record.hire} onChange={(e) => UpdateCandidateStatus(e.target.checked, 'hire', record)}>Hire</Checkbox>
            </>),
        },
        {
            title: 'CV',
            key: 'cv',
            align: 'center',
            render: (_, record) => (<Tooltip title={constants.DownloadCV}>
                <Button type='primary' icon={<DownloadOutlined onClick={() => goToDownloadFile(record)} />} />
            </Tooltip>)
        },
    ];


    //On load function call
    useEffect(() => {
        if (props.location.state && buyerId===props?.location?.state?.state?.buyerId) {
            setLoadCheck(false);
            setSelectedSupplier(props.location.state?.state.supplierId)
            setMonthData(props.location.state?.state.monthData)
            setQuality(props.location.state?.state.quality)
        }
        fetchCandidateSuppliers()
    }, []);

    useEffect(() => {
        if(!loadcheck || buyerId!==props?.location?.state?.state?.buyerId){
            fetchCandidates()
        }
    }, [quality, monthData, selectedSupplier, tableConfig]);

    useEffect(() => {
    },[reload]);    
    //Functions
    const fetchCandidateSuppliers = async () => {
        setSupplierLoader(true);
        const url = WebLinks.GetMultiCandidatesAdminSupliers + "?buyerId=" + buyerId;
        const response = await APIUtils.apiCall('GET', url);
        if (!response.isCatch && response.resultObj.statusCode === "2000") {
            setSuppliers(response.resultObj.data);
            setSupplierLoader(false);
        } else {
            setSupplierLoader(false);
        }
    }

    const fetchCandidates = async () => {
        const suppliers = selectedSupplier.includes("showall") ? '' : selectedSupplier.toString()
        setLoader(true);
        const queryParams = new URLSearchParams(tableConfig).toString();
        const url1 = `${WebLinks.GetMultiCandidatesAdmin}?${queryParams}`;
        let url = url1 +
            `&fromDate=${dayjs(monthData.fromDate).format(constants.DateFormat2)}&toDate=${dayjs(monthData.toDate).format(constants.DateFormat2)}&buyerId=${buyerId}&supplierId=${suppliers}`
        if (quality !== null) {
            url = url1 +
                `&fromDate=${dayjs(monthData.fromDate).format(constants.DateFormat2)}&toDate=${dayjs(monthData.toDate).format(constants.DateFormat2)}&buyerId=${buyerId}&supplierId=${suppliers}&Quality=${quality}`
        }

        const response = await APIUtils.apiCall('GET', url);
        if (!response.isCatch && response.resultObj.statusCode === "2000") {
            setCandidates(response.resultObj.data);
            setTotal(response.resultObj.getCount)
            setQualityRadio(response?.resultObj?.quality||0);
            setQualityFailRadio(response?.resultObj?.qualityFail||0);
            setInternationalRadio(response?.resultObj?.international||0);
            setInterviewRadio(response?.resultObj?.interview||0);
            setHiredRadio(response?.resultObj?.hired||0);
            setRejectedRadio(response?.resultObj?.rejected||0);
            setQualifiedRadio(response?.resultObj?.qualified||0);
            setLoader(false);
        } else {
            setCandidates([]);
            setTotal(0);
            setLoader(false);
            message.error(response.resultObj.message);
        }
    }

    const UpdateCandidateStatus = async (e, title, record) => {
        record[title] = e
        if(title==="hire" && e===true){
            record["interview"]=true;
        }
        setLoader(true);
        const payload = {
            "rejected": record.rejected || false,
            "interview": record.interview|| record.interview || false,
            "hire": record.hire || false,
            "qualified": record.qualified || false,
            "id": record.id
        }
        const url = WebLinks.UpdateCandidateStatus;
        const response = await APIUtils.apiCall('PUT', url, JSON.stringify(payload));
        if (!response.isCatch && response.resultObj.statusCode === "2000") {
            setLoader(false);
            message.success(response.resultObj.message)
        } else {
            setLoader(false);
            message.error(response.resultObj.message);
        }
    }

    const onchange = (dates) => {
        if (dates) {
            const [startMonth, startYear] = [dates[0].month() + 1, dates[0].year()];
            const [endMonth, endYear] = [dates[1].month() + 1, dates[1].year()];
            setMonthData({
                monthFrom: startMonth,
                yearFrom: startYear,
                monthTo: endMonth,
                yearTo: endYear,
                toDate:dayjs(dates[1])?.format(constants.DateFormat2),
                fromDate:dayjs(dates[0])?.format(constants.DateFormat2),
            });
        }
    };

    const handleSearch = (value) => {
        // if (value) {
        //     let data = candidates.filter((record) =>
        //         Object.keys(record).some((key) =>
        //             record[key] && record[key].toString().toLowerCase().includes(value.toLowerCase())
        //         ))
        //     setFilteredData(data)
        // }
        // else {
        //     setFilteredData([])
        // }
        Cookies.set(constants.COOKIE_CANDIDATE_SEARCH, value, { path: '/', expires: constants.COOKIE_EXPIRY_DAYS });
        setTableConfig((prevConfig) => ({
            ...prevConfig,
            search: value,
            pageNo: 1
        }));
    };

    const goToDownloadFile = (record) => {
        props.history.push({
            pathname: '/DownloadFile',
            state: {
                buyerId: record.buyerId,
                cVid: record.cVid,
                userId: record.userId,
                supplierId: selectedSupplier,
                quality: quality,
                monthData: monthData,
                buyerId: buyerId
            }
        });
    }

    const handleSelectChange = (selectedOptions) => {
        if (selectedOptions) {
            const hasShowAllOption = selectedOptions.includes('showall');
            if (hasShowAllOption && selectedOptions.length > 1) {
                const selectedValues = selectedOptions.filter(value => value !== 'showall');
                setSelectedSupplier(selectedValues);
            } else if (hasShowAllOption) {
                setSelectedSupplier(['showall']);
            } else {
                setSelectedSupplier(selectedOptions);
            }
        } else {
            setSelectedSupplier(['showall']);
        }

    };

    const onChangeTable = (pagination, filters, sorter) => {
        const { current, pageSize } = pagination;

        // Update the tableConfig state with the new pagination values
        setTableConfig((prevConfig) => ({
            ...prevConfig,
            pageNo: current,
            limit: pageSize
        }));

        // If there's sorting, update the tableConfig state with the new sorting values
        if (filters && filters.field) {
            setTableConfig((prevConfig) => ({
                ...prevConfig,
                sortBy: filters.field,
                sortDesc: filters.order === 'descend'?true:false
            }));
        }

    };

    const downloadFile = () => {
        setLoader(true)
        const suppliers = selectedSupplier.includes("showall") ? '' : selectedSupplier.toString()
        downloadFiles(WebLinks.DownloadCandidateAdminExcelFile + `&fromDate=${dayjs(monthData.fromDate).format(constants.DateFormat2)}&toDate=${dayjs(monthData.toDate).format(constants.DateFormat2)}&buyerId=${buyerId}&sortBy=createdOn&sortDesc=true&supplierId=${suppliers}&quality=${quality}`,"MultiCandidateAdmin.xlsx");
        setTimeout(() => {
            setLoader(false)
        }, 3000);
    };

    return (
        <div>
            <Spin spinning={loader}>
                <>
                    <div className='uppercase font-bold text-xl'>Multi Candidate Admin</div>
                    <Divider className='mt-2 mb-3' />
                    <div className='flex flex-wrap justify-between items-center gap-2 mb-3'>
                        <RangePicker
                            // picker="month"
                            value={[
                                dayjs(monthData.fromDate),
                                dayjs(monthData.toDate)
                            ]}
                            onChange={onchange}
                            className='w-56'
                        />
                        <Radio.Group defaultValue={1} onChange={(e) => setQuality(e.target.value)} value={quality}>
                            <Radio value={0}>All [{total}]</Radio>
                            <Radio value={1}>Quality [{qualityRadio}]</Radio> 
                            <Radio value={2}>Quality Fail [{qualityFailRadio}]</Radio>
                            <Radio value={3}>International [{internationalRadio}]</Radio>
                            <Radio value={4}>Interview [{interviewRadio}]</Radio>
                            <Radio value={5}>Hired [{hiredRadio}]</Radio>
                            <Radio value={6}>Rejected [{rejectedRadio}]</Radio> 
                            <Radio value={7}>Qualified [{qualifiedRadio}]</Radio>
                        </Radio.Group>
                        <div className='flex flex-wrap justify-between items-center gap-2'>
                            <Select className='w-60'
                                loading={supplierLoader}
                                value={selectedSupplier || []}
                                placeholder={constants.supplierName}
                                mode='multiple'
                                allowClear
                                onChange={(e) => handleSelectChange(e)}>
                                <Option key='showall' value='showall'>Show all</Option>
                                {suppliers?.map((supplier) => (
                                    <Option key={supplier.supplierId}
                                        value={supplier.supplierId}
                                    >
                                        {supplier.supplierName}</Option>
                                ))}

                            </Select>
                            <Search
                                defaultValue={tableConfig.search}
                                placeholder="Search"
                                allowClear
                                enterButton
                                className="w-full md:w-60"
                                onSearch={handleSearch}
                            />
                        </div>
                        <Space wrap>
                            <Tooltip placement='right' title="Download as Excel">
                                <Button
                                    className='bg-green-800 text-white hover:!text-white hover:!border-green-500'
                                    icon={<FileExcelOutlined />}
                                    onClick={()=>{downloadFile()}}
                                >
                                    Download as Excel
                                </Button>
                            </Tooltip>
                        </Space>
                    </div>
                    <div className='overflow-x-auto'>
                        <Table
                            columns={columns}
                            dataSource={filteredData.length ? filteredData : candidates}
                            scroll={{ x: 900 }}
                            pagination={{
                                pageSizeOptions: ["50", "100", "200", "500"],
                                total: total,
                                current: tableConfig.pageNo,
                                pageSize: tableConfig.limit,
                                onChange: (current, pageSize) => {
                                    onChangeTable({ current, pageSize });
                                },
                                onShowSizeChange: (current, pageSize) => {
                                    onChangeTable({ current, pageSize });
                                },
                            }}
                            onChange={(pagination, filters, sorter) => {
                                onChangeTable(pagination, sorter);
                            }}
                            footer={() => 'Showing ' + ((tableConfig.pageNo - 1) * tableConfig.limit + 1) + ' to ' + (((tableConfig.pageNo ) * tableConfig.limit)>=total?total:(tableConfig.pageNo ) * tableConfig.limit ) + ' of ' + total + ' entries'}
                        />
                    </div>
                </>
            </Spin>
        </div>
    );
};

export default MultiCandidateAdmin;