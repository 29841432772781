import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import constants from '../../constants/constants';
import APIUtils from '../../api/APIUtils';
import WebLinks from '../../api/WebLinks';
import { Spin, message } from 'antd';

const MultiDashboard = () => {

  const [reToolEmbededURL,setReToolEmbededURL]=useState("");
  const [loader,setLoader]=useState(false);

  useEffect(() => {
    getMultiDashboardUrl();
  }, [Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)]);

  const getMultiDashboardUrl=async()=>{
    let payload=null;
    payload={
      landingPageUuid: constants.MultiDashboardLandingPageUUIDTabbed,
      email: process.env.REACT_APP_RETOOL_USER,
      groupIds: constants.RetoolGroupIds,
      buyerId: Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)
    }
    setLoader(true);
    const response = await APIUtils.apiCall('POST',WebLinks.GetRetoolDashbordLink,JSON.stringify(payload));
		if(!response.isCatch && response.resultObj.statusCode==="2000"){
      setReToolEmbededURL(response.resultObj.data);
      setTimeout(() => {
        setLoader(false);
      }, 6000);
		}else{
			message.error(response.resultObj.message);
      setLoader(false);
		}
  };

  return (
    <div>
      <Spin spinning={loader}>
      {/* <h3 className='font-bold text-2xl'>Hire Intelligence Advanced Analytics:</h3>11820 */}
        <iframe src={reToolEmbededURL} width="100%" height="20020" frameborder="0"></iframe>
      </Spin>
    </div>
  );
};

export default MultiDashboard;
