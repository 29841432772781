const constants = {
  //ReTool Credentials
  DashboardLandingPageUUID:"64c3ff4e-d5fa-11ee-bb57-83a6126db139",
  MultiDashboardLandingPageUUID:"71309648-d5fa-11ee-942a-d37adf8294d0",
  DashboardLandingPageUUIDTabbed:"504475de-3929-11ef-abff-bb5b54b68785",
  MultiDashboardLandingPageUUIDTabbed:"2d8e0c9c-441b-11ef-8174-cfba81a96f10",
  RetoolGroupIds:[2634742],
  pageSize:25,
  supplierPageSize:250,
  BudgetpageSize:100,
  defaultSocialPagesize:96,
  pageSizeBudgets:100,
  Email: "Email",
  Password: "Password",
  Login: "Login",
  LoginEmailValid:"Enter Email",
  LoginPasswordValid:"Enter Password",
  CreateNew:"Create New",
  SaveOrder:"Save Order",
  UploadHistoryList:["Last ", <strong key='upload-history'>30 day</strong> ," upload history"],
  ActivityLogLast90:["Last ", <strong key='upload-history'>90 days</strong> ," activity log"],
  JobsIndexed:"jobs indexed on Hire Intelligence",
  PriorityLevels:"Priority Levels:",
  UpdatePriorityLevels:"Update all selected jobs:",
  AppReportTab1:"Daily Report",
  AppReportTab2:"Month Report",
  AppReportTab3:"Occupation",
  AppReportTab4:"Occupation Matrix",
  AppReportTab5:"Spend Per Job",
  AppReportTab6:"Application Log",
  AppReportTab7:"Quality",
  AppReportTab8:"Interviews/Hires",
  MultiBudgetsAdminTab1:"Budgets",
  MultiBudgetsAdminTab2:"Not Delivering",
  MultiBudgetsAdminTab3:"Over Budget",
  MultiApplicationReport1:"Month Report",
  MultiApplicationReport2:"Month Day Report",

  Pause:"Pause",
  Paused:"Paused",
  UpdateBtn:"Update",
  DistributionTab1:"Occupation",
  DistributionTab2:"Priority Level",
  BudgetTab1:"Budgets",
  BudgetTab2:"Spend",
  BudgetTab5:"Contracts Spend",
  BudgetTab3:"Spend By Cost Center",
  BudgetTab4:"Budgets By Cost Center",
  BudgetsMsg:"Please contact your account manager to update JOBBOARD BUDGET",
  PriorityPopup:"Create new priority filter",
  PriorityRulesPopup: "Create new priority rules",
  supplierName:"Supplier Name",
  DownloadCV:"Download CV",
  Download:"Download",
  SinglePostDetails:"Update Single Post Details",
  ActivityLogsTab1:"Priority Level",
  ActivityLogsTab2:"Distribution Rules",

  //Date Format
  DateFormat:"DD-MM-YYYY",
  DateFormat2:"YYYY-MM-DD",
  AmericanDateFormat:"MM-DD-YYYY",
  AmericanDateLongFormat:"MMM-DD-YYYY",
  MonthFormat:"MMM-YYYY",

  // Cookies
  COOKIE_EXPIRY_DAYS:3,
  COOKIE_TOKEN:'token',
  COOKIE_LOGGED_BUYER_ID:'loggedInBuyerId',
  COOKIE_LOGGED_BUYER_NAME:'loggedInBuyerName',
  COOKIE_LOGGED_BUYER_SELF_POSTED:'loggedInBuyerSelfPosted',
  COOKIE_SELECTED_BUYER_ID:'SelectedBuyerId',
  COOKIE_SELECTED_BUYER_NAME:'SelectedBuyerName',
  COOKIE_IS_ADMIN:"hireIntelligenceAdmin",
  COOKIE_SELECTED_IS_SUB_BUYER_ADMIN:"selectedSubBuyerAdmin",
  COOKIE_LOGGEDIN_IS_SUB_BUYER_ADMIN:"loggedInSubBuyerAdmin",
  COOKIE_SELECTED_BUYER_SELF_POSTED:"SelectedBuyerSelfPosted",
  COOKIE_SOCIAL_TOKEN:"SocialToken",
  COOKIE_EMAIL_POST:"emailPost",
  COOKIE_CANDIDATE_SEARCH:"candidateSearch",
  COOKIE_CLICK_POST:"clickPost",
  COOKIE_IS_RECRUITER:"userRecruiter",
  // Messages
  ERROR_MESSAGE:"Something went wrong. Please try again later.",
  NO_DATA:"No data found.",
  SUCCESS_SAVE:"Data saved successfully.",
  FAILED_SAVE:"Failed to save data.",
  PRIORITY_FILTER_TEXT_ERROR:"Enter text in atleast one filter.",
  MANAGE_SUPPIER_INFO_MSG:"Please contact your account manager to add this supplier",
  CONTRACT_SPEND_INFO_MSG:"This page is used to assign spend for Job Board fixed term posting contrats to allow CPA/CPI/CPH to be calculated. The value can be assigned to completed months by checing use Last Months Budgets on Suppliers page the inputted value from last month will continue to be assigned each month until changed.",

  //SocialIDs for jobStatus
  AllJobsJS: 'alljobs',
  ActiveJS:'active',
  InActiveJS:'inactive',
  ErrorJS:'error',
  SpendingBudget:'Spending',
  SpentBudget:'Spent',
  NotLiveBudget:'Not Live',

  //spend conditions
  HasLessThan: 'Has less than',
  HasMoreThan: 'Has more than',

  //Currency
  CurrencyConstants:{
    "GBP":"£",
    "EUR":"€",
    "USD":"$"
  },

  //Priotity Rules and filters
  PrioritySubHeader1:"*THESE RULES CAN ONLY BE EDITED BY THE MASTER ACCOUNT.",
  PrioritySubHeader2:"*TO REMOVE THE INHERIT SETTING CONTACT YOUR ACCOUNT MANAGER.",

  //Priority constants
  PriorityConstantList1: [
      {
        value: 0,
        label: (
          <div className="w-3.5 h-3.5 border rounded-full border-black bg-white my-2"></div>
        ),
      },
      {
        value: 1,
        label: (
          <div className="w-3.5 h-3.5 border rounded-full border-black bg-lime-400 my-2"></div>
        ),
      },
      {
        value: 2,
        label: (
          <div className="w-3.5 h-3.5 border rounded-full border-black bg-orange-400 my-2"></div>
        ),
      },
      {
        value: 3,
        label: (
          <div className="w-3.5 h-3.5 border rounded-full border-black bg-red-600 my-2"></div>
        ),
      },
      {
        value: 4,
        label: (
          <div className="w-3.5 h-3.5 border rounded-full border-black bg-purple-600 my-2"></div>
        ),
      },
    ],
  PriorityConstantList2:[
    {
      value: 0,
      label: (
        <div className="w-3.5 h-3.5 border rounded-full border-black bg-white my-2"></div>
      ),
    },
    {
      value: 1,
      label: (
        <div className="w-3.5 h-3.5 border rounded-full border-black bg-lime-400 my-2"></div>
      ),
    },
    {
      value: 2,
      label: (
        <div className="w-3.5 h-3.5 border rounded-full border-black bg-green-900 my-2"></div>
      ),
    },
    {
      value: 3,
      label: (
        <div className="w-3.5 h-3.5 border rounded-full border-black bg-yellow-400 my-2"></div>
      ),
    },
    {
      value: 4,
      label: (
        <div className="w-3.5 h-3.5 border rounded-full border-black bg-orange-400 my-2"></div>
      ),
    },
    {
      value: 5,
      label: (
        <div className="w-3.5 h-3.5 border rounded-full border-black bg-red-600 my-2"></div>
      ),
    },
    {
      value: 6,
      label: (
        <div className="w-3.5 h-3.5 border rounded-full border-black bg-pink-600 my-2"></div>
      ),
    },
    {
      value: 7,
      label: (
        <div className="w-3.5 h-3.5 border rounded-full border-black bg-purple-600 my-2"></div>
      ),
    },
    {
      value: 8,
      label: (
        <div className="w-3.5 h-3.5 border rounded-full border-black bg-slate-900 my-2"></div>
      ),
    },
  ]
}
export default constants;
