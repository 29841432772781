import { useState, useEffect } from 'react';
import { Space, Divider, Select, Button, Input, Table, Popconfirm, Tooltip, Modal, Form, Switch, message, Breadcrumb } from 'antd';
import { EditOutlined, DeleteOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import {useHistory  } from 'react-router-dom'
import Cookies from 'js-cookie';
import constants from '../../constants/constants';
import APIUtils from '../../api/APIUtils';
import WebLinks from '../../api/WebLinks';

const { Search, TextArea } = Input;
const { Option } = Select

const EditJobDetails = () => {

  const { jobId } = useParams();
  const [form] = Form.useForm();
  const history = useHistory();
  const [countryList, setCountryList] = useState([]);
  const [countryLoader, setCountryLoader] = useState(false);
  const [isNewJob, setIsNewJob] = useState(false);
  const [selectedJob, setSelectedJob] = useState([]);

  useEffect(() => {
    fetchCountryList();
    getEditData(jobId);
  }, []);

  const fetchCountryList = async () => {
    const response = await APIUtils.apiCall("GET", WebLinks.GetCountryList);
    if (!response.isCatch) {
      setCountryList(response.resultObj.data)
    } else {
      message.error(response.resultObj.message);
    }
  }

  //Function to save edited job
  const saveJobEditDetails = async () => {
      const selectedCountry = form.getFieldValue('country')
      const selectedCountryObject = countryList.find(country => country.countryCode === selectedCountry);
      const selectedCountryName = selectedCountryObject ? selectedCountryObject.country : '';
  
      let formValues = {
        address: form.getFieldValue('address') || "",
        //companyLogo: form.getFieldValue('companyLogo') || "",
        country: selectedCountryName,
        countryCode: selectedCountry,
        county: form.getFieldValue('county') || "",
        postcode: form.getFieldValue('postcode') || "",
        place: form.getFieldValue('place') || "",
        contract: form.getFieldValue('contract') || false,
        fullTime: form.getFieldValue('fullTime') || false,
        hourlyWork: form.getFieldValue('hourlyWork') || false,
        partTime: form.getFieldValue('partTime') || false,
        applyEmail: Cookies.get(constants.COOKIE_EMAIL_POST)==="true" || Cookies.get(constants.COOKIE_EMAIL_POST)==true?form.getFieldValue('applyEmail')||"":"" 
      }
      console.log("form values",formValues);
      let method, url, body;
      if (isNewJob) {
        method = "POST";
        url = WebLinks.AddNewJob;
        //TODO: change the body accordingly
        body = JSON.stringify({ ...form.getFieldsValue(), place: form.getFieldValue('place'), ...formValues, buyerId: Cookies.get(constants.COOKIE_SELECTED_BUYER_ID) });
      } else {
        method = "PUT";
        url = WebLinks.UpdateSelectedJob;
        body = JSON.stringify({ ...form.getFieldsValue(), id: selectedJob.id, ...formValues });
      }
      const response = await APIUtils.apiCall(method, url, body);
      if (!response.isCatch || response.resultObj.statusCode === "2000") {
        // handleCancel();
        message.success(response.resultObj.message);
        // yourJobsListFetch(currentPage, pageSize, "", sortedValue.columnKey, sortedValue.order === "ascend" ? false : true);
      } else {
        // handleCancel();
        message.error(response?.resultObj?.message || constants.ERROR_MESSAGE);
      }
  };
  //Function the to get the details of a job on click of Edit.
  const getEditData = async (jobId) => {
    setIsNewJob(false)
    const response = await APIUtils.apiCall("GET", WebLinks.GetSelectedJobDetails + jobId);
    if (!response.isCatch && response.resultObj) {
      setSelectedJob(response.resultObj.data);
    }
    let values = response?.resultObj?.data
    form.setFieldsValue({
      title: values.title,
      reference: values.reference,
      companyName: values.companyName,
      //companyLogo: values.companyLogo,
      country: values.countryCode,
      address: values.address,
      place: values.place,
      county: values.county,
      postcode: values.postcode,
      salary: values.salary,
      priority: values.priority,
      description: values.description,
      fullTime: values.fullTime === true ? true : false,
      partTime: values.partTime === true ? true : false,
      contract: values.contract === true ? true : false,
      hourlyWork: values.hourlyWork === true ? true : false,
      applyEmail: values.applyEmail
    })
  };  
  return (
    <div>
      {/* <Breadcrumb className='mb-0' items={[{ title: 'Your Jobs',onClick:()=>history.replace('/your-jobs'),href:'' },{ title: 'Edit Job' }]} />
      <Divider/> */}
      <div className="uppercase font-bold text-xl mb-4 mt-0">Edit Job Detils</div>
      <Form form={form} name="form_in_modal" layout="vertical" onFinish={saveJobEditDetails}>
          <div className="grid xs:grid-cols-1 lg:grid-cols-2 gap-4">
            <Form.Item
              name="title"
              label="Job Title"
              rules={[
                {
                  required: true,
                  message: "Required field",
                },
              ]}
              className="mb-0"
            >
              <Input />
            </Form.Item>
            <Form.Item name="reference" label="Reference" className="mb-0">
              <Input />
            </Form.Item>
            <Form.Item name="companyName" label="Company Name" className="mb-0"
              rules={[
                {
                  required: true,
                  message: "Required field",
                },
              ]}>
              <Input />
            </Form.Item>
            <Form.Item
              name="country"
              label="Country"
              className="mb-0"
            >
              <Select loading={countryLoader}>
                {countryList.map((item) => (
                  <Option key={item.countryCode} value={item.countryCode}>{item.country}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="address" label="Address" className="mb-0">
              <TextArea rows={1} />
            </Form.Item>
            <Form.Item name="place" label="City/Town" className="mb-0">
              <Input />
            </Form.Item>
            <Form.Item name="county" label="County" className="mb-0">
              <Input />
            </Form.Item>
            <Form.Item name="postcode" label="Post code" className="mb-0">
              <Input />
            </Form.Item>
            <Form.Item name="salary" label="Salary"
              // rules={[
              //   {
              //     required: true,
              //     message: "Required field",
              //   },
              // ]} 
              className="mb-0">
              <Input />
            </Form.Item>
            <Form.Item name="priority" label="Priority"
              rules={[
                {
                  required: true,
                  message: "Required field",
                },
              ]} 
              className="mb-0">
              <Select
                className="w-full"
                options={[
                  {
                    value: 0,
                    label: (
                      <Space>
                        <div className="w-3.5 h-3.5 border rounded-full border-black bg-white"></div>
                        Organic
                      </Space>
                    ),
                  },
                  {
                    value: 1,
                    label: (
                      <Space>
                        <div className="w-3.5 h-3.5 border rounded-full border-black bg-lime-400"></div>
                        Standard
                      </Space>
                    ),
                  },
                  {
                    value: 2,
                    label: (
                      <Space>
                        <div className="w-3.5 h-3.5 border rounded-full border-black bg-orange-400"></div>
                        Extra
                      </Space>
                    ),
                  },
                  {
                    value: 3,
                    label: (
                      <Space>
                        <div className="w-3.5 h-3.5 border rounded-full border-black bg-red-600"></div>
                        Max + Social
                      </Space>
                    ),
                  },
                  {
                    value: 4,
                    label: (
                      <Space>
                        <div className="w-3.5 h-3.5 border rounded-full border-black bg-purple-600"></div>
                        Critical + Social
                      </Space>
                    ),
                  },
                ]}
              />
            </Form.Item>
            {Cookies.get(constants.COOKIE_EMAIL_POST)==="true" || Cookies.get(constants.COOKIE_EMAIL_POST)==true?(
              <Form.Item
                name="applyEmail"
                label="Recruiters Email"
              >
                <Input />
              </Form.Item>
             ):null }
          </div>
          <div className="grid xs:grid-cols-1 lg:grid-cols-4 gap-4">
            <Form.Item name="fullTime" label="Full Time" valuePropName='checked'>
              <Switch checkedChildren="Yes" unCheckedChildren="No"
              />
            </Form.Item>
            <Form.Item name="partTime" label="Part Time" valuePropName='checked'>
              <Switch checkedChildren="Yes" unCheckedChildren="No" />
            </Form.Item>
            <Form.Item name="contract" label="Contract" valuePropName='checked'>
              <Switch checkedChildren="Yes" unCheckedChildren="No" />
            </Form.Item>
            <Form.Item name="hourlyWork" label="Hourly Work" valuePropName='checked'>
              <Switch checkedChildren="Yes" unCheckedChildren="No" />
            </Form.Item>
          </div>
          <Form.Item name="description" label="Job Description"
            rules={[
              {
                required: true,
                message: "Required field",
              },
            ]}>
            <TextArea
              autoSize={{
                minRows: 6,
                maxRows: 12,
              }}
            />
          </Form.Item>
          <div className='flex justify-end'>
          <Form.Item>
            <Button className='mr-1' onClick={()=>history.replace('/your-jobs')}>
              Cancel
            </Button>
          </Form.Item>            
          <Form.Item>
            <Button type="primary"  htmlType="submit">
              Save
            </Button>
          </Form.Item>
          </div>
        </Form>
    </div>
  );
};

export default EditJobDetails;