import { Table, message, Button, Popconfirm, Image, Space, Modal, Form, Input, InputNumber, DatePicker, Row, Col, Select, Tooltip } from 'antd';
import { CheckOutlined, CloseOutlined,KeyOutlined ,DeleteOutlined} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import APIUtils from '../api/APIUtils';
import WebLinks from '../api/WebLinks';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';

import constants from '../constants/constants';
import dayjs from 'dayjs';
const { Option } = Select

const ManageSuppliersAdmin = (props) => {
    //States Declaration
    const [activeSuppliers, setActiveSuppliers] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [activeSuppliersTotal, setActiveSuppliersTotal] = useState(0);
    const [suppliersTotal, setSuppliersTotal] = useState(0);
    const [activeSuppliersLoading, setActiveSuppliersloading] = useState(false);
    const [suppliersLoading, setSuppliersloading] = useState(false);
    const [openKeyModal,setOpenKeyModal]=useState(false);
    const [supplierID,setSupplierId]=useState(null);
    const [countryList, setCountryList] = useState([]);
    const [countryLoader, setCountryLoader] = useState(false);
    const [filteredSupplier,setFilteredSuppliers]= useState([]);
    const [activeSuppliersConfig, setActiveSuppliersConfig] = useState({
        pageNumber: 1,
        sortDesc: false,
        pageSize: constants.supplierPageSize
    });
    const [suppliersStateConfig, setSuppliersConfig] = useState({
        pageNumber: 1,
        sortDesc: false,
        pageSize: constants.supplierPageSize
    });
    const history = useHistory();
    const [form] = Form.useForm();

    //Variable Declarations
    const buyerId = Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)

    //On activeSuppliersConfig update
    useEffect(() => {
        fetchActiveSuppliers()
    }, [activeSuppliersConfig]);
    useEffect(() => {
        fetchSuppliers();
        fetchCountryList();
    }, [suppliersStateConfig]);

    //Function to get the list of active suppliers to the buyer
    const fetchActiveSuppliers = async () => {
        setActiveSuppliersloading(true);
        const pageNo = activeSuppliersConfig.pageNumber
        const sortDesc = activeSuppliersConfig.sortDesc
        const pazeSize= suppliersStateConfig.pageSize
        const url = WebLinks.GetMultiActiveSuppliers + "?buyerId=" + buyerId + "&limit=" + pazeSize + "&pageNo=" + pageNo +
            "&sortDesc=" + sortDesc;
        const activeSuppliersList = await APIUtils.apiCall("GET", url);
        if (activeSuppliersList.isCatch) {
            message.error(constants.ERROR_MESSAGE);
        } else {
            if (activeSuppliersList.resultObj?.statusCode === "2000") {
                setActiveSuppliers(activeSuppliersList.resultObj.data)
                setActiveSuppliersTotal(activeSuppliersList.resultObj.data?.getCount || 0)
            } else {
                message.success(activeSuppliersList.resultObj?.message | constants.ERROR_MESSAGE)
            }
        }
        setActiveSuppliersloading(false);
    }

    //Function to get the list of suppliers not assigned to the buyer
    const fetchSuppliers = async () => {
        setSuppliersloading(true);
        const pageNo = suppliersStateConfig.pageNumber
        const sortDesc = suppliersStateConfig.sortDesc
        const pazeSize= suppliersStateConfig.pageSize
        const url = WebLinks.GetMultiSuppliers + "?buyerId=" + buyerId + "&limit=" + pazeSize + "&pageNo=" + pageNo +
            "&sortDesc=" + sortDesc
        const suppliersList = await APIUtils.apiCall("GET", url);
        if (suppliersList.isCatch) {
            message.error(constants.ERROR_MESSAGE);
        } else {
            if (suppliersList.resultObj?.statusCode === "2000") {
                setSuppliers(suppliersList.resultObj.data)
                setFilteredSuppliers(suppliersList.resultObj.data);
                setSuppliersTotal(suppliersList.resultObj?.getCount | 0)
            } else {
                message.success(suppliersList.resultObj?.message | constants.ERROR_MESSAGE)
            }
        }
        setSuppliersloading(false);
    }
    const handleJobInFeedClick =(record)=>{
        // history.push(`/jobs-infeed-block/${record.supplierId}`); //${record.id}
        history.push(`/jobs-infeed/${record.supplierId}`); //${record.id}

    };
    const handleSinglePostJobInFeedClick =(record)=>{
        history.push(`/jobs-singlepost/${record.supplierId}`); //${record.id}
    };

    const fetchCountryList = async () => {
        setCountryLoader(true)
        const response = await APIUtils.apiCall("GET", WebLinks.GetCountryList);
        if (!response.isCatch) {
          setCountryList(response.resultObj.data)
        } else {
          message.error(response.resultObj.message);
        }
        setCountryLoader(false);
    }
    //On country change
    const onCountryslect=(value)=>{
        if(!value||value==="showall"){
            setFilteredSuppliers(suppliers);
        }else{
            let tempFilterData= suppliers.filter((item)=> item.allowedCountryCodes?.toLowerCase()===value?.toLowerCase()||item.allowedCountryCodes?.toLowerCase()==='global');
            setFilteredSuppliers(tempFilterData);
        }
    };
    //initiaze table components
    const section = [
        {
            key: '0',
            title: 'Active Suppliers',
            total: activeSuppliersTotal,
            tableData: activeSuppliers,
            loading: activeSuppliersLoading,
            columns: [{
                dataIndex: "supplierName",
                key: "supplierName",
                render: (_, data) => <Space>
                    {data.logoURL && data.logoURL !== "" ? <Image src={data.logoURL} preview={false} width={100} /> :
                        data.supplierName}{data.singlePostApi?<Button className='!py-0' icon={<span className="text-xl">&#128273;</span>} type='link' onClick={()=>{setSupplierId(data.supplierId); ShowKeyModal(data)}}/>:null}</Space>
            }, {
                dataIndex: "live",
                key: "live",
                title: "Live",
                align: 'center',
                render: (_, data) => <>
                    {renderTick(_, data, "live")}
                </>
            }, {
                title: "Jobs in Feed",
                dataIndex: "jobsInFeedCount",
                key: "jobsinfeed",
                align: 'center',
                render: (_, record) => 
                (
                   <Button type='link' 
                //    disabled={!record.jobsInFeedCount || record.jobsInFeedCount<=0} 
                   onClick={() => handleJobInFeedClick(record)}>{record.jobsInFeedCount || 0}</Button>
                ),
            },{
                title: "Single Post",
                dataIndex: "jobCount",
                key: "jobCount",
                align: 'center',
                render: (_, record) => 
                (
                    <>{record.singlePostApi?
                   <Button type='link' onClick={()=>handleSinglePostJobInFeedClick(record)}>{record.jobCount || 0}</Button>:
                   <span className='font-extrabold center'>-</span>}
                   </>
                ),
            },
             {
                dataIndex: "includeOrganic",
                key: "includeOrganic",
                title: "Include Organic",
                align: 'center',
                render: (_, data) => <>
                    {renderTick(_, data, "includeOrganic")}
                </>
            },
            //  {
            //     dataIndex: "overallBudget",
            //     key: "overallBudget",
            //     title: "Overall Budget",
            //     align: 'center',
            //     render: (_, data) => <>
            //         {renderTick(_, data, "overallBudget")}
            //     </>
            // },
             {
                dataIndex: "useLastMonthsBudget",
                key: "useLastMonthsBudget",
                title: "Use Last Months Budget",
                align: 'center',
                render: (_, data) => <>
                    {renderTick(_, data, "useLastMonthsBudget")}
                </>
            }, {
                dataIndex: "weeklyPacing",
                key: "weeklyPacing",
                title: "WeeklyPacing",
                align: 'center',
                render: (_, data) => <>
                    {renderTick(_, data, "weeklyPacing")}
                </>
            },
            {
                title: '',
                dataIndex: 'delete',
                width: '5%',
                render: (_, record) =>
                    <Tooltip title="Delete" placement='bottom'>
                        <Popconfirm title="Sure you want to delete?" onConfirm={() => handleDelete(record)} placement="left">
                            <Button icon={<DeleteOutlined />} type="link"  size='large' />
                        </Popconfirm>
                    </Tooltip>                       
            },
        ]
        },
        {
            key: '1',
            title: <Form><span className='mb-2 text-xl'>Add Supplier</span>
            <Form.Item className="w-60" label={<span className='text-sm normal-case'>Country</span>}>
            <Select 
                onChange={(e)=>onCountryslect(e)} 
                allowClear 
                showSearch
                loading={countryLoader} 
                placeholder={<span className='text-sm normal-case'>Select Country</span>}  
                className="w-60"
                filterOption={(input, option) =>
                    (option?.props?.children ?? '').toLowerCase().includes(input.toLowerCase())
                }
            >
            <Option key="showall" value="showall">Show All</Option>
            <Option key="global" value="global">Global</Option>
            {countryList.map((item) => (
              <Option key={item.countryCode} value={item.countryCode}>{item.country}</Option>
            ))}
          </Select></Form.Item></Form>,
            total: suppliersTotal,
            tableData: filteredSupplier,
            loading: suppliersLoading,
            columns: [
                {
                    dataIndex: "supplierName",
                    key: "supplierName",
                    render: (_, data) => <>
                    {data.logoURL && data.logoURL !== "" ? <Image src={data.logoURL} preview={false} width={100} /> :
                        data.supplierName}</>
                },
                {
                    dataIndex: "allowedCountryCodes",
                    key: "allowedCountryCodes",
                    title:"Countries",
                }, 
                {
                    dataIndex: "type",
                    key: "type",
                    title:"Type",
                },  
                {
                    key: "Add Supplier",
                    title: "",
                    render: (_, data) => <>
                        {
                            <Popconfirm
                                title="Add Supplier"
                                description="Are you sure to add this supplier?"
                                onConfirm={() => handleAddSupplier(data)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button>Add Supplier</Button>
                            </Popconfirm>
                        }
                    </>
                }
            ]
        }
    ];

    //Delete record
	const handleDelete = async(record) => {
		const deleteResponse = await APIUtils.apiCall("DELETE",WebLinks.DeleteActiveSuppier+buyerId+"&supplierId="+record.supplierId);
		if(deleteResponse.isCatch || deleteResponse?.resultObj?.statusCode!=='2000'){
			message.error( deleteResponse?.resultObj?.message || constants.ERROR_MESSAGE);
		}else{
            fetchActiveSuppliers();
            message.success(deleteResponse?.resultObj?.message)
        }
	};
    //To render the tick and cross icons
    function renderTick(value, data, name) {
        if (value) {
            return <CheckOutlined onClick={() => handleUpdateSupplier(data, name)} className='text-xl text-lime-500' />
        }
        return <CloseOutlined onClick={() => handleUpdateSupplier(data, name)} className='text-xl text-red-500' />
    }

    //Function to add the supplier to the buyer
    const handleAddSupplier = async (data) => {
        const url = WebLinks.AddMultiSupplier + "?buyerId=" + buyerId + "&supplierId=" + data.supplierId;
        const response = await APIUtils.apiCall('POST', url);
        if (!response.isCatch && response.resultObj.statusCode === "2000") {
            message.success(response.resultObj.message)
        } else {
            message.error(response.resultObj.message)
        }
        fetchActiveSuppliers()
        fetchSuppliers()
    }

    //Called when there is an update in table page
    const onChangeTable = (pagination, filters, sorter, extra) => {
        const pageNo = pagination.current
        const sortDesc = sorter.order === "ascend" ? false : true
        const pazeSize= pagination.pageSize
        if (section[0].key === extra.currentDataSource) {
            setActiveSuppliersConfig((prevState) => ({
                ...prevState,
                pageNumber: pageNo,
                sortDesc: sortDesc,
                pageSize:pazeSize
            }));
        }
        else {
            setSuppliersConfig((prevState) => ({
                ...prevState,
                pageNumber: pageNo,
                sortDesc: sortDesc,
                pageSize:pazeSize
            }));
        }
    }

    const handleUpdateSupplier = async (data, property) => {
        const index = activeSuppliers.findIndex(item => item.supplierId === data.supplierId);
        const updatedActiveSuppliers = [...activeSuppliers];
        updateActiveSuppliers(updatedActiveSuppliers, index, property)
        let payload = null;
        payload = {
            "buyerId": buyerId,
            "supplierId": updatedActiveSuppliers[index].supplierId,
            "live": updatedActiveSuppliers[index].live,
            "includeOrganic": updatedActiveSuppliers[index].includeOrganic,
            "overallBudget": updatedActiveSuppliers[index].overallBudget,
            "useLastMonthsBudget": updatedActiveSuppliers[index].useLastMonthsBudget,
            "weeklyPacing": updatedActiveSuppliers[index].weeklyPacing
        }
        const response = await APIUtils.apiCall('PUT', WebLinks.MultiUpdateSupplier, JSON.stringify(payload));
        if (!response.isCatch && response.resultObj.statusCode === "2000") {
            message.success(response.resultObj.message)
        } else {
            updateActiveSuppliers(updatedActiveSuppliers, index, property)
            message.error(response.resultObj.message)
        }
    }

    function updateActiveSuppliers(updatedActiveSuppliers, index, property) {
        if (index !== -1) {
            updatedActiveSuppliers[index] = {
                ...updatedActiveSuppliers[index],
                [property]: !updatedActiveSuppliers[index][property]
            };
            setActiveSuppliers(updatedActiveSuppliers);
        }
    }

    //Key modal function
    const handleOk = () => {
        setOpenKeyModal(false)
        setSupplierId(null);
    };
    const handleCancel = () => {
        form.resetFields();
        setSupplierId(null);
        setOpenKeyModal(false);
    };
    const ShowKeyModal=(data)=>{
        setOpenKeyModal(true);
        getkeydetailValues(data.supplierId);
    }
    const onKeyUpdateFinish = async(values) => {
        let payload=null;
        payload={
            buyerId:buyerId,
            supplierId:supplierID,
            singlePostCreditsStartedOn:values.startedon?dayjs(values.startedon).format(constants.DateFormat2):null,
            singlePostCredits:values.postcredits?values.postcredits:null,
            singlePostUsername:values.keyname?values.keyname:null,
            singlePostCostPerCredits:values.costpercredit?values.costpercredit:null,
            singlePostPassword:values.keyword?values.keyword:null
        }
        const response = await APIUtils.apiCall('PUT',WebLinks.MultiUpdateKeyPost,JSON.stringify(payload) );
        if (!response.isCatch && response.resultObj.statusCode === "2000") {
            message.success(response.resultObj.message)
            handleCancel();
        } else {
            message.error(response.resultObj.message)
        }
    };
    const getkeydetailValues =async(supplierId)=>{
        const response = await APIUtils.apiCall('GET', WebLinks.MultiGetKeyDetails+buyerId+"&supplierId="+supplierId);
        if (!response.isCatch && response.resultObj.statusCode === "2000") {
            form.setFieldsValue({
                startedon:response?.resultObj?.data?.singlePostCreditsStartedOn?dayjs(response.resultObj.data.singlePostCreditsStartedOn):null,
                postcredits:response?.resultObj?.data?.singlePostCredits,
                costpercredit:response?.resultObj?.data?.singlePostCostPerCredits,
                keyname:response?.resultObj?.data?.singlePostUsername,
                keyword:response?.resultObj?.data?.singlePostPassword||"",
            })
        } else {
            // message.error(response?.resultObj?.message)
        }        
    }

    //Render
    return (
        <div>
            {section.map((sectionItem) => (
                <>
                    <div className='flex flex-wrap justify-between items-center gap-1'>
                        <div className='uppercase font-bold text-xl'>{sectionItem.title}</div>
                    </div>
                    <div className='overflow-x-auto'>
                        <Table dataSource={sectionItem.tableData}
                            columns={sectionItem.columns}
                            loading={sectionItem.loading}
                            sortDirections={['ascend', 'descend', 'ascend']}

                            pagination={{
                                pageSizeOptions: ["250", "500", "1000"],
                                total: sectionItem.total,
                                defaultPageSize: constants.supplierPageSize,
                                // showSizeChanger:true
                            }}
                            onChange={(pagination, filters, sorter, extra) => onChangeTable(pagination, filters, sorter, { ...extra, currentDataSource: sectionItem.key })}
                        >
                        </Table>
                    </div>
                </>))}
            
            <Modal
            title={constants.SinglePostDetails}
            open={openKeyModal}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            >
                {/* <p>{supplierID}</p> */}
                <Form
                    name="basic"
                    onFinish={onKeyUpdateFinish}
                    autoComplete="off"
                    form={form}
                    layout='vertical'
                >
                    <Row gutter={[16, 8]}>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Started on"
                                name="startedon"
                            >
                                <DatePicker format={constants.DateFormat2} className='!w-full' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Credits"
                                name="postcredits"
                            >
                                <InputNumber min={0} className='!w-full' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        label="Cost Per Credit"
                        name="costpercredit"
                        >
                        <InputNumber min={0.0} className='w-full'/>
                    </Form.Item>
                    <Form.Item
                        label="User"
                        name="keyname"
                        >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Security code"
                        name="keyword"                       
                        >
                        <Input/>
                    </Form.Item>
                    <div className='flex justify-end'>
                        <Form.Item className='!mb-0'>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </div>
                </Form>                
            </Modal>
        </div>
    );
};

export default ManageSuppliersAdmin;