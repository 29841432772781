import React from 'react';
import { Typography } from 'antd';
const { Title } = Typography;

const DataProcessingPolicy = () => {
  return (
    <div className='container mx-auto my-5'>
      <Title level={2}>Data Processing Policy</Title>
      <p className="mb-3">We are committed to safeguarding the privacy of our customers and other website visitors. This policy explains how we handle your personal data.</p>

      <Title level={4}>How we use your personal data</Title>
      <p className="mb-3">This section provides you with details about:</p>

      <ol className="list-decimal mb-3 list-inside">
        <li>what personal data we may process;</li>
        <li>in the case of personal data that we did not obtain directly from you, where we obtained that data from, and what types of data we have collected;</li>
        <li>the purposes for which we may process your personal data; and</li>
        <li>the legal grounds on which we process your data.</li>
      </ol>

      <Title level={4}>Account data</Title>
      <p className="mb-3">We may process the personal data that you have provided to us when you register so that we can set up your account with us (&quot;account data&quot;). The account data may include your name and email address. The account data may be processed for the purposes of operating our website, providing our services, ensuring the security of our website and services, maintaining back-ups of our databases and communicating with you.</p>
      <p className="mb-3">The legal basis for this processing is our legitimate interests. Where you have provided your consent for us to do so, we may consider you for opportunities that you did not specifically apply for but which we think might be a good fit for your skillset, represent you to employers who may have appropriate vacancies for you, share your account data with our partner agencies, and process your account data to facilitate finding you a job.</p>
      <p className="mb-3">Where you have given us consent to process your information for the purposes detailed above, we will enter the account data into our central recruitment database (&quot;talent pool&quot;).</p>

      <Title level={4}>Profile data</Title>
      <p className="mb-3">We may process other information that you provide to us (&quot;profile data&quot;). This profile data may include your name, address, telephone number, email address, profile pictures, gender, date of birth, relationship status, interests and hobbies, educational details, employment history, curriculum vitae, job preferences and employment details. The profile data may be processed for the purposes of enabling and monitoring your use of our website and services.</p>
      <p className="mb-3">We may also process your profile data in relation to job vacancies that you have applied for, generally processing any job applications, facilitating the recruitment process and furthering our relationship with you. The legal basis for this processing is our legitimate interests in finding an appropriate person for a particular role.</p>
      <p className="mb-3">Where you have provided your consent for us to do so, we may consider you for opportunities that you did not specifically apply for but which we think might be a good fit for your skillset, represent you to employers who may have appropriate vacancies for you, and share your profile data.</p>
      <p className="mb-3">Where you have given us consent to process your information for the purposes detailed above, we will enter the profile data into our central recruitment database (&quot;talent pool&quot;).</p>

      <Title level={4}>Contact data</Title>
      <p className="mb-3">We may collect your details from third-party sources such as LinkedIn, CV-Library or a similar website, this information may include your name, email address or telephone number (&quot;contact data&quot;). We may do this where we identify that you are suitable for an available vacancy. We may use the contact data to contact you to ask whether you would like us to provide you with recruitment services. Our use of the contact data in these circumstances is limited to making contact with you to determine whether you are interested in receiving our services. The legal basis for this processing is our legitimate interest as a business to maintain a viable talent pool.</p>

      <Title level={4}>Usage data</Title>
      <p className="mb-3">We may process data about your use of our website and services (&quot;usage data&quot;). The usage data may include your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views and website navigation paths, as well as information about the timing, frequency and pattern of your service use. The source of the usage data is our analytics tracking system. This usage data may be processed for the purposes of analysing the use of the website and services.</p>
      <p className="mb-3">The legal basis for this processing is our legitimate interests, namely monitoring and improving our website and services.</p>

      <Title level={4}>Enquiry data</Title>
      <p className="mb-3">We may process information contained in any enquiry you submit to us regarding our services (&quot;enquiry data&quot;). The enquiry data may be processed for the purposes of offering, marketing and selling relevant products and/or services to you.</p>
      <p className="mb-3">The legal basis for this processing is consent.</p>

      <Title level={4}>Transaction data</Title>
      <p className="mb-3">We may process information relating to any payments made to you through our website (&quot;transaction data&quot;). The transaction data may include your contact details, your bank account details, and the transaction details. The transaction data may be processed for the purposes of processing these payments and keeping proper records of those transactions.</p>
      <p className="mb-3">The legal basis for this processing is the performance of a contract between you and us and/or taking steps, at your request, to enter into such a contract and our legitimate interests, namely our interest in the proper administration of our website and business.</p>

      <Title level={4}>Notification data</Title>
      <p className="mb-3">We may process information that you provide to us for the purpose of subscribing to our email notifications, job alerts and/or newsletters (&quot;notification data&quot;). The notification data may be processed for the purposes of sending you the relevant notifications, job alerts and/or newsletters.</p>
      <p className="mb-3">The legal basis for this processing is consent.</p>

      <Title level={4}>Correspondence data</Title>
      <p className="mb-3">We may process information contained in or relating to any communication that you send to us (&quot;correspondence data&quot;). The correspondence data may include the communication content and metadata associated with the communication. Our website will generate the metadata associated with communications made using the website contact forms. The correspondence data may be processed for the purposes of communicating with you and record-keeping.</p>
      <p className="mb-3">The legal basis for this processing is our legitimate interests, namely the proper administration of our website and business and communications with users.</p>

      <Title level={4}>Other processing activities</Title>
      <p className="mb-3">In addition to the specific purposes for which we may process your personal data set out above, we may also process any of your personal data where such processing is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person.</p>
      <p className="mb-3" class="mb-4">Please do not supply any other person&#39;s personal data to us, unless we prompt you to do so.</p>

      <Title level={3}>Providing your personal data to others</Title>
      <Title level={4}>To our group companies</Title>
      <p className="mb-3">We may disclose your personal data to any member of our group of companies (this means our subsidiaries, our ultimate holding company and all its subsidiaries) insofar as reasonably necessary for the purposes set out in this policy.</p>

      <Title level={4}>Our insurers/professional advisers</Title>
      <p className="mb-3">We may disclose your personal data to our insurers and/or professional advisers insofar as reasonably necessary for the purposes of obtaining and maintaining insurance coverage, managing risks, obtaining professional advice and managing legal disputes.</p>

      <Title level={4}>Our clients/potential employers/partner agencies</Title>
      <p className="mb-3">We may disclose your profile data and account data to our clients, potential employers, and our partner agencies insofar as reasonably necessary in relation to potential job vacancies, and in the process of representing you to employers who may have an appropriate vacancy for you and administering any job placement.</p>

      <Title level={4}>Where we provide your personal data to any third party</Title>
      <p className="mb-3">Where we share your personal data with any third party, we will ensure this processing is protected by appropriate safeguards including a suitable data processing agreement with that third party.</p>

      <Title level={4}>To comply with legal obligations</Title>
      <p className="mb-3" class="mb-4">In addition to the specific disclosures of personal data detailed above, we may also disclose your personal data where such disclosure is necessary for compliance with a legal obligation we have to comply with, or in order to protect your vital interests or the vital interests of another individual.</p>

      <Title level={3}>Transfers of your personal data outside of the European Economic Area</Title>
      <p className="mb-3">Where your personal data is transferred outside of the EEA, we will ensure that either (a) The European Commission has made an &quot;adequacy decision&quot; with respect to the data protection laws of the country to which it is transferred, or (b) we have entered into a suitable data processing agreement with the third party situated in that country to ensure the adequate protection of your data. Transfers outside of the EEA will be protected by appropriate safeguards.</p>
      <p className="mb-3" class="mb-4">You acknowledge that personal data that you submit for publication through our website or services may be available, via the internet, around the world. We cannot prevent the use (or misuse) of such personal data by others.</p>

      <Title level={3}>Retaining and deleting personal data</Title>
      <p className="mb-3">Personal data that we process for any purpose or purposes shall not be kept for longer than is necessary for that purpose or those purposes. We will retain and delete your personal data in accordance with our Data Retention Policy.</p>
      <p className="mb-3" class="mb-4">We may retain your personal data where such retention is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person.</p>

      <Title level={3}>Amendments</Title>
      <p className="mb-3">We may update this policy from time to time by publishing a new version on our website.</p>
      <p className="mb-3">You should check this page occasionally to ensure you are happy with any changes to this policy.</p>
      <p className="mb-3" class="mb-4">We may notify you of changes to this policy by email.</p>

      <Title level={3}>Your rights</Title>
      <p className="mb-3">You may instruct us to provide you with any personal information we hold about you; provision of such information will be subject to:</p>
      <ol className="list-decimal mb-3 list-inside">
        <li>your request not being found to be unfounded or excessive, in which case a charge may apply; and</li>
        <li>the supply of appropriate evidence of your identity (for this purpose, we will usually accept a photocopy of your passport certified by a solicitor or bank plus an original copy of a utility bill showing your current address).</li>
      </ol>
      <p className="mb-3">We may withhold personal information that you request to the extent permitted by law.</p>
      <p className="mb-3">You may instruct us at any time not to process your personal information for marketing purposes.</p>
      <p className="mb-3">In practice, you will usually either expressly agree in advance to our use of your personal information for marketing purposes, or we will provide you with an opportunity to opt out of the use of your personal information for marketing purposes.</p>
      <p className="mb-3">The rights you have under data protection law are:</p>
      <ul className="list-disc mb-3 list-inside">
        <li>the right to access;</li>
        <li>the right to rectification;</li>
        <li>the right to erasure;</li>
        <li>the right to restrict processing;</li>
        <li>the right to object to processing;</li>
        <li>the right to data portability;</li>
        <li>the right to complain to a supervisory authority; and</li>
        <li>the right to withdraw consent.</li>
      </ul>

      <Title level={4}>Your right to access your data</Title>
      <p className="mb-3">You have the right to ask us to confirm whether or not we process your personal data and, to have access to the personal data, and any additional information. That additional information includes the purposes for which we process your data, the categories of personal data we hold and the recipients of that personal data. You may request a copy of your personal data. The first copy will be provided free of charge, but we may charge a reasonable fee for additional copies.</p>

      <Title level={4}>Your right to rectification</Title>
      <p className="mb-3">If we hold any inaccurate personal data about you, you have the right to have these inaccuracies rectified. Where necessary for the purposes of the processing, you also have the right to have any incomplete personal data about you completed.</p>

      <Title level={4}>Your right to erasure</Title>
      <p className="mb-3">In certain circumstances you have the right to have personal data that we hold about you erased. This will be done without undue delay. These circumstances include the following: it is no longer necessary for us to hold those personal data in relation to the purposes for which they were originally collected or otherwise processed; you withdraw your consent to any processing which requires consent; the processing is for direct marketing purposes; and the personal data have been unlawfully processed. However, there are certain general exclusions of the right to erasure, including where processing is necessary: for exercising the right of freedom of expression and information; for compliance with a legal obligation; or for establishing, exercising or defending legal claims.</p>

      <Title level={4}>Your right to restrict processing</Title>
      <p className="mb-3">In certain circumstances you have the right for the processing of your personal data to be restricted. This is the case where: you do not think that the personal data we hold about you is accurate; your data is being processed unlawfully, but you do not want your data to be erased; it is no longer necessary for us to hold your personal data for the purposes of our processing, but you still require that personal data in relation to a legal claim; and you have objected to processing, and are waiting for that objection to be verified. Where processing has been restricted for one of these reasons, we may continue to store your personal data. However, we will only process it for other reasons: with your consent; in relation to a legal claim; for the protection of the rights of another natural or legal person; or for reasons of important public interest.</p>

      <Title level={4}>Your right to object to processing</Title>
      <p className="mb-3">You can object to us processing your personal data on grounds relating to your particular situation, but only as far as our legal basis for the processing is that it is necessary for: the performance of a task carried out in the public interest, or in the exercise of any official authority vested in us; or the purposes of our legitimate interests or those of a third party. If you make an objection, we will stop processing your personal information unless we are able to: demonstrate compelling legitimate grounds for the processing, and that these legitimate grounds override your interests, rights and freedoms; or the processing is in relation to a legal claim.</p>

      <Title level={4}>Your right to object to direct marketing</Title>
      <p className="mb-3">You can object to us processing your personal data for direct marketing purposes. If you make an objection, we will stop processing your personal data for this purpose.</p>

      <Title level={4}>Automated data processing</Title>
      <p className="mb-3">To the extent that the legal basis we are relying on for processing your personal data is consent, and where the processing is automated, you are entitled to receive your personal data from us in a structured, commonly used and machine-readable format. However, you may not have this right if it would adversely affect the rights and freedoms of others.</p>

      <Title level={4}>Complaining to a supervisory authority</Title>
      <p className="mb-3">If you think that our processing of your personal data infringes data protection laws, you can lodge a complaint with a supervisory authority responsible for data protection. You may do this in the EU member state of your habitual residence, your place of work or the place of the alleged infringement.</p>

      <Title level={4}>Right to withdraw consent</Title>
      <p className="mb-3">To the extent that the legal basis we are relying on for processing your personal data is consent, you are entitled to withdraw that consent at any time. Withdrawal will not affect the lawfulness of processing before the withdrawal.</p>

      <Title level={4}>Exercising your rights</Title>
      <p className="mb-3">You may exercise any of your rights in relation to your personal data by written notice to us in addition to the other methods specified above.</p>
      <p className="mb-3">Thank you for using Hire Intelligence.</p>
    </div>
  );
};

export default DataProcessingPolicy;
