import { useEffect, useState } from 'react';
import { Input, Space, Table, message } from 'antd';
import moment from 'moment';
import Cookies from 'js-cookie';
import APIUtils from '../../api/APIUtils';
import constants from '../../constants/constants';
import WebLinks from '../../api/WebLinks';
import { GetCustomPriorityLevelIcons, GetPriorityLevelColors } from '../../globalFunctions/GlobalFunctions';

const { Search } = Input;

const PriorityLogs = () => {

	const [priorityActivityLog, setPriorityActivityLog] = useState([]);
	const [loading,setloading]=useState(false);
	const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
	const [sortedValue, setSorterValue] = useState({
    columnKey: "",
    order: 'descend'
  });
	const [logsCount, setLogsCount] = useState(0);

	useEffect(()=>{
		priorityActivityLogListFetch(currentPage, pageSize, "", sortedValue.columnKey, sortedValue.order === "ascend" ? false : true);
	},[]);

	//fetch priority activity logs for last 90 days 
	const priorityActivityLogListFetch = async (currentPage, pazeSize, searchValue, sortColumn, isSortDesc) =>{	
		setloading(true);
		const response = await APIUtils.apiCall('GET',WebLinks.GetPriorityActivityLogs+Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)
		+"&limit=" + pazeSize + "&pageNo=" + currentPage + "&search=" + searchValue + "&sortBy=" + sortColumn + "&sortDesc=" + isSortDesc
		);
		if(!response.isCatch && response.resultObj && response.resultObj.statusCode === "2000"){
			setPriorityActivityLog(response.resultObj.data);
			setLogsCount(response.resultObj.getCount);
			setloading(false);
		}else{
      message.error(response?.resultObj?.message||constants.ERROR_MESSAGE);
			setloading(false);
    }
	};

	// Table start
	const priorityActivityLogColumns = [
		{
			title: 'Buyer Name',
			dataIndex: 'buyerName',
			key: 'buyerName',
    	sorter: (a, b) => a.buyerName.localeCompare(b.buyerName)
		},
		{
			title: 'Job Title',
			dataIndex: 'jobTitle',
			key: 'jobTitle',
		},
		{
			title: 'Changed From',
			dataIndex: 'priorityLevelStartName',
			key: 'priorityLevelStartName',
			render:(text,element)=>
			(<Space><div className={element.morePriorityLevels?GetCustomPriorityLevelIcons(element.priorityLevelStart):GetPriorityLevelColors(element.priorityLevelStart)}></div> {text}</Space>)
		},
		{
			title: 'Changed To',
			dataIndex: 'priorityLevelEndName',
			key: 'priorityLevelEndName',
			render:(text,element)=>
			(<Space><div className={element.morePriorityLevels?GetCustomPriorityLevelIcons(element.priorityLevelEnd):GetPriorityLevelColors(element.priorityLevelEnd)}></div> {text}</Space>)
		},
		{
			title: 'Updated On',
			dataIndex: 'createdOn',
			key: 'createdOn',
			render: (createdOn)=>{
				return createdOn?moment(createdOn)?.format('YYYY-MM-D, h:mm a'):"";
			}
		},
		{
			title: 'Updated By',
			dataIndex: 'updatedByBuyerName',
			key: 'updatedByBuyerName',
			sorter: (a, b) => a?.updatedByBuyerName.localeCompare(b?.updatedByBuyerName)
		},
	];

	// Table pagination change
  const onChangeJobTable = (pagination, filters, sorter) => {
    if (pagination) {
      if (pageSize !== pagination.pageSize) {
        setCurrentPage(1);
      } else {
        setCurrentPage(pagination?.current);
      }
      setPageSize(pagination?.pageSize);
      if (sorter) {
        setSorterValue(sorter);
        priorityActivityLogListFetch(pagination?.current, pagination?.pageSize, "", sorter.order ? sorter.columnKey : "", sorter.order === "ascend" ? false : true);
      } else {
        priorityActivityLogListFetch(pagination?.current, pagination?.pageSize, "", sortedValue.columnKey, sortedValue.order === "ascend" ? false : true);
      }
    }
  };

	return (
		<div>
			<div className='uppercase font-bold text-xl'>Activity Logs - Priority Rules</div>
			<div className='flex flex-wrap justify-between items-center gap-1 my-3'>
				<span className='text-slate-500'>{constants.ActivityLogLast90}</span>
				{/* <Search
					placeholder="Search"
					allowClear
					// onSearch={onSearch}
					enterButton
					className='w-60'
				/> */}
			</div>
			<div className='overflow-x-auto'>
				<Table 
					columns={priorityActivityLogColumns} 
					dataSource={priorityActivityLog} 
					loading={loading}
					onChange={onChangeJobTable}
          pagination={{
            total: logsCount,
            showSizeChanger: true,
            pageSizeOptions: ["50", "100", "200", "500"],
            defaultPageSize: 100,
            current: currentPage,
          }}
          footer={() => priorityActivityLog.length>0?
             'Showing ' + ((currentPage - 1) * pageSize + 1) + ' to ' + ((currentPage - 1) * pageSize + priorityActivityLog?.length) + ' of ' + logsCount + ' entries'
            : 'Showing 0 entries'
          }
				/>
			</div>
		</div>
	);
};

export default PriorityLogs;