import React, { useEffect, useState } from 'react';
import { Input, Select, Button, Space, Table, Popconfirm, Tooltip, Switch, Modal, message, Empty, Form, Spin, } from 'antd';
import constants from '../../constants/constants';
import { MenuOutlined, DeleteOutlined, SaveOutlined, CheckOutlined, CloseOutlined, UndoOutlined } from '@ant-design/icons';
import { DndContext } from '@dnd-kit/core';
import {
	arrayMove,
	SortableContext,
	useSortable,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import APIUtils from '../../api/APIUtils';
import WebLinks from '../../api/WebLinks';
import Cookies from 'js-cookie';

const { Option } = Select


// Drag & Drop Stuff
const Row = ({ children, ...props }) => {
	const {
		attributes,
		listeners,
		setNodeRef,
		setActivatorNodeRef,
		transform,
		transition,
		isDragging,
	} = useSortable({
		id: props['data-row-key'],
	});
	const style = {
		...props.style,
		transform: CSS.Transform.toString(
			transform && {
				...transform,
				scaleY: 1,
			},
		)?.replace(/translate3d\(([^,]+),/, 'translate3d(0,'),
		transition,
		...(isDragging
			? {
				position: 'relative',
				zIndex: 9999,
			}
			: {}),
	};
	return (
		<tr {...props} ref={setNodeRef} style={style} {...attributes}>
			{React.Children.map(children, (child) => {
				if (child.key === 'sort') {
					return React.cloneElement(child, {
						children: (
							<MenuOutlined
								ref={setActivatorNodeRef}
								style={{
									touchAction: 'none',
									cursor: 'move',
								}}
								{...listeners}
							/>
						),
					});
				}
				return child;
			})}
		</tr>
	);
};

const OccupationFilterAdmin = () => {
	const buyerId = Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)
	const [occupations, setOccupations] = useState([]);
	// Data
	const [dataSource, setDataSource] = useState([]);
	const [loader, setLoader] = useState(false);
	const [count, setCount] = useState(dataSource.length);
	const [isAddPriorityOpen, setIsAddPriorityOpen] = useState(false);
	const [isOrderChanged, setIsOrderChanged] = useState(false);
	const [form] = Form.useForm();
	const [dynamicForm] = Form.useForm();

	// Table Columns
	const columns = [
		{
			key: 'sort',
			width: '5%'
		},
		{
			title: 'Filter',
			dataIndex: 'filter',
			render: (_, record) =>
				<Form form={dynamicForm}>{filterForm(true, record)}</Form>,
		},
		{
			title: 'operation',
			dataIndex: 'operation',
			width: '5%',
			render: (_, record) =>
				dataSource.length >= 1 ? (
					<Space direction='vertical'>
						{record.isUpdated ? (
							<>
								<Tooltip title="Save" placement='top'>
									<Button icon={<SaveOutlined />} type="link" size='large' onClick={() => (saveFilterData(record))} />
								</Tooltip>
								<Tooltip title="Undo" placement='top'>
									<Button icon={<UndoOutlined />} type="link" size='large' onClick={() => (undoFilterChange(record))} />
								</Tooltip>
							</>
						) : null}
						<Tooltip title="Delete Priority" placement='bottom'>
							<Popconfirm title="Sure you want to delete this filter?" onConfirm={() => handleDelete(record)}>
								<Button icon={<DeleteOutlined />} type="link" danger size='large' />
							</Popconfirm>
						</Tooltip>
					</Space>
				) : null,
		},
	];
	useEffect(() => {
		getData()
		getOccupation();
	}, []);

	const getOccupation = async () => {
		const res = await APIUtils.apiCall("GET", WebLinks.GetOccupationList + buyerId, null);
		if (res.isCatch || res?.resultObj?.statusCode !== '2000') {
			message.error(res?.resultObj?.message || constants.ERROR_MESSAGE);
		} else {
			setOccupations(res.resultObj.data);
		}
	}

	// Delete Priority Filters Row
	const handleDelete = async (filter) => {
		setLoader(true);
		const deleteResponse = await APIUtils.apiCall("DELETE", WebLinks.DeleteOccupationFilter + filter.id);
		if (deleteResponse.isCatch || deleteResponse?.resultObj?.statusCode !== '2000') {
			message.error(deleteResponse?.resultObj?.message || constants.ERROR_MESSAGE);
		} else {
			message.success("Deleted successfully")
			const newData = dataSource.filter((item) => item.key !== filter.key);
			setDataSource(newData);
		}
		setLoader(false);
	};
	// Add New filter
	const addFilter = async (newFilter) => {
		setLoader(true);
		const addResponse = await APIUtils.apiCall("POST", WebLinks.AddOccupationFilter, JSON.stringify(newFilter));

		if (addResponse.isCatch || addResponse?.resultObj?.statusCode !== '2000') {
			message.error(addResponse?.resultObj?.message || constants.ERROR_MESSAGE);
		} else {
			//newFilter.id = addResponse?.resultObj?.priorityFilterId;
			setDataSource([...dataSource, newFilter]);
			setCount(count + 1);
			setIsAddPriorityOpen(false);
			form.resetFields();
			getData();
		}
		setLoader(false);
	};
	const handleOk = (e) => {
				let newfilter = {
			//id: 0,
			buyerId: buyerId,
			key: count + 1,
			//isUpdated: false,
			filterOrder: dataSource.length + 1,
			titleFilterText: e.jobText || "",
			occupationId: e.occupation,
			//locationFilterText: e.locationText || "",
			//priorityLevel: e.priorityLevel,
			titleFilterType: e.jobTitleType || "",
			appendText: e.appendText,
			occupation: "",
			//locationFilterType: e.locationType || "",
			//isLive: e.live === undefined ? true : e.live,
			//changedLive: e.live === undefined ? true : e.live
		}
		addFilter(newfilter);

	};
	const handleCancel = () => {
		form.resetFields();
		setIsAddPriorityOpen(false);
	};
	// Drag & Drop Stuff
	const onDragEnd = ({ active, over }) => {
		if (!isOrderChanged) { setIsOrderChanged(true) }
		if (active.id !== over?.id) {
			setDataSource((previous) => {
				const activeIndex = previous.findIndex((i) => i.key === active.id);
				const overIndex = previous.findIndex((i) => i.key === over?.id);
				return arrayMove(previous, activeIndex, overIndex);
			});
		}
	};
	const getData = async () => {
		setDataSource([]);
		form.resetFields();
		dynamicForm.resetFields();
		const res = await APIUtils.apiCall("GET", WebLinks.GetOccupationfilter + buyerId, null);
		if (res.isCatch || res?.resultObj?.statusCode !== '2000') {
			message.error(res?.resultObj?.message || constants.ERROR_MESSAGE);
			setDataSource([]);
			setCount(0);
		} else {
			const modifiedData = res?.resultObj?.data?.map((item, index) => ({ key: index + 1, isUpdated: false, changedLive: item.isLive, ...item, }));
			setDataSource(modifiedData || []);
			setCount(modifiedData?.length || 0)
		}
	}
	const getRowColor = (record) => {
		if (record.locationFilterType !== "" && record.titleFilterType !== "") {
			return "teal-tr"
		} else if (record.locationFilterType !== "") {
			return "yellow-tr"
		} else {
			return "purple-tr"
		}
	}
	const buildOrderpayload = () => {
		const Filterlist = dataSource;
		return Filterlist.map((item, index) => { item.filterOrder = index + 1; return item; });
	}
	const handleSaveOrder = async () => {
		setLoader(true);
		const payload = await buildOrderpayload();
		const addResponse = await APIUtils.apiCall("PUT", WebLinks.UpdateOccupationOrderPriorityFilter, JSON.stringify(payload));
		if (addResponse.isCatch || addResponse?.resultObj?.statusCode !== '2000') {
			message.error(addResponse?.resultObj?.message || constants.ERROR_MESSAGE);
		} else {
			message.success(addResponse?.resultObj?.message);
			setIsOrderChanged(false);
		}
		setLoader(false);
	}
	const setFilterAsUpdated = (record, isSaved = false, value=null) => {
		const modifiedList = dataSource.map((item) => {
			if (item.key === record.key) {
				item.isUpdated = isSaved ? false : true;
				item.occupationId = value?value:record.occupationId;
			}
			return item;
		})
		setDataSource(modifiedList);
	};
	const saveFilterData = async (record) => {
		setLoader(true);
		const formValues = dynamicForm.getFieldsValue();
		let updateFilter = {
			id: record.id,
			buyerId: record.buyerId,
			filterOrder: record.filterOrder,
			titleFilterText: formValues['jobText-' + record.key],
			titleFilterType: formValues['jobTitleType-' + record.key],
			appendText: formValues['appendText-' + record.key],
			//locationFilterText: record.locationFilterText,
			//locationFilterType: record.locationFilterType,
			//priorityLevel: record.priorityLevel,
			isLive: record.isLive,
			occupationId: formValues['occupation-' + record.key],
			occupation: ""
		}
		if(formValues['jobTitleType-' + record.key].trim() && !formValues['jobText-' + record.key].trim()){
			message.error("Filter text is required.");
		}else{
			const addResponse = await APIUtils.apiCall("PUT", WebLinks.UpdateOccupationFilter, JSON.stringify(updateFilter));
			if (addResponse.isCatch || addResponse?.resultObj?.statusCode !== '2000') {
				message.error(addResponse?.resultObj?.message || constants.ERROR_MESSAGE);
			} else {
				message.success(addResponse?.resultObj?.message);
				setFilterAsUpdated(record, true);
			//	getData();
			}
		}
		setLoader(false);
	};
	const undoFilterChange = (record) => {
		setLoader(true);
		dynamicForm.setFieldsValue({
			['jobText-' + record.key]: record.titleFilterText,
			['jobTitleType-' + record.key]: record.titleFilterType,
			['locationText-' + record.key]: record.locationFilterText,
			['locationType-' + record.key]: record.locationFilterType,
			['priorityLevel-' + record.key]: record.priorityLevel,
			['occupation-' + record.key]:record.occupationId,//not working
			['appendText-' + record.key]:record.appendText,
			// ['live-'+record.key]:record.isLive
		});
		setLiveValue(record, true);
		setLoader(false);
	};
	const getFieldName = (name, update, record = null) => {
		return update ? name + '-' + record?.key || 0 : name;
	}
	const setLiveValue = (record, reset = false) => {
		setFilterAsUpdated(record, reset);
		let list = dataSource.map((item) => {
			if (item.key === record.key) {
				item.changedLive = reset ? item.isLive : !item.changedLive;
			}
			return item;
		});
		setDataSource(list);
	};
	const filterForm = (update = false, record = null) => {
		const jobTitleOptions = [
			{
				value: 'Equals',
				label: 'Equals',
			},
			{
				value: 'Contains',
				label: 'Contains',
			},
		];
		return (
		<div class="overflow-x-auto">
			<table className='w-full priority-list'>
				<colgroup>
					<col width="10%" />
					<col width="20%" />
					<col width="35%" />
					<col width="20%" />
				</colgroup>
				<thead>
					<tr className='uppercase font-bold text-left'>
						<th></th>
						<th>Conditions</th>
						<th>Filter text</th>
						<th>Occupation</th>
					</tr>
				</thead>
				<tbody>

					<tr>
						<td className='text-right uppercase font-bold'>
							If Job Title
						</td>
						<td>
							<Form.Item name={getFieldName('jobTitleType', update, record)} className='!mb-0' initialValue={update ? record.titleFilterType : null}>
								<Select
									allowClear
									className='!w-52 md:!w-full'
									options={jobTitleOptions}
									onChange={() => (update ? setFilterAsUpdated(record) : null)}
								/>
							</Form.Item>
						</td>
						<td>
							<Form.Item name={getFieldName('jobText', update, record)} dependencies={[getFieldName('jobTitleType', update, record)]} hasFeedback className='!mb-0'
								rules={[
									({ getFieldValue }) => ({
										validator(_, value) {
											if ((getFieldValue(getFieldName('jobTitleType', update, record)) && value) || (!getFieldValue(getFieldName('jobTitleType', update, record)) && !value)) {
												return Promise.resolve();
											}
											return Promise.reject(new Error('Enter the text.'));
										},
									}),
								]}
									initialValue={update ? record.titleFilterText : null}><Input onChange={() => (update ? setFilterAsUpdated(record) : null)} className='w-56 md:w-full' /></Form.Item>
						</td>
						<td>
							<Form.Item name={getFieldName('occupation', update, record)} className='!mb-0'
								rules={[
									{
										required: true,
										message: 'Select Occupation',
									},]}
								initialValue={update ? record.occupationId === 0 ? null : record.occupationId : null}
							>
								<Select
									onChange={(e) => (update ? setFilterAsUpdated(record, false, e) : null)}
									allowClear
									showSearch
									filterOption={(input, option) =>
										option.props.value.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
										option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
										className='w-52 md:w-full'
								>{occupations?.map((item) => (
									<Option key={item.id} value={item.id}>{item.occupation}</Option>
								))}
								</Select>
							</Form.Item>
						</td>
					</tr>
					<tr>
						<td className='text-right uppercase font-bold'>
							Append Text to Job Title:
						</td>
						<td>
							<Form.Item name={getFieldName('appendText', update, record)} className='!mb-0'
								dependencies={[getFieldName('appendText', update, record)]} hasFeedback
								initialValue={update ? record.appendText : null}>
								<Input onChange={() => (update ? setFilterAsUpdated(record) : null)} />
							</Form.Item>
						</td>
						<td colSpan={2}></td>
					</tr>
				</tbody>
			</table>
		</div>
		)
	};

	return (
		<div>
			<Spin spinning={loader}>
				<div className='flex justify-between items-center'>
					<div className='uppercase font-bold text-xl'>Occupation Filter</div>
					{/* Add new row button */}
					{/* <Button size="large" type='primary' onClick={handleAdd}>
						{constants.CreateNew}
					</Button> */}
					<Space>
						<Button hidden={!isOrderChanged} size="large" type='primary' onClick={() => handleSaveOrder()}>
							{constants.SaveOrder}
						</Button>
						<Button size="large" type='primary' onClick={() => setIsAddPriorityOpen(true)}>
							{constants.CreateNew}
						</Button>
					</Space>
				</div>
				{/* Draggable rows Priority filter list start. */}
				<div className='mt-3 overflow-x-auto grid gap-y-4'>
					{/* p-3 border rounded-md border-l-8 border-teal-500, border-secondary, border-violet-500 */}
					{dataSource.length > 0 ? (
						<DndContext onDragEnd={onDragEnd}>
							<SortableContext
								// rowKey array
								items={dataSource.map((i) => i.key)}
								strategy={verticalListSortingStrategy}
							>
								<Table
									components={{
										body: {
											row: Row,
										},
									}}
									rowKey="key"
									columns={columns}
									dataSource={dataSource}
									showHeader={false}
									pagination={false}
									size="small"
									className='priority-table'
									rowClassName={(record, index) => (getRowColor(record))} /* yellow-tr, purple-tr */
								/>
							</SortableContext>
						</DndContext>
					) : <Empty description={"You currently have no filters."} />}
				</div>
				{/* Priority Popup */}
				<Modal
					title={constants.PriorityPopup}
					open={isAddPriorityOpen}
					onOk={() => form.submit()}
					onCancel={() => handleCancel()}
					okText={<span className="capitalize">Create</span>}
					cancelText={<span className="capitalize">Reset</span>}
					cancelButtonProps={{ disabled: loader }}
					width={1000}
					confirmLoading={loader}
				>
					<Form onFinish={handleOk} form={form}>
						{filterForm()}
					</Form>

				</Modal>
			</Spin>
		</div>
	);
};

export default OccupationFilterAdmin;