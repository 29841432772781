import { Table, Space, message, Image, Empty, Spin } from 'antd';
import constants from '../constants/constants';
import React, { useContext, useEffect, useState } from "react";
import WebLinks from '../api/WebLinks';
import { GetCustomPriorityLevelIcons, GetPriorityLevelColors, GetPriorityLevelIcons } from '../globalFunctions/GlobalFunctions';
import APIUtils from '../api/APIUtils';
import Cookies from 'js-cookie';
import { CurrencyContext } from '../Context';

const Budgets = () => {
	const currencyContext=useContext(CurrencyContext);
	const [Loading,setLoading]=useState(false);
	const [data,setData]=useState([]);
	const [total,setTotal]=useState(0);
	const [currentPage,setCurrentPage]=useState(1);
	const [priorityCheck,setPriorityCheck]=useState("f");
	const getoverallLabel=(record,index)=>{
		if (index === 0) {
			return '';
		} else if(record.budget) {
			return '(Budget)';
		} else {
			return '(Distribute)';
		}
	};
	const columns = [
		{
			dataIndex: 'suppliers',
			key: 'suppliers',
			width: '20%',
			render: (text, record, index) =>
			<>
				{index === 0 ? (<span className='uppercase font-extrabold text-lg'>Total Budget</span>) : (record.logoURL && record.logoURL!==""? <Image src={record.logoURL} preview={false} width={100}/> : <span className='uppercase font-bold text-gray-400'>{record.supplierName} Budget</span>)}
			</>
		},
		{
			dataIndex: 'budgetCategory',
			key: 'budgetCategory',
			width: '15%',
			render: (text, record, index) => 
			<span className='font-bold text-gray-400'>
				{getoverallLabel(record,index)}
			</span>,
		},
		{
			title: <span className='uppercase font-bold'>Last month</span>,
			align: "left",
			children:[
				{
					title:<span className='font-bold'>Budget</span>,
					dataIndex: 'lastMonth',
					key: 'lastMonth',
					// align:"center",
					render: (text,record) => <>{record.budget?<span className='font-extrabold'>{currencyContext.currency}{text}</span>:<span className='font-extrabold center'>-</span>}</>
				},
				{
					title: <span>(spend)</span>,
					dataIndex:'lastMonthSpend',
					key:'lastMonthSpend',
					render:(_,item) =>
					<>
					{item.budget?
					<Space size="small">
						<span>({currencyContext.currency}{item.lastMonthSpend})</span>
						{item.lastMonth>0?<span className={item.lastMonthSpend==0?'text-red-500 font-bold':'text-green-500 font-bold'}>{item.lastMonthSpentPer+"%"}</span>:null}
					</Space>
					:<span className='font-extrabold center'>-</span>}
					</>
				}
			]
		},
		{
			title: <span className='uppercase font-bold'>This month</span>,
			align: "left",
			children:[
				{
					title:<span className='font-bold'>Budget</span>,
					key: 'thisMonth',
					dataIndex: 'thisMonth',
					render: (text,record) => (
						<>{record.budget?<span className='font-extrabold'>{currencyContext.currency}{text}</span>:<span className='font-extrabold center'>-</span>}</>
					),
				},
				{
					title:<span>(spend)</span>,
					key: 'thisMonthSpend',
					dataIndex: 'thisMonthSpend',
					render: (_,item) => (
						<>{item.budget?
						<Space size="small">
							<span>({currencyContext.currency}{item.thisMonthSpend})</span>
							{item.thisMonth>0?<span className={item.thisMonthSpend==0?'text-red-500 font-bold':'text-green-500 font-bold'}>{item.thisMonthSpentPer+"%" }</span>:null}
						</Space>
						:<span className='font-extrabold center'>-</span>}</>
					),
				}
			]
		},
		{
			title: <>
				<span className='uppercase font-bold'>Next month</span>
				<br/>
				<span className='font-bold'>Budget</span>
			</>,
			key: 'nextMonth',
			dataIndex: 'nextMonth',
			render: (text,record) => (
				<>{record.budget?<span className='font-extrabold'>{currencyContext.currency}{text}</span>:<span className='font-extrabold center'>-</span>}</>
			),
		},
	];
	// const data = [
	// 	{
	// 		key: '0',
	// 		suppliers: 'Total',
	// 		budgetCategory: '',
	// 		lastmonth:1000,
	// 		lastmonthSpend:5000,
	// 		thismonth: 4000,
	// 		thismonthSpend:3300,
	// 		nextmonth: 4000,
	// 		OverallBudget :true
	// 	},
	// 	{
	// 		key: '1',
	// 		suppliers: 'Social Media',
	// 		budgetCategory: '',
	// 		lastmonth:5000,
	// 		lastmonthSpend:5000,
	// 		thismonth: 5000,
	// 		thismonthSpend:1000,
	// 		nextmonth: 6000,
	// 		OverallBudget :true
	// 	},
	// 	{
	// 		key: '2',
	// 		suppliers: 'Indeed',
	// 		budgetCategory: 'Overall',
	// 		lastmonth:5000,
	// 		lastmonthSpend:5000,
	// 		thismonth: 6000,
	// 		thismonthSpend:1000,
	// 		nextmonth: 6000,
	// 		OverallBudget :true
	// 	},
	// 	{
	// 		key: '3',
	// 		suppliers: 'JobSwipe',
	// 		budgetCategory: 'By Priority',
	// 		lastmonth:5000,
	// 		lastmonthSpend:5000,
	// 		thismonth: 3000,
	// 		thismonthSpend:1000,
	// 		nextmonth: 3000,
	// 		OverallBudget :false,
	// 		priorityList:[{
	// 			key: '0',
	// 			suppliers: 'JobSwipe',
	// 			priority: 4,
	// 			lastmonth:8000,
	// 			lastmonthSpend:8000,
	// 			thismonth: 7000,
	// 			thismonthSpend:7000,
	// 			nextmonth: 7000,
	// 		},
	// 		{
	// 			key: '1',
	// 			suppliers: 'JobSwipe',
	// 			priority: 1,
	// 			lastmonth:5000,
	// 			lastmonthSpend:5000,
	// 			thismonth: 3000,
	// 			thismonthSpend:1000,
	// 			nextmonth: 3000,
	// 		},
	// 		{
	// 			key: '3',
	// 			suppliers: 'JobSwipe',
	// 			priority: 2,
	// 			lastmonth:5000,
	// 			lastmonthSpend:5000,
	// 			thismonth: 3000,
	// 			thismonthSpend:1000,
	// 			nextmonth: 3000,
	// 		},
	// 	]
	// 	},
	// 	{
	// 		key: '4',
	// 		suppliers: 'JobSwipe',
	// 		budgetCategory: 'By Priority',
	// 		lastmonth:5000,
	// 		lastmonthSpend:5000,
	// 		thismonth: 3000,
	// 		thismonthSpend:1000,
	// 		nextmonth: 3000,
	// 		OverallBudget :true
	// 	},
	// 	{
	// 		key: '111',
	// 		suppliers: 'Indeed',
	// 		lastmonth:5000,
	// 		lastmonthSpend:5000,
	// 		thismonth: 3000,
	// 		thismonthSpend:1000,
	// 		nextmonth: 3000,
	// 		budgetCategory: 'Overall',
	// 		OverallBudget :true,
	// 		priorityList:[{
	// 			key: '10',
	// 			priority: 10,
	// 			lastmonth:5000,
	// 			lastmonthSpend:5000,
	// 			thismonth: 3000,
	// 			thismonthSpend:1000,
	// 			nextmonth: 3000,
	// 		},]
	// 	},
	// 	{
	// 		key: '113',
	// 		suppliers: 'JobSwipe',
	// 		lastmonth:5000,
	// 		lastmonthSpend:5000,
	// 		thismonth: 3000,
	// 		thismonthSpend:1000,
	// 		nextmonth: 3000,
	// 		budgetCategory: 'By Priority',
	// 		OverallBudget :false,
	// 		priorityList:[
	// 			{
	// 				key: '1',
	// 				priority: 1,
	// 				lastmonth:5000,
	// 				lastmonthSpend:5000,
	// 				thismonth: 3000,
	// 				thismonthSpend:1000,
	// 				nextmonth: 3000,
	// 			},
	// 			{
	// 				key: '2',
	// 				priority: 2,
	// 				lastmonth:5000,
	// 				lastmonthSpend:5000,
	// 				thismonth: 3000,
	// 				thismonthSpend:1000,
	// 				nextmonth: 3000,
	// 			}
	// 		]
	// 	}
	// ];
	// Priority expandable sub table.
	const expandedRowRender = (record,index, indent, expanded) => {
		const byPriorityColumns = [
			{
				dataIndex: 'suppliers',
				key: 'suppliers',
				width: '21%',
				render: (text) => <span className='uppercase font-bold invisible'>{text} Budget</span>,
			},
			{
				dataIndex: 'priorityLevel',
				key: 'priorityLevel',
				width: '15.5%',
				render: (text) => <Space>
					{/* {GetPriorityLevelIcons(text)} */}
					<div className={priorityCheck==="t"?GetCustomPriorityLevelIcons(text):GetPriorityLevelColors(text)}></div>
					<span>{text}</span>
				</Space>,
			},
			{
				title: <span className='uppercase font-bold'>Last month</span>,
				align: "left",
				children:[
					{
						title:<span className='font-bold'>Budget</span>,
						dataIndex: 'lastMonth',
						key: 'lastMonth',
						render: (text) => <span className='font-extrabold'>{currencyContext.currency}{text}</span>
					},
					{
						title: <span>(spend)</span>,
						dataIndex:'lastMonthSpend',
						key:'lastMonthSpend',
						render:(_,item) =>
						<Space size="small">
							<span>({currencyContext.currency}{item.lastMonthSpend})</span>
							{item.lastMonth>0?<span className={item.lastMonthSpend>0?'text-green-500 font-bold':'text-red-500 font-bold'}>{item.lastMonthSpentPer}%</span>:null}
						</Space>
					}
				]
			},
			{
				title: <span className='uppercase font-bold'>This month</span>,
				align: "left",
				children:[
					{
						title:<span className='font-bold'>Budget</span>,
						key: 'thisMonth',
						dataIndex: 'thisMonth',
						render: (_,item) => (
						<span className='font-extrabold'>{currencyContext.currency}{item.thisMonth}</span>						),
					},
					{
						title:<span>(spend)</span>,
						key: 'thisMonthSpend',
						dataIndex: 'thisMonthSpend',
						render: (_,item) => (
							<Space size="small">
								<span>({currencyContext.currency}{item.thisMonthSpend})</span>
								{item.thisMonth>0?<span className={item.thisMonthSpend>0?'text-green-500 font-bold':'text-red-500 font-bold'}>{item.thisMonthSpentPer}%</span>:null}
							</Space>
						),
					}
				]
			},
			{
				title: <span className='uppercase font-bold'>Next month</span>,
				key: 'nextMonth',
				width: '15.5%',
				render: (_,item) => (
					<>
						<div>
							<span className='font-extrabold'>{currencyContext.currency}{item.nextMonth}</span>		
						</div>
					</>
				),
			},
		];
		const byPriorityData = record.priorityList.filter((item)=>item.priorityLevel!==10);
		const byPriorityData1 = [
			{
				key: '0',
				suppliers: 'JobSwipe',
				priority: 'Organic'
			},
			{
				key: '1',
				suppliers: 'JobSwipe',
				priority: 'Standard'
			},
			{
				key: '2',
				suppliers: 'JobSwipe',
				priority: 'Extra'
			},
			{
				key: '3',
				suppliers: 'JobSwipe',
				priority: 'Max'
			},
			{
				key: '4',
				suppliers: 'JobSwipe',
				priority: 'Critical'
			},
		];
		return <Table columns={byPriorityColumns} dataSource={byPriorityData} size="middle" showHeader={false} pagination={false} />;
	};
	const getData = async(currentpage=1) =>{
		setLoading(true);
		const todayDate =new Date();
		const response = await APIUtils.apiCall('GET',WebLinks.GetBudget+Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)+"&month="+(todayDate.getMonth()+1)+"&year="+todayDate.getFullYear()+"&limit="+constants.pageSizeBudgets+"&pageNo="+currentpage);
    	if(!response.isCatch && response.resultObj.statusCode==="2000"){
			//Start: Added unique key to fix all inner tables opening together
			response.resultObj.data.map((item,index)=>{
				item.key=index;
				if(index===0){
					item.budget=true;
				}
			})
			//end
			setData(response.resultObj.data);
			setTotal(response.resultObj.getCount);
    	}else{
			setData([]);
			message.error(response.resultObj.message);
    	}
		setLoading(false);
	};
	const onchangeBudgetTable = (pagination, filters, sorter) =>{
		if(pagination){
			setCurrentPage(pagination?.current);
			getData(pagination?.current);
		}
	};
  //Function to get priority list
  const getBuyerPrioritylist=async()=>{
    let url = WebLinks.GetBuyerPriorityFilters + Cookies.get(constants.COOKIE_SELECTED_BUYER_ID);    
    const response = await APIUtils.apiCall('GET', url);
    if (!response.isCatch && response.resultObj) {
      setPriorityCheck(response?.resultObj?.data?.length>5?"t":"f");
    } else {
      message.error(response?.resultObj?.message);
    }
  };
	useEffect(()=>{
		getBuyerPrioritylist();
		getData();
	},[])
	return (
		<div>
			<Spin spinning={Loading}>
				<div className='flex flex-wrap justify-between items-center gap-1'>
					<div className='uppercase font-bold text-xl'> </div>
					<span className='text-gray-400'>{constants.BudgetsMsg}</span>
				</div>
				<div className='mt-3 overflow-x-auto'>
					{data.length>0?
					<Table
						className='budgets-table'
						size="middle"
						columns={columns}
						loading={Loading}
						dataSource={data}
						// scroll={{ x: 1366, /* y: 450 */ }}
						expandable={{
							// defaultExpandedRowKeys: ['3', '4'],
							defaultExpandAllRows:true,
							expandedRowRender,
							rowExpandable: (record) => record.overallBudget === null ? false : !record.overallBudget, //record.suppliers !== 'Indeed' && record.suppliers !== 'Total' && record.suppliers !== 'Social Media',
						}}
						pagination={{
							pageSize:constants.pageSizeBudgets,
							total:total,
							onChange:onchangeBudgetTable
						}}
					/>:<Empty/>}
				</div>
			</Spin>
		</div>
	);
};

export default Budgets;