// DataTable.js
import React, { useEffect, useState } from 'react';
import { Table, Button, Divider, Spin, message, Modal, Form, Upload, Input, Select, Image, Space, Popconfirm, Tooltip } from 'antd';
import APIUtils from '../api/APIUtils';
import WebLinks from '../api/WebLinks';
import { UploadOutlined, PlayCircleOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import constants from '../constants/constants';

const SocialConfigure = () => {
    const [Loading,setLoading]=useState(false);
    const [NewModalOpen,setNewModalOpen]=useState(false);
    const [updateRecord,setUpdateRecord]=useState(false);
    const [updateImage,setUpdateImage]=useState(null);
    const [data,setData]=useState([]);
    const [occupations,setOccupations]=useState([]);
    const [form] = Form.useForm();
    const [visibleVideo, setVisibleVideo] = useState(false);
    const [pagination, setPagination] = useState({
      current: 1,
      pageSize: constants.pageSize,
      total: 0,
    });
    const [videoUrl, setVideoUrl] = useState('');
  
    const [sorter, setSorter] = useState({
      field: 'Occupation',
      order: 'ascend',
    });
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        sorter: true
      },
      {
        title: 'Occupation',
        dataIndex: 'occupation',
        key: 'occupation',
        sorter: true
      },
      {
        title: 'Promo Image',
        dataIndex: 'socialPostImageURL',
        key: 'promoimage',
          render: (text) => <Image src={text} alt="Promo" className='!w-36' />,
        align:'center'
      },
      {
        title: 'Promo Video',
        dataIndex: 'socialPostVideoURL',
        key: 'socialPostVideoURL',
        render: (text) =>text?<Tooltip title="Play Video"><Button type='link' onClick={() => handlePlay(text)} icon={<PlayCircleOutlined className='!text-3xl text-teal-500' />}/></Tooltip>:null,
        align:'center'
      },
      {
        title: 'Promo Text',
        dataIndex: 'socialPostText',
        key: 'promotext',
      },
      {
        title: 'Actions',
        key: 'actions',
        align: 'center',
        render: (text, record) => (
          <Space>
            <Button type="primary" size='small' onClick={() => handleUpdate(record.id)}>
              Update
            </Button>
            <Popconfirm
              title="Are you sure to delete this record?"
              onConfirm={ () => handleDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
                <Button type="primary" danger size='small'>
                Delete
              </Button>
            </Popconfirm>
          </Space>
        ),
      },
    ];
    const handleUpdate = (id) => {
      loadOccupations();
      const editRecord = data.find((row)=> row.id===id);
      setUpdateRecord(editRecord);
      setNewModalOpen(true);
      setUpdateImage(editRecord.socialPostImageURL);
      form.setFieldsValue({
        promoText:editRecord.socialPostText,
        occupation: editRecord.occupationId,
      })
    };
    const handleDelete = async(id) => {
          setLoading(true);
          const response = await APIUtils.apiCall('DELETE',WebLinks.DeleteSocialConfig+id,null,true,false);
          if(!response.isCatch && response.resultObj.statusCode==="2000"){
             getData(pagination.current);
             getOccupations();
             message.success(response?.resultObj?.message || "Deleted record successfully");
          }else{
              message.error(response?.resultObj?.message || constants.ERROR_MESSAGE);
          }
          setLoading(false);
    };
    const getData = async()=>{
        setLoading(true);
        let desc = sorter.order === 'descend' ? "true" : "false";
        const response = await APIUtils.apiCall('GET',WebLinks.GetSocialConfigure+"buyerId="+Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)+"&limit="+pagination.pageSize+"&pageNo="+pagination.current+"&orderBy="+sorter?.field +"&sortDesc="+desc,null,true,false);
        if(!response.isCatch && response.resultObj.statusCode==="2000"){
            //Added unique key to fix all inner tables opening together
            response.resultObj.data?.map((item,index)=>{
                item.key=index;
            });
            setData(response?.resultObj?.data);
            setPagination((prev) => ({
              ...prev,
              total: response?.resultObj?.getCount || 0,
            }));
        }else{
            setData([]);
            //message.error(response?.resultObj?.message);
        }
        setLoading(false);
    };
    const getOccupations = async() =>{
        setLoading(true);
        const response = await APIUtils.apiCall('GET',WebLinks.GetSocialOccupations+Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)+"&limit=25&pageNo=1&orderBy=OccupationId&sortDesc=false",null,true,false);
        if(!response.isCatch && response.resultObj.statusCode==="2000"){
            //Added unique key to fix all inner tables opening together
            response.resultObj.data?.map((item,index)=>{
                item.key=index;
                item.value = item.occupationId;
                item.label = item.occupation;
                item.disabled = !!(response.resultObj.defaultExist && item.occupationId===0);
            });
            setOccupations(response?.resultObj?.data);
        }else{
            setData([]);
            message.error(response?.resultObj?.message || constants.ERROR_MESSAGE);
        }
        setLoading(false);
    };
    const handleCancel = () => {
        form.resetFields();
        setNewModalOpen(false);
        if(updateRecord){
          setUpdateRecord(null);
        }
    };
    const onFinish = async (values) => {
      if(values?.promoVideo && values?.promoVideo[0]?.originFileObj){
        if(values?.promoVideo[0]?.originFileObj.type!=="video/mp4"){
          message.error('You can only upload MP4 files!');
        }else{
          saveConfig(values);
        }
      }else{
        saveConfig(values);
      }
    };
    const saveConfig=async (values)=>{
      const formData = new FormData();
      if(values?.promoImage && values?.promoImage[0]?.originFileObj){
        formData.append('Image',  values.promoImage[0].originFileObj);
      }
      if(values?.promoVideo && values?.promoVideo[0]?.originFileObj){
        formData.append('Video',  values.promoVideo[0].originFileObj);
      }
      if(updateRecord){
        formData.append('SocialConfigure.Id', updateRecord.id);
      }
      formData.append('SocialConfigure.BuyerId', Cookies.get(constants.COOKIE_SELECTED_BUYER_ID));
      formData.append('SocialConfigure.OccupationId', values.occupation);
      formData.append('SocialConfigure.SocialPostText', values.promoText);
      try {
        let url= updateRecord?WebLinks.UpdateSocialConfig:WebLinks.AddSocialConfig;
          const response = await fetch(url, {
            method: updateRecord?'PUT':'POST',
            headers: {
              Authorization: "Bearer "+Cookies.get(constants.COOKIE_TOKEN)
            },
            body: formData,
          });
          const responseData = await response.json();
          if (response.ok) {
            message.success(responseData?.message || "Inserted record successfully");
            handleCancel();
            getData(pagination.current);
          } else {
              message.error(responseData?.message || constants.ERROR_MESSAGE);
          }
        } catch (error) {
          console.error('Error uploading image:', error);
      }  
    }
    const loadOccupations =async()=>{
      if (!occupations.length > 0) {
        await getOccupations();
      }
    };
    const renderUpdateImageFields = () =>{
      return(
        <div className="flex flex-wrap mb-4">
          {/* Current Image */}
          <div className="w-1/2 pr-4">
              <Image src={updateImage} className="max-w-full" />
          </div>
        {/* Upload Button */}
          <div className="w-1/2 pl-4">
            <Form.Item
              name="promoImage"
              label=" "
              valuePropName="fileList"
              getValueFromEvent={(e) => e.fileList} 
            >
              <Upload
              name="logo"
              listType="picture"
              beforeUpload={() => false}
              maxCount={1}
              >
                <Button icon={<UploadOutlined />}>Click to update Promo Image</Button>
              </Upload>
            </Form.Item>
          </div>
      </div>
      );
    };
    const renderUpdateVideoFields = () =>{
      return(
        <div className="flex flex-wrap mb-4">
          {/* Current Video */}
          <div className="w-1/2 pr-4">
            <video controls className="max-w-full" >
              <source src={updateRecord?.socialPostVideoURL}/>
            </video>
          </div>
        {/* Upload Button */}
          <div className="w-1/2 pl-4">
            <Form.Item
              name="promoVideo"
              label="Promo Video"
              valuePropName="fileList"
              getValueFromEvent={(e) => e.fileList} 
            >
              <Upload
              name="videoPromo"
              listType="picture"
              accept="video/mp4"
              beforeUpload={()=>false}
              maxCount={1}
              >
                <Button icon={<UploadOutlined />}>Click to add Promo Video</Button><span className='ml-2'><i>(MP4 file only)</i></span>
              </Upload>                    
            </Form.Item> 
          </div>
      </div>
      );
    };
    const handleTableChange = (pagination, filters, sorter) => {
      console.log("the pagination ",pagination);
      console.log("the sort ",sorter);
      setPagination(pagination);
      if(sorter?.field!==undefined){
        setSorter(sorter);
      }
    };
    const handlePlay = (url) => {
      setVideoUrl(url);
      setVisibleVideo(true);
    };
  
    const handleCancelVideo = () => {
      setVideoUrl('');
      setVisibleVideo(false);
    }

    useEffect(()=>{
        getData()
    },[pagination.current, pagination.pageSize, sorter.field, sorter.order]);
  return(
    <div>
        <Spin spinning={Loading}>
            <div className='flex flex-wrap justify-between items-center'>
                <div className='uppercase font-bold text-xl'>Social Media Promo Configuration</div>
                <div>
                    <Button type="primary" style={{ marginRight: '10px' }} onClick={async () => {
                        loadOccupations();
                        setNewModalOpen(true);
                    }}>
                        Add New
                    </Button>
                    {/* Add your logic to handle the "Add New" button */}
                </div>
            </div>
            <Divider className='my-3' />
            <Table scroll={{ x: '1000' }} sortDirections={['descend', 'ascend', 'descend']} columns={columns} dataSource={data} pagination={pagination} onChange={handleTableChange}/>
            {/* AddNew */}
            <Modal
              title={updateRecord?"Edit Configuration":"Add New Configuration"}
              open={NewModalOpen}
              onCancel={handleCancel}
              okText={<span className="capitalize">Save</span>}
              onOk={() => form.submit()}
              style={{ top: 20 }}
              width={800}
            >
                <Form form={form} name="form_in_modal" layout="vertical" onFinish={onFinish}>
                    <Form.Item
                        name="occupation"
                        label="Occupation"
                        rules={[
                            {
                            required: true,
                            message: "Required field",
                            },
                        ]}
                    >
                        <Select options={occupations} />
                    </Form.Item>
                    {updateRecord?renderUpdateImageFields():(
                      <Form.Item
                        name="promoImage"
                        label="Promo Image"
                        valuePropName="fileList"
                        getValueFromEvent={(e) => e.fileList} 
                        rules={[
                            {
                            required: true,
                            message: "Required field",
                            },
                        ]}
                      >
                        <Upload
                        name="logo"
                        listType="picture"
                        beforeUpload={() => false}
                        maxCount={1}
                        >
                          <Button icon={<UploadOutlined />}>Click to add Promo Image</Button>
                        </Upload>                    
                      </Form.Item>
                    )}
                    {updateRecord&&updateRecord.socialPostVideoURL?renderUpdateVideoFields():(
                    <Form.Item
                        name="promoVideo"
                        label="Promo Video"
                        valuePropName="fileList"
                        getValueFromEvent={(e) => e.fileList} 
                      >
                        <Upload
                        name="videoPromo"
                        listType="picture"
                        accept="video/mp4"
                        beforeUpload={()=>false}
                        maxCount={1}
                        >
                          <Button icon={<UploadOutlined />}>Click to add Promo Video</Button><span className='ml-2'><i>(MP4 file only)</i></span>
                        </Upload>                    
                      </Form.Item> )}                   
                    <Form.Item
                        name="promoText"
                        label="Promo Text"
                        rules={[
                            {
                            required: true,
                            message: "Required field",
                            },
                        ]}
                    >
                        <Input.TextArea rows={5}/>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
              title="Promo Video"
              open={visibleVideo}
              onCancel={handleCancelVideo}
              footer={null}
              width={800}
            >
              <video
                src={videoUrl}
                controls
                style={{ width: '100%' }}
                autoPlay
              />
            </Modal>
        </Spin>
  </div>
    );
};

export default SocialConfigure;
