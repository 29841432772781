import { Tabs, Divider } from 'antd';
import constants from '../../constants/constants';
import { IdcardOutlined, ProfileOutlined } from '@ant-design/icons';
import OccupationRules from './OccupationRules';
import PriorityLevelRules from './PriorityLevelRules'
import { useEffect, useState } from 'react';
import APIUtils from '../../api/APIUtils';
import WebLinks from '../../api/WebLinks';
import Cookies from 'js-cookie';

const { TabPane } = Tabs;

const DistributionRules = () => {
	//Variable Declaration
  const [suppliers,setSuppliers]=useState([]);

	//Function on load
  useEffect(()=>{
		fetchData();
	},[])

	//Functions
	//Function to get suppliers list
  const fetchData = async () =>{
		const supplierList = await APIUtils.apiCall("GET",WebLinks.GetJobRulesSuppliers+"buyerId="+Cookies.get(constants.COOKIE_SELECTED_BUYER_ID),null);
		if (supplierList.isCatch) {
			setSuppliers([])
		} else {
			if(supplierList.resultObj?.statusCode==="2000") {
				setSuppliers(supplierList.resultObj.data)
			}
		}
	}

	//Render
	return (
		<div>
      <div className='uppercase font-bold text-xl'>Distribution Rules</div>
			<Divider className='my-3'/>
			<Tabs defaultActiveKey="2">
			<TabPane
        tab={ <span className='uppercase'><ProfileOutlined className='text-xl' />{constants.DistributionTab2}</span> }
        key={2}
      >
        <PriorityLevelRules suppliers={suppliers}/>
      </TabPane>
			<TabPane
        tab={ <span className='uppercase'><IdcardOutlined className='text-xl' />{constants.DistributionTab1}</span> }
        key={1}
      >
        <OccupationRules suppliers={suppliers}/>
      </TabPane>
			</Tabs>
		</div>
	);
};

export default DistributionRules;