import { useEffect, useState } from 'react';
import { Input, Table } from 'antd';
import constants from '../constants/constants';
import moment from 'moment';
import WebLinks from '../api/WebLinks';
import Cookies from 'js-cookie';
import APIUtils from '../api/APIUtils';

const { Search } = Input;

const JobUploadHistory = () => {

	const [jobsUploadHistoryData, setJobsUploadHistoryData] = useState([]);

	useEffect(()=>{
		jobUploadHistoryListFetch();
	  },[]);

	  //fetch the jobs upload history for the last 30 days 
	const jobUploadHistoryListFetch = async () =>{
		
		const response = await APIUtils.apiCall('GET',WebLinks.JobUploadHistory+Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)+'&sortBy=CreatedOn&sortDesc='+true);
		if(!response.isCatch && response.resultObj){
			setJobsUploadHistoryData(response.resultObj.data);
		}
	};

	// Table start
	const jobsUploadHistoryColumns = [
		{
			title: 'Created On',
			dataIndex: 'createdOn',
			key: 'createdOn',
			render: (createdOn)=>{
				return moment(createdOn).format('YYYY-MM-D, h:mm a');
			}
		},
		{
			title: 'Inserts',
			dataIndex: 'insertCount',
			key: 'inserts',
    		sorter: (a, b) => a.insertCount - b.insertCount,
		},
		{
			title: 'Deletes',
			dataIndex: 'deleteCount',
			key: 'deletes',
    		sorter: (a, b) => a.deleteCount - b.deleteCount,
		},
		{
			title: 'Updates',
			dataIndex: 'updateCount',
			key: 'updates',
    		sorter: (a, b) => a.updateCount - b.updateCount,
		},
		{
			title: 'Jobs Live',
			dataIndex: 'jobsLive',
			key: 'jobsLive',
    		sorter: (a, b) => a.jobsLive - b.jobsLive,
		}
	];

	return (
		<div>
			<div className='uppercase font-bold text-xl'>Jobs Upload History</div>
			<div className='flex flex-wrap justify-between items-center gap-1 my-3'>
				<span className='text-slate-500'>{constants.UploadHistoryList}</span>
				<span className='text-slate-500'><strong>{jobsUploadHistoryData[0]?.jobsLive}</strong> {constants.JobsIndexed}</span>
				<Search
					placeholder="Search"
					allowClear
					// onSearch={onSearch}
					enterButton
					className='w-60'
				/>
			</div>
			<div className='overflow-x-auto'>
				<Table 
					columns={jobsUploadHistoryColumns} 
					dataSource={jobsUploadHistoryData} 
					pagination={
						{
							defaultPageSize:100,
							pageSizeOptions:[10,50,100,200],
							showSizeChanger:true
						}
					}
				/>
			</div>
		</div>
	);
};

export default JobUploadHistory;