import React, { useEffect, useState } from 'react';
import { Input, Select, Button, Space, Table, Popconfirm, Tooltip, Switch, Modal, message, Empty, Form, Spin, Checkbox,} from 'antd';
import { MenuOutlined, DeleteOutlined, SaveOutlined, CheckOutlined, CloseOutlined, UndoOutlined} from '@ant-design/icons';
import { DndContext } from '@dnd-kit/core';
import {
	arrayMove,
	SortableContext,
	useSortable,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Cookies from 'js-cookie';
import constants from '../../constants/constants';
import APIUtils from '../../api/APIUtils';
import WebLinks from '../../api/WebLinks';
import { GetCustomPriorityLevelIcons, GetPriorityLevelColors } from '../../globalFunctions/GlobalFunctions';


// Drag & Drop Stuff
const Row = ({ children, ...props }) => {
	const {
		attributes,
		listeners,
		setNodeRef,
		setActivatorNodeRef,
		transform,
		transition,
		isDragging,
	} = useSortable({
		id: props['data-row-key'],
	});
	const style = {
		...props.style,
		transform: CSS.Transform.toString(
			transform && {
				...transform,
				scaleY: 1,
			},
		)?.replace(/translate3d\(([^,]+),/, 'translate3d(0,'),
		transition,
		...(isDragging
			? {
				position: 'relative',
				zIndex: 9999,
			}
			: {}),
	};
	return (
		<tr {...props} ref={setNodeRef} style={style} {...attributes}>
			{React.Children.map(children, (child) => {
				if (child.key === 'sort') {
					return React.cloneElement(child, {
						children: (
							<MenuOutlined
								ref={setActivatorNodeRef}
								style={{
									touchAction: 'none',
									cursor: 'move',
								}}
								{...listeners}
							/>
						),
					});
				}
				return child;
			})}
		</tr>
	);
};
const { Option } = Select;

const MultiPriorityRules = () => {
	// Data
	const [dataSource, setDataSource] = useState([]);
	const [loader, setLoader] = useState(false);
	const [count, setCount] = useState(dataSource.length);
	const [isAddPriorityOpen, setIsAddPriorityOpen] = useState(false);
	const [isOrderChanged, setIsOrderChanged] = useState(false);
	const [checkedExcludeOrganic, setCheckedExcludeOrganic] = useState(false);
	const [priorityColorCode,setPriorityColorCode]=useState([]);
	const [checkedPaidOnly, setCheckedPaidOnly] = useState(false);
 
	const [form] = Form.useForm();
	const [dynamicForm] = Form.useForm();

	// Table Columns
	const columns = [
		{
			title: 'Filter',
			dataIndex: 'filter',
			render: (_,record) =>
			<Form form={dynamicForm}>{filterForm(true,record)}</Form>,
		},
		{
			title: 'operation',
			dataIndex: 'operation',
			width: '5%',
			render: (_, record) =>
				dataSource.length >= 1 ? (
					<Space direction='vertical' className='!gap-0'>
						{record.isUpdated?(
							<>
								<Tooltip title="Save" placement='top'>
										<Button icon={<SaveOutlined />} type="link" size='large' onClick={()=>(saveFilterData(record))} />
								</Tooltip>
								<Tooltip title="Undo" placement='top'>
										<Button icon={<UndoOutlined />} type="link" size='large' onClick={()=>(undoFilterChange(record))} />
								</Tooltip>
							</>
						):null}
						<Tooltip title="Delete Priority" placement='bottom'>
							<Popconfirm title="Sure you want to delete this filter?" onConfirm={() => handleDelete(record)}  placement="left">
								<Button icon={<DeleteOutlined />} type="link" danger size='large' />
							</Popconfirm>
						</Tooltip>
					</Space>
				) : null,
		},
	];
	// Delete Priority Filters Row
	const handleDelete = async(filter) => {
		setLoader(true);
		const deleteResponse = await APIUtils.apiCall("DELETE",WebLinks.DeletePriorityRules+filter.id);
		if(deleteResponse.isCatch || deleteResponse?.resultObj?.statusCode!=='2000'){
			message.error( deleteResponse?.resultObj?.message || constants.ERROR_MESSAGE);
		}else{
			const newData = dataSource.filter((item) => item.key !== filter.key);
			setDataSource(newData);
		}
		
		setLoader(false);
	};
	// Add New filter
	const addFilter = async(newFilter) => {
		setLoader(true);
		const addResponse = await APIUtils.apiCall("POST",WebLinks.AddPriorityRules,JSON.stringify(newFilter));

		if(addResponse.isCatch || addResponse?.resultObj?.statusCode!=='2000'){
			message.error( addResponse?.resultObj?.message || constants.ERROR_MESSAGE);
		}else{
			newFilter.id=addResponse?.resultObj?.buyerPriorityRuleId;
			setDataSource([...dataSource, newFilter]);
			setCount(count + 1);
			handleCancel(); //To reset model form
		}
		setLoader(false);
  	};
	const handleOk = (e) => {
		let newfilter={
			id:0,
			buyerId:Cookies.get(constants.COOKIE_SELECTED_BUYER_ID),
			key:count + 1,
			isUpdated:false,
			filterOrder: dataSource.length + 1,

			// titleFilterText: e.jobText || "",
			// locationFilterText: e.locationText || "",

			priorityLevelStart: e.startPriorityLevel,
			priorityLevelEnd: e.endPriorityLevel,
			
			// titleFilterType: e.jobTitleType || "",
			// locationFilterType: e.locationType ||"",

			changedLive: e.live===undefined?true:e.live,

			jobLiveDays: e.jobLiveDays,
			applicationCount: e.jobApplications,
			// priorityLevelStart: e.priorityLevel,
			// priorityLevelEnd: null,
			excludePriorityLevel:checkedExcludeOrganic?0:null,
			changedExcludePriorityLevel:checkedExcludeOrganic?0:null,
			paidOnly:checkedPaidOnly?true:false,
			changedPaidOnly:checkedPaidOnly?true:false,
			live: e.live===undefined?true:e.live,
			applicationCountOperator:e.jobConditions,
			UpdatedByBuyerId:Cookies.get(constants.COOKIE_LOGGED_BUYER_ID)
		}
		addFilter(newfilter);
		
	};
	const handleCancel = () => {
		form.resetFields();
		setCheckedExcludeOrganic(false);
		setCheckedPaidOnly(false);
		setIsAddPriorityOpen(false);
	};
	// Drag & Drop Stuff
	const onDragEnd = ({ active, over }) => {
		if(!isOrderChanged){setIsOrderChanged(true)}
		if (active.id !== over?.id) {
			setDataSource((previous) => {
				const activeIndex = previous.findIndex((i) => i.key === active.id);
				const overIndex = previous.findIndex((i) => i.key === over?.id);
				return arrayMove(previous, activeIndex, overIndex);
			});
		}
	};
	const getData =async()=>{
		setLoader(true)
		const res =await APIUtils.apiCall("GET",WebLinks.GetPriorityRules+Cookies.get(constants.COOKIE_SELECTED_BUYER_ID),null);
		if(res.isCatch || res?.resultObj?.statusCode!=='2000'){
			message.error( res?.resultObj?.message || constants.ERROR_MESSAGE);
		}else{
			const modifiedData= res?.resultObj?.data?.map((item,index)=>({key:index+1,isUpdated:false,changedLive:item.live,changedExcludePriorityLevel:item.excludePriorityLevel,changedPaidOnly:item.paidOnly, ...item,}));
			setDataSource(modifiedData || []);
			setCount(modifiedData?.length || 0)
		}
		setLoader(false)
	}
	const getRowColor = (record) =>{
		if(record.locationFilterType!=="" && record.titleFilterType!==""){
			return"teal-tr"
		}else if(record.locationFilterType!==""){
			return"yellow-tr"
		}else{
			return"purple-tr"
		}
	}
	const buildOrderpayload = () =>{
		const Filterlist= dataSource;
		return Filterlist.map((item,index)=>{item.filterOrder=index+1;return item;});
	}
	const handleSaveOrder = async() =>{
		setLoader(true);
		const payload = await buildOrderpayload();
		const addResponse = await APIUtils.apiCall("PUT",WebLinks.UpdateOrderPriorityFilter,JSON.stringify(payload));
		if(addResponse.isCatch || addResponse?.resultObj?.statusCode!=='2000'){
			message.error( addResponse?.resultObj?.message || constants.ERROR_MESSAGE);
		}else{
			message.success(addResponse?.resultObj?.message);
			setIsOrderChanged(false);
		}
		setLoader(false);
	}
	const setFilterAsUpdated = (record,isSaved=false) =>{
		const modifiedList=dataSource.map((item)=>{
			if(item.key===record.key){
				item.isUpdated=!isSaved;
			}
			return item;
		})
		setDataSource(modifiedList);
	};
	const saveFilterData = async (record) =>{
		setLoader(true);
		const formValues= dynamicForm.getFieldsValue();
		let excludeOrganicLevel
		if(formValues['excludeOrganic-'+record.key]!==undefined){
			excludeOrganicLevel=formValues['excludeOrganic-'+record.key]?0:null;
		}
		let paidOnlyValue
		if(formValues['paidOnly-'+record.key]!==undefined){
			paidOnlyValue=formValues['paidOnly-'+record.key]?0:null;
		}
		let updateFilter={
			id:record.id,
			buyerId:record.buyerId,
			key:record.key,
			isUpdated:false,
			filterOrder: record.filterOrder,
			
			// titleFilterText: formValues['jobText-'+record.key],
			// titleFilterType: formValues['jobTitleType-'+record.key],
			// locationFilterText: formValues['locationText-'+record.key],
			// locationFilterType: formValues['locationType-'+record.key],
			// priorityLevel: formValues['priorityLevel-'+record.key],
			// isLive: formValues['live-'+record.key]===undefined?record.isLive:formValues['live-'+record.key],

			jobLiveDays: formValues['jobLiveDays-'+record.key],
			applicationCount: formValues['jobApplications-'+record.key],
			priorityLevelStart: formValues['startPriorityLevel-'+record.key],
			priorityLevelEnd: formValues['endPriorityLevel-'+record.key],
			// priorityLevelEnd: null,
			excludePriorityLevel: record.changedExcludePriorityLevel,
			paidOnly:record.changedPaidOnly,
			live: formValues['live-'+record.key]===undefined?record.live:formValues['live-'+record.key],
			applicationCountOperator: formValues['jobConditions-'+record.key],
			UpdatedByBuyerId:Cookies.get(constants.COOKIE_LOGGED_BUYER_ID)
		}
		const addResponse = await APIUtils.apiCall("PUT",WebLinks.UpdatePriorityRules,JSON.stringify(updateFilter));
		if(addResponse.isCatch || addResponse?.resultObj?.statusCode!=='2000'){
			message.error( addResponse?.resultObj?.message || constants.ERROR_MESSAGE);
		}else{
			message.success(addResponse?.resultObj?.message);
			setFilterAsUpdated(record,true);
		}
		setLoader(false);
	};
	const undoFilterChange = (record) =>{
		setLoader(true);
		dynamicForm.setFieldsValue({ ['jobLiveDays-'+record.key] : record.jobLiveDays,
		['jobApplications-'+record.key]:record.applicationCount,
		['startPriorityLevel-'+record.key]:record.priorityLevelStart,
		['endPriorityLevel-'+record.key]:record.priorityLevelEnd,
		['excludeOrganic-'+record.key]:record.excludePriorityLevel===0?true:false,
		['paidOnly-'+record.key]:record.paidOnly===0?true:false,
		['live-'+record.key]:record.live,
		['jobConditions-'+record.key]:record.applicationCountOperator,
		// ['live-'+record.key]:record.isLive
		});
		setLiveValue(record,true);
		setLoader(false);
	};
	const getFieldName= (name,update,record=null) =>{
		return update?name+'-'+record?.key || 0:name;
	}
	const setLiveValue = (record,reset=false) =>{
		setFilterAsUpdated(record,reset);
		let list=dataSource.map((item)=>{
			if(item.key===record.key){
				item.changedLive=reset?item.live : !item.changedLive;
				if(reset){
					item.changedExcludePriorityLevel=item.excludePriorityLevel;
					item.changedPaidOnly=item.paidOnly;
				}
			}
			return item;
		});
		setDataSource(list);
	};
	const getExcludeOrganicJobs = (record) =>{
		return record.changedExcludePriorityLevel === 0;
	};
	const updateIsExcludeOrganicJobs = (record, checkedexcludePriorityLevel, reset) =>{
		setFilterAsUpdated(record,false);
		let val=checkedexcludePriorityLevel?0:null;
		let list=dataSource.map((item)=>{
			if(item.key===record.key){
				item.changedExcludePriorityLevel=reset?item.excludePriorityLevel : val;
			}
			return item;
		});
		setDataSource(list);
	};
	const getPaidOnlyJobs = (record) =>{
		return record.changedPaidOnly === true;
	};
	const updateIsPaidOnly = (record, changedPaidOnly, reset) =>{
		setFilterAsUpdated(record,false);
		let val=changedPaidOnly?true:null;
		let list=dataSource.map((item)=>{
			if(item.key===record.key){
				item.changedPaidOnly=reset?item.paidOnly : val;
			}
			return item;
		});
		setDataSource(list);
	};
	const filterForm = (update = false, record=null) =>{
		const jobConditions = [
			{
				value: "<",
				label: constants.HasLessThan,
			},
			{
				value: ">",
				label: constants.HasMoreThan,
			},
		];
		const jobApplications = [];

		for (let i = 0; i <= 400; i++) {
			jobApplications.push({
				value: i,
				label: i,
			});
		}
		const jobLiveDays = [];

		for (let i = 0; i <= 120; i++) {
			jobLiveDays.push({
				value: i,
				label: i+" Days",
			});
		}
		// const priorityLevelOptions = [
		// 	{
		// 		value: 0,
		// 		label: (
		// 			<Space>
		// 				<div className="w-3.5 h-3.5 border rounded-full border-black bg-white"></div>
		// 				Organic
		// 			</Space>
		// 		),
		// 	},
		// 	{
		// 		value: 1,
		// 		label: (
		// 			<Space>
		// 				<div className="w-3.5 h-3.5 border rounded-full border-black bg-lime-400"></div>
		// 				Standard
		// 			</Space>
		// 		),
		// 	},
		// 	{
		// 		value: 2,
		// 		label: (
		// 			<Space>
		// 				<div className="w-3.5 h-3.5 border rounded-full border-black bg-orange-400"></div>
		// 				Extra
		// 			</Space>
		// 		),
		// 	},
		// 	{
		// 		value: 3,
		// 		label: (
		// 			<Space>
		// 				<div className="w-3.5 h-3.5 border rounded-full border-black bg-red-600"></div>
		// 				Max + Social
		// 			</Space>
		// 		),
		// 	},
		// 	{
		// 		value: 4,
		// 		label: (
		// 			<Space>
		// 				<div className="w-3.5 h-3.5 border rounded-full border-black bg-purple-600"></div>
		// 				Critical + Social
		// 			</Space>
		// 		),
		// 	},
		// ];
		return(
			<div class="overflow-x-auto">
				<table className='w-full priority-rules'>
					<colgroup>
						<col width="5%" />
						<col width="5%" />
						<col width="20%" />
						<col width="20%" />
						<col width="15%" />
						<col width="15%" />
						<col width="20%" />
					</colgroup>
					<thead>
						<tr className='uppercase font-bold text-left text-xs 2xl:text-base'>
							<th></th>
							<th></th>
							<th className='whitespace-nowrap'>Is Priority Level</th>
							<th></th>
							<th>Applications</th>
							<th>After</th>
							<th>Set to Priority Level</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className='text-right uppercase font-bold text-xs 2xl:text-base'>
								<Space>
								Live
								<Form.Item name={getFieldName('live',update,record)} className='!mb-0'>
									<Switch
										onChange={()=>(update?setLiveValue(record):null)}
										checked={update?record?.changedLive:true}
										checkedChildren={<CheckOutlined />}
										unCheckedChildren={<CloseOutlined />}
									/>
									{/* <Checkbox defaultChecked={update?record?.isLive:true}/> */}
								</Form.Item>
								</Space>
							</td>
							<td className='text-right uppercase font-bold text-xs 2xl:text-base whitespace-nowrap'>
								If Job
							</td>
							<td>
								<Form.Item name={getFieldName('startPriorityLevel',update,record)} className='!mb-0'
									rules={[
										{
											required: true,
											message: 'Enter start priority level.',
										},]}
										initialValue={update?record.priorityLevelStart:null}
								>
									<Select
										onChange={()=>(update?setFilterAsUpdated(record):null)}
										allowClear
										className='w-full'
										// options={[{
										// 	value: 10,
										// 	label: "Any",
										// },...priorityLevelOptions]}
									>
										<Option value={10}>Any</Option>
										{priorityColorCode?.map((item=>{
											return(
										<Option value={item.value}>
												<Space>
													<div className={item.colorValue}></div>
													{item.customName}
												</Space>             
										</Option>
											)
										}))}
									</Select>
								</Form.Item>
							</td>
							<td>
								<Form.Item name={getFieldName('jobConditions',update,record)} className='!mb-0' initialValue={update?record.applicationCountOperator:null}>
									<Select
										allowClear
										className='w-full'
										options={jobConditions}
										onChange={()=>(update?setFilterAsUpdated(record):null)}
									/>
								</Form.Item>
							</td>
							<td>
								<Form.Item name={getFieldName('jobApplications',update,record)} className='!mb-0' initialValue={update?record.applicationCount:null}>
									<Select
										allowClear
										className='w-full'
										options={jobApplications}
										onChange={()=>(update?setFilterAsUpdated(record):null)}
									/>
								</Form.Item>
							</td>
							<td>
								<Form.Item name={getFieldName('jobLiveDays',update,record)} className='!mb-0' initialValue={update?record.jobLiveDays:null}>
									<Select
										allowClear
										className='w-full'
										options={jobLiveDays}
										onChange={()=>(update?setFilterAsUpdated(record):null)}
									/>
								</Form.Item>
							</td>
							<td>
							<Form.Item name={getFieldName('endPriorityLevel',update,record)} className='!mb-0'
								rules={[
									{
										required: true,
										message: 'Enter priority level.',
									},]}
									initialValue={update?record.priorityLevelEnd:null}
							>
								<Select
									onChange={()=>(update?setFilterAsUpdated(record):null)}
									allowClear
									className='w-full'
									// options={priorityLevelOptions}
								>
								{priorityColorCode?.map((item=>{
											return(
											<Option value={item.value}>
													<Space>
														<div className={item.colorValue}></div>
														{item.customName}
													</Space>             
											</Option>
											)
										}))}
									</Select>
							</Form.Item>
							</td>
						</tr>
						<tr>
							<td colspan="5"></td>
							<td>
								<div className='capitalize font-bold text-xs 2xl:text-base'>
									<Space>
										<Form.Item name={getFieldName('excludeOrganic', update, record)} className='!mb-0'>
											<Checkbox 
											checked={update? getExcludeOrganicJobs(record) : checkedExcludeOrganic}
											onChange={(e)=>update?updateIsExcludeOrganicJobs(record,e.target.checked):setCheckedExcludeOrganic(e.target.checked)} > 
											{/* update?: */} 
												<span className='whitespace-nowrap'>Exclude Organic Jobs</span>
											</Checkbox>
										</Form.Item>									
									</Space>
								</div>
							</td>
							<td>
								<div className='capitalize font-bold text-xs 2xl:text-base'>
									<Space>
										<Form.Item name={getFieldName('paidOnly', update, record)} className='!mb-0'>
											<Checkbox 
											checked={update? getPaidOnlyJobs(record) : checkedPaidOnly}
											onChange={(e)=>update?updateIsPaidOnly(record,e.target.checked):setCheckedPaidOnly(e.target.checked)} > 
											{/* update?: */} 
												<span className='whitespace-nowrap'>Only Paid Apps</span>
											</Checkbox>
										</Form.Item>									
									</Space>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	};
  //Function to get priority list
  const getBuyerPrioritylist=async()=>{
    let url = WebLinks.GetBuyerPriorityFilters + Cookies.get(constants.COOKIE_SELECTED_BUYER_ID);    
    const response = await APIUtils.apiCall('GET', url);
    if (!response.isCatch && response.resultObj) {
      let temppriorityColorCode=[];
      response?.resultObj?.data.forEach(element => {
        let priorityObj={
          value:element.priorityLevel,
          colorValue:response?.resultObj?.data?.length>5?GetCustomPriorityLevelIcons(element.priorityLevel):GetPriorityLevelColors(element.priorityLevel),
          customName:element.customName,
        }
        temppriorityColorCode.push(priorityObj);
        setPriorityColorCode(temppriorityColorCode);
      });
    } else {
			setPriorityColorCode([]);
      message.error(response?.resultObj?.message);
    }
  };
	useEffect(()=>{
		getBuyerPrioritylist();
		getData();
	},[]);
	return (
		<div>
			<Spin spinning={loader}>
				<div className='flex justify-between items-center'>
					<div className='uppercase font-bold text-xl'>Master Priority Rules</div>
					{/* Add new row button */}
					{/* <Button size="large" type='primary' onClick={handleAdd}>
						{constants.CreateNew}
					</Button> */}
					<Space>
						<Button hidden={!isOrderChanged} size="large" type='primary' onClick={()=>handleSaveOrder()}>
							{constants.SaveOrder}
						</Button>
						<Button size="large" type='primary' onClick={()=>setIsAddPriorityOpen(true)}>
							{constants.CreateNew}
						</Button>
					</Space>
				</div>
				{/* Draggable rows Priority filter list start. */}
				<div className='mt-3 overflow-x-auto grid gap-y-4'>
				{/* p-3 border rounded-md border-l-8 border-teal-500, border-secondary, border-violet-500 */}
					{dataSource.length>0?(
						<DndContext onDragEnd={onDragEnd}>
							<SortableContext
								// rowKey array
								items={dataSource.map((i) => i.key)}
								strategy={verticalListSortingStrategy}
							>
								<Table
									components={{
										body: {
											row: Row,
										},
									}}
									rowKey="key"
									columns={columns}
									dataSource={dataSource}
									showHeader={false}
									pagination={false}
									size="small"
									className='priority-table priority-rules-main'
									rowClassName={(record,index)=>(getRowColor(record))} /* yellow-tr, purple-tr */
								/>
							</SortableContext>
						</DndContext>
					):<Empty description={"You currently have no rules."}/>}
				</div>
				{/* Priority Popup */}
				<Modal 
					title={constants.PriorityRulesPopup} 
					open={isAddPriorityOpen} 
					onOk={()=>form.submit()} 
					onCancel={()=>handleCancel()}
					okText={<span className="capitalize">Create</span>}
					cancelText={<span className="capitalize">Reset</span>}
					cancelButtonProps={{disabled:loader}}
					width={1000}
					confirmLoading={loader}
				>
					<Form onFinish={handleOk} form={form}>
						{filterForm()}
					</Form>
					
				</Modal>
	  		</Spin>
		</div>
	);
};

export default MultiPriorityRules;
