import { Excel } from "antd-table-saveas-excel";
import constants from "../constants/constants";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import { message } from "antd";

	//Download table into an excel file
	export const DownloadExcel = (columns,data,filename,sheetName) => {
    const excel = new Excel();
    excel
      .addSheet(sheetName)
      .addColumns(columns)
      .addDataSource(data, {
        str2Percent: true
      })
      .saveAs(filename+".xlsx");
  };

  //Get todays date
  export const GetTodaysDateString =()=>{
    let todaysDate=dayjs().format(constants.DateFormat);
    return todaysDate;
  };

  //Table search functionality logic
  export const GetSearchFilteredData=(value,dataSet)=>{
    let searchData=dataSet.filter(item =>Object.keys(item)
		.some(k =>String(item[k]).toLowerCase().includes(value.toLowerCase()))
		)
    return searchData;
  };

  //Get priority level icons
  export const GetPriorityLevelIcons=(priorityLevel)=>{
    switch (priorityLevel) {
      case 10: return(<div class="w-3.5 h-3.5 border rounded-full border-black bg-white"></div>);
      case 1: return(<div class="w-3.5 h-3.5 border rounded-full border-black bg-lime-400"></div>);
      case 2: return(<div class="w-3.5 h-3.5 border rounded-full border-black bg-orange-400"></div>);
      case 3: return(<div class="w-3.5 h-3.5 border rounded-full border-black bg-red-600"></div>);
      case 4: return(<div class="w-3.5 h-3.5 border rounded-full border-black bg-purple-600"></div>);
      default: return(<div class="w-3.5 h-3.5 border rounded-full border-black bg-white"></div>);
    }
  }

  //Get custom priority level icons
  export const GetCustomPriorityLevelIcons=(priorityLevel)=>{
    switch (priorityLevel) {
      case 1: return"w-3.5 h-3.5 border rounded-full border-black bg-lime-400";
      case 2: return"w-3.5 h-3.5 border rounded-full border-black bg-green-900";
      case 3: return"w-3.5 h-3.5 border rounded-full border-black bg-yellow-400";
      case 0: return"w-3.5 h-3.5 border rounded-full border-black bg-white";
      case 4: return"w-3.5 h-3.5 border rounded-full border-black bg-orange-400";
      case 5: return"w-3.5 h-3.5 border rounded-full border-black bg-red-600";
      case 6: return"w-3.5 h-3.5 border rounded-full border-black bg-pink-600";
      case 7: return"w-3.5 h-3.5 border rounded-full border-black bg-purple-600";
      case 8: return"w-3.5 h-3.5 border rounded-full border-black bg-slate-900";
      default: return"w-3.5 h-3.5 border rounded-full border-black bg-white";
    }
  }

  //Get priority level colors
  export const GetPriorityLevelColors=(priorityLevel)=>{
    switch (priorityLevel) {
      case 10: return "w-3.5 h-3.5 border rounded-full border-black bg-white";
      case 1: return "w-3.5 h-3.5 border rounded-full border-black bg-lime-400";
      case 2: return "w-3.5 h-3.5 border rounded-full border-black bg-orange-400";
      case 3: return "w-3.5 h-3.5 border rounded-full border-black bg-red-600";
      case 4: return "w-3.5 h-3.5 border rounded-full border-black bg-purple-600";
      default: return "w-3.5 h-3.5 border rounded-full border-black bg-white";
    }
  }

  //Function to get priority count
  export const getPriorityCount=(priorityValue,priorityCountListValues)=>{
    let filterPriority= priorityCountListValues?.filter((item)=>item.priorityLevel===priorityValue);
    if (filterPriority.length>0){
      return filterPriority[0].priorityCount;
    }else{
      return 0;
    }
  }

  //Function to download file
  export const downloadFiles = (urlValue,filename) => {
    const url = urlValue
    const accessToken = Cookies.get(constants.COOKIE_TOKEN)
    fetch(url, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
        }
    })
        .then(response => response.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            message.success('File downloaded successfully');
        })
        .catch(error => {
            message.error('Error downloading file');
        });
}