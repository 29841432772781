import { Table, Spin, message, Image, Form, Space, DatePicker, Radio } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import APIUtils from '../../api/APIUtils';
import WebLinks from '../../api/WebLinks';
import { useEffect, useState } from 'react';
import constants from '../../constants/constants';
import Cookies from 'js-cookie';
import { type } from '@testing-library/user-event/dist/type';
import { useContext } from 'react';
import { CurrencyContext } from '../../Context';

const { Column } = Table;
const { RangePicker } = DatePicker;

const OccupationMatrix = (props) => {
	//Variable Declaration
	const currencyContext=useContext(CurrencyContext);
	const [occupations, setOccupations] = useState([]);
	const [suppliers, setSuppliers] = useState([]);
	const [pageNumber, setPageNumber] = useState(1);
	const [sortDesc, setSortDesc] = useState(false);
	const [total, setTotal] = useState(0);
	const [loading, setloading] = useState(false);
	const [rangePickerValue, setRangePickerValue] = useState([]);
	const [type, setType] = useState(1);
	const [form] = Form.useForm();

	//On page load
	useEffect(() => {
		const date1 = dayjs().startOf('month')
		const date2 = dayjs().endOf('month')
		form.setFieldsValue({
			rangePicker: [
				date1,
				date2,
			],
		});
		setRangePickerValue([
			date1,
			date2
		]);
		console.log("date1", date1)
		fetchData(date1.format('YYYY-MM-DD'), date2.format('YYYY-MM-DD'));
	}, [props]);

	//Functions
	//Function to fetch occupation data
	const fetchData = async (date1, date2) => {
		setloading(true);
		if (suppliers.length <= 0) {
			await fetchSupplier();
		}
		const occupations = await APIUtils.apiCall("GET", WebLinks.GetOccupationMatrix + Cookies.get(constants.COOKIE_SELECTED_BUYER_ID) +
			'&startDate=' + date1 + '&endDate=' + date2, null);
		if (occupations.isCatch) {
			message.error(constants.ERROR_MESSAGE);
		} else {
			if (occupations.resultObj?.statusCode === "2000") {
				setOccupations(occupations.resultObj.data)
				setTotal(occupations.resultObj.data?.getCount | 0);
			} else {
				message.success(occupations.resultObj?.message | constants.ERROR_MESSAGE)
			}
		}
		setloading(false);
	}
	//Function to get suppliers list
	const fetchSupplier = async () => {
		const supplierList = await APIUtils.apiCall("GET", WebLinks.GetJobRulesSuppliers + "buyerId=" + Cookies.get(constants.COOKIE_SELECTED_BUYER_ID), null);
		if (supplierList.isCatch) {
			setSuppliers([])
		} else {
			if (supplierList.resultObj?.statusCode === "2000") {
				setSuppliers(supplierList.resultObj.data);
			}
		}
	}

	const changeType = (radio) => {
		setType(radio.target.value)
		//fetchData(rangePickerValue[0].format('YYYY-MM-DD'), rangePickerValue[1].format('YYYY-MM-DD'), radio.target.value);
	}

	const RangePickerChanged = debounce((_, dateStrings) => {
		console.log("datepicker", _, dateStrings)
		const date1 = dayjs(dateStrings[0], constants.MonthFormat)
		const date2 = dayjs(dateStrings[1], constants.MonthFormat)
		setRangePickerValue([
			date1,
			date2
		]);
		fetchData(date1.format('YYYY-MM-DD'), date2.format('YYYY-MM-DD'));
	}, 500);

	//Render
	return (
		<div>
			<div className='flex flex-wrap gap-3 justify-between items-center mb-3'>
				<Space wrap>
					<Form form={form}>
						<Form.Item name="rangePicker" className='mb-0'>
							<RangePicker picker="month"
								placeholder={["From", "To"]}
								onChange={RangePickerChanged}
								format={constants.MonthFormat} />
						</Form.Item>
					</Form>
				</Space>
				<Space wrap>
					<Radio.Group name="radiogroup" defaultValue={type} onChange={changeType}>
						<Radio value={1}>Cost Per Quality App</Radio>
						<Radio value={2}>Cost Per Interview</Radio>
						<Radio value={3}>Cost Per Hire</Radio>
					</Radio.Group>
				</Space>
			</div>
			<div className='overflow-x-auto'>
				<Table dataSource={occupations}
					loading={loading}
					sortDirections={['descend', 'ascend', 'descend']}
					pagination={false}
					scroll={{ x: 1000, y: 590 }} // Set the y property to the desired height
				>
					<Column width="100px" title="ID" dataIndex="occupationId" key="occupationId" sorter={(a, b) => (a.occupationId - b.occupationId)} fixed={true} />
					<Column width="150px" title="Occupation" dataIndex="occupationName" key="occupation" sorter={(a, b) => a.occupationName.localeCompare(b.occupationName)} fixed={true} />
					{suppliers?.map((item, index) => {
						return (
							<Column width="85px" title={item.logoURL && item.logoURL !== "" ? <Image src={item.logoURL} preview={false} width={70} /> :
								item.supplierName} dataIndex={item.supplierName} key={item.supplierName} align='center'
								render={(_, data, oIndex) => {
									let supplierIndex = data.suppliers?.findIndex((dataItem) => dataItem.supplierName === item.supplierName);
									let supplierItem = data.suppliers[supplierIndex]
									return (
										<>
											{
												type === 1 && supplierItem?.qualityApps === 0 ? (
													<>
														<div>{currencyContext.currency}-</div>
														<div>
															<span className='text-red-500 whitespace-nowrap text-xs'>{currencyContext.currency} {supplierItem?.spent}</span>
														</div>
													</>
												) : type === 2 && supplierItem?.interviews === 0 ? (
													<>
														<div>{currencyContext.currency}-</div>
														<div>
															<span className='text-red-500 whitespace-nowrap text-xs'>{currencyContext.currency} {supplierItem?.spent}</span>
														</div>
													</>
												) : type === 3 && supplierItem?.hires === 0 ? (
													<>
														<div>{currencyContext.currency}-</div>
														<div>
															<span className='text-red-500 whitespace-nowrap text-xs'>{currencyContext.currency} {supplierItem?.spent}</span>
														</div>
													</>
												) : (
													supplierItem?.spentPerQualityApps && type === 1 ? (
														<>
															<div className='whitespace-nowrap text-base'>{currencyContext.currency}{supplierItem?.spentPerQualityApps}</div>
															<div className='whitespace-nowrap text-xs'>({currencyContext.currency}{supplierItem?.spent})</div>
														</>
													) : supplierItem?.spentPerInterviews && type === 2 ? (
														<>
															<div className='whitespace-nowrap text-base'>{currencyContext.currency}{supplierItem?.spentPerInterviews}</div>
															<div className='whitespace-nowrap text-xs'>({currencyContext.currency}{supplierItem?.spent})</div>
														</>
													) : supplierItem?.spentPerHires && type === 3 && (
														<>
															<div className='whitespace-nowrap text-base'>{currencyContext.currency}{supplierItem?.spentPerHires}</div>
															<div className='whitespace-nowrap text-xs'>({currencyContext.currency}{supplierItem?.spent})</div>
														</>
													)
												)
											}

										</>
									)
								}} />)
					})}
				</Table>
			</div>
		</div>
	);
};

export default OccupationMatrix;