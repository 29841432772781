import { DatePicker, Space, Select, Table, message, Spin, Form, Image, Row, Col, Tooltip, Button } from 'antd';
import dayjs from 'dayjs';
import { FileExcelOutlined } from '@ant-design/icons';
import constants from '../../constants/constants';
import { useEffect, useState } from 'react';
import WebLinks from '../../api/WebLinks';
import Cookies from 'js-cookie';
import APIUtils from '../../api/APIUtils';
import { downloadFiles } from '../../globalFunctions/GlobalFunctions';
const { RangePicker } = DatePicker;
const { Option } = Select;

const MonthReport = (props) => {

	//Variable declaration
	const columns = [
		{
			title: 'Buyer',
			dataIndex: 'buyerName',
			key: 'buyerName',
			sorter: (a, b) => a.buyerName.localeCompare(b.buyerName)
		},
		{
			title: 'Applies',
			dataIndex: 'applies',
			key: 'applies',
			align:'center',
			sorter:(a, b) => a.applies - b.applies,
		},
		{
			title: 'Cost',
			dataIndex: 'totalCost',
			key: 'totalCost',
			align:'center',
			sorter:(a, b) => a.totalCost - b.totalCost,
		},
		{
			title: 'Cost/Apply',
			dataIndex: 'totalCostApplies',
			key: 'totalCostApplies',
			align:'center',
			sorter:(a, b) => a.totalCostApplies - b.totalCostApplies,
		},
		{
			title: 'Quality',
			dataIndex: 'quality',
			key: 'quality',
			align:'center',
			sorter:(a, b) => a.quality - b.quality,
			render:(_,record)=>{
                return(
					<Space size={3}>
						<span>{record.quality+" "}</span><span>({record.qualityPer}%)</span>
					</Space>
				)
				
			}
		},
		{
			title: 'Cost/Quality',
			dataIndex: 'costQuality',
			key: 'costQuality',
			align:'center',
			sorter:(a, b) => a.costQuality - b.costQuality,
		},
		{
			title: 'Interviews',
			dataIndex: 'interview',
			key: 'interview',
			align:'center',
			sorter:(a, b) => a.interview - b.interview,
			render:(_,record)=>{
                return(
					<>
						<span>{record.interview+" " }</span><span>({record.interviewPer}%)</span>
					</>
				)
				
			}
		},
		{
			title: 'Cost/Interview',
			dataIndex: 'costInterview',
			key: 'costInterview',
			align:'center',
			sorter:(a, b) => a.costInterview - b.costInterview,
		},
		{
			title: 'Hires',
			dataIndex: 'hired',
			key: 'hired',
			align:'center',
			sorter:(a, b) => a.hired - b.hired,
			render:(_,record)=>{
                return(
					<Space size={3}>
						<span>{record.hired+" "}</span><span>({record.hiredPer}%)</span>
					</Space>
				)
				
			}
		},
		{
			title: 'Cost/Hire',
			dataIndex: 'costHired',
			key: 'costHired',
			align:'center',
			sorter:(a, b) => a.costHired - b.costHired,
		}
	];
	const [monthlyData,setMonthlyData]=useState([]);
	const [loader,setLoader]=useState(false);
	const [monthReportTotal, setMonthReportTotal] = useState([]);
	const [form] = Form.useForm();
	const [buyerListLoader,setBuyerListLoader]= useState(false);
	const [allowedBuyerList, setAllowedBuyerList] = useState([]);
	const [rangePickerValue, setRangePickerValue] = useState([]);
	const [suplierListLoader,setSuplierListLoader]= useState(false);
	const [allowedSupplierList, setAllowedSupplierList] = useState([]);
	const [selectedBuyerIds,setSelectedBuyerIds]=useState([]);
	const [selectedSupplierIds,setSelectedsupplierIds]=useState([]);

	//On load function call
	useEffect(()=>{
		form.setFieldsValue({
			buyerList:["showall"],
			supplierList:["showall"]
		})
		getAllowedBuyers();
		getAllowedSuppliers();
		form.setFieldsValue({
			rangePicker:[dayjs(),dayjs()],
		})
		setRangePickerValue([
			dayjs().format(constants.MonthFormat),
			dayjs().format(constants.MonthFormat)
		]);
		GetMonthlyReportData(dayjs().format(constants.MonthFormat),dayjs().format(constants.MonthFormat));
	},[props]);

	//Functions
	//Function runs when range picker value is changed
	const RangePickerChanged= (_,dateString) => {
		setRangePickerValue([
			dayjs(dateString[0], constants.MonthFormat),
			dayjs(dateString[1], constants.MonthFormat),
		]);
		form.setFieldsValue({
			buyerList:["showall"],
			supplierList:["showall"]
		})
		GetMonthlyReportData(dayjs(dateString[0]).format(constants.MonthFormat),dayjs(dateString[1]).format(constants.MonthFormat));
	};
	//Function to get the month report for the multiApplicationReport / subBuyers
	//this fn will only be called if the props.tabName is monthReport 
	const GetMonthlyReportData = async (startDate,endDate,buyerIds="",supplierIds="")=>{
		setLoader(true);
		const response = await APIUtils.apiCall('GET',WebLinks.GetSubBuyerMonthlyReport+Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)+"&fromDate="+startDate+"&toDate="+endDate+"&selectedBuyerId="+buyerIds+"&selectedSupplierId="+supplierIds);
		if(!response.isCatch && response.resultObj.statusCode==="2000"){
				setMonthReportTotal(response.resultObj.data.monthReport.pop());
				setMonthlyData(response.resultObj.data.monthReport);
		}else{
				setMonthlyData([]);
				//message.error(constants.ERROR_MESSAGE);
		}
		setLoader(false);
	};
	//Function to display the month total in the table
	const monthReportSummary =()=>{
		return (
			<>
				<Table.Summary.Row className='bg-teal-50'>
					<Table.Summary.Cell index={0} align='center'>
						<strong>Totals</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={1} className='text-center'>
						<strong>{monthReportTotal.applies}</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={2} className='text-center'>
						<strong>{monthReportTotal.totalCost}</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={3} className='text-center'>
						<strong>{monthReportTotal.totalCostApplies}</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={4} className='text-center'>
						<strong><Space size={3}>{monthReportTotal.quality+" "}<span>({monthReportTotal.qualityPer}%)</span></Space></strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={5} className='text-center'>
						<strong>{monthReportTotal.costQuality}</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={6} className='text-center'>
						<strong><Space size={3}>{monthReportTotal.interview+" "}<span>({monthReportTotal.interviewPer}%)</span></Space></strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={7} className='text-center'>
						<strong>{monthReportTotal.costInterview}</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={8} className='text-center'>
						<strong><Space size={3}>{monthReportTotal.hired+" "}<span>({monthReportTotal.hiredPer}%)</span></Space></strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={9} className='text-center'>
						<strong>{monthReportTotal.costHired}</strong>
					</Table.Summary.Cell>
				</Table.Summary.Row>
			</>
		)
	};
	//Function to get Allowed suppliers list
	const getAllowedBuyers =async()=>{
		setBuyerListLoader(true);
		const response = await APIUtils.apiCall('GET',WebLinks.GEtSubBuyerAllotedList+Cookies.get(constants.COOKIE_SELECTED_BUYER_ID));
		if(!response.isCatch && response.resultObj.statusCode==="2000"){
			setAllowedBuyerList(response?.resultObj?.data||[])
			setBuyerListLoader(false);
		}else{
			setAllowedBuyerList([]);
			setBuyerListLoader(false);
			message.error(response.resultObj.message);
		}
	}
	//Function on change of supplier selection
	const handleBuyerSelect=(selectedBuyers)=>{
		if(selectedBuyers.length>0){
			let tempSelectedBuyers=selectedBuyers.filter(value => value !== 'showall');
			let selectedBuyersFinal=tempSelectedBuyers.length>0?tempSelectedBuyers:"";
			form.setFieldsValue({
				buyerList:selectedBuyersFinal
			})
			setSelectedBuyerIds(selectedBuyersFinal);
			GetMonthlyReportData(dayjs(rangePickerValue[0]).format(constants.MonthFormat), dayjs(rangePickerValue[1]).format(constants.MonthFormat),selectedBuyersFinal,selectedSupplierIds.length>0?selectedSupplierIds:"");
		}else{
			form.setFieldsValue({
				buyerList:["showall"]
			})
			setSelectedBuyerIds([]);
			GetMonthlyReportData(dayjs(rangePickerValue[0]).format(constants.MonthFormat), dayjs(rangePickerValue[1]).format(constants.MonthFormat),"",selectedSupplierIds.length>0?selectedSupplierIds:"");
		}
	}
	//Function on change of supplier selection
	const handleSupplierSelect=(selectedSuppliers)=>{
		if(selectedSuppliers.length>0){
			let tempSelectedSuppliers=selectedSuppliers.filter(value => value !== 'showall');
			let selectedSuppliersFinal=tempSelectedSuppliers.length>0?tempSelectedSuppliers:"";
			form.setFieldsValue({
				supplierList:selectedSuppliersFinal
			})
			setSelectedsupplierIds(selectedSuppliersFinal);
			GetMonthlyReportData(dayjs(rangePickerValue[0]).format(constants.MonthFormat), dayjs(rangePickerValue[1]).format(constants.MonthFormat),selectedBuyerIds.length>0?selectedBuyerIds:"",selectedSuppliersFinal);
		}else{
			form.setFieldsValue({
				supplierList:["showall"]
			})
			setSelectedsupplierIds([]);
			GetMonthlyReportData(dayjs(rangePickerValue[0]).format(constants.MonthFormat), dayjs(rangePickerValue[1]).format(constants.MonthFormat),selectedBuyerIds.length>0?selectedBuyerIds:"","");
		}
	}
	//Function to get Allowed suppliers list
	const getAllowedSuppliers =async()=>{
		setSuplierListLoader(true);
		const response = await APIUtils.apiCall('GET',WebLinks.GetALlAllowedSuppliers);
		if(!response.isCatch && response.resultObj.statusCode==="2000"){
			setAllowedSupplierList(response?.resultObj?.data||[])
			setSuplierListLoader(false);
		}else{
			setAllowedSupplierList([]);
			setSuplierListLoader(false);
			message.error(response.resultObj.message);
		}
	}

	//Function to download as excel
	const downloadFile = () => {
		setLoader(true)
		let url=WebLinks.MultiDownloadApplicationMonthReport+Cookies.get(constants.COOKIE_SELECTED_BUYER_ID) + `&fromDate=${dayjs(rangePickerValue[0]).format(constants.MonthFormat)}&toDate=${dayjs(rangePickerValue[1]).format(constants.MonthFormat)}`
		if(selectedBuyerIds.length>0){
			url=url+"&selectedBuyerId="+selectedBuyerIds
		}
		if(selectedSupplierIds.length>0){
			url= url+"&selectedSupplierId="+selectedSupplierIds
		}
		downloadFiles(url,"MultiApplicationMonthReport.xlsx");
		setTimeout(() => {
				setLoader(false)
		}, 3000);
	};

	return (
		<>
		<Spin spinning={loader}>
			<div>
				<div className='flex justify-between items-center mb-3'>
					<Space wrap>
						<Form form={form}>
							<Row gutter={[12, 12]}>
								<Col>
								<Space wrap>
								<Form.Item name="rangePicker" className='mb-0'>
									<RangePicker picker="month" placeholder={["From", "To"]} onChange={RangePickerChanged} format={constants.MonthFormat} />
								</Form.Item>
								<Tooltip placement='right' title="Download as Excel">
										<Button
												className='bg-green-800 text-white hover:!text-white hover:!border-green-500'
												icon={<FileExcelOutlined />}
												onClick={()=>{downloadFile()}}
										>
											Download as Excel
										</Button>
									</Tooltip>
									</Space>
								</Col>
								<Col>
								<Space wrap>
									<label>Filter by Buyer</label>
									<Form.Item name="buyerList" className='mb-0'>
										<Select
											loading={buyerListLoader}
											className='!w-72'
											placeholder={"Buyer"}
											mode='multiple'
											allowClear
											defaultValue={"showall"}
											showSearch
										filterOption={(input, option) =>
												option.props.value.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
												option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
										}
											onChange={(e) => handleBuyerSelect(e)}
											>
												<Option key='showall' value='showall'>Show all</Option>
													{allowedBuyerList?.map((buyer) => (
														<Option key={buyer.subPartnerId}
																value={buyer.subPartnerId}
														>
														{buyer.name}</Option>
											))}
										</Select>
									</Form.Item>
									<label>Filter by Supplier</label>
									<Form.Item name="supplierList" className='mb-0'>
										<Select
											loading={suplierListLoader}
											className='!w-72'
											// value={selectedSupplier || []}
											placeholder={constants.supplierName}
											mode='multiple'
											allowClear
											defaultValue={"showall"}
											showSearch
										filterOption={(input, option) =>
												option.props.value.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
												option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
										}
											onChange={(e) => handleSupplierSelect(e)}
											>
												<Option key='showall' value='showall'>Show all</Option>
													{allowedSupplierList?.map((supplier) => (
														<Option key={supplier.supplierId}
																value={supplier.supplierId}
														>
														{supplier.name}</Option>
											))}
										</Select>
									</Form.Item>
								</Space>
								</Col>
							</Row>
						</Form>
					</Space>
				</div>
				<div className='overflow-x-auto'>
					<Table
						columns={columns}
						dataSource={monthlyData}
						scroll={{
							x: 1000,
						}}
						sortDirections={['descend','ascend','descend']}
						pagination={false}
						summary={() => monthReportSummary()}
					/>
				</div>
			</div>
		</Spin>
		</>
	);
};

export default MonthReport;