import { DatePicker, Space, Table, message, Spin, Form, Image } from 'antd';
import dayjs from 'dayjs';
import constants from '../../constants/constants';
import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import WebLinks from '../../api/WebLinks';
import Cookies from 'js-cookie';
import APIUtils from '../../api/APIUtils';
const { RangePicker } = DatePicker;

const ClickMonthReport = (props) => {

	//Variable declaration
	const columns = [
		{
			title: 'Month/Supplier',
			dataIndex: 'name',
			key: 'name',
			sorter: (a, b) => a.name.length - b.name.length,
			render: (_, record) => {
				return (
					<>
						{record.logoURL && record.logoURL !== "" ?
							<Image src={record.logoURL} preview={false} width={100} /> :
							<span>{record.name}</span>
						}
					</>
				)

			}
		},
		{
			title: 'Clicks',
			dataIndex: 'totalClicks',
			key: 'totalClicks',
			align: 'center',
			sorter: (a, b) => a.totalClicks - b.totalClicks,
		},
		{
			title: 'Cost',
			dataIndex: 'cost',
			key: 'cost',
			align: 'center',
			sorter: (a, b) => a.cost - b.cost,
		},
		{
			title: 'CPC',
			dataIndex: 'cpc',
			key: 'cpc',
			align: 'center',
			sorter: (a, b) => a.cpc - b.cpc,
		},
		{
			title: 'Expired',
			dataIndex: 'expired',
			key: 'expired',
			align: 'center',
			sorter: (a, b) => a.expired - b.expired,
		},
		{
			title: 'Rejected',
			dataIndex: 'rejected',
			key: 'rejected',
			align: 'center',
			sorter: (a, b) => a.rejected - b.rejected,
		},
		{
			title: 'Interview',
			dataIndex: 'interview',
			key: 'interview',
			align: 'center',
			sorter: (a, b) => a.interview - b.interview,
		},
    {
			title: 'Hired',
			dataIndex: 'hired',
			key: 'hired',
			align: 'center',
			sorter: (a, b) => a.hired - b.hired,
		},
	];
	const [clickMonthlyData, setClickMonthlyData] = useState([]);
	const [rangePickerValue, setRangePickerValue] = useState([]);
	const [loader, setLoader] = useState(false);
	const [clickMonthReportTotal, setClickMonthReportTotal] = useState([]);
	const [form] = Form.useForm();

	useEffect(() => {
		const date1 = dayjs().startOf('month')
		const date2 = dayjs().endOf('month')
		form.setFieldsValue({
			rangePicker: [
				date1,
				date2,
			],
		});
		GetClickMonthlyReportData(
			date1.format(constants.MonthFormat),
			date2.format(constants.MonthFormat));
	}, []);

	//Functions
	//Function runs when range picker value is changed
	const RangePickerChanged = debounce((_, dateStrings) => {
		props.changeDate(dateStrings);
		setRangePickerValue([
			dayjs(dateStrings[0], constants.MonthFormat),
			dayjs(dateStrings[1], constants.MonthFormat),
		]);
		GetClickMonthlyReportData(dateStrings[0], dateStrings[1]);
	}, 500);

	//Function to Month report data
	const GetClickMonthlyReportData = async (startDate, endDate) => {
		setLoader(true);
		const response = await APIUtils.apiCall('GET', WebLinks.GetClickReportMonthDayDetails + Cookies.get(constants.COOKIE_SELECTED_BUYER_ID) + "&startDate=" + startDate + "&endDate=" + endDate);
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			setClickMonthReportTotal(response.resultObj.data.pop());
			setClickMonthlyData(response.resultObj.data);
			setLoader(false);
		} else {
			setClickMonthlyData([]);
			setLoader(false);
			message.error(response.resultObj.message);
		}
	}

	//Function to display the month total in the table
	const clickMonthReportSummary = () => {
		return (
			<>
				<Table.Summary.Row className='bg-teal-50'>
					<Table.Summary.Cell index={0} align='center'>
						<strong>Total</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={1} className='text-center'>
						<strong>{clickMonthReportTotal.totalClicks}</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={2} className='text-center'>
						<strong>{clickMonthReportTotal.cost}</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={3} className='text-center'>
						<strong>{clickMonthReportTotal.cpc}</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={4} className='text-center'>
						<strong>{clickMonthReportTotal.expired}</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={5} className='text-center'>
						<strong>{clickMonthReportTotal.rejected}</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={6} className='text-center'>
						<strong>{clickMonthReportTotal.interview}</strong>
					</Table.Summary.Cell>
          <Table.Summary.Cell index={7} className='text-center'>
						<strong>{clickMonthReportTotal.hired}</strong>
					</Table.Summary.Cell>
				</Table.Summary.Row>
			</>
		)
	};

	return (
		<>
			<Spin spinning={loader}>
				<div>
					<div className='flex justify-between items-center mb-3'>
						<Space wrap>
							<Form form={form}>
								<Form.Item name="rangePicker" className='mb-0'>
									<RangePicker picker="month"
										placeholder={["From", "To"]}
										onChange={RangePickerChanged}
										format={constants.MonthFormat} />
								</Form.Item>
							</Form>
						</Space>
					</div>
					<div className='overflow-x-auto'>
						<Table
							columns={columns}
							dataSource={clickMonthlyData}
							scroll={{
								x: 1000,
							}}
							sortDirections={['descend', 'ascend', 'descend']}
							pagination={{
								defaultPageSize: 100,
							}}
							summary={() => clickMonthReportSummary()}
						/>
					</div>
				</div>
			</Spin>
		</>
	);
};

export default ClickMonthReport;