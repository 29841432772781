import { DatePicker, Space, Select, Table, message, Spin, Form, Image, Tooltip, Button } from 'antd';
import dayjs from 'dayjs';
import { FileExcelOutlined } from '@ant-design/icons';
import constants from '../../constants/constants';
import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import WebLinks from '../../api/WebLinks';
import Cookies from 'js-cookie';
import APIUtils from '../../api/APIUtils';
import { downloadFiles } from '../../globalFunctions/GlobalFunctions';
import moment from 'moment';
const { RangePicker } = DatePicker;

const InterviewsHires = (props) => {

	//Variable declaration
	const columns = [
		{
			title: 'Month/Supplier',
			dataIndex: 'supplierName',
			key: 'supplierName',
			sorter: (a, b) => a.supplierName.length - b.supplierName.length,
			render: (_, record) => {
				return (
					<>
						{record.logoURL && record.logoURL !== "" ?
							<Image src={record.logoURL} preview={false} width={100} /> :
							<span>{record.supplierName}</span>
						}
					</>
				)

			}
		},
		{
			title: 'Interviews',
			dataIndex: 'interview',
			key: 'interview',
			align: 'center',
			sorter: (a, b) => a.interview - b.interview,
			render: (_, record) => {
				return (
					<>
						{/* <span>{record?.interview||"" + " "}</span><span>({record?.interviewPer||0}%)</span> */}
						<span>{record?.interview}</span>
					</>
				)

			}
		},
		// {
		// 	title: 'Cost/Interview',
		// 	dataIndex: 'costInterview',
		// 	key: 'costInterview',
		// 	align: 'center',
		// 	sorter: (a, b) => a.costInterview - b.costInterview,
		// },
		{
			title: 'Hires',
			dataIndex: 'hired',
			key: 'hired',
			align: 'center',
			sorter: (a, b) => a.hired - b.hired,
			render: (_, record) => {
				return (
					<Space size={3}>
						{/* <span>{record?.hired||"" + " "}</span><span>({record?.hiredPer||0}%)</span> */}
						<span>{record?.hired}</span>
					</Space>
				)

			}
		},
		{
			title: 'Rejected',
			dataIndex: 'rejected',
			key: 'rejected',
			align: 'center',
			sorter: (a, b) => a.rejected - b.rejected,
			render: (_, record) => {
				return (
					<Space size={3}>
						{/* <span>{record?.rejected||"" + " "}</span><span>({record?.rejectedPer||0}%)</span> */}
						<span>{record?.rejected}</span>
					</Space>
				)

			}
		},
		// {
		// 	title: 'Cost/Hire',
		// 	dataIndex: 'costHired',
		// 	key: 'costHired',
		// 	align: 'center',
		// 	sorter: (a, b) => a.costHired - b.costHired,
		// }
	];
	const [interviewsHiresData, setInterviewsHiresData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [rangePickerValue, setRangePickerValue] = useState([]);
	const [loader, setLoader] = useState(false);
	const [totalInterviewHiresCount, setTotalInterviewHiresCount] = useState(0);
	const [interviewdHiresReportTotal, setInterviewdHiresReportTotal] = useState([]);
	const [form] = Form.useForm();

	useEffect(() => {
		const date1 = dayjs().startOf('month')
		const date2 = dayjs().endOf('month')
		form.setFieldsValue({
			rangePicker: [
				date1,
				date2,
			],
		});
		setRangePickerValue([
			date1.format(constants.MonthFormat),
			date2.format(constants.MonthFormat),
		]);
		GetInterviewsHiredReportData(1, 100,
			date1.format(constants.MonthFormat),
			date2.format(constants.MonthFormat));
	}, []);


	//Functions
	//Function runs when range picker value is changed
	const RangePickerChanged = debounce((_, dateStrings) => {
		props.changeDate(dateStrings);
		setRangePickerValue([
			dayjs(dateStrings[0], constants.MonthFormat),
			dayjs(dateStrings[1], constants.MonthFormat),
		]);
		GetInterviewsHiredReportData(currentPage, pageSize, dateStrings[0], dateStrings[1]);
	}, 500);

	//Function to Month report data
	const GetInterviewsHiredReportData = async (currentPage, pazeSize, startDate, endDate) => {
		setLoader(true);
		const response = await APIUtils.apiCall('GET', WebLinks.GetApplicationInterviewsHiresReport + Cookies.get(constants.COOKIE_SELECTED_BUYER_ID) + "&fromDate=" + startDate + "&toDate=" + endDate + "&limit=" + pazeSize + "&pageNo=" + currentPage);
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			setInterviewdHiresReportTotal(response.resultObj.data.pop());
			setInterviewsHiresData(response.resultObj.data);
			setTotalInterviewHiresCount(response.resultObj.getCount);
			setLoader(false);
		} else {
			setInterviewsHiresData([]);
      setTotalInterviewHiresCount(0);
			setLoader(false);
			message.error(response.resultObj.message);
		}
	}

	//Function on change of application table
	const onchangeApplicationTable = (pagination, filters, sorter) => {
		if (pagination) {
			if (pageSize !== pagination.pageSize) {
				setCurrentPage(1);
			} else {
				setCurrentPage(pagination?.current);
			}
			setPageSize(pagination?.pageSize);
			GetInterviewsHiredReportData(pagination?.current, pagination?.pageSize, rangePickerValue[0], rangePickerValue[1])
		}
	};

	//Function to download as excel
	const downloadFile = () => {
		setLoader(true)
		downloadFiles(WebLinks.DownloadApplicationInterviesHiresReport+Cookies.get(constants.COOKIE_SELECTED_BUYER_ID) + `&fromDate=${dayjs(rangePickerValue[0]).format(constants.MonthFormat)}&toDate=${dayjs(rangePickerValue[1]).format(constants.MonthFormat)}`,"ApplicationInterviewsHiresReport.xlsx");
		setTimeout(() => {
				setLoader(false)
		}, 3000);
	};

	//Function to display the month total in the table
	const interviewsHiresReportSummary = () => {
		return (
			<>
				<Table.Summary.Row className='bg-teal-50'>
					<Table.Summary.Cell index={0} align='center'>
						<strong>Totals</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={1} className='text-center'>
						<strong><Space size={3}>{interviewdHiresReportTotal.interview}</Space></strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={2} className='text-center'>
						<strong><Space size={3}>{interviewdHiresReportTotal.hired}</Space></strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={3} className='text-center'>
						<strong>{interviewdHiresReportTotal.rejected}</strong>
          </Table.Summary.Cell>
				</Table.Summary.Row>
			</>
		)
	};
  const disabledDate = (current) => {
    // Disable all dates before the start of the current month
    return current && current < moment().startOf('month');
  };
	return (
		<>
			<Spin spinning={loader}>
				<div>
					<div className='flex flex-wrap gap-3 justify-between items-center mb-3'>
						<Form form={form}>
							<Form.Item name="rangePicker" className='mb-0'>
								<RangePicker picker="month"
									placeholder={["From", "To"]}
									onChange={RangePickerChanged}
									//value={rangePickerValue}
                  disabledDate={disabledDate}
									format={constants.MonthFormat} />
							</Form.Item>
						</Form>
						
          <Tooltip placement='right' title="Download as Excel">
              <Button
                  className='bg-green-800 text-white hover:!text-white hover:!border-green-500'
                  icon={<FileExcelOutlined />}
                  onClick={()=>{downloadFile()}}
              >
                Download as Excel
              </Button>
          </Tooltip>
					</div>
					<div className='overflow-x-auto'>
						<Table
							columns={columns}
							dataSource={interviewsHiresData}
							scroll={{
								x: 1000,
							}}
							// onChange ={onchangeApplicationTable}
							sortDirections={['descend', 'ascend', 'descend']}
							// pagination={{
							// 	total: totalInterviewHiresCount,
							// 	// showSizeChanger: true,
							// 	// pageSizeOptions: ["25", "50", "100"],
							// 	defaultPageSize: 100,
							// 	// current: currentPage,
							// }}
              pagination={false}
							summary={() => interviewsHiresReportSummary()}
						/>
					</div>
				</div>
			</Spin>
		</>
	);
};

export default InterviewsHires;