import { Input, Form, Button, Divider, Card, Space, message, Spin, Select } from 'antd';
import APIUtils from '../../../api/APIUtils';
import WebLinks from '../../../api/WebLinks';
import Cookies from 'js-cookie';
import constants from '../../../constants/constants';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GetCustomPriorityLevelIcons, GetPriorityLevelColors, getPriorityCount } from '../../../globalFunctions/GlobalFunctions';

const { Option } = Select

const Profile = () => {

  const formlayout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const formlayoutCard = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 18,
    },
  };

  //Variables declaration
  const [buyerProfileForm] = Form.useForm();
  const history = useHistory();
  const [showPasswordFields,setShowPasswordFields]=useState(false);
  const [loader,setLoader]=useState(false);
  const [priorityColorCode,setPriorityColorCode]=useState([]);
  const [adBudget,setAdBudget]=useState([]);

  //On load function call
  useEffect(()=>{
    createBudgetArray();
    getBuyerPrioritylist();
    GetBuyerDetails();
  },[]);

  //Fuctions
  //Function to get buyer details{id}.
  const GetBuyerDetails = async () =>{
    setLoader(true);
		const response = await APIUtils.apiCall('GET',WebLinks.GetBuyerDetails+Cookies.get(constants.COOKIE_SELECTED_BUYER_ID));
    if(!response.isCatch && response.resultObj.statusCode==="2000"){
      //Setting field values for profile form.
      if(response.resultObj&&response.resultObj.data){
      buyerProfileForm.setFieldsValue({
        name:response.resultObj.data.name,
        // domain:response.resultObj.data.domain,
        accountManager:response?.resultObj?.data?.accountManager,
       // cpa:response.resultObj.data.cpa,
        cpacurrency:response.resultObj.data.cpaCurrency,
        xmlurl:response.resultObj.data.xmlFeedUrl,
        registrationapi:response.resultObj.data.registrationApiUrl,
        applicationapi:response.resultObj.data.applicationApiUrl,
        email:response.resultObj.data.email,
        //userapi:response.resultObj.data.userCheckApiUrl,
        priority:response?.resultObj?.data?.priority?.toString(),
        SocialBudgetPerAd:response?.resultObj?.data?.socialBudgetPerAd,
      })
      setLoader(false);
    }else{
      message.error(constants.NO_DATA);
      setLoader(false);
    }
		}
	};

  //Function to save edited profile data.
  const onProfileSave = async(values) => {
    setLoader(true);
    //Payload creation for edit profile save.
    let profileSavePayload=null;
    profileSavePayload={
      buyerId: Cookies.get(constants.COOKIE_SELECTED_BUYER_ID),
      name: values.name,
      //domain: values.domain,
      accountManager:values.accountManager,
      //cpa: values.cpa,
      cpaCurrency: values.cpacurrency,
      xmlFeedUrl: values.xmlurl,
      priority:values?.priority||null,
      SocialBudgetPerAd:values?.SocialBudgetPerAd||null,
      // registrationApiUrl: values.registrationapi,
      // applicationApiUrl: values.applicationapi,
      email: values.email,
      //userCheckApiUrl: values.userapi
    };
    //Reset password
    if(values.password&&values.newpassword){
      profileSavePayload.password=values.password;
      profileSavePayload.newpassword=values.newpassword;
    }
    //Edit profile save API call
    const saveProfileResponse = await APIUtils.apiCall('PUT',WebLinks.LoggedInBuyerDetails,JSON.stringify(profileSavePayload));
    if(!saveProfileResponse.isCatch && saveProfileResponse.resultObj.statusCode==="2000"){
      message.success(constants.SUCCESS_SAVE);
    }else{
      message.error(constants.FAILED_SAVE);
    }
    setLoader(false);
  };

    //Function to get priority list
  const getBuyerPrioritylist=async()=>{
    let url = WebLinks.GetBuyerPriorityFilters + Cookies.get(constants.COOKIE_SELECTED_BUYER_ID);    
    const response = await APIUtils.apiCall('GET', url);
    if (!response.isCatch && response.resultObj) {
      let temppriorityColorCode=[];
      response?.resultObj?.data.forEach(element => {
        let priorityObj={
          value:element.priorityLevel.toString(),
          colorValue:response?.resultObj?.data?.length>5?GetCustomPriorityLevelIcons(element.priorityLevel):GetPriorityLevelColors(element.priorityLevel),
          customName:element.customName,
          prioritycount:getPriorityCount(element.priorityLevel,response?.resultObj?.priorityCount)
        }
        temppriorityColorCode.push(priorityObj);
        setPriorityColorCode(temppriorityColorCode);
      });
    } else {
      setPriorityColorCode([]);
      message.error(response?.resultObj?.message);
    }
  };

  //create array in tens
  const createBudgetArray = () => {
    const start = 80;
    const end = 400;
    const step = 10;
    const array = [];

    for (let i = start; i <= end; i += step) {
      array.push(i);
    }

    setAdBudget(array);
  };

  return (
    <div>
      <Spin spinning={loader}>
        <>
        <div className='uppercase font-bold text-xl'>Profile</div>
        <Divider className='mt-2 mb-3' />
        <Form {...formlayout} className='lg:w-4/5' form={buyerProfileForm} onFinish={onProfileSave}>
          <Form.Item
            name="name"
            label="Name"
          >
            <Input disabled />
          </Form.Item>
          {/* <Form.Item
            name="domain"
            label="Domain"
          >
            <Input disabled />
          </Form.Item> */}
          {/* <Form.Item
            name="cpa"
            label="CPA"
          >
            <Input disabled />
          </Form.Item> */}
          <Form.Item
            name="cpacurrency"
            label="CPA Currency"
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="xmlurl"
            label="XML Feed URL"
          >
            <Input disabled />
          </Form.Item>
           <Form.Item
           name="priority"
           label="Priority"
           >
            <Select
            disabled={!(Cookies.get(constants.COOKIE_IS_ADMIN) === "true" || Cookies.get(constants.COOKIE_IS_ADMIN) === true)}
             placeholder="Priority"
             className="w-[65vw] md:w-40"
             allowClear
            >
              {priorityColorCode?.map((item=>{
                return(
                  <Option value={item.value}>
                      <Space>
                        <div className={item.colorValue}></div>
                        {item.customName}
                      </Space>             
                  </Option>
                )
              }))}
            </Select>
           </Form.Item>
          {/* <Form.Item
            name="registrationapi"
            label="Registration API URL"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="applicationapi"
            label="Application API URL"
          >
            <Input />
          </Form.Item> */}
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                type: 'email',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="accountManager"
            label="Account Manager"
          >
            <Input />
          </Form.Item>
          <Form.Item
           name="SocialBudgetPerAd"
           label="Social Budget Per Ad"
           >
            <Select
            disabled={!(Cookies.get(constants.COOKIE_IS_ADMIN) === "true" || Cookies.get(constants.COOKIE_IS_ADMIN) === true)}
             placeholder="Social Budget Per Ad"
             className="w-[65vw] md:w-40"
             allowClear
            >
              {adBudget?.map((item=>{
                return(
                  <Option value={item}>
                        {item}
                  </Option>
                )
              }))}
            </Select>
           </Form.Item>
          {/* <Form.Item
            name="userapi"
            label="User Check API URL"
          >
            <Input />
          </Form.Item> */}
          <Card
            size="small"
            // title={}
            className='lg:w-[90%] bg-lime-50 ml-auto'
          >
            <Form.Item
              {...formlayoutCard}
              label={<span className='uppercase font-semibold'>Reset Password </span>}
              className='!mb-0'
            >
              <Button type='primary' onClick={()=>setShowPasswordFields(!showPasswordFields)}>Reset</Button>
            </Form.Item>
            <Divider className='my-3'/>
            {showPasswordFields?
            <>
            <Form.Item
              {...formlayoutCard}
              label="Current Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              {...formlayoutCard}
              name="newpassword"
              label="New Password"
              dependencies={['password']}
              rules={[
                {
                  required: true,
                  message: 'Please input your new password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') !== value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('New password can not be same as old password!'));
                  },
                }),
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              {...formlayoutCard}
              name="confirm"
              label="Confirm Password"
              dependencies={['newpassword']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newpassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            </>:null}
          </Card>
          <Form.Item className='mt-3 flex justify-end items-center'>
            <Space>
              <Button className='bg-rose-600 text-white uppercase font-bold hover:!text-white hover:!border-rose-500'
                onClick={()=>history.push("/")}
              >
                Cancel
              </Button>
              <Button type="primary" htmlType='submit'>Save</Button>
            </Space>
          </Form.Item>
        </Form>
        </>
      </Spin>
    </div>
  );
};

export default Profile;