import { Tabs, Divider } from 'antd';
import constants from '../../constants/constants';
import { UnorderedListOutlined, CalendarOutlined } from '@ant-design/icons';
import Budgets from './Budgets';
// import MonthReport from './MonthReport';
import { useState } from 'react';
import { useEffect } from 'react';
// import OccupationReport from './OccupationReport';

const { TabPane } = Tabs;

const MultiBudgetAdmin = () => {

  //Variable declaration
  const [dateValues,setDateValues]=useState(null);
  const [tabValue,seTabValue]=useState(2)

  //Functions
  //Functon to set changed date value
  const changeDate=(selectedDate)=>{
    setDateValues(selectedDate);
  }

	//On load function call
	// useEffect(()=>{
	// },[tabValue]);

	return (

		<div>
            <div className='uppercase font-bold text-xl'>Multi Budget Admin</div>
            <Divider className='my-3'/>
            <Tabs defaultActiveKey="1" onChange={(e)=>seTabValue(e)}>
                <TabPane
                    tab={ <span className='uppercase'><CalendarOutlined className='text-xl' />{constants.MultiBudgetsAdminTab1}</span> }
                    key={1}
                >
                    <Budgets tabName={constants.MultiBudgetsAdminTab1}/>
                </TabPane>
                <TabPane
                    tab={ <span className='uppercase'><UnorderedListOutlined className='text-xl' />{constants.MultiBudgetsAdminTab2}</span> }
                    key={2}
                >
                    <Budgets tabName={constants.MultiBudgetsAdminTab2}/>
                </TabPane>
                <TabPane
                    tab={ <span className='uppercase'><CalendarOutlined className='text-xl' />{constants.MultiBudgetsAdminTab3}</span> }
                    key={3}
                >
                    <Budgets tabName={constants.MultiBudgetsAdminTab3}/>
                </TabPane>
			</Tabs>
		</div>
	);
};

export default MultiBudgetAdmin;