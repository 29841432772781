import { Tabs, Table, Image, message } from 'antd';
import constants from '../../constants/constants';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import APIUtils from '../../api/APIUtils';
import WebLinks from '../../api/WebLinks';
import Cookies from 'js-cookie';

const { TabPane } = Tabs;

const QualityFiltersSupplier = ({ filterValue }) => {
	//Variable Declaration
	const buyerId = Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)
	const [suppliers, setSuppliers] = useState([]);

	// Function on load and when filterValue changes
	useEffect(() => {
		// Check the filterValue and trigger the API call if needed
		if (filterValue === "All" || filterValue === "Rules") {
			fetchData();
		}
	}, [filterValue]);

	//Functions
	//Function to get suppliers list
	const fetchData = async () => {
		const supplierList = await APIUtils.apiCall("GET", WebLinks.QualityFilterSupplier + Cookies.get(constants.COOKIE_SELECTED_BUYER_ID) + '/qualityfiltersupplier');
		if (supplierList.isCatch) {
			setSuppliers([])
		} else {
			if (supplierList.resultObj?.statusCode === "2000") {
				setSuppliers(supplierList.resultObj.data)
			}
		}
	}

	const updateQualityFilter = async (value) => {
		const newDatasource = suppliers.map((item) =>
			item.supplierId === value.supplierId ? { ...item, buyerQualityFilter: !item.buyerQualityFilter } : item
		);

		setSuppliers(newDatasource);

		const url = WebLinks.UpdateQualityFilterSupplier + buyerId + "&supplierId=" + value.supplierId;
		const response = await APIUtils.apiCall('POST', url);
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
		    message.success(response.resultObj.message);
		}
	}

	const deleteQualityFilter = async (value) => {
        const newDatasource = suppliers.map((item) =>
            item.supplierId === value.supplierId ? { ...item, buyerQualityFilter: !item.buyerQualityFilter } : item
        );
        setSuppliers(newDatasource);
        const url = WebLinks.DeleteQualityFilter + buyerId + "&supplierId=" + value.supplierId;
        const response = await APIUtils.apiCall('DELETE', url);
        if (!response.isCatch && response.resultObj.statusCode === "2000") {
			fetchData();
            message.success(response.resultObj.message);
        }
    }

	//Render
	return (
		<div>
			{filterValue !== "None" &&
				<Table
					pagination={false}
					dataSource={suppliers}
					scroll={{ x: '1000' }}
				>
					<Table.Column
						key="supplierId" title="Supplier" dataIndex="logoURL"
						render={(_, data) => (
							<div>
								{data.logoURL && data.logoURL !== "" ? <Image src={data.logoURL} preview={false} width={100} /> :
									data.supplierName}
							</div>
						)}
					/>
					<Table.Column key="buyerQualityFilter" title="Filter" dataIndex="buyerQualityFilter"
						render={(_, record) => (
							record.buyerQualityFilter ?
								(<CheckOutlined className='text-xl text-lime-500' onClick={() => deleteQualityFilter(record)} />) :
								(<CloseOutlined className='text-xl text-red-500' onClick={() => updateQualityFilter(record)} />)
						)}
					/>
				</Table>}
		</div>
	);
};

export default QualityFiltersSupplier;