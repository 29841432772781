import React from 'react';
import { Typography } from 'antd';
const { Title } = Typography;

const CookiePolicy = () => {
  return (
    <div className='container mx-auto my-5'>
      <Title level={2}>Cookie Notice</Title>
      <p className="mb-3">Hire Intelligence uses cookies and has prepared this notice to inform you of the types of cookies we use, why we use them, how we use them and action that you can take if you wish to avoid the placement of cookies in connection with your usage of our website.</p>

      <Title level={3}>What are cookies?</Title>
      <p className="mb-3">Cookies are small files of letters and numbers that a provider of online services can place on a user’s device (such as a computer, smartphone or tablet) when the user visits the website. Cookies are stored in a file on the user’s device and in some circumstances may capture personal data which can enable identification of the user. Some cookies, known as “session” cookies are only stored temporarily whereas other cookies, known as “persistent” cookies, are placed on the user’s hard drive.</p>

      <p className="mb-3">Cookies do not run programs or install software but are uniquely assigned to your device and can be accessed by the domain that issued them.</p>

      <Title level={3}>What kinds of cookies do you use?</Title>
      <p className="mb-3">Hire Intelligence uses the following types of cookies:</p>

      <ul className="list-disc mb-3 list-inside">
        <li>Strictly necessary cookies which are required for the effective operation of the website</li>
        <li>Analytical or performance cookies which allow us to recognise and count the number of visitors to the site, and to monitor how users navigate the site</li>
        <li>Functionality cookies which recognise users when they return to the website</li>
        <li>Targeting cookies which record a user’s visit to the website, pages visited and any links that are accessed</li>
        <li>Social media cookies which allow the user to share their activity on social media websites</li>
      </ul>

      <Title level={3}>How do you use cookies?</Title>
      <p className="mb-3">We use cookies for the following reasons:</p>

      <ul className="list-disc mb-3 list-inside">
        <li>To help personalise content and your user experience, including by storing user preferences, tailoring search results and the display of advertisements, and tracking usage trends.</li>
        <li>To help moderate the content on forums that we administer, for example by enabling us to identify users that have been prohibited as a consequence of previously posting harmful or offensive content.</li>
        <li>For technical assistance reasons, such as to enable load balancing.</li>
        <li>To enable users to navigate the site efficiently by making use of redirection pages.</li>
        <li>To enable us to retrieve specific information that you have previously provided, such as your username and password.</li>
        <li>If you are a partner of ours, to recall your status and display the correct pricing information.</li>
        <li>To assess user activity, such as aggregate patterns, to help us better understand how users navigate our website and to help us improve the functionality of the site and overall user experience.</li>
        <li>To associate user activity with third party websites that have referred users to our website.</li>
      </ul>

      <Title level={3}>On what basis do you use cookies?</Title>
      <p className="mb-3">With the exception of cookies that are strictly necessary for the effective operation of the website, we will only place cookies where we have your consent to do so. [You are prompted to consent to the use of cookies when first accessing our website and you can select which cookies to allow and which cookies to disable]. You can [also] disable cookies through your web browser, and we recommend that if you wish to do this that you contact the relevant provider of the browser that you are using.</p>
      <p className="mb-3">If you do not consent to the use of cookies then you can still use the website, however some of the features and functionality may be negatively affected, meaning that the quality of your user experience is reduced.</p>




      <Title level={3}>Third Party Advertising Cookies</Title>

      <p className="mb-3">We use advertisements to support the site financial.
        The advertisements you see on this website are provided through Google AdSense
        and in turn by other organisations (<a className="!text-cyan-500 hover:!text-cyan-300" href="https://support.google.com/adsense/answer/9012903" target="_blank" rel="nofollow" >ad technology providers</a>) that use the AdSense
        platform. Some of these organisations use their own anonymous cookies to track how
        many people have seen a particular ad, or to track how many people have seen it
        more than once. The companies that generate these cookies have their own privacy policies,
        and we have no access to read or write these cookies. These organizations may use
        their cookies to anonymously target advertising to you on other websites,
        based on your visit to StreetCheck. You can view a list of the ad technology
        providers with which data is shared by <a className="!text-cyan-500 hover:!text-cyan-300" href="https://support.google.com/adsense/answer/9012903" target="_blank" rel="nofollow">clicking here</a>.
        Google AdSense also uses
        cookies to allow for frequency capping, aggregated ad reporting, and to combat fraud and abuse.</p>

      <p className="mb-3">Google's advertising policy can be found by <a className="!text-cyan-500 hover:!text-cyan-300" href="https://www.google.com/policies/technologies/ads/" target="_blank" rel="nofollow">clicking here</a>, and from that link you
        can also change your cookie preferences and advertising preferences for Google. Google's cookie policy can be found <a className="!text-cyan-500 hover:!text-cyan-300" href="https://www.google.com/policies/technologies/types/" target="_blank" rel="nofollow">here</a>.</p>
    </div>
  );
};

export default CookiePolicy;
