import { DatePicker, Space, Select, Table, message, Spin, Form, Image, Tooltip, Button } from 'antd';
import dayjs from 'dayjs';
import { FileExcelOutlined } from '@ant-design/icons';
import constants from '../../constants/constants';
import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import WebLinks from '../../api/WebLinks';
import Cookies from 'js-cookie';
import APIUtils from '../../api/APIUtils';
import { downloadFiles } from '../../globalFunctions/GlobalFunctions';
const { RangePicker } = DatePicker;

const QualityReport = (props) => {

	//Variable declaration
	const columns = [
		{
			title: 'Month/Supplier',
			dataIndex: 'supplierName',
			key: 'supplierName',
			sorter: (a, b) => a.supplierName.length - b.supplierName.length,
			render: (_, record) => {
				return (
					<>
						{record.logoURL && record.logoURL !== "" ?
							<Image src={record.logoURL} preview={false} width={100} /> :
							<span>{record.supplierName}</span>
						}
					</>
				)

			}
		},
		{
			title: 'Applies',
			dataIndex: 'totalApps',
			key: 'totalApps',
			align: 'center',
			sorter: (a, b) => a.totalApps - b.totalApps,
		},
		{
			title: 'Internationals',
			dataIndex: 'internationals',
			key: 'internationals',
			align: 'center',
			sorter: (a, b) => a.internationals - b.internationals,
      render: (_, record) => {
				return (
					<Space size={3}>
						<span>{record.internationals}</span><span>({record.internationalPer}%)</span>
					</Space>
				)

			}
		},
		{
			title: 'Minimum Data  Missing',
			dataIndex: 'minimumDataMissing',
			key: 'minimumDataMissing',
			align: 'center',
			sorter: (a, b) => a.minimumDataMissing - b.minimumDataMissing,
      render: (_, record) => {
				return (
					<Space size={3}>
						<span>{record.minimumDataMissing}</span><span>({record.minimumDataMissingPer}%)</span>
					</Space>
				)
			}
		},
		{
			title: 'Screening Questions Incomplete',
			dataIndex: 'screeningQuestionsIncomplete',
			key: 'screeningQuestionsIncomplete',
			align: 'center',
			sorter: (a, b) => a.screeningQuestionsIncomplete - b.screeningQuestionsIncomplete,
      render: (_, record) => {
				return (
					<Space size={3}>
						<span>{record.screeningQuestionsIncomplete}</span><span>({record.screeningQuestionsIncompletePer}%)</span>
					</Space>
				)
			}
		},
		{
			title: 'Failed Killer Questions',
			dataIndex: 'failedKillerQuestions',
			key: 'failedKillerQuestions',
			align: 'center',
			sorter: (a, b) => a.failedKillerQuestions - b.failedKillerQuestions,
      render: (_, record) => {
				return (
					<Space size={3}>
						<span>{record.failedKillerQuestions}</span><span>({record.failedKillerQuestionPer}%)</span>
					</Space>
				)
			}
		},
		{
			title: 'Quality Filter Failed ',
			dataIndex: 'qualityFilterFailed',
			key: 'qualityFilterFailed',
			align: 'center',
			sorter: (a, b) => a.qualityFilterFailed - b.qualityFilterFailed,
      render: (_, record) => {
				return (
					<Space size={3}>
						<span>{record.qualityFilterFailed}</span><span>({record.qualityFilterFailedPer}%)</span>
					</Space>
				)
			}
		},
		{
			title: 'Too Many Applies',
			dataIndex: 'tooManyApplies',
			key: 'tooManyApplies',
			align: 'center',
			sorter: (a, b) => a.hired - b.hired,
      render: (_, record) => {
				return (
					<Space size={3}>
						<span>{record.tooManyApplies}</span><span>({record.tooManyAppliePer}%)</span>
					</Space>
				)
			}
		},
		{
			title: 'Already Exists',
			dataIndex: 'alreadyExists',
			key: 'alreadyExists',
			align: 'center',
			sorter: (a, b) => a.alreadyExists - b.alreadyExists,
      render: (_, record) => {
				return (
					<Space size={3}>
						<span>{record.alreadyExists}</span><span>({record.alreadyExistPer}%)</span>
					</Space>
				)
			}
		}
	];
	const [qualityData, setQualityData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [rangePickerValue, setRangePickerValue] = useState([]);
	const [loader, setLoader] = useState(false);
	const [totalQualityCount, setTotalQualityCount] = useState(0);
	const [qualityReportTotal, setQualityReportTotal] = useState([]);
	const [form] = Form.useForm();

	useEffect(() => {
		const date1 = dayjs().startOf('month')
		const date2 = dayjs().endOf('month')
		form.setFieldsValue({
			rangePicker: [
				date1,
				date2,
			],
		});
		setRangePickerValue([
			date1.format(constants.MonthFormat),
			date2.format(constants.MonthFormat),
		]);
		GetQualityReportData(1, 100,
			date1.format(constants.MonthFormat),
			date2.format(constants.MonthFormat));
	}, []);


	//Functions
	//Function runs when range picker value is changed
	const RangePickerChanged = debounce((_, dateStrings) => {
		props.changeDate(dateStrings);
		setRangePickerValue([
			dayjs(dateStrings[0], constants.MonthFormat),
			dayjs(dateStrings[1], constants.MonthFormat),
		]);
		GetQualityReportData(currentPage, pageSize, dateStrings[0], dateStrings[1]);
	}, 500);

	//Function to Month report data
	const GetQualityReportData = async (currentPage, pazeSize, startDate, endDate) => {
		setLoader(true);
		const response = await APIUtils.apiCall('GET', WebLinks.GetApplicationQualityReport + Cookies.get(constants.COOKIE_SELECTED_BUYER_ID) + "&fromDate=" + startDate + "&toDate=" + endDate + "&limit=" + pazeSize + "&pageNo=" + currentPage);
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			setQualityReportTotal(response.resultObj.data.pop());
			setQualityData(response.resultObj.data);
			setTotalQualityCount(response.resultObj.getCount);
			setLoader(false);
		} else {
			setQualityData([]);
      setTotalQualityCount(0);
			setLoader(false);
			message.error(response.resultObj.message);
		}
	}

	//Function on change of application table
	const onchangeApplicationTable = (pagination, filters, sorter) => {
		if (pagination) {
			if (pageSize !== pagination.pageSize) {
				setCurrentPage(1);
			} else {
				setCurrentPage(pagination?.current);
			}
			setPageSize(pagination?.pageSize);
			GetQualityReportData(pagination?.current, pagination?.pageSize, rangePickerValue[0], rangePickerValue[1])
		}
	};

	//Function to download as excel
	const downloadFile = () => {
		setLoader(true)
		downloadFiles(WebLinks.DownloadApplicationQualityReport+Cookies.get(constants.COOKIE_SELECTED_BUYER_ID) + `&fromDate=${dayjs(rangePickerValue[0]).format(constants.MonthFormat)}&toDate=${dayjs(rangePickerValue[1]).format(constants.MonthFormat)}`,"ApplicationQualityReport.xlsx");
		setTimeout(() => {
				setLoader(false)
		}, 3000);
	};

	//Function to display the month total in the table
	const monthReportSummary = () => {
		return (
			<>
				<Table.Summary.Row className='bg-teal-50'>
					<Table.Summary.Cell index={0} align='center'>
						<strong>Totals</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={1} className='text-center'>
						<strong>{qualityReportTotal?.totalApps}</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={2} className='text-center'>
            <strong><Space size={3}>{qualityReportTotal?.internationals}<span>({qualityReportTotal?.internationalPer}%)</span></Space></strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={3} className='text-center'>
            <strong><Space size={3}>{qualityReportTotal?.minimumDataMissing}<span>({qualityReportTotal?.minimumDataMissingPer}%)</span></Space></strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={4} className='text-center'>
            <strong><Space size={3}>{qualityReportTotal?.screeningQuestionsIncomplete}<span>({qualityReportTotal?.screeningQuestionsIncompletePer}%)</span></Space></strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={5} className='text-center'>
            <strong><Space size={3}>{qualityReportTotal?.failedKillerQuestions}<span>({qualityReportTotal?.failedKillerQuestionPer}%)</span></Space></strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={6} className='text-center'>
            <strong><Space size={3}>{qualityReportTotal?.qualityFilterFailed}<span>({qualityReportTotal?.qualityFilterFailedPer}%)</span></Space></strong>					
          </Table.Summary.Cell>
					<Table.Summary.Cell index={7} className='text-center'>
            <strong><Space size={3}>{qualityReportTotal?.tooManyApplies}<span>({qualityReportTotal?.tooManyAppliePer}%)</span></Space></strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={8} className='text-center'>
            <strong><Space size={3}>{qualityReportTotal?.alreadyExists}<span>({qualityReportTotal?.alreadyExistPer}%)</span></Space></strong>
					</Table.Summary.Cell>
				</Table.Summary.Row>
			</>
		)
	};

	return (
		<>
			<Spin spinning={loader}>
				<div>
					<div className='flex flex-wrap gap-3 justify-between items-center mb-3'>
						<Form form={form}>
							<Form.Item name="rangePicker" className='mb-0'>
								<RangePicker picker="month"
									placeholder={["From", "To"]}
									onChange={RangePickerChanged}
									//value={rangePickerValue}

									format={constants.MonthFormat} />
							</Form.Item>
						</Form>
						
          <Tooltip placement='right' title="Download as Excel">
              <Button
                  className='bg-green-800 text-white hover:!text-white hover:!border-green-500'
                  icon={<FileExcelOutlined />}
                  onClick={()=>{downloadFile()}}
              >
                Download as Excel
              </Button>
          </Tooltip>
					</div>
					<div className='overflow-x-auto'>
						<Table
							columns={columns}
							dataSource={qualityData}
							scroll={{
								x: 1000,
							}}
							// onChange ={onchangeApplicationTable}
							sortDirections={['descend', 'ascend', 'descend']}
							// pagination={{
							// 	total: totalQualityCount,
							// 	// showSizeChanger: true,
							// 	// pageSizeOptions: ["25", "50", "100"],
							// 	defaultPageSize: 100,
							// 	// current: currentPage,
							// }}
							pagination={false}
							summary={() => monthReportSummary()}
						/>
					</div>
				</div>
			</Spin>
		</>
	);
};

export default QualityReport;