import React, { useState } from 'react'
import { Divider, Image, Row, Col, Modal } from 'antd';
import JobSwipe from '../assets/jobswipe-logo.png';
import TiktokSnip from '../assets/Tiktok-snip.png';
import TiktokVidSnipImg from '../assets/Tiktok-video-snip-3.png';
import shape1 from '../assets/tiktok-bgs/shape-1.png';
import shape2 from '../assets/tiktok-bgs/shape-2.png';
import shape5 from '../assets/tiktok-bgs/shape-5-light.png';
import shape6 from '../assets/tiktok-bgs/shape-6-light.png';
import TiktokVidSnip from '../assets/tik-tok-ellen-starbucks-mob.mp4';
import { CloseCircleFilled } from '@ant-design/icons';

const NumberWithCommas = ({ number }) => {
  // Function to add commas after every third digit from the right
  const addCommas = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return <span>{addCommas(number)}</span>;
};

const handleNavigate = () => {
  window.open('www.jobswipe.net', '_blank');
};

const TikTok = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div className='relative'>
      <div>
        <img src={shape1} className='absolute top-[-13px] right-[-20px] w-1/4' alt='vector' />
        <img src={shape2} className='absolute top-[7%] left-[-20px]' alt='vector' />
        <img src={shape5} className='absolute bottom-[-20px] 2xl:bottom-[-90px] left-[-20px] z-10' alt='vector' />
        <img src={shape6} className='absolute bottom-[-20px] right-[-20px] z-20' alt='vector' />
      </div>
      <div className='container mx-auto'>
        <div className='z-30 relative'>
          <div className='text-center'>
            <Image src={JobSwipe} onClick={handleNavigate} title="www.jobswipe.net" className='!w-3/5 2xl:!w-9/12 mx-auto cursor-pointer' preview={false} />
          </div>
          <div className='uppercase text-center font-bold text-xl 2xl:text-2xl mb-5'>
            <span className='text-[#2bad75]'>tik tok employer branding + </span><br />application generator
          </div>
        </div>
        <Row gutter={[8, 8]}>
          <Col xs={24} lg={10} className='z-0'>
            <Image src={TiktokSnip} className='!w-4/5 mx-auto' preview={false} />
          </Col>
          <Col xs={24} lg={14} className='font-semibold text-lg 2xl:text-2xl z-30 leading-7'>
            <p className='mb-5'>
              How many companies are using Tik Tok effectively to hire? Pretty much none!
            </p>
            <p className='mb-5'>
              Let us help you be at the forefront of social media hiring with innovative custom Tik Tok campaigns.
            </p>
            <p className='mb-5'>
              Position your employer brand as a “dream job” targeting millions of Gen-Z and Millenials and allow them to apply to your open positions on the JobSwipe app.
            </p>
            <h3>How it Works</h3>
            <ul className='font-normal list-disc text-base 2xl:text-2xl leading-7'>
              <li>
                We only work with quality brands with a national distribution or based in several big cities.
              </li>
              <li>
                We only work with a maximum of 5 brands per month.
              </li>
              <li>
                We use our influencer network to find a suitable influencer to create unique Tik Toks where they position your brand as a “dream job” eg I just got my Dream Job at Starbucks…I found it on the JobSwipe app… let me show you how…
              </li>
              <li>
                We will deliver 500k - 2 million impressions with a goal of 100k - 500k views viewing the 2 seconds.
              </li>
              <li>
                Brands need to list their jobs in the app and will receive applications at reduced CPA rates (30% discount).
              </li>
            </ul>
          </Col>
          <Col xs={24} lg={13} className='font-semibold text-lg 2xl:text-2xl z-30'>
            <p className='mb-4'>
              Here is an example of a recent campaign with Starbucks
            </p>
            <h4 className='mb-2 text-center lg:text-left'>Stats</h4>
            <table className='font-normal text-base mb-4 w-full lg:w-3/4 border-collapse border border-slate-400'>
              <colgroup>
                <col span="1" className='w-2/4' />
                <col span="1" />
              </colgroup>
              <tbody>
                <tr>
                  <td className='border border-slate-300 p-3'>Video Views:</td>
                  <td className='border border-slate-300 p-3 text-center'><NumberWithCommas number={2064543} /></td>
                </tr>
                <tr>
                  <td className='border border-slate-300 p-3'>View over 2 secs:</td>
                  <td className='border border-slate-300 p-3 text-center'><NumberWithCommas number={507007} /></td>
                </tr>
                <tr>
                  <td className='border border-slate-300 p-3'>View over 6 secs:</td>
                  <td className='border border-slate-300 p-3 text-center'><NumberWithCommas number={144319} /></td>
                </tr>
                <tr>
                  <td className='border border-slate-300 p-3'>Avg Watch / Person:</td>
                  <td className='border border-slate-300 p-3 text-center'>4.82 Seconds</td>
                </tr>
                <tr>
                  <td className='border border-slate-300 p-3'>Clicks:</td>
                  <td className='border border-slate-300 p-3 text-center'><NumberWithCommas number={18342} /></td>
                </tr>
              </tbody>
            </table>

            <h4 className='mb-2 text-center lg:text-left'>View Demographics</h4>
            <table className='font-normal text-base mb-4 w-full lg:w-3/4 border-collapse border border-slate-400'>
              <colgroup>
                <col span="1" className='w-2/4' />
                <col span="1" />
              </colgroup>
              <tr>
                <td className='border border-slate-300 p-3'>18-24</td>
                <td className='border border-slate-300 p-3 text-center'><NumberWithCommas number={1674296} /></td>
              </tr>
              <tr>
                <td className='border border-slate-300 p-3'>25-34</td>
                <td className='border border-slate-300 p-3 text-center'><NumberWithCommas number={275234} /></td>
              </tr>
              <tr>
                <td className='border border-slate-300 p-3'>35 - 44</td>
                <td className='border border-slate-300 p-3 text-center'><NumberWithCommas number={56725} /></td>
              </tr>
              <tr>
                <td className='border border-slate-300 p-3'>45-54</td>
                <td className='border border-slate-300 p-3 text-center'><NumberWithCommas number={24528} /></td>
              </tr>
              <tr>
                <td className='border border-slate-300 p-3'>55+</td>
                <td className='border border-slate-300 p-3 text-center'><NumberWithCommas number={33705} /></td>
              </tr>
            </table>
          </Col>
          <Col xs={24} lg={11} className='font-semibold text-lg 2xl:text-2xl z-30'>
            <Image src={TiktokVidSnipImg} title="Click to preview" onClick={showModal} className='mx-auto cursor-pointer mb-5' preview={false} />
            <div>
              <p className='mb-3'>Pricing:</p>
              <h4 className='mb-2'>1 Month inc. creatives</h4>
              <table className='font-normal text-base mb-4 w-3/4 border-collapse border border-slate-400'>
                <tr>
                  <td className='border border-slate-300 p-3'>£4,200</td>
                  <td className='border border-slate-300 p-3 text-center'>(500k views)</td>
                </tr>
              </table>
              <h4 className='mb-2'>2 Months inc. creatives</h4>
              <table className='font-normal text-base mb-4 w-3/4 border-collapse border border-slate-400'>
                <tr>
                  <td className='border border-slate-300 p-3'>£10,450</td>
                  <td className='border border-slate-300 p-3 text-center'>(2 million views)</td>
                </tr>
              </table>
              <p className='mb-3'>
                To discuss this opportunity further please contact your account manager.
              </p>
            </div>
          </Col>
        </Row>
      </div>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        centered
        closeIcon={<CloseCircleFilled className='text-red-500 text-2xl drop-shadow-lg' />}
        className='!w-1/4 vid-preview'
      >
        <video loop autoPlay>
          <source src={TiktokVidSnip} type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>
      </Modal>
    </div>
  )
}

export default TikTok