import React, { useState, useEffect } from "react";
import { Table, Button, Alert, Divider, message } from "antd";
import WebLinks from "../../api/WebLinks";
import APIUtils from "../../api/APIUtils";
import Cookies from "js-cookie";
import constants from "../../constants/constants";

const SetBulkHire = (props) => {
  const buyerId = Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)
  const [dataSource, setDataSource] = useState([]);
  const [total, setTotal] = useState(props?.location?.state?.data.getCount || 0);
  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName"
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName"
    },
    {
      title: "Contact",
      dataIndex: "telephone",
      key: "telephone",
      render: (_, record) => (
        <>
          <div><a href={"mailto:" + record.email} className="text-cyan-500 underline">{record.email}</a></div>
          <div><a href={"tel:" + record.telephone} className="text-cyan-500 underline">{record.telephone}</a></div>
        </>
      ),
    },
    {
      title: "Ref",
      key: "jobRefNumber",
      dataIndex: "jobRefNumber"
    },
    {
      title: "Job Title",
      key: "jobTitle",
      dataIndex: "jobTitle"
    },
    {
      title: "Location",
      key: "jobLocation",
      dataIndex: "jobLocation"
    },
    {
      title: "Status",
      key: "status",
      render: (_, record) => (
        <Button className="bg-[#00B2A8] hover:!bg-[#00B2A8] text-white hover:!text-white !border-[#00B2A8]" onClick={() => HireCandidate(record.applicationId)}>
          Hire
        </Button>
      )
    }
  ];

  useEffect(() => {
    console.log("props", props)
    if (props?.location?.state?.data?.data.length) {
      setDataSource(props?.location?.state?.data.data)
      setTotal(props?.location?.state?.data.getCount);
    }
  }, [props.location?.state?.data]);

  const handleDelete = (id) => {
    //const newData = dataSource.filter((item) => item.key !== key);
    const newArray = dataSource.filter(item => item.applicationId !== id);
    setDataSource(newArray);
  };

  const HireCandidate = async (id) => {
    const url = WebLinks.HireCandidate + id;
    const response = await APIUtils.apiCall('PUT', url);
    if (!response.isCatch && response.resultObj.statusCode === "2000") {
      message.success(response.resultObj.message)
      handleDelete(id)
      fetchApplicationList(props?.location?.state?.emails)
    }
  }

  const fetchApplicationList = async (values) => {
    console.log("payload", values)
    const url = WebLinks.SubmitBulkHire + buyerId;
    const response = await APIUtils.apiCall('POST', url, JSON.stringify(values));
    if (!response.isCatch && response.resultObj.statusCode === "2000") {
      setDataSource(response.resultObj.data)
      setTotal(response.resultObj.getCount)
    }
  }

  return (
    <div>
      <>
        <div className='uppercase font-bold text-xl'>Set candidates to be hired in bulk</div>
        <Divider className='mt-2 mb-3' />
        <div>
          <Alert message={"We have detected " + total + " candidates with single applications and set them to Hired."} type="success" showIcon className='mb-2' />
          <p className='mb-3'>These candidates below have applied for more than one job, so please select which job they were hired for:</p>
        </div>
        <div>
          <Table columns={columns} dataSource={dataSource} pagination={false} />
        </div>
      </>
    </div>
  );
};

export default SetBulkHire;