import {Typography, Divider, Tabs, Spin,} from 'antd';
import { useEffect,useState } from 'react';
import WebLinks from '../api/WebLinks';
import TikTok from './TikTok';
import APIUtils from '../api/APIUtils';
import Cookies from 'js-cookie';
import constants from '../constants/constants';
import TikTokPromoDisplay from './TikTokPromoDisplay';

const { Paragraph } = Typography;

const TikTokPromo = () => {
	//variables
	const [loader,setLoader]=useState(false);
	const [isPromoPresent,setIsPromoPresent]=useState(false);
	const TikTokItems = [
		{
			key: '1',
			label: 'Tik Toks',
			children: <><TikTok/></>,
		},
		{
			key: '2',
			label: 'Tik Tok Promo',
			children: <><TikTokPromoDisplay/></>,
		},
	];

	useEffect(()=>{
		checkTikTokForBuyer();
	},[]);

	//Funtion to check if tik toks are present for buyer
	const checkTikTokForBuyer=async()=>{
		setLoader(true);
		const response = await APIUtils.apiCall('GET', WebLinks.GetTiktokPromoRecords + Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)+"&limit=3&pageNo=1");
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			setLoader(false);
			setIsPromoPresent(response?.resultObj?.getCount>0?true:false)
		} else {
			setIsPromoPresent(false);
			setLoader(false);
		}
	};

	return (
		<div>
			<Spin spinning={loader}>
			 <div className='flex flex-wrap justify-between items-center'>
          <div className='uppercase font-bold text-xl'>TikTok Promo</div>
        </div>
        <Divider className='mt-3 mb-0' />
			<div>
				{!isPromoPresent?
				<TikTok/>:
				<Tabs defaultActiveKey="1" items={TikTokItems} />
			}
			</div>
			</Spin>
		</div>
	);
};

export default TikTokPromo;
