import { Tabs, Divider } from 'antd';
import { UnorderedListOutlined, CalendarOutlined } from '@ant-design/icons';
import constants from '../../constants/constants';
// import ClickMonthReport from './ClickMonthReport';
import { useState } from 'react';
import MultiClickMonthReport from './MultiClickMonthReport';
import MultiClickDailyReport from './MultiClickDayReport';
// import ClickDailyReport from './ClickDayReport';
const { TabPane } = Tabs;

const MultiClickReport = () => {
  //Variable declaration
	const [dateValues,setDateValues]=useState(null);

	//Functions
	//Functon to set changed date value
	const changeDate=(selectedDate)=>{
		setDateValues(selectedDate);
	}
		
	return (
		<div>
      <div className='uppercase font-bold text-xl'>Multi Click Report</div>
			<Divider className='my-3'/>
			<Tabs defaultActiveKey="2" >
      <TabPane
        tab={ <span className='uppercase'><CalendarOutlined className='text-xl' />{constants.AppReportTab2}</span> }
        key={2}>
        <MultiClickMonthReport changeDate={changeDate} dateSelectedValue={dateValues}/>
      </TabPane>
			<TabPane
        tab={ <span className='uppercase'><UnorderedListOutlined className='text-xl' />{constants.AppReportTab1}</span> }
        key={1}
      >
        <MultiClickDailyReport changeDate={changeDate} dateSelectedValue={dateValues}/>
      </TabPane>
			</Tabs>
		</div>
	);
};

export default MultiClickReport;