import { Card, Col, Row, Space, Button, Avatar, Typography, Image, Pagination, Select, Divider, message, Empty, Spin, } from 'antd';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import constants from '../constants/constants';
import WebLinks from '../api/WebLinks';
import APIUtils from '../api/APIUtils';
import dayjs from 'dayjs';
import { SwapRightOutlined, EyeOutlined } from '@ant-design/icons';
import Chart from "react-google-charts";

const TikTokPromoDisplay = () => {
	const [Loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [total, setTotal] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(constants.defaultSocialPagesize);

	const getJobAD = async (TablePageSize = pageSize, currentPagenum = currentPage) => {
		setLoading(true);
		const response = await APIUtils.apiCall('GET', WebLinks.GetTiktokPromoRecords + Cookies.get(constants.COOKIE_SELECTED_BUYER_ID) + "&limit=" + TablePageSize + "&pageNo=" + currentPage);
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			setData(response.resultObj.data);
			setTotal(response.resultObj.getCount);
			setLoading(false);
		} else {
			setData([]);
			setTotal(0);
			setLoading(false);
		}
	};

	const renderJobAdCards = (item, index) => {
		const DemographicData = [
			["Age", "Views", { role: 'annotation' }, { role: "style" }],
			["18 - 24", item.viewDemog18to24, item.viewDemog18to24, "#d9e021"],
			["25 - 34", item.viewDemog25to34, item.viewDemog25to34, "#27ac76"],
			["35 - 44", item.viewDemog35to44, item.viewDemog35to44, "#74b8ef"],
			["45 - 54", item.viewDemog45to54, item.viewDemog45to54, "#e89225"],
			["55 +", item.viewDemog55plus, item.viewDemog55plus, "#8b5cf6"],
		];

		const options = {
			bars: "horizontal",
			legend: { position: "none" },
			hAxis: {
				titleTextStyle: {
					color: '#333',
					bold: true
				},
				title: "Video Views",
				minValue: 0,
			},
			vAxis: {
				titleTextStyle: {
					color: '#333',
					bold: true
				},
				title: "Age",
			},
			// chartArea: { width: "85%" },
		};
		return (
			<Card className='w-full' bodyStyle={{ padding: 10, display: 'grid', rowGap: '0.2rem' }}>
				<div>
					<div className='mb-2'>
						<label className='font-bold'>Campaign: </label>
						<span className='font-semibold'>{item.campaignName}</span>
					</div>
					<div className='flex items-center gap-3'>
						<div className='text-center'>
							<div><label className='font-bold text-slate-600 text-xs'>Start Date</label></div>
							<span className='font-semibold text-sm'>{dayjs(item.startDate).format(constants.DateFormat2)}</span>
						</div>
						<SwapRightOutlined />
						<div className='text-center'>
							<div><label className='font-bold text-slate-600 text-xs'>End Date</label></div>
							<span className='font-semibold text-sm'>{dayjs(item.endDate).format(constants.DateFormat2)}</span>
						</div>
					</div>
				</div>
				<Divider className='!my-2' />
				{/* Performance */}
				<div>
					<div className='mb-2'>
						<label className='font-bold text-sm'>Performance</label>
					</div>
					<Space direction='vertical' size='small'>
						<div>
							<label className='font-semibold'><Space><EyeOutlined /> Video Views:</Space> </label>
							<span className='font-bold'>{item.videoViews}</span>
						</div>
						<div className='flex items-center gap-3'>
							<div className='text-center'>
								<div><label className='font-bold text-slate-600 text-xs'>Over 2 Secs</label></div>
								<span className='font-semibold text-sm'>{item.videoViewsOver2Secs}</span>
							</div>
							<Divider type="vertical" />
							<div className='text-center'>
								<div><label className='font-bold text-slate-600 text-xs'>Over 6 Secs</label></div>
								<span className='font-semibold text-sm'>{item.videoViewsOver6Secs}</span>
							</div>
						</div>
						<div>
							<label className='font-semibold'>Avg Watch Per Person: </label>
							<span className='font-bold'>{item.avgWatchPerPerson}</span>
						</div>
						<div>
							<label className='font-semibold'>Clicks: </label>
							<span className='font-bold'>{item.clicks}</span>
						</div>
					</Space>
				</div>
				<Divider className='!my-2' />
				{/* Demographics */}
				<div>
					<div>
						<label className='font-bold text-sm relative z-20'>Demographics</label>
						<div className='relative z-10 top-[-20px]'>
							<Chart
								chartType="BarChart"
								width="100%"
								loader={<div>Loading Chart</div>}
								data={DemographicData}
								options={options}
							/>
						</div>
					</div>
					<div>
						<Space size={8}>
							<Avatar
								src={<img src={null} alt="avatar" />}
								size="large" className='border border-gray-300'
							/>
							<Space direction='vertical' size={0}>
								<div className='font-bold text-sm'>{item.buyerName}</div>
								<div className='text-xs'>Sponsored</div>
							</Space>
						</Space>
					</div>
					<div className='w-full mt-2 flex justify-center'>
						<video poster="placeholder.png" className='border-2 border-indigo-600' controls disablePictureInPicture controlslist="nofullscreen nodownload noremoteplayback noplaybackrate foobar">
							<source src={item.videoURL + "#t=0.1"} type="video/mp4" />
							Your browser does not support HTML5 video.
						</video>
					</div>
				</div>
			</Card>
		)
	}
	const handleSizeChange = (current, newSize) => {
		if (newSize !== pageSize) {
			setPageSize(newSize);
			setCurrentPage(1);
			getJobAD(newSize, 1);
		} else {
			setCurrentPage(current);
			getJobAD(pageSize, current);
		}
	};
	useEffect(() => {
		getJobAD()
	}, []);

	return (
		<div>
			<Spin spinning={Loading}>
				{data?.length > 0 ? (
					<div className='mt-3'>
						<Row gutter={[{ sm: 16, xxl: 8 }, { sm: 16, xxl: 8 }]}>
							{data?.map((item, index) => {
								return (
									<Col md={12} lg={8} xxl={6} key={index}>
										{renderJobAdCards(item, index)}
									</Col>
								);
							})}
						</Row>
						<div className='mt-3 grid place-content-end'>
							<Pagination
								showSizeChanger
								pageSizeOptions={["24", "48", "96", "192"]}
								pageSize={pageSize}
								// onShowSizeChange={handleSizeChange}
								current={currentPage}
								total={total}
								onChange={handleSizeChange} />
						</div>
					</div>
				) : <Empty />}
			</Spin>
		</div>
	);
};

export default TikTokPromoDisplay;
