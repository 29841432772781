import { Tabs, Divider, Select, Space, message } from 'antd';
import constants from '../../constants/constants';
import { IdcardOutlined, ProfileOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import APIUtils from '../../api/APIUtils';
import WebLinks from '../../api/WebLinks';
import Cookies from 'js-cookie';
import QualityFilterOccupation from './QualityFilterOccupation';
import QualityFiltersSupplier from './QualityFiltersSupplier';

const { TabPane } = Tabs;
const { Option } = Select;

const QualityFilters = () => {
	const buyerId = Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)
	const [filterValue, setFilterValue] = useState("None");
	const [isHireIntelligence,setIsHireIntelligence]=useState(true);

	// Function on load
	useEffect(() => {
		fetchDropdownData()
	}, []);

	const fetchDropdownData = async () => {
		const url = WebLinks.QualityFilterType + buyerId;
		const response = await APIUtils.apiCall('GET', url);
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			setFilterValue(response.resultObj.data.qualityFilterType);
			setIsHireIntelligence(response.resultObj.data.hireIntelligenceAdmin);
		}else{
			setIsHireIntelligence(false)
		}
	}

	const changeFilterValue = async (value) => {
		setFilterValue(value)
		const url = WebLinks.UpdateQualityFilterType + buyerId + "&qualityFilterType=" + value;
		const response = await APIUtils.apiCall('POST', url);
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			//message.success(response.resultObj.message)
		}
	}


	//Render
	return (
		<div>
			<div className='flex flex-wrap justify-between items-center gap-1'>
				<div className='uppercase font-bold text-xl'>Quality Filters</div>
				<Space>
					Quality Filter Type:
					<Select placeholder="Quality Filters"
						onChange={(value) => changeFilterValue(value)}
						value={filterValue}
						className={isHireIntelligence?'w-44':'w-44 select-readonly'}
						disabled={!isHireIntelligence}
					>
						<Option key={"None"} value={"None"}>None</Option>
						<Option key={"All"} value={"All"}>All Jobs</Option>
						<Option key={"Rules"} value={"Rules"}>Filter By Rules</Option>

					</Select>
				</Space>
			</div>
			<Divider className='my-3' />
			<Tabs defaultActiveKey="1">
				<TabPane
					tab={<span className='uppercase'><IdcardOutlined className='text-xl' />{constants.DistributionTab1}</span>}
					key={1}
				>
					<QualityFilterOccupation filterValue={filterValue} />
				</TabPane>
				<TabPane
					tab={<span className='uppercase'><ProfileOutlined className='text-xl' />Supplier</span>}
					key={2}
				>
					<QualityFiltersSupplier filterValue={filterValue} />
				</TabPane>
			</Tabs>
		</div>
	);
};

export default QualityFilters;