import { Space, Divider, Select, Button, Input, Table, Modal, Form, Switch, message, Image } from 'antd';
import constants from '../constants/constants';
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import WebLinks from '../api/WebLinks';
import APIUtils from '../api/APIUtils';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { GetCustomPriorityLevelIcons, GetPriorityLevelColors, getPriorityCount } from '../globalFunctions/GlobalFunctions';

const { Search, TextArea } = Input;
const { Option } = Select

const JobsOverride = () => {
  //Variable declaration
  const [YourJobsData, setYourJobsData] = useState([]);
  const [supplierLogoUrl, setSupplierLogoUrl] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [multiPriorityUpdate, setMultiPriorityUpdate] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isNewJob, setIsNewJob] = useState(false);
  const [selectedJob, setSelectedJob] = useState([]);
  const [form] = Form.useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [searchedValue, setSearchedValue] = useState("");
  const [jobsCount, setJobsCount] = useState(0);
  const [countryList, setCountryList] = useState([]);
  const [countryLoader, setCountryLoader] = useState(false);
  const [sortedValue, setSorterValue] = useState({
    columnKey: "",
    order: 'descend'
  });
  const [isLoading, setIsLoading] = useState(false);
  const { supplierId } = useParams();
  const [priorityList,setPriorityList]=useState([]);
  const [priorityColorCode,setPriorityColorCode]=useState([]);
  const [priorityCheck,setPriorityCheck]=useState("f");

  //Function on page load
  useEffect(() => {
    getBuyerPrioritylist();
    yourJobsListFetch(currentPage, pageSize, "", sortedValue.columnKey, sortedValue.order === "ascend" ? false : true);
  }, []);

  //Fetch the jobs upload history for the last 30 days 
  const yourJobsListFetch = async (currentPage, pazeSize, searchValue, sortColumn, isSortDesc, priorityLevel) => {
    setIsLoading(true);
    sortColumn = sortColumn !== undefined ? sortColumn : ""
    let url = WebLinks.GetJobsInFeedOverrideListing + Cookies.get(constants.COOKIE_SELECTED_BUYER_ID) +"&supplierId="+supplierId+ "&limit=" + pazeSize + "&pageNo=" + currentPage + "&search=" + searchValue + "&sortBy=" + sortColumn + "&sortDesc=" + isSortDesc
    if (priorityLevel && priorityLevel !== "-1") {
      url += "&priorityLevel=" + priorityLevel;
    }
    const response = await APIUtils.apiCall('GET', url);
    if (!response.isCatch && response.resultObj) {
      setYourJobsData(response.resultObj.data);
      setJobsCount(response.resultObj.getCount);
      setSupplierLogoUrl(response.resultObj.supplierUrl);
    } else {
      message.error(response.resultObj.message);
    }
    setIsLoading(false)
  };

  //Function to get priority list
  const getBuyerPrioritylist=async()=>{
    let url = WebLinks.GetBuyerPriorityFilters + Cookies.get(constants.COOKIE_SELECTED_BUYER_ID);    
    const response = await APIUtils.apiCall('GET', url);
    if (!response.isCatch && response.resultObj) {
      setPriorityList(response.resultObj.data);
      let temppriorityColorCode=[];
      setPriorityCheck(response?.resultObj?.data?.length>5?"t":"f");
      response?.resultObj?.data.forEach(element => {
        let priorityObj={
          value:element.priorityLevel.toString(),
          colorValue:response?.resultObj?.data?.length>5?GetCustomPriorityLevelIcons(element.priorityLevel):GetPriorityLevelColors(element.priorityLevel),
          customName:element.customName,
          prioritycount:getPriorityCount(element.priorityLevel,response?.resultObj?.priorityCount)
        }
        temppriorityColorCode.push(priorityObj);
        setPriorityColorCode(temppriorityColorCode);
      });
    } else {
      setPriorityList([]);
      setPriorityColorCode([]);
      message.error(response?.resultObj?.message);
    }
  };

  //Function to get the Priority Level to change multiple jobs.
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => onSelectChange(selectedRowKeys, selectedRows),
  };

  //Function to Update priority levels
  const UpdatePriorityLevels = async (priorityLevel, selectedIds, multiple = false) => {
    setIsLoading(true);
    let priorityUpdatePayload = selectedIds;
    const priorityLevelResponse = await APIUtils.apiCall("PUT", WebLinks.PutUpdateSinglePriority + "buyerId=" + Cookies.get(constants.COOKIE_SELECTED_BUYER_ID) + "&PriorityLevelEnd=" + priorityLevel +"&UpdatedByBuyerId="+Cookies.get(constants.COOKIE_LOGGED_BUYER_ID), JSON.stringify(priorityUpdatePayload));
    if (priorityLevelResponse.isCatch) {
      message.error(priorityLevelResponse.resultObj.message);
    } else {
      if (multiple) {
        yourJobsListFetch(currentPage, pageSize, searchedValue, sortedValue.columnKey, sortedValue.order === "ascend" ? false : true);
      }
      message.success(priorityLevelResponse.resultObj.message);
    }
    setIsLoading(false);
  }

  //Function to change the Priority Level of an individual job.
  const singlePriorityChange = async (prioritySelected, id) => {
    let tempJobData = [...YourJobsData];
    let itemIndex = tempJobData.findIndex(item => item.id === id);
    tempJobData[itemIndex].priority = prioritySelected;
    setYourJobsData(tempJobData);
    let tempPriorityList = [];
    tempPriorityList.push(id);
    UpdatePriorityLevels(prioritySelected, tempPriorityList);
  };

  //Function to change the Block status of the Job
  const BlockChange = async (override, id)=>{
    setIsLoading(true);
    let tempJobData = [...YourJobsData];
    let itemIndex = tempJobData.findIndex(item => item.id === id);
    tempJobData[itemIndex].override = override==1?true:false;
    setYourJobsData(tempJobData);
    UpdateBlock(id,override==1?true:false);
  };
  const UpdateBlock = async(id,override)=>{
    let blockResponse=null;
    if(override){
      blockResponse = await APIUtils.apiCall("POST", `${WebLinks.InsertJobsOverrideInclude}${Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)}&supplierId=${supplierId}&jobId=${id}&UpdatedByBuyerId=${Cookies.get(constants.COOKIE_LOGGED_BUYER_ID)}`);   
    }else{
      blockResponse = await APIUtils.apiCall("DELETE", `${WebLinks.DeleteJobsOverrideInclude}${Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)}&supplierId=${supplierId}&jobId=${id}&UpdatedByBuyerId=${Cookies.get(constants.COOKIE_LOGGED_BUYER_ID)}`);
    }
    if (blockResponse.isCatch) {
      message.error(blockResponse.resultObj.message);
    } else {
      message.success(blockResponse.resultObj.message);
    }
    setIsLoading(false);
  };

  //Function to get the priority level for multi-select dropdown
  const allSelectedPriority = (selectedPriority) => {
    setMultiPriorityUpdate(selectedPriority);
  };

  //Function to change the Priority Level for multiple jobs.
  const multiplePriorityChange = () => {
    UpdatePriorityLevels(multiPriorityUpdate, selectedRowKeys, true);
  };

  //Function to save edited job
  const saveJobEditDetails = async () => {
    setIsLoading(true);
    const selectedCountry = form.getFieldValue('country')
    const selectedCountryObject = countryList.find(country => country.countryCode === selectedCountry);
    const selectedCountryName = selectedCountryObject ? selectedCountryObject.country : '';

    let formValues = {
      address: form.getFieldValue('address') || "",
      //companyLogo: form.getFieldValue('companyLogo') || "",
      country: selectedCountryName,
      countryCode: selectedCountry,
      county: form.getFieldValue('county') || "",
      postcode: form.getFieldValue('postcode') || "",
      place: form.getFieldValue('place') || "",
      contract: form.getFieldValue('contract') || false,
      fullTime: form.getFieldValue('fullTime') || false,
      hourlyWork: form.getFieldValue('hourlyWork') || false,
      partTime: form.getFieldValue('partTime') || false,
      applyEmail: Cookies.get(constants.COOKIE_EMAIL_POST)==="true" || Cookies.get(constants.COOKIE_EMAIL_POST)==true?form.getFieldValue('applyEmail')||"":"" 
    }
    let method, url, body;
    if (isNewJob) {
      method = "POST";
      url = WebLinks.AddNewJob;
      //TODO: change the body accordingly
      body = JSON.stringify({ ...form.getFieldsValue(), place: form.getFieldValue('place'), ...formValues, buyerId: Cookies.get(constants.COOKIE_SELECTED_BUYER_ID) });
    } else {
      method = "PUT";
      url = WebLinks.UpdateSelectedJob;
      body = JSON.stringify({ ...form.getFieldsValue(), id: selectedJob.id, ...formValues });
    }
    const response = await APIUtils.apiCall(method, url, body);
    if (!response.isCatch || response.resultObj.statusCode === "2000") {
      handleCancel();
      message.success(response.resultObj.message);
      yourJobsListFetch(currentPage, pageSize, "", sortedValue.columnKey, sortedValue.order === "ascend" ? false : true);
    } else {
      handleCancel();
      message.error(response?.resultObj?.message || constants.ERROR_MESSAGE);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const selectedCountry = form.getFieldValue('country')
    fetchCountryList()
    if (isEditModalOpen && !selectedCountry) {
      fetchBuyerCountry()
    }
  }, [isEditModalOpen]);

  const fetchCountryList = async () => {
    setIsLoading(true)
    const response = await APIUtils.apiCall("GET", WebLinks.GetCountryList);
    if (!response.isCatch) {
      setCountryList(response.resultObj.data)
    } else {
      message.error(response.resultObj.message);
    }
    setIsLoading(false);
  }

  const fetchBuyerCountry = async () => {
    setCountryLoader(true)
    const buyerId = Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)
    const response = await APIUtils.apiCall("GET", WebLinks.GetBuyerCountry + buyerId);
    if (!response.isCatch) {
      form.setFieldsValue({
        country: response.resultObj.data.countryCode
      });
      setCountryLoader(false)
    } else {
      setCountryLoader(false)
      message.error(response.resultObj.message);
    }
  }

  //Function on change of application table
  const onChangeJobTable = (pagination, filters, sorter) => {
    if (pagination) {
      if (pageSize !== pagination.pageSize) {
        setCurrentPage(1);
      } else {
        setCurrentPage(pagination?.current);
      }
      setPageSize(pagination?.pageSize);
      if (sorter) {
        setSorterValue(sorter);
        yourJobsListFetch(pagination?.current, pagination?.pageSize, searchedValue, sorter.order ? sorter.columnKey : "", sorter.order === "ascend" ? false : true);
      } else {
        yourJobsListFetch(pagination?.current, pagination?.pageSize, searchedValue, sortedValue.columnKey, sortedValue.order === "ascend" ? false : true);
      }
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setIsEditModalOpen(false);
  };

  //Function on search
  const searchJob = (searchText) => {
    yourJobsListFetch(1, 100, searchText, sortedValue.columnKey, sortedValue.order === "ascend" ? false : true);
    setCurrentPage(1);
    setPageSize(25);
  }

  const filterPriority = (value) => {
    yourJobsListFetch(1, 100, searchedValue, sortedValue.columnKey, sortedValue.order === "ascend" ? false : true, value);
  }

  // Table columns
  const yourJobsColumns = [
    {
      title: 'Priority',
      key: 'priority',
      dataIndex: 'priority',
      align: 'center',
      width: '5%',
      sorter: (a, b) => a.priority - b.priority,
      render: (_, record) => (
        <Select
          onChange={(e) => singlePriorityChange(e, record.id)}
          value={record.priority}
          className='w-14'
          options={priorityCheck==="t"?constants.PriorityConstantList2:constants.PriorityConstantList1}
          // options={[
          //   {
          //     value: 0,
          //     label: (
          //       <div className="w-3.5 h-3.5 border rounded-full border-black bg-white my-2"></div>
          //     ),
          //   },
          //   {
          //     value: 1,
          //     label: (
          //       <div className="w-3.5 h-3.5 border rounded-full border-black bg-lime-400 my-2"></div>
          //     ),
          //   },
          //   {
          //     value: 2,
          //     label: (
          //       <div className="w-3.5 h-3.5 border rounded-full border-black bg-orange-400 my-2"></div>
          //     ),
          //   },
          //   {
          //     value: 3,
          //     label: (
          //       <div className="w-3.5 h-3.5 border rounded-full border-black bg-red-600 my-2"></div>
          //     ),
          //   },
          //   {
          //     value: 4,
          //     label: (
          //       <div className="w-3.5 h-3.5 border rounded-full border-black bg-purple-600 my-2"></div>
          //     ),
          //   },
          // ]}
        />
      ),
    },
    {
      title: 'Job Title',
      dataIndex: 'title',
      key: 'jobTitle',
      width: '20%',
      sorter: (a, b) => a.title - b.title,
    },
    {
      title: 'Reference',
      dataIndex: 'reference',
      key: 'reference',
      width: '10%',
      sorter: (a, b) => a.reference - b.reference,
    },
    {
      title: 'Location',
      dataIndex: 'place',
      key: 'place',
      width: '35%',
      sorter: (a, b) => a.place - b.place,
    },
    {
      title: 'Occupation',
      dataIndex: 'occupation',
      key: 'occupation',
      width: '15%',
      sorter: (a, b) => a.occupation - b.occupation,
    },
    {
      title: 'Override',
      key: 'override',
      align: 'center',
      width: '15%',
      sorter: (a, b) => a.override - b.override,
      render: (_, record) => (
        <Space>
          <Select
          // className="w-full"
            allowClear
            onChange={(e) => BlockChange(e,record.id)}
            value={record.override?1:null}
            className='w-24'
            options={[
              {
                value: 1,
                label:"Include",
              },
            ]}
        />
        </Space>
      ),
  },
  ];
  // Table end

  return (
    <div>
      <div className="flex justify-end mb-3 text-xs">
       <Space wrap>
          <label>
          <strong>{constants.PriorityLevels}</strong>
          </label>
          {priorityColorCode?.map((item=>{
            return(
              <Space>
              <div className={item.colorValue}></div>
              <span>{item.customName} [{item.prioritycount}]</span>
            </Space>
            )
          }))}
         </Space>
      </div>
      <div className="flex flex-wrap justify-between items-center gap-1">
        <Space>
          <div className="uppercase font-bold text-xl">Jobs Override : </div> <Image src={supplierLogoUrl} preview={false} width={100} />
        </Space>
        {/* <Space wrap>
          <label>
            <strong>{constants.PriorityLevels}</strong>
          </label>
          <Space size={[5, 8]} wrap split={<Divider type="vertical" />}>
            <Space>
              <div className="w-3.5 h-3.5 border rounded-full border-black bg-white"></div>
              Organic
            </Space>
            <Space>
              <div className="w-3.5 h-3.5 border rounded-full border-black bg-lime-400"></div>
              Standard
            </Space>
            <Space>
              <div className="w-3.5 h-3.5 border rounded-full border-black bg-orange-400"></div>
              Extra
            </Space>
            <Space>
              <div className="w-3.5 h-3.5 border rounded-full border-black bg-red-600"></div>
              Max (inc. Social Media)
            </Space>
            <Space>
              <div className="w-3.5 h-3.5 border rounded-full border-black bg-purple-600"></div>
              Critical (inc. Social Media)
            </Space>
          </Space>
        </Space> */}
      </div>
      <Divider className="my-3" />
      <div className="flex flex-wrap justify-between items-center gap-1 mb-3">
        <Space wrap>
          <span>{constants.UpdatePriorityLevels}</span>
          <Space.Compact>
            <Select
              onChange={allSelectedPriority}
              placeholder="Filter by Priority"
              className="w-[65vw] md:w-60"
              // options={[
              //   {
              //     value: "0",
              //     label: (
              //       <Space>
              //         <div className="w-3.5 h-3.5 border rounded-full border-black bg-white"></div>
              //         Organic
              //       </Space>
              //     ),
              //   },
              //   {
              //     value: "1",
              //     label: (
              //       <Space>
              //         <div className="w-3.5 h-3.5 border rounded-full border-black bg-lime-400"></div>
              //         Standard
              //       </Space>
              //     ),
              //   },
              //   {
              //     value: "2",
              //     label: (
              //       <Space>
              //         <div className="w-3.5 h-3.5 border rounded-full border-black bg-orange-400"></div>
              //         Extra
              //       </Space>
              //     ),
              //   },
              //   {
              //     value: "3",
              //     label: (
              //       <Space>
              //         <div className="w-3.5 h-3.5 border rounded-full border-black bg-red-600"></div>
              //         Max (inc. Social Media)
              //       </Space>
              //     ),
              //   },
              //   {
              //     value: "4",
              //     label: (
              //       <Space>
              //         <div className="w-3.5 h-3.5 border rounded-full border-black bg-purple-600"></div>
              //         Critical (inc. Social Media)
              //       </Space>
              //     ),
              //   },
              // ]}
            >
              {priorityColorCode?.map((item=>{
                return(
              <Option value={item.value}>
                  <Space>
                    <div className={item.colorValue}></div>
                    {item.customName}
                  </Space>             
              </Option>
                )
              }))}
            </Select>
            <Button type="primary" onClick={() => multiplePriorityChange()}>Update</Button>
          </Space.Compact>
        </Space>
        <Select
          onChange={filterPriority}
          defaultValue={"-1"}
          placeholder="Please select"
          className="w-[65vw] md:w-60"
          // options={[
          //   {
          //     value: "-1",
          //     label: (
          //       <Space>
          //         Priority Filter - Show All
          //       </Space>
          //     ),
          //   },
          //   {
          //     value: "0",
          //     label: (
          //       <Space>
          //         <div className="w-3.5 h-3.5 border rounded-full border-black bg-white"></div>
          //         Organic
          //       </Space>
          //     ),
          //   },
          //   {
          //     value: "1",
          //     label: (
          //       <Space>
          //         <div className="w-3.5 h-3.5 border rounded-full border-black bg-lime-400"></div>
          //         Standard
          //       </Space>
          //     ),
          //   },
          //   {
          //     value: "2",
          //     label: (
          //       <Space>
          //         <div className="w-3.5 h-3.5 border rounded-full border-black bg-orange-400"></div>
          //         Extra
          //       </Space>
          //     ),
          //   },
          //   {
          //     value: "3",
          //     label: (
          //       <Space>
          //         <div className="w-3.5 h-3.5 border rounded-full border-black bg-red-600"></div>
          //         Max (inc. Social Media)
          //       </Space>
          //     ),
          //   },
          //   {
          //     value: "4",
          //     label: (
          //       <Space>
          //         <div className="w-3.5 h-3.5 border rounded-full border-black bg-purple-600"></div>
          //         Critical (inc. Social Media)
          //       </Space>
          //     ),
          //   },
          // ]}
        >
          <Option value="-1">
            <Space>
              Priority Filter - Show All
            </Space>
          </Option>
          {priorityColorCode?.map((item=>{
                return(
              <Option value={item.value}>
                   <Space>
                     <div className={item.colorValue}></div>
                     {item.customName}
                   </Space>             
              </Option>
                )
          }))}
        </Select>  

        <Search
          placeholder="Search"
          allowClear
          onSearch={(e) => searchJob(e)}
          onChange={(e) => { searchJob(e.target.value); setSearchedValue(e.target.value) }}
          enterButton
          className="w-full md:w-60"
        />
      </div>
      <div className="overflow-x-auto">
        <Table
        loading={isLoading}
          size='small'
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
            selectedRowKeys: selectedRowKeys
          }}
          rowKey="id"
          columns={yourJobsColumns}
          dataSource={YourJobsData}
          onChange={onChangeJobTable}
          pagination={{
            total: jobsCount,
            showSizeChanger: true,
            pageSizeOptions: ["50", "100", "200", "500"],
            defaultPageSize: 100,
            current: currentPage,
          }}
          footer={() => YourJobsData?
             'Showing ' + ((currentPage - 1) * pageSize + 1) + ' to ' + ((currentPage - 1) * pageSize + YourJobsData?.length) + ' of ' + jobsCount + ' entries'
            : 'Showing 0 entries'
          }
        />
      </div>
      <Modal
        title="Edit Job details"
        open={isEditModalOpen}
        onCancel={handleCancel}
        okText={<span className="capitalize">Save</span>}
        onOk={() => form.submit()}
        style={{ top: 20 }}
        width={800}
      >
        <Form form={form} name="form_in_modal" layout="vertical" onFinish={saveJobEditDetails}>
          <div className="grid xs:grid-cols-1 lg:grid-cols-2 gap-4">
            <Form.Item
              name="title"
              label="Job Title"
              rules={[
                {
                  required: true,
                  message: "Required field",
                },
              ]}
              className="mb-0"
            >
              <Input />
            </Form.Item>
            <Form.Item name="reference" label="Reference" className="mb-0">
              <Input />
            </Form.Item>
            <Form.Item name="companyName" label="Company Name" className="mb-0"
              rules={[
                {
                  required: true,
                  message: "Required field",
                },
              ]}>
              <Input />
            </Form.Item>
            <Form.Item
              name="country"
              label="Country"
              className="mb-0"
            >
              <Select loading={countryLoader}>
                {countryList.map((item) => (
                  <Option key={item.countryCode} value={item.countryCode}>{item.country}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="address" label="Address" className="mb-0">
              <TextArea rows={1} />
            </Form.Item>
            <Form.Item name="place" label="City/Town" className="mb-0">
              <Input />
            </Form.Item>
            <Form.Item name="county" label="County" className="mb-0">
              <Input />
            </Form.Item>
            <Form.Item name="postcode" label="Post code" className="mb-0">
              <Input />
            </Form.Item>
            <Form.Item name="salary" label="Salary"
              // rules={[
              //   {
              //     required: true,
              //     message: "Required field",
              //   },
              // ]} 
              className="mb-0">
              <Input />
            </Form.Item>
            <Form.Item name="priority" label="Priority"
              rules={[
                {
                  required: true,
                  message: "Required field",
                },
              ]} 
              className="mb-0">
              <Select
                className="w-full"
                options={[
                  {
                    value: 0,
                    label: (
                      <Space>
                        <div className="w-3.5 h-3.5 border rounded-full border-black bg-white"></div>
                        Organic
                      </Space>
                    ),
                  },
                  {
                    value: 1,
                    label: (
                      <Space>
                        <div className="w-3.5 h-3.5 border rounded-full border-black bg-lime-400"></div>
                        Standard
                      </Space>
                    ),
                  },
                  {
                    value: 2,
                    label: (
                      <Space>
                        <div className="w-3.5 h-3.5 border rounded-full border-black bg-orange-400"></div>
                        Extra
                      </Space>
                    ),
                  },
                  {
                    value: 3,
                    label: (
                      <Space>
                        <div className="w-3.5 h-3.5 border rounded-full border-black bg-red-600"></div>
                        Max + Social
                      </Space>
                    ),
                  },
                  {
                    value: 4,
                    label: (
                      <Space>
                        <div className="w-3.5 h-3.5 border rounded-full border-black bg-purple-600"></div>
                        Critical + Social
                      </Space>
                    ),
                  },
                ]}
              />
            </Form.Item>
            {Cookies.get(constants.COOKIE_EMAIL_POST)==="true" || Cookies.get(constants.COOKIE_EMAIL_POST)==true?(
              <Form.Item
                name="applyEmail"
                label="Recruiters Email"
              >
                <Input />
              </Form.Item>
             ):null }
          </div>
          <div className="grid xs:grid-cols-1 lg:grid-cols-4 gap-4">
            <Form.Item name="fullTime" label="Full Time" valuePropName='checked'>
              <Switch checkedChildren="Yes" unCheckedChildren="No"
              />
            </Form.Item>
            <Form.Item name="partTime" label="Part Time" valuePropName='checked'>
              <Switch checkedChildren="Yes" unCheckedChildren="No" />
            </Form.Item>
            <Form.Item name="contract" label="Contract" valuePropName='checked'>
              <Switch checkedChildren="Yes" unCheckedChildren="No" />
            </Form.Item>
            <Form.Item name="hourlyWork" label="Hourly Work" valuePropName='checked'>
              <Switch checkedChildren="Yes" unCheckedChildren="No" />
            </Form.Item>
          </div>
          <Form.Item name="description" label="Job Description"
            rules={[
              {
                required: true,
                message: "Required field",
              },
            ]}>
            <TextArea
              autoSize={{
                minRows: 6,
                maxRows: 12,
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default JobsOverride;