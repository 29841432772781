import { Table, Spin, message, Image} from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import APIUtils from '../../api/APIUtils';
import WebLinks from '../../api/WebLinks';
import { useEffect, useState } from 'react';
import constants from '../../constants/constants';
import Cookies from 'js-cookie';

const{Column}=Table;

const OccupationRules = (props) => {
	//Variable Declaration
	const [occupations,setOccupations]=useState([]);
	const [suppliers,setSuppliers]=useState([]);
  const [pageNumber,setPageNumber]=useState(1);
  const [sortDesc,setSortDesc]=useState(false);
  const [total,setTotal]=useState(0);
  const [loading,setloading]=useState(false);

	//On page load
	useEffect(()=>{
		fetchData();
		setSuppliers(props.suppliers);
	},[props]);

	//Functions
	//Function to fetch occupation data
	const fetchData = async (isSortDesc=sortDesc) =>{
		setloading(true);
		const occupations = await APIUtils.apiCall("GET",WebLinks.GetOccupationsRule+"buyerId="+Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)+
		"&limit="+constants.pageSize+"&pageNo="+pageNumber+"&sortDesc="+isSortDesc,null);
		if (occupations.isCatch) {
			message.error(constants.ERROR_MESSAGE);
		} else {
			if(occupations.resultObj?.statusCode==="2000") {
				setOccupations(occupations.resultObj.data)
				setTotal(occupations.resultObj.data?.getCount | 0);
			} else {
				message.success(occupations.resultObj?.message | constants.ERROR_MESSAGE)
			}
		}
		setloading(false);
	}

	// Table start
	const onChangeTable = async(pagination, filters, sorter, extra)=>{
		let isSortDesc=sorter.order==="ascend"?false:true
		await setSortDesc(isSortDesc);
		fetchData(isSortDesc);
	  }
	// Table end

	//Function to insert occupation block
	const InsertOccupationBlock = async (buyerId,supplierId,occupationId, occupationIndex,supplierIndex) =>{
		let insertPayload=null;
		insertPayload={
			buyerId:buyerId,
			SupplierId:supplierId,
			occupationId:occupationId,
			UpdatedByBuyerId:Cookies.get(constants.COOKIE_LOGGED_BUYER_ID)
		}
		const insertOccupationResponse = await APIUtils.apiCall('POST',WebLinks.InsertOccupationBlock,JSON.stringify(insertPayload));
    if(!insertOccupationResponse.isCatch && insertOccupationResponse.resultObj.statusCode==="2000"){
			let tempOccupationData=[...occupations]
			tempOccupationData[occupationIndex].suppliers[supplierIndex].isActive=false;
			setOccupations(tempOccupationData);
			message.success(insertOccupationResponse.resultObj.message)
    }else{
			message.error(insertOccupationResponse.resultObj.message)
    }
	};

	//Function to insert occupation block
	const DeleteOccupationBlock = async (buyerId,supplierId,occupationId,occupationIndex,supplierIndex) =>{
		let deletePayload=null;
		deletePayload={
			buyerId:buyerId,
			SupplierId:supplierId,
			occupationId:occupationId,
			UpdatedByBuyerId:Cookies.get(constants.COOKIE_LOGGED_BUYER_ID)
		}
		const deleteOccupationResponse = await APIUtils.apiCall('DELETE',WebLinks.DeleteOccupationBlock,JSON.stringify(deletePayload));
    if(!deleteOccupationResponse.isCatch && deleteOccupationResponse.resultObj.statusCode==="2000"){
			let tempOccupationData=[...occupations]
			tempOccupationData[occupationIndex].suppliers[supplierIndex].isActive=true;
			setOccupations(tempOccupationData);
			message.success(deleteOccupationResponse.resultObj.message)
    }else{
			message.error(deleteOccupationResponse.resultObj.message)
    }
	};

	//Render
	return (
		<div>
			<div className='overflow-x-auto'>
				<Table dataSource={occupations} 
					loading={loading}
					sortDirections={['descend', 'ascend','descend']}
					pagination= {false}
					scroll={{ x: 1000, y: 590 }} // Set the y property to the desired height
					// pagination={{
					// 	onChange: (page) => {
					// 		setPageNumber(page);
					// 	},
					// 	defaultPageSize: constants.pageSize,
					// 	pageSizeOptions: ["25", "50", "100"],
					// 	total:total,
					// }}
					// onChange={onChangeTable}
					>
					<Column width="100px" title="ID" dataIndex="occupationId" key="occupationId" sorter={(a, b) => (a.occupationId - b.occupationId)} fixed={window.innerWidth > 1024 ? true : false}/>
					<Column width="150px" title="Occupation" dataIndex="occupationName" key="occupation" sorter={(a, b) => a.occupationName.localeCompare(b.occupationName)} fixed={window.innerWidth > 1024 ? true : false} />
					<Column width="80px" title="Job Count" dataIndex="occupationCount" key="jobCount" align='center' fixed={window.innerWidth > 1024 ? true : false}/>
					{suppliers?.map((item,index)=>{
						return(<Column width="80px" title={item.logoURL && item.logoURL!==""?<Image src={item.logoURL} preview={false} width={70}/>:item.supplierName} dataIndex={item.supplierName} key={item.supplierName} align='center'
						render={(_,data,oIndex) => {
							let supplierIndex = data.suppliers.findIndex((dataItem)=>dataItem.supplierName === item.supplierName);
							let supplierItem = data.suppliers[supplierIndex]
							return(
								<>
									{supplierItem.isActive?
										<CheckOutlined className='text-xl text-lime-500' onClick={()=>{InsertOccupationBlock(data.buyerId,supplierItem.supplierId,data.occupationId,oIndex,supplierIndex)}} />:
										<CloseOutlined className='text-xl text-red-500' onClick={()=>{DeleteOccupationBlock(data.buyerId,supplierItem.supplierId,data.occupationId,oIndex,supplierIndex)}} />}
								</>
							)
							}}/>)
						})}
				</Table>
			</div>
		</div>
	);
};

export default OccupationRules;