import { useState, createContext } from "react"
const CurrencyContext = createContext()

function ContextProvider(props) {
	const [currency, setCurrency] = useState("$")

	function changeCurrencyValue(value) {
		setCurrency(value)
	}

	return (
		<CurrencyContext.Provider value={{currency,changeCurrencyValue}}>
			{props.children}
		</CurrencyContext.Provider>
		)
}
export {ContextProvider,CurrencyContext}
