import { Table, Form, Spin, Space, message, Image, DatePicker, Button, Tooltip} from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import APIUtils from '../../api/APIUtils';
import WebLinks from '../../api/WebLinks';
import { useEffect, useState } from 'react';
import constants from '../../constants/constants';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import { downloadFiles } from '../../globalFunctions/GlobalFunctions';
const { RangePicker } = DatePicker;
const{Column}=Table;

const BudgetSpendMulti = (props) => {
	//Variable Declaration
	const [data,setData]=useState([]);
	const [monthList,setmonthList]=useState([]);
	const [pageNumber,setPageNumber]=useState(1);
	const [sortDesc,setSortDesc]=useState(false);
	const [total,setTotal]=useState(0);
	const [loading,setloading]=useState(false);
	const [rangePickerValue, setRangePickerValue] = useState([]);
	const [form] = Form.useForm();
	const [summaryData,setSummaryData]=useState([]);
	const [scrollWidth, setScrollWidth] = useState(1000);

	//On page load
	useEffect(()=>{
		const date1 = dayjs().startOf('month')
		const date2 = dayjs().endOf('month')
		form.setFieldsValue({
			rangePicker: [
				date1,
				date2,
			],
		});
		setRangePickerValue([
			date1.format(constants.MonthFormat),
			date2.format(constants.MonthFormat),
		]);
		generateMonthList(
			date1.format(constants.MonthFormat),
			date2.format(constants.MonthFormat));
		fetchData(1,
			date1.format(constants.MonthFormat),
			date2.format(constants.MonthFormat));
	},[props]);

	// Effect to update scrollWidth based on screen size
	useEffect(() => {
		const handleResize = () => {
			// Update scroll width for large screens
			if (window.innerWidth >= 1024) { // Tailwind's 'lg' breakpoint
				setScrollWidth(1000);
			} else {
				setScrollWidth(500); // Adjust or set to 'auto' for smaller screens
			}
		};

		// Add event listener
		window.addEventListener('resize', handleResize);

		// Call handler right away
		handleResize();

		// Cleanup event listener on component unmount
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	//Functions
	const generateMonthList = (startDateString, endDateString) => {
		const months = [];
		let startDate = new Date(startDateString);
		let endDate = new Date(endDateString);
	  
		// If start date and end date are the same, add only that month
		if (startDate.toDateString() === endDate.toDateString()) {
		  const monthYearString = `${startDate.toLocaleString('en-US', {
			month: 'short',
		  })}-${startDate.getFullYear()}`;
		  months.push(monthYearString);
		} else {
		  // Loop through months until reaching the end date
		  while (startDate <= endDate) {
			const monthYearString = `${startDate.toLocaleString('en-US', {
			  month: 'short',
			})}-${startDate.getFullYear()}`;
	  
			months.push(monthYearString);
			// Move to the next month
			startDate.setMonth(startDate.getMonth() + 1);
		  }
		}
		setmonthList(months);
	  };
	  
	//Function to fetch occupation data
	const fetchData = async (currentPage, startDate, endDate) =>{
		setloading(true);
		const response =await APIUtils.apiCall("GET",WebLinks.GetMultiBudgetSpend+Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)+
		"&limit="+constants.supplierPageSize+"&pageNo="+currentPage+"&startDate="+startDate+"&endDate="+endDate,null);
		if (response.isCatch) {
			message.error(constants.ERROR_MESSAGE);
		} else {
			if(response.resultObj?.statusCode==="2000") {
				const newDataArray = response?.resultObj?.data?.slice(0, -1);
				let tempSummaryData=response?.resultObj?.data[response?.resultObj?.data.length-1]||[];
				let tempSummaryDataFiltered=tempSummaryData?.months?.filter(obj => obj.name !== "Total");
				let totalObj=tempSummaryData?.months?.filter(obj => obj.name === "Total");
				if(totalObj.length>0){
					tempSummaryDataFiltered.push(totalObj[0]);
				}
				setSummaryData(tempSummaryDataFiltered||[]);
				setData(newDataArray||[]);
				setTotal(response?.resultObj?.getCount | 0);
			} else {
				// message.success(response.resultObj?.message | constants.ERROR_MESSAGE)
			}
		}
		setloading(false);
	};
	//Function runs when range picker value is changed
	const RangePickerChanged = debounce((_, dateStrings) => {
		setRangePickerValue([
			dayjs(dateStrings[0], constants.MonthFormat),
			dayjs(dateStrings[1], constants.MonthFormat),
		]);
		generateMonthList(dateStrings[0], dateStrings[1]);
		fetchData(pageNumber, dateStrings[0], dateStrings[1]);
	}, 500);
	const disabledDate = (current) => {
		// Disable dates that are in the future
		return current && current > Date.now();
	  };
	// Table start
	const onChangeTable = async(pagination, filters, sorter, extra)=>{
		setPageNumber(pagination.current);
		fetchData(pagination.current,rangePickerValue[0],rangePickerValue[1]);
	  }
	// Table end

	//Function to download as excel
	const downloadFile = () => {
		setloading(true)
		downloadFiles(WebLinks.BudgetSpendMultiReport+Cookies.get(constants.COOKIE_SELECTED_BUYER_ID) + `&startDate=${dayjs(rangePickerValue[0]).format(constants.MonthFormat)}&endDate=${dayjs(rangePickerValue[1]).format(constants.MonthFormat)}`,"BudgetMultiSpendReport.xlsx");
		setTimeout(() => {
			setloading(false)
		}, 3000);
	};

	//Render
	return (
		<div>
			<div className='overflow-x-auto'>
				<Space wrap>
					<Form form={form}>
						<Form.Item name="rangePicker" className='mb-2'>
							<RangePicker picker="month"
								placeholder={["From", "To"]}
								onChange={RangePickerChanged}
								format={constants.MonthFormat}
								disabledDate={disabledDate} />
						</Form.Item>
					</Form>
					<Tooltip placement='right' title="Download as Excel">
              <Button
                  className='bg-green-800 text-white hover:!text-white hover:!border-green-500 mb-2'
                  icon={<FileExcelOutlined />}
                	onClick={()=>{downloadFile()}}
              >
                Download as Excel
              </Button>
          </Tooltip>
				</Space>
				<Table 
					dataSource={data} 
					loading={loading}
					scroll={{ x: scrollWidth, y: 590 }} // Set the y property to the desired height
					// pagination={{
					// 	onChange: (page) => {
					// 		setPageNumber(page);
					// 	},
					// 	defaultPageSize: constants.pageSize,
					// 	total:total,
					// }}
					pagination={false}
					onChange={onChangeTable}
					sticky
					summary={() => (
						<Table.Summary fixed={'top'}>
							<Table.Summary.Row>
							<Table.Summary.Cell index={0} width="150px" fixed={true} className='font-semibold'>
								Total Spend
							</Table.Summary.Cell>
							{monthList?.map((item, index) => (
									<Table.Summary.Cell index={index+1} width="80px" align="center" className='font-semibold'>
										{summaryData[index]?.spend||0}
									</Table.Summary.Cell>
							))}
							<Table.Summary.Cell index={monthList.length+2} width="80px" align="center" className='font-semibold'>
								{summaryData[summaryData?.length-1]?.spend||0}
							</Table.Summary.Cell>
							</Table.Summary.Row>
						</Table.Summary>
					)}
				>
					<Column width="150px" title="Month/Supplier" dataIndex="logoURL" key="supplierName" fixed={true} 
						render={(item,data,oIndex) => {
							return(
								<>
								{item && item !== "" ?
									<Image src={item} preview={false} width={100} /> :
									<span>{data.supplierName==="Total"?<strong>{data.supplierName}</strong>:data.supplierName}</span>
								}
							</>
							)}}
						/>
						{monthList?.map((item, index) => (
							<>
							<Column
								width="80px"
								title={dayjs(item).format('MMM-YYYY')}
								dataIndex="months"
								align="center"
								render={(months, data, oIndex) => {
								const monthItem = data?.months?.find((dataItem) => dataItem.name === item);
								const spend=monthItem ? monthItem.spend : 0;
								return <>{data?.supplierName==="Total"?<strong>{spend}</strong>:spend}</>;
								}}
							/>
							</>
						))}
						<Column
							width="80px"
							title={"Total"}
							dataIndex="months"
							align="center"
							render={(months, data, oIndex) => {
							const monthItem = data?.months?.find((dataItem) => dataItem.name === "Total");
							return <strong>{monthItem ? monthItem.spend : 0}</strong>;
							}}
						/>
				</Table>
			</div>
		</div>
	);
};

export default BudgetSpendMulti;