const BaseURL = "https://partnersapi.applygateway.com/api/";
const BaseURLSocialAPI = "https://socialapi.hireintelligence.io/api/";
const WebLinks = {
    // user
    Login: BaseURL + 'Login/login',
    //buyer
    LoggedInBuyerDetails: BaseURL + 'Buyer',
    SubBuyerList: BaseURL + 'Buyer/BuyerSubList/',
    GetBuyerDetails: BaseURL + 'Buyer/',
    SaveProfileDetails: BaseURL + "",
    GEtSubBuyerAllotedList: BaseURL+ "Buyer/GetSubBuyerList?buyerId=",
    //click reports
    GetClickReportDetails: BaseURL + 'Clicks',
    GetClickReportDetailedInfo: BaseURL + 'Clicks/ClickReportInfo',
    JobUploadHistory: BaseURL + 'Job/UploadHistory?buyerId=',
    GetYourJobsListing: BaseURL + 'Job?buyerId=',
    GetAllYourJobsListing: BaseURL + 'Job/GetAllJobs?buyerId=',
    GetAllYourJobsListingClick: BaseURL + 'Job/GetClickPostJobs?buyerId=',
    GetAllYourJobsListingPerformance: BaseURL + 'Job/GetPerformance?buyerId=',
    GetAllYourJobsListingPerformanceMulti:BaseURL + 'Job/GetMultiPerformance?buyerId=',
    GetAllYourJobsListingPerformanceClick: BaseURL + 'Job/GetClickPostPerformance?buyerId=',
    GetAllYourJobsListingPerformanceClickMulti: BaseURL + '',
    GetSelectedJobDetails: BaseURL + 'Job/',
    UpdatePriorityLevel: BaseURL + 'Job/PriorityLevel?priorityLevel=',
    UpdateSelectedJob: BaseURL + 'Job',
    AddNewJob: BaseURL + 'Job/PostJob',
    GetClickReportMonthDayDetails: BaseURL + 'Clicks/GetMonthlyClickReport?buyerId=',
    GetClickReportDayDetails: BaseURL + 'Clicks/GetDayClickReport?buyerId=',
    MultiGetClickReportMonthDayDetails: BaseURL + 'MultiClickReport/GetMultiMonthlyClickReport?buyerId=',
    MultiGetClickReportDayDetails: BaseURL + 'MultiClickReport/GetMultiDailyClickReport?buyerId=',
    GetClickSpendPerJob: BaseURL + 'Clicks/SpendPerClick?buyerId=',
    DownloadClickSpendPerJobFile: BaseURL + 'Clicks/GenerateSpendPerClickExcel?buyerId=',
    //application reports
    GetOccupationOptionsList: BaseURL + "",
    GetApplicationReport: BaseURL + "Report/ApplicationReport",
    GetApplicationQualityReport: BaseURL + "Report/QualityReport?buyerId=",
    GetApplicationInterviewsHiresReport: BaseURL + "Report/GetInterviewHires?buyerId=",
    GetApplicationDailyDteailedReport: BaseURL + "Report/GetAdminXlsContent",
    GetOccupationsReport: BaseURL + "Report/GetBuyerOccupations?buyerid=",
    GetOccupations: BaseURL + "Report/GetOccupations?buyerId=",
    GetApplicationSpendPerJob: BaseURL + "Report/SpendPerJob?buyerId=",
    GetMultiApplicationSpendPerJob: BaseURL + "MultiApplicationReport/GetMultiSpendPerJob?buyerId=",
    DownloadApplicationSpendPerJob: BaseURL + "Report/GenerateJobSpendExcel?buyerId=",
    DownloadMultiApplicationSpendPerJob: BaseURL + "MultiApplicationReport/GenerateMultiSpendPerJobExcel?buyerId=",
    GetApplicationLog: BaseURL + "Report/GetApplicationLog?buyerid=",
    GetYourJobApplications:BaseURL + "Job/GetYourJobsApplications?buyerid=",
    DownloadAplicationLog: BaseURL + "Report/GenerateApplicationLogExcel?buyerid=",
    DownloadApplicationMonthReport: BaseURL + "Report/GenerateMonthReportExcel?buyerId=",
    DownloadApplicationQualityReport: BaseURL + "Report/GenerateQualityReportExcel?buyerId=",
    DownloadApplicationInterviesHiresReport: BaseURL + "Report/GenerateInterviewHiresExcel?buyerId=",
    DownloadOccupationReportAll: BaseURL + "Report/GenerateMonthReportExcel?buyerId=",
    DownloadOccupationReport: BaseURL + "Report/GenerateOccupationReportExcel?buyerid=",
    DownloadApplicationDayReport: BaseURL + "Report/GenerateDailyReportExcel?selectedbuyerid=",
    DownloadYourJobApplications: BaseURL + "Job/GenerateYourJobApplicationExcel?buyerid=",
    MultiDownloadApplicationMonthReport: BaseURL + "MultiApplicationReport/GenerateMultiMonthReportExcel?buyerId=",
    MultiDownloadApplicationDayReport: BaseURL + "",

    //your jobs
    PutActiveJob: BaseURL + "Job/UpdateActive",
    PutUpdateSinglePriority: BaseURL + "Job/PriorityLevel?",
    GetCountryList: BaseURL + "BackofficeBuyer/GetCountryList",
    GetBuyerCountry: BaseURL + "Job/GetCountryForBuyer?buyerId=",
    DownloadYourJobReport: BaseURL + "Job/GenerateYourJobExcel?buyerId=",
    DownloadYourJobReportClick:  BaseURL + "Job/GenerateClickPostYourJobExcel?buyerId=",
    DownloadYourJobPerformance: BaseURL + "Job/GenerateYourJobPerformanceExcel?buyerId=",
    DownloadYourJobPerformanceMulti: BaseURL + "Job/GenerateMultiPerformanceExcel?buyerId=",
    DownloadYourJobPerformanceClick: BaseURL + "Job/GenerateClickPostYourJobPerformanceExcel?buyerId=",
    DownloadYourJobPerformanceClickMulti: BaseURL + "",
    //job occupations
    OccupationsList: BaseURL + "Job/OccupationList?buyerId=",
    JobOccupations: BaseURL + "Job/Occupations?buyerId=",
    NewOccupations: BaseURL + "Job/GetNewOccupationList",
    UpdateJobOccupation: BaseURL + "Job/UpdateJobOccupationId?occupationId=",
    //JobRules
    GetOccupationsRule: BaseURL + "JobRule/OccupationRule?",
    GetJobRulesSuppliers: BaseURL + "JobRule/GetSupplierList?",
    InsertOccupationBlock: BaseURL + "JobRule/InsertOccupationBlock",
    DeleteOccupationBlock: BaseURL + "JobRule/DeleteOccupationBlock",
    GetDistributionPriorityRules: BaseURL + "JobRule/PriorityRule?",
    InsertPriorityBlock: BaseURL + "JobRule/InsertPriorityBlock",
    DeletePriorityBlock: BaseURL + "JobRule/DeletePriorityBlock",
    //PriorityFilter
    GetPriorityFilters: BaseURL + "PriorityFilter/GetAllFilter?buyerId=",
    AddPriorityFilter: BaseURL + "PriorityFilter/AddFilter",
    DeletePriorityFilter: BaseURL + "PriorityFilter/DeleteFilter?filterId=",
    UpdateOrderPriorityFilter: BaseURL + "PriorityFilter/UpdatePriorityOrderFilter",
    UpdatePriorityFilter: BaseURL + "PriorityFilter/UpdateFilter",
    GetBuyerPriorityFilters: BaseURL + "Job/GetPriorityFilter?buyerId=",
    //Budget
    GetBudget: BaseURL + "Budget?buyerId=",
    GetMultiBUdget: BaseURL + "MultiBudgetsAdmin/GetMultiSupplierBudget?buyerId=",
    UpdateBudget: BaseURL + "Budget/UpdateBudget",
    SpendBudget: BaseURL + "Budget/GetBuyerSpend?buyerId=",
    GetMultiBudgetSpend: BaseURL + "MultiBudgetsAdmin/GetMultiSupplierSpend?buyerId=",
    GetMultiBudgetSpendPerCostCenter: BaseURL + "MultiBudgetsAdmin/GetMultiSpendPerCostCenter?buyerId=",
    UpdateAllowedBodgetSupplier: BaseURL +"Budget/UpdateAllowedSupplierBudget",
    BudgetSpendReport: BaseURL + "Budget/GenerateBudgetSpendExcel?buyerId=",
    BudgetSpendMultiReport: BaseURL + "MultiBudgetsAdmin/GenerateMultiBudgetSpendExcel?buyerId=",
    BudgetSpendByCostCenterMultiReport: BaseURL + "MultiBudgetsAdmin/GenerateMultiSpendPerCostCenterExcel?buyerId=",
    GetContractsSpend: BaseURL + "Budget/GetContractSpend?buyerId=",
    UpdateContractSpendValue: BaseURL + "Budget/InsertContractSpendvalue",
    //ManageSuppliers
    GetActiveSuppliers: BaseURL + "ManageSupplier/ViewActiveSuppliers",
    GetMultiActiveSuppliers: BaseURL + "ManageSupplierAdmin/AdminViewActiveSuppliers",
    GetSuppliers: BaseURL + "ManageSupplier/ViewSuppliers",
    GetMultiSuppliers: BaseURL + "ManageSupplierAdmin/AdminViewSuppliers",
    AddSupplier: BaseURL + "ManageSupplier/AddBuyerSupplier",
    DeleteActiveSuppier:BaseURL + "ManageSupplier/DeleteBuyerSupplier?buyerId=",
    AddMultiSupplier: BaseURL + "ManageSupplierAdmin/AdminAddBuyerSupplier",
    UpdateSupplier: BaseURL + "ManageSupplier/UpdateBuyerSupplier",
    MultiUpdateSupplier: BaseURL + "ManageSupplierAdmin/AdminUpdateBuyerSupplier",
    UpdateKeyPost: BaseURL + "ManageSupplier/UpdateAllowedSupplier",
    MultiUpdateKeyPost: BaseURL + "ManageSupplierAdmin/AdminUpdateAllowedSupplier",
    GetKeyDetails: BaseURL + "ManageSupplier/GetActiveSupplierSinglePostDetails?buyerId=",
    MultiGetKeyDetails: BaseURL +"ManageSupplierAdmin/GetAdminActiveSupplierSinglePostDetails?buyerId=",
    GetSinglePosts: BaseURL + "ManageSupplier/GetSinglePosts?buyerId=",
    DeleteSinglePost: BaseURL + "ManageSupplier/RepostJob?id=",
    GetMultiSupplierAdmin: BaseURL + "MultiSupplierAdmin/GetMultiCostCenters?buyerId=",
    UpdateMultiSupplierAdmin: BaseURL + "MultiSupplierAdmin/UpdateBuyersSuppliers",
    //SocialMedia
    SocialMediaLogin: BaseURLSocialAPI + "login",
    GetJobAd: BaseURLSocialAPI + "job-ads?buyerId=",
    //Social MediaPromo Configure
    GetSocialConfigure: BaseURL + "SocialConfigure?",
    GetSocialOccupations: BaseURL + "SocialConfigure/GetBuyerOccuptionList?buyerId=",
    DeleteSocialConfig: BaseURL + "SocialConfigure/DeleteSocialConfigure?Id=",
    UpdateSocialConfig: BaseURL + "SocialConfigure/UpdateSocialConfigure",
    AddSocialConfig: BaseURL + "SocialConfigure/AddSocialConfigure",

    //Candidates
    GetCandidates: BaseURL + "Candidate",
    GetCandidateSuppliers: BaseURL + "Candidate/SupplierList",
    GetCandidateCVDetail: BaseURL + "Candidate/CandidateCombination",
    UpdateCandidateStatus: BaseURL + "Candidate/UpdateStatus",
    SubmitBulkHire: BaseURL + "Candidate/BulkHire?buyerId=",
    HireCandidate: BaseURL + "Candidate/HireCandidate?applicationId=",
    DownloadExcelFile: BaseURL + "Candidate/GenerateCandidateExcel?",
    GetNoteDetails: BaseURL + "Candidate/GetCandidateNote?noteId=",
    UpdateNoteDetails: BaseURL + "Candidate/UpdateCandidateNote",
    AddNoteDetails: BaseURL +"Candidate/AddCandidateNote",
    DeleteNoteDetails: BaseURL +"Candidate/DeleteCandidateNote",

    //Occupation filter
    GetOccupationfilter: BaseURL + "OccupationFilter/GetOccupationFilter?buyerId=",
    GetOccupationList: BaseURL + "OccupationFilter/OccupationList?buyerId=",
    UpdateOccupationFilter: BaseURL + "OccupationFilter/UpdateOccupationFilter",
    AddOccupationFilter: BaseURL + "OccupationFilter/AddOccupationFilter",
    DeleteOccupationFilter: BaseURL + "OccupationFilter/DeleteOccupationFilter?id=",
    UpdateOccupationOrderPriorityFilter: BaseURL + "OccupationFilter/UpdatePriorityFilterOrder",
    //Sub Buers Admin
    SubBuyerBudget: BaseURL + "MultiBudgetsAdmin?loggedInBuyerId=",
    SubMultiBudgetByCostCenter: BaseURL + "MultiBudgetsAdmin/GetMasterBudget?loggedInBuyerId=",
    SubBuyerNotDelivering: BaseURL + "MultiBudgetsAdmin/NotDelivering?buyerId=",
    SubBuyerOverBudget: BaseURL + "MultiBudgetsAdmin/OverBudget?buyerId=",
    GetSubBuyerSuppliers: BaseURL + "MultiBudgetsAdmin/SupplierList",
    GetSubBuyerMonthlyReport: BaseURL + "MultiApplicationReport/MultiApplicationMonthReport?buyerId=",
    GetSubBuyerDailyReport: BaseURL + "MultiApplicationReport/MultiApplicationDayReport?buyerId=",
    GetSubBuyerJobs: BaseURL + "Job/GetAllSubbuyerJobs?buyerId=",
    PutSubBuyerPriority: BaseURL + "Job/SubBuyerPriorityLevel?",
    //InFeed
    GetJobsInFeedBlockListing: BaseURL + 'JobsInFeed?buyerId=',
    UpdateJobsInFeedBlock: BaseURL + 'JobsInFeed/UpdateOverride?buyerId=',
    //Override
    InsertJobsOverrideInclude:BaseURL + 'JobsInFeed/InsertOverride?buyerId=',
    DeleteJobsOverrideInclude:BaseURL + 'JobsInFeed/DeleteOverride?buyerId=',
    GetJobsInFeedOverrideListing:BaseURL + 'JobsInFeed/GetJobOverride?buyerId=',
    //PriorityRules
    GetPriorityRules: BaseURL + 'PriorityRule?buyerId=',
    AddPriorityRules: BaseURL + 'PriorityRule/AddPriorityRule',
    UpdatePriorityRules: BaseURL + 'PriorityRule/UpdatePriorityRule',
    DeletePriorityRules: BaseURL + 'PriorityRule/DeletePriorityRule?id=',
    //QualityFiltering
    GetQualityFilter: BaseURL + 'QualityFilter/',
    AddQualityFilter: BaseURL + 'QualityFilter/',
    //QualityFilters:
    QualityFilter: BaseURL + 'QualityFilter?buyerId=',
    SubmitQualityFilterFeedback: BaseURL + 'QualityFilter/UpdateBuyerQualityFilterFeedback',
    QualityFilterSupplier: BaseURL + 'QualityFilter/',
    UpdateQualityFilter: BaseURL + 'QualityFilter/InsertBuyerQualityFilters?buyerId=',
    UpdateQualityFilterSupplier: BaseURL + 'QualityFilter/UpdateFilterSupplier?buyerId=',
    DeleteQualityFilter: BaseURL + 'QualityFilter/DeleteBuyerQualityFilter?buyerId=',
    QualityFilterType: BaseURL + 'QualityFilter/GetQualityFilterType?buyerId=',
    UpdateQualityFilterType: BaseURL + "QualityFilter/QualityFilterTypeUpdate?buyerId=",
    //OccupationMatrix
    GetOccupationMatrix: BaseURL + 'Report/GetOccupationMatrix?buyerId=',

    //Multi Candidate Admin
    GetMultiCandidatesAdmin: BaseURL + 'Candidate/MultiCandidateAdmin',
    DownloadCandidateAdminExcelFile: BaseURL + 'Candidate/GenerateMultiCandidateAdminExcel?',
    GetMultiCandidatesAdminSupliers: BaseURL + 'Candidate/SubBuyerSupplierList',

    //Allowed Suppliers
    GetAllowedSuppliers: BaseURL + 'Report/GetAllowedSupplierList?buyerId=',
    GetALlAllowedSuppliers: BaseURL + 'ManageSupplier/GetAllAllowedSupplierList',

    //TikTok Promo
    GetTiktokPromoRecords: BaseURL + 'TikTokPromo/GetBuyerTikTokPromo?buyerId=',

    //Retool
    GetRetoolDashbordLink: BaseURL + 'ReTool',

    //Cost center
    GetCostCenter: BaseURL + 'CostCenter/GetCostCenters?buyerId=',

    //ActivityLogd
    GetPriorityActivityLogs: BaseURL + 'ActivityLog/GetPriorityActionLog?buyerId=',
    GetDistributionRulesActivityLogs: BaseURL + 'ActivityLog/GetDistributionActiveLog?buyerId='

};
export default WebLinks;
