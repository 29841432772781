import { useEffect, useState } from 'react';
import { Input, Space, Table, Tooltip, message } from 'antd';
import moment from 'moment';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import APIUtils from '../../api/APIUtils';
import constants from '../../constants/constants';
import WebLinks from '../../api/WebLinks';
import { GetCustomPriorityLevelIcons, GetPriorityLevelColors } from '../../globalFunctions/GlobalFunctions';

const { Search } = Input;

const DistributionRulesLogs = () => {

	const [distributionRulesActivityLog, setDistributionRulesActivityLog] = useState([]);
	const [loading,setloading]=useState(false);
	const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
	const [sortedValue, setSorterValue] = useState({
    columnKey: "",
    order: 'descend'
  });
	const [logsCount, setLogsCount] = useState(0);

	useEffect(()=>{
		distibutionRulesActivityLogListFetch(currentPage, pageSize, "", sortedValue.columnKey, sortedValue.order === "ascend" ? false : true);
	},[]);

	//fetch priority activity logs for last 90 days
	const distibutionRulesActivityLogListFetch = async (currentPage, pazeSize, searchValue, sortColumn, isSortDesc) =>{	
		setloading(true);
		const response = await APIUtils.apiCall('GET',WebLinks.GetDistributionRulesActivityLogs+Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)+"&limit=" + pazeSize + "&pageNo=" + currentPage + "&search=" + searchValue + "&sortBy=" + sortColumn + "&sortDesc=" + isSortDesc);
		if(!response.isCatch && response.resultObj && response.resultObj.statusCode === "2000"){
			setDistributionRulesActivityLog(response.resultObj.data);
			setLogsCount(response.resultObj.getCount);
			setloading(false);
		}else{
      message.error(response.resultObj.message);
			message.error(response?.resultObj?.message||constants.ERROR_MESSAGE);
			setloading(false);
    }
	};

	// Table start
	const distributionRulesActivityLogColumns = [
		{
			title: 'Buyer Name',
			dataIndex: 'selectedBuyerName',
			key: 'selectedBuyerName',
    	sorter: (a, b) => a.selectedBuyerName.localeCompare(b.selectedBuyerName)
		},
		{
			title: 'Supplier Name',
			dataIndex: 'supplierName',
			key: 'supplierName',
    	sorter: (a, b) => a.supplierName.localeCompare(b.supplierName)
		},
		{
			title: 'Priority',
			dataIndex: 'priorityLevelName',
			key: 'priorityLevelName',
    	render:(text,element)=>
			(<Space><div className={element.morePriorityLevels?GetCustomPriorityLevelIcons(element.priorityId):GetPriorityLevelColors(element.priorityId)}></div> {text}</Space>)
		},
		{
			title: 'Occupation',
			dataIndex: 'occupationName',
			key: 'occupationName',
    	sorter: (a, b) => a.selectedBuyerName.localeCompare(b.occupationName)
		},
		// {
		// 	title: 'Type',
		// 	dataIndex: 'type',
		// 	key: 'type',
		// },
		{
			title: 'Action',
			dataIndex: 'method',
			key: 'method',
			render: (text,item)=>{
				return (
				<>
					{item.method==="DELETE"?
						<Tooltip title="INSERTED"><CheckOutlined className='text-xl text-lime-500' /></Tooltip>:
						<Tooltip title="DELETED"><CloseOutlined className='text-xl text-red-500' /></Tooltip>}
				</>);
			}
		},
		{
			title: 'Updated On',
			dataIndex: 'createdOn',
			key: 'createdOn',
			render: (createdOn)=>{
				return createdOn?moment(createdOn)?.format('YYYY-MM-D, h:mm a'):"";
			}
		},
		{
			title: 'Updated By',
			dataIndex: 'loggedInBuyerName',
			key: 'loggedInBuyerName',
    	sorter: (a, b) => a.loggedInBuyerName.localeCompare(b.loggedInBuyerName)
		},
	];

	// Table pagination change
  const onChangeJobTable = (pagination, filters, sorter) => {
    if (pagination) {
      if (pageSize !== pagination.pageSize) {
        setCurrentPage(1);
      } else {
        setCurrentPage(pagination?.current);
      }
      setPageSize(pagination?.pageSize);
      if (sorter) {
        setSorterValue(sorter);
        distibutionRulesActivityLogListFetch(pagination?.current, pagination?.pageSize, "", sorter.order ? sorter.columnKey : "", sorter.order === "ascend" ? false : true);
      } else {
        distibutionRulesActivityLogListFetch(pagination?.current, pagination?.pageSize, "", sortedValue.columnKey, sortedValue.order === "ascend" ? false : true);
      }
    }
  };

	return (
		<div>
			<div className='uppercase font-bold text-xl'>Activity Logs - Distribution Rules</div>
			<div className='flex flex-wrap justify-between items-center gap-1 my-3'>
				<span className='text-slate-500'>{constants.ActivityLogLast90}</span>
				{/* <Search
					placeholder="Search"
					allowClear
					// onSearch={onSearch}
					enterButton
					className='w-60'
				/> */}
			</div>
			<div className='overflow-x-auto'>
				<Table 
					columns={distributionRulesActivityLogColumns} 
					dataSource={distributionRulesActivityLog} 
					loading={loading}
					onChange={onChangeJobTable}
          pagination={{
            total: logsCount,
            showSizeChanger: true,
            pageSizeOptions: ["50", "100", "200", "500"],
            defaultPageSize: 100,
            current: currentPage,
          }}
          footer={() => distributionRulesActivityLog.length>0?
             'Showing ' + ((currentPage - 1) * pageSize + 1) + ' to ' + ((currentPage - 1) * pageSize + distributionRulesActivityLog?.length) + ' of ' + logsCount + ' entries'
            : 'Showing 0 entries'
          }
					/>
			</div>
		</div>
	);
};

export default DistributionRulesLogs;