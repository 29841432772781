import { DatePicker, Space, Tooltip, Button, Select, Table, Modal, Input, Typography, message, Spin, Form, Image } from 'antd';
import { SearchOutlined, EyeOutlined, FileExcelOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import moment from 'moment';
import constants from '../../constants/constants';
import dayjs from 'dayjs';
import { async } from 'q';
import APIUtils from '../../api/APIUtils';
import WebLinks from '../../api/WebLinks';
import { DownloadExcel, GetSearchFilteredData, GetTodaysDateString, downloadFiles } from '../../globalFunctions/GlobalFunctions';
import Cookies from 'js-cookie';
const { RangePicker } = DatePicker;
const { Search } = Input;
const { Link } = Typography;
const { Option } = Select;

const DailyReport = (props) => {

	//Variable declaration
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [rangePickerValue, setRangePickerValue] = useState([]);
	const [allowedSupplierList, setAllowedSupplierList] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [dailyReportData, setDailyReportData] = useState([]);
	const [loader, setLoader] = useState(false);
	const [totalDailyCount, setTotalDailyCount] = useState(0);
	const [detailedDailyReportData, setDetailedDailyReportData] = useState([]);
	const [detailedDailyReportTableData, setDetailedDailyReportTableData] = useState([]);
	const [dateSelected, setDateSelected] = useState("");
	const [dailyReportTotal, setDailyReportTotal] = useState([]);
	const [suplierListLoader,setSuplierListLoader]= useState(false);
	const [supplierids,setSupplierIds]= useState("");
	const [form] = Form.useForm();

	// Expanded Table
	const expandedTableColumns = [
		{
			title: 'Supplier',
			dataIndex: 'partnerName',
			key: 'partnerName',
			render: (_, record) => {
				return (
					<>
						{record.logoURL && record.logoURL !== "" ?
							<Image src={record.logoURL} preview={false} width={100} /> :
							<span>{record.partnerName}</span>
						}
					</>
				)

			}
		},
		{
			title: 'Applies',
			dataIndex: 'applies',
			key: 'applies',
			align: 'center'
		},
		{
			title: 'Cost',
			dataIndex: 'totalCost',
			key: 'totalCost',
			align: 'center'
		},
		{
			title: 'Cost/Applies',
			dataIndex: 'totalCostApplies',
			key: 'totalCostApplies',
			align: 'center'
		},
		{
			title: 'Quality',
			dataIndex: 'quality',
			key: 'quality',
			align: 'center',
			sorter: (a, b) => a.quality - b.quality,
			render: (_, record) => {
				return (
					<>
						<span>{record.quality + " "}</span><span>({record.qualityPer}%)</span>
					</>
				)

			}
		},
		{
			title: 'Cost/Quality',
			dataIndex: 'costQuality',
			key: 'costQuality',
			align: 'center',
			sorter: (a, b) => a.costQuality - b.costQuality,
		},
		{
			title: 'Interviews',
			dataIndex: 'interview',
			key: 'interview',
			align: 'center',
			sorter: (a, b) => a.interview - b.interview,
			render: (_, record) => {
				return (
					<>
						<span>{record.interview + " "}</span><span>({record.interviewPer}%)</span>
					</>
				)

			}
		},
		{
			title: 'Cost/Interview',
			dataIndex: 'costInterview',
			key: 'costInterview',
			align: 'center',
			sorter: (a, b) => a.costInterview - b.costInterview,
		},
		{
			title: 'Hires',
			dataIndex: 'hired',
			key: 'hired',
			align: 'center',
			sorter: (a, b) => a.hired - b.hired,
			render: (_, record) => {
				return (
					<Space size={3}>
						<span>{record.hired + " "}</span><span>({record.hiredPer}%)</span>
					</Space>
				)

			}
		},
		{
			title: 'Cost/Hire',
			dataIndex: 'costHired',
			key: 'costHired',
			align: 'center',
			sorter: (a, b) => a.costHired - b.costHired,
		}
	];
	const expandedTableData = [
		{
			key: 1,
			supplier: 'JobSwipeUKCPA',
			applies: 2404,
			cost: '1442.4',
			costapplies: '0.6',
			qualityfails: 18,
			otherfails: 0,
			exists: 190,
			emailed: 158,
			clicked: 58,
		},
	]; const sorter = (a, b) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);
	// Main Table Below
	const columns = [
		{
			title: 'Day',
			dataIndex: 'reportDate',
			key: 'reportDate',
			sorter: (a, b) => sorter(a.reportDate, b.reportDate),
			render: (_, object) => {
				return moment(object.reportDate).format('YYYY-M-D');
			}
		},
		{
			title: 'Applies',
			dataIndex: 'applies',
			key: 'applies',
			align: 'center',
			sorter: (a, b) => a.applies - b.applies,
		},
		{
			title: 'Cost',
			dataIndex: 'totalCost',
			key: 'totalCost',
			align: 'center',
			sorter: (a, b) => a.totalCost - b.totalCost,
		},
		{
			title: 'Cost/Applies',
			dataIndex: 'totalCostApplies',
			key: 'totalCostApplies',
			align: 'center',
			sorter: (a, b) => a.totalCostApplies - b.totalCostApplies,
		},
		{
			title: 'Quality',
			dataIndex: 'quality',
			key: 'quality',
			align: 'center',
			sorter: (a, b) => a.quality - b.quality,
			render: (_, record) => {
				return (
					<>
						<span>{record.quality + " "}</span><span>({record.qualityPer}%)</span>
					</>
				)

			}
		},
		{
			title: 'Cost/Quality',
			dataIndex: 'costQuality',
			key: 'costQuality',
			align: 'center',
			sorter: (a, b) => a.costQuality - b.costQuality,
		},
		{
			title: 'Interviews',
			dataIndex: 'interview',
			key: 'interview',
			align: 'center',
			sorter: (a, b) => a.interview - b.interview,
			render: (_, record) => {
				return (
					<>
						<span>{record.interview + " "}</span><span>({record.interviewPer}%)</span>
					</>
				)

			}
		},
		{
			title: 'Cost/Interview',
			dataIndex: 'costInterview',
			key: 'costInterview',
			align: 'center',
			sorter: (a, b) => a.costInterview - b.costInterview,
		},
		{
			title: 'Hires',
			dataIndex: 'hired',
			key: 'hired',
			align: 'center',
			sorter: (a, b) => a.hired - b.hired,
			render: (_, record) => {
				return (
					<Space size={3}>
						<span>{record.hired + " "}</span><span>({record.hiredPer}%)</span>
					</Space>
				)

			}
		},
		{
			title: 'Cost/Hire',
			dataIndex: 'costHired',
			key: 'costHired',
			align: 'center',
			sorter: (a, b) => a.costHired - b.costHired,
		}
		// {
		// 	title: 'View',
		// 	key: 'view',
		// 	align: 'center',
		// 	render: (_, object) =>
		// 		<Tooltip title="View">
		// 			<Button size='small' type="link" onClick={() => GetDetailedDailyReportData(object.reportDate)}>
		// 				<EyeOutlined />
		// 			</Button>
		// 		</Tooltip>,
		// },
	];
	const downloadReportColumns = [
		{
			title: 'IP Address',
			dataIndex: 'userIpAddress',
			key: 'userIpAddress'
		},
		{
			title: 'Status',
			dataIndex: 'description',
			key: 'description',
		},
		{
			title: 'Date',
			dataIndex: 'createdOn',
			key: 'createdOn',
		},
		{
			title: 'Job ID',
			key: 'jobId',
			dataIndex: 'jobId',
		},
		{
			title: 'Job Title',
			key: 'jobTitle',
			dataIndex: 'jobTitle',
		},
		{
			title: 'Job Location',
			key: 'jobLocation',
			dataIndex: 'jobLocation',
		},
		{
			title: 'Job Country Code',
			key: 'jobCountryCode',
			dataIndex: 'jobCountryCode',
		},
		{
			title: 'Job URL',
			key: 'jobUrl',
			dataIndex: 'jobUrl',
			render: (text) => <Link href={text} target="_blank" copyable>{text}</Link>,
		},
	];

	//On load function call
	useEffect(() => {
		if (props && props.dateSelectedValue) {
			form.setFieldsValue({
				rangePicker: [dayjs(props.dateSelectedValue[0]), dayjs(props.dateSelectedValue[1])],
			})
		} else {
			form.setFieldsValue({
				rangePicker: [dayjs(), dayjs()],
			})
		}
		setCurrentPage(1);
		setPageSize(100);
		getAllowedSuppliers();
		setRangePickerValue(props && props.dateSelectedValue ? props.dateSelectedValue : [dayjs().format(constants.MonthFormat), dayjs().format(constants.MonthFormat)]);
		GetOccupationsList();
		GetDailyReportData(1, 100, props && props.dateSelectedValue ? props.dateSelectedValue[0] : dayjs().format(constants.MonthFormat), props && props.dateSelectedValue ? props.dateSelectedValue[1] : dayjs().format(constants.MonthFormat));
	}, [props]);

	//Functions
	//Modal Functions
	const showModal = () => {
		setIsModalOpen(true);
	};
	const handleOk = () => {
		setIsModalOpen(false);
	};
	const handleCancel = () => {
		setIsModalOpen(false);
	};

	//Function runs when range picker value is changed
	const RangePickerChanged = (_, dateString) => {
		props.changeDate(dateString);
		setRangePickerValue(dateString);
		form.setFieldsValue({
			"supplierList":["showall"]
		})
		setSupplierIds("");
		GetDailyReportData(currentPage, pageSize, dateString[0], dateString[1],supplierids);
	};

	//Function to get occupation options list
	const GetOccupationsList = async () => {
		// const response = await APIUtils.apiCall('GET',WebLinks.GetOccupationOptionsList);
		// if(!response.isCatch && response.resultObj.statusCode==="2000"){
		// 	setOccupationList([])
		// }else{
		// 	setOccupationList([]);
		// 	message.error(response.resultObj.message);
		// }
	}

	//Function to daily report data
	const GetDailyReportData = async (currentPage, pazeSize, startDate, endDate,suplierIds="") => {
		setLoader(true);
		const response = await APIUtils.apiCall('GET', WebLinks.GetApplicationReport + "/" + Cookies.get(constants.COOKIE_SELECTED_BUYER_ID) + "?fromData=" + startDate + "&toDate=" + endDate + "&limit=100&pageNo=1"+"&supplierId="+suplierIds);
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			//Start: Added unique key to fix all inner tables opening together
			response.resultObj.data.dayReport.map((item, index) => {
				item.key = index;
			})
			//end
			setDailyReportTotal(response.resultObj.data.dayReport.pop());
			setDailyReportData(response?.resultObj?.data?.dayReport||[]);
			setTotalDailyCount(response.resultObj.data.dayReportCount);
			setLoader(false);
		} else {
			setDailyReportData([]);
			setLoader(false);
			message.error(response.resultObj.message);
		}
	}

	//Function to detailed daily report data
	const GetDetailedDailyReportData = async (passedDate) => {
		setLoader(true);
		setDateSelected(dayjs(passedDate).format(constants.DateFormat));
		const response = await APIUtils.apiCall('GET', WebLinks.GetApplicationDailyDteailedReport + "/" + Cookies.get(constants.COOKIE_SELECTED_BUYER_ID) + "?Date=" + passedDate);
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			setDetailedDailyReportData(response.resultObj.data);
			setDetailedDailyReportTableData(response.resultObj.data);
			showModal();
			setLoader(false);
		} else {
			setDetailedDailyReportData([]);
			setLoader(false);
			message.error(response.resultObj.message);
		}
	}

	//Funcion to render inner table
	const getInnerTable = (tableData) => {
		return (
			<Table
				bordered
				size="small"
				columns={expandedTableColumns}
				dataSource={tableData}
				pagination={{
					defaultPageSize: 100,
					pageSizeOptions: ["100", "250", "500"],
				}}
			/>
		)
	}

	//Function on change of application table
	const onchangeApplicationTable = (pagination, filters, sorter) => {
		if (pagination) {
			if (pageSize !== pagination.pageSize) {
				setCurrentPage(1);
			} else {
				setCurrentPage(pagination?.current);
			}
			setPageSize(pagination?.pageSize);
			GetDailyReportData(pagination?.current, pagination?.pageSize, rangePickerValue[0], rangePickerValue[1],supplierids)
		}
	};

	//Function to Search
	const filterDataSearch = (e) => {
		const filteredData = GetSearchFilteredData(e, detailedDailyReportData);
		setDetailedDailyReportTableData(filteredData);
	};

	//Function to display the daily total in the table
	const dailyReportSummary = () => {
		return (
			<>

				<Table.Summary.Row className='bg-teal-50'>
					<Table.Summary.Cell colSpan={2} align='center' index={0}>
						<strong>Total</strong>
					</Table.Summary.Cell>

					<Table.Summary.Cell index={2} className='text-center'>
						<strong>{dailyReportTotal.applies}</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={3} className='text-center'>
						<strong>{dailyReportTotal.totalCost}</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={4} className='text-center'>
						<strong>{dailyReportTotal.totalCostApplies}</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={5} className='text-center'>
						<strong>{dailyReportTotal.quality}</strong><strong>({dailyReportTotal.qualityPer}%)</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={6} className='text-center'>
						<strong>{dailyReportTotal.costQuality}</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={7} className='text-center'>
						<strong>{dailyReportTotal.interview}</strong><strong>({dailyReportTotal.interviewPer}%)</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={8} className='text-center'>
						<strong>{dailyReportTotal.costInterview}</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={9} className='text-center'>
						<strong>{dailyReportTotal.hired + " "}</strong><strong>({dailyReportTotal.hiredPer}%)</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={10} className='text-center'>
						<strong>{dailyReportTotal.costHired}</strong>
					</Table.Summary.Cell>
					{/* <Table.Summary.Cell index={10} className='text-center'>
						<strong></strong>
					</Table.Summary.Cell> */}

				</Table.Summary.Row>
			</>
		)
	};

	//Function to get Allowed suppliers list
	const getAllowedSuppliers =async()=>{
		setSuplierListLoader(true);
		const response = await APIUtils.apiCall('GET',WebLinks.GetAllowedSuppliers+Cookies.get(constants.COOKIE_SELECTED_BUYER_ID));
		if(!response.isCatch && response.resultObj.statusCode==="2000"){
			setAllowedSupplierList(response?.resultObj?.data||[])
			setSuplierListLoader(false);
		}else{
			setAllowedSupplierList([]);
			setSuplierListLoader(false);
			message.error(response.resultObj.message);
		}
	}

	//Function on change of supplier selection
	const handleSupplierSelect=(selectedSuppliers)=>{
		if(selectedSuppliers.length>0){
			let tempSelectedSuppliers=selectedSuppliers.filter(value => value !== 'showall');
			let selectedSuppliersFinal=tempSelectedSuppliers.length>0?tempSelectedSuppliers:"";
			form.setFieldsValue({
				supplierList:selectedSuppliersFinal
			})
			setSupplierIds(selectedSuppliersFinal);
			GetDailyReportData(currentPage, pageSize,  rangePickerValue[0], rangePickerValue[1],selectedSuppliersFinal);
		}else{
			form.setFieldsValue({
				supplierList:["showall"]
			})
			setSupplierIds("");
			GetDailyReportData(currentPage, pageSize,  rangePickerValue[0], rangePickerValue[1]);
		}
	}

	const downloadFile = () => {
		setLoader(true)
		let url=WebLinks.DownloadApplicationDayReport+Cookies.get(constants.COOKIE_SELECTED_BUYER_ID) + `&fromDate=${dayjs(rangePickerValue[0]).format(constants.MonthFormat)}&toDate=${dayjs(rangePickerValue[1]).format(constants.MonthFormat)}`
		if(supplierids.length>0){
			url= url+"&selectedSupplierId="+supplierids
		}
		downloadFiles(url,"ApplicationDayReport.xlsx");
		setTimeout(() => {
				setLoader(false)
		}, 3000);
	};

	return (
		<>
			<Spin spinning={loader}>
				<div>
					<div >
						{/* <Space > */}
							<Form form={form} className='!w-full flex flex-wrap gap-3 justify-between items-center mb-3'>
								<Form.Item name="rangePicker" className='mb-0'>
									<RangePicker picker="month" onChange={RangePickerChanged} format={constants.MonthFormat} />
								</Form.Item>
								<Space wrap>
								<label>Filter by Supplier</label>
								<Form.Item name="supplierList" className='mb-0'>
									<Select
										loading={suplierListLoader}
										className='!w-72'
										// value={selectedSupplier || []}
										placeholder={constants.supplierName}
										mode='multiple'
										allowClear
										defaultValue={"showall"}
										showSearch
									filterOption={(input, option) =>
											option.props.value.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
											option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
										onChange={(e) => handleSupplierSelect(e)}
										>
											<Option key='showall' value='showall'>Show all</Option>
												{allowedSupplierList?.map((supplier) => (
													<Option key={supplier.supplierId}
															value={supplier.supplierId}
													>
													{supplier.name}</Option>
										))}
									</Select>
								</Form.Item>
								<Tooltip placement='right' title="Download as Excel">
								<Button
										className='bg-green-800 text-white hover:!text-white hover:!border-green-500'
										icon={<FileExcelOutlined />}
										onClick={()=>{downloadFile()}}
								>
									Download as Excel
								</Button>
								</Tooltip>
								</Space>
							</Form>
							<Tooltip title="search">
								{/* <Button type="primary" icon={<SearchOutlined />} /> */}
							</Tooltip>
						{/* </Space> */}
						{/* <Space wrap>
						<label>Filter by Occupation</label>
						<Select
							className='w-72'
							mode="multiple"
							allowClear
							maxTagCount='responsive'
							defaultValue="allOccupation"
							options={[
								{
									value: 'allOccupation',
									label: 'Show All',
								},
								// {
								// 	value: 'Nurses',
								// 	label: 'Nurses',
								// },
							]}
						/>
					</Space> */}
					</div>
					<div className='overflow-x-auto'>
						<Table
							columns={columns}
							bordered
							sortDirections={['descend', 'ascend', 'descend']}
							expandable={{
								expandedRowRender: (record) => (<div>
									{getInnerTable(record.dayReport)}
								</div>)
							}}
							dataSource={dailyReportData}
							// onChange ={onchangeApplicationTable}
							pagination={{
								total: totalDailyCount,
								// showSizeChanger: true,
								pageSizeOptions: ["100", "250", "500"],
								defaultPageSize: 100,
								// current: currentPage,
							}}
							summary={() => dailyReportSummary()}
						/>
					</div>
					{/* View full report modal */}
					<Modal
						title='Download report'
						open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
						style={{ top: 20 }}
						width={1000}
						footer={null}
					>
						{detailedDailyReportData &&
							<>
								<div className='flex justify-between items-center mb-3'>
									<Tooltip placement='right' title="Download as Excel">
										<Button
											className='bg-green-800 text-white hover:!text-white hover:!border-green-500'
											icon={<FileExcelOutlined />}
											onClick={() => DownloadExcel(
												downloadReportColumns,
												detailedDailyReportTableData,
												"ApplicationReport-" + dateSelected + "(" + GetTodaysDateString() + ")",
												"report"
											)}
										/>
									</Tooltip>
									<Input.Search
										placeholder="Search"
										allowClear
										//onSearch={onSearch}
										onChange={(e) => filterDataSearch(e.target.value)}
										enterButton
										className='w-60'
									/>
								</div>
								<Table columns={downloadReportColumns} dataSource={detailedDailyReportTableData} scroll={{ y: 300, }} />
							</>
						}
					</Modal>
				</div>
			</Spin>
		</>
	);
};

export default DailyReport;